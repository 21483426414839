import debounce from 'debounce-promise';
import awsExports from '../../../aws-exports';
import { getClient, getStaffUsersClient } from '../../../search/algolia';

const indexNames = {
  offers: `offer-${awsExports.stackeryEnvironmentName}`,
  users: `users-${awsExports.stackeryEnvironmentName}`,
  policy: `policysummary-${awsExports.stackeryEnvironmentName}`,
  staffUsers: `staff-users-${awsExports.stackeryEnvironmentName}`
};

/**
 * This function mantains a cache of debounced function based on the provided key, in the case of algolia search,the searchIndex
 *
 * The point of this is to debounce only similar request.
 * This was done in response to a bug that Dave reported which was as a result of totally
 * different request getting debounced and essentially returning the same data.
 * For example, parallel request to query offer and customer only triggers a single network request
 *
 * @param {*} debuncedFunc
 * @param {*} timeOut
 */
const debounceWithArgs = (debuncedFunc, timeOut) => {
  const debouncedFuncs = {};

  return (key, ...args) => {
    if (!(key in debouncedFuncs)) debouncedFuncs[key] = debounce(debuncedFunc, timeOut);

    return debouncedFuncs[key](key, ...args);
  };
};

// Explanation of how the debounce works: https://github.com/bjoerge/debounce-promise/blob/d85e8d1298e8789af13afa8267517b49a81d95d8/README.md
export const search = debounceWithArgs(async (indexName, searchParams, apiKey) => {
  const client = await (indexName === indexNames.staffUsers ? getStaffUsersClient() : getClient(apiKey));

  return client ? client.initIndex(indexName).search(searchParams) : [];
}, 500);

export const runSearch = async ({ searchString, index, page, hitsPerPage = 10, expandedSearch }, apiKey) => {
  const indexName = indexNames[index];

  const searchParameters = {
    query: searchString,
    hitsPerPage,
    page
  };

  if (index === 'offers') {
    searchParameters.filters = '(code:S OR code:C)';
    searchParameters.restrictSearchableAttributes = [
      'quote.drivers.firstName',
      'quote.drivers.lastName',
      'quote.fname',
      'quote.lname',
      'quote.submittedAddress.address',
      'quote.submittedAddress.city',
      'quote.submittedAddress.state',
      'quote.submittedAddress.zip',
      'quote.global.affinity',
      'quote.rep'
    ];
    if (expandedSearch) {
      searchParameters.restrictSearchableAttributes = searchParameters.restrictSearchableAttributes.concat([
        'quote.email',
        'quote.phone',
        'webUserId'
      ]);
    }
  }

  if (index === 'users') {
    searchParameters.restrictSearchableAttributes = [
      'Username',
      'attributes.address',
      'attributes.custom_state',
      'attributes.custom_city',
      'attributes.custom_zip',
      'attributes.name',
      'attributes.family_name'
    ];

    if (expandedSearch) {
      searchParameters.restrictSearchableAttributes = searchParameters.restrictSearchableAttributes.concat([
        'attributes.phone_number',
        'attributes.custom_addl_phone_numbers',
        'attributes.email'
      ]);
    }
  }

  const algoliaResults = await search(indexName, searchParameters, apiKey);
  return algoliaResults;
};
