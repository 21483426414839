import React, { useCallback, useContext } from 'react';
import { useFormikContext } from 'formik';
import { useHistory } from 'react-router-dom';
import { lookupsJson, rentersPolicyAvailable } from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import switchIcon from 'core/assets/svg/switch.svg';
import { useStore } from 'core/store';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { CardLine } from '../../card-line';
import useStyles from './toggle-renter-button.styles';

const ToggleRentersButton = () => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const session = useContext(AuthContext);
  const { values, setValues, handleSubmit, validateForm, setErrors } = useFormikContext();
  const isRenters = store.offer.options.some((opt) => opt.type === 'AR');
  const history = useHistory();
  const allowLicensedActions = store?.getIsLicensedForState(session);
  const state = values?.home?.homeLocation?.state;

  const toggleRenters = useCallback(() => {
    /*
    [dependency] isRenters notes if we are CURRENTLY on renters. If true, we want includeRenters to be false.

    if we don't validate first, you could get stuck in an invalid state
    ex: we have errors in home details, we switch to auto-renters (which is disabled),
    and we can't submit or fix the errors because the home details inputs are hidden
    */

    /*
    Toggling on renters while home payment method is mortgage while having a blank mortgage (due to the offer
    being updated without mortgage details) results in some errors, so the below logic is to replace that payment method
    with credit card, which makes more sense for Renters. Also, adds other values needed for Renters.
    */
    const rentersValues = {
      ...values,
      global: { ...values.global, homeownersPaymentMethod: lookupsJson?.homeownersPaymentMethod?.[0]?.id || 'C' },
      includeRenters: !isRenters,
      isHomeOwner: isRenters,
      selectedOption: isRenters ? 'HA' : 'AR'
    };
    validateForm(rentersValues)
      .then(() => {
        // @note we should probably re think this approach, as it doesn't seem to be scalable once we expand the options into new policies ie live, pets, etc
        setValues(rentersValues);
        store.swapHomeRentersOptions(history);
      })
      .catch((errors) => {
        setErrors(errors);
        throw errors;
      });
  }, [isRenters, handleSubmit, setErrors, validateForm, setValues, values, store, history]);

  const disableSwitching =
    isRenters &&
    (store?.offer?.quote?.offerings?.homeownersRejectCode || !store?.offer?.quote?.offerings?.offerHomeowners);

  if (!store.offer.quote.offerings.offerAuto) {
    return null;
  }
  return (
    <CardLine>
      {rentersPolicyAvailable[state] ? (
        <Button
          className={classes.button}
          color="secondary"
          variant="text"
          onClick={toggleRenters}
          disabled={!allowLicensedActions || disableSwitching}
        >
          <img src={switchIcon} alt="arrows" />
          {`Switch To ${isRenters ? 'Home Owners' : 'Renters'} Options`}
        </Button>
      ) : (
        <Label type="policyLabel" className={classes.disabled}>
          Renters Coverage Not Available In This State
        </Label>
      )}
    </CardLine>
  );
};

export default ToggleRentersButton;
