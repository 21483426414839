import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  notificationText: {
    lineHeight: 1.4
  },
  notificationSubtitleText: {
    lineHeight: 1.4,
    textTransform: 'uppercase',
    fontSize: 10
  }
});

export default makeStyles(styles);
