import React from 'react';
import Grid from '@material-ui/core/Grid';

import { Card } from 'core/components/card';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import ValueField from 'core/components/value-field';
import useStyles from './policy-preview-refund-notification.styles';
import NotificationIconOrange from 'core/assets/svg/info-orange.svg';
import { paymentMethod } from '@ourbranch/lookups';

const PolicyPreviewRefundNotification = ({ preview }) => {
  const classes = useStyles();
  const {
    account: {
      policies: {
        policy: {
          billingDetails: {
            activePaymentMethod: { brand, last4, bankName }
          },
          policy: { paymentMethod: policyPaymentMethod }
        }
      }
    }
  } = useStore();

  const { skipImmediateBillOrRefund } = preview;

  const paymentInfo = () => {
    if (preview.billingDescription.includes('via check')) {
      // if we switch from one cc/ach to escrow, store isn't aware yet, so if this is in the description dont show payment data
      return null;
    }
    if (policyPaymentMethod === paymentMethod.CreditCard) {
      return ` to credit card ${brand} ****${last4}`;
    }
    if (policyPaymentMethod === paymentMethod.ACH) {
      return ` to bank account ****${last4} ${bankName}`;
    }
  };

  return (
    <Card className={classes.cardContainer}>
      <Grid className={classes.columnContainer}>
        <Grid className={classes.iconContainer}>
          <img src={NotificationIconOrange} className={classes.iconSmall} alt="Notification Icon" />
          <p className={classes.boldText}>This policy requires a refund</p>
        </Grid>
        <ValueField
          label="Billing Description"
          value={
            <div className={classes.inline}>
              {preview?.billingDescription}{' '}
              {!skipImmediateBillOrRefund && policyPaymentMethod !== paymentMethod.Escrow && paymentInfo()}
            </div>
          }
        />

        {skipImmediateBillOrRefund && (
          <Label type="greenExtraSmall">
            Unless this is an extenuating circumstance, please go back and UNCHECK the &quot;Delay Payment/Refund&quot;
            option.
          </Label>
        )}
      </Grid>
    </Card>
  );
};

export default PolicyPreviewRefundNotification;
