import gql from 'graphql-tag';

export const GET_BILLING_DETAILS = gql`
  query ($id: ID!, $accountId: ID!) {
    billingDetails: getBillingDetails(accountId: $accountId, policyId: $id) {
      nextPaymentDate
      nextPaymentAmount
      activePaymentMethod {
        id
        brand
        last4
        bankName
      }
      transactions {
        id
        paymentAmount
        paymentRefunded
        paymentDate
        paymentMethod
        paymentMethodBrand
        paymentMethodLast4
        paymentStatus
        internalDescription
      }
    }
  }
`;

export const MODIFY_CREDIT_CARD = gql`
  mutation ($policyId: ID!, $accountId: ID!, $basisTheoryCardToken: String!) {
    creditCard: modifyCreditCard(
      accountId: $accountId
      policyId: $policyId
      basisTheoryCardToken: $basisTheoryCardToken
    ) {
      last4
      brand
    }
  }
`;

export const ADD_CREDIT_CARD = gql`
  mutation ($policyId: ID!, $accountId: ID!, $basisTheoryCardToken: String!) {
    creditCard: addCreditCard(accountId: $accountId, policyId: $policyId, basisTheoryCardToken: $basisTheoryCardToken) {
      id
      last4
      brand
    }
  }
`;

export const RUN_MANUAL_CHARGE = gql`
  mutation (
    $policyId: ID!
    $accountId: ID!
    $paymentMethod: String!
    $amount: Float!
    $internalDescription: String!
    $billType: String!
  ) {
    runManualCharge(
      policyId: $policyId
      accountId: $accountId
      paymentMethod: $paymentMethod
      amount: $amount
      internalDescription: $internalDescription
      billType: $billType
    ) {
      last4
      type
      success
      error
    }
  }
`;
