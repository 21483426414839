import { makeStyles } from '@material-ui/core/styles';

const styles = () => ({
  container: {
    width: 720,
    margin: '0 auto',
    textAlign: 'center',
    position: 'relative'
  },
  title: {
    justifyContent: 'center',
    marginBottom: 40
  },
  submit: {
    marginTop: 32,
    width: 260,
    fontWeight: 600,
    fontSize: 16
  },
  warning: {
    paddingTop: 20
  }
});

export default makeStyles(styles);
