import React from 'react';
import { lookupsJson } from '@ourbranch/lookups';

import Field from 'core/components/form/form.v2';
import AddressAutocomplete from 'core/components/address-autocomplete';
import { useDisabledState } from 'common/disabled-context/context';
import useStyles from './renters-address-form.styles';

const RentersAddressForm = () => {
  const classes = useStyles();
  const { disabled } = useDisabledState();

  return (
    <div className={classes.content}>
      <AddressAutocomplete
        mode="light"
        name="rentersCoverage.rentersLocation.address"
        id="rentersCoverage.rentersLocation.address"
        label="Address*"
        xs={12}
        fast={false}
        disabled={disabled}
      />
      <Field
        mode="light"
        name="rentersCoverage.rentersLocation.address2"
        id="rentersCoverage.rentersLocation.address2"
        type="string"
        label="Floor, unit, building, etc"
        xs={12}
        className={classes.addressFormField}
      />
      <Field
        mode="light"
        name="rentersCoverage.rentersLocation.city"
        id="rentersCoverage.rentersLocation.city"
        type="string"
        label="City*"
        xs={4}
        className={classes.addressFormField}
      />
      <Field
        mode="light"
        name="rentersCoverage.rentersLocation.state"
        id="rentersCoverage.rentersLocation.state"
        type="autocomplete"
        label="State*"
        options={lookupsJson.usStates}
        xs={4}
        className={classes.addressFormField}
        stateSelectForm
        disabled
      />
      <Field
        mode="light"
        name="rentersCoverage.rentersLocation.zip"
        id="rentersCoverage.rentersLocation.zip"
        type="string"
        label="Zip Code*"
        xs={4}
        className={classes.addressFormField}
      />
    </div>
  );
};

export default RentersAddressForm;
