import React, { createContext, useState, useContext } from 'react';
import PropTypes from 'prop-types';

const DisableContext = createContext(true);

export const DisableProvider = ({ children }) => {
  const [disabled, setDisabled] = useState(false);
  return <DisableContext.Provider value={{ disabled, setDisabled }}>{children}</DisableContext.Provider>;
};

export const useDisabledState = () => {
  const { disabled, setDisabled } = useContext(DisableContext);
  return { disabled, setDisabled };
};

export const withDisabledState = (Component) => (props) => {
  const { disabled, setDisabled } = useContext(DisableContext);
  return <Component {...props} disabled={disabled} setDisabled={setDisabled} />;
};

DisableProvider.propTypes = {
  children: PropTypes.node.isRequired
};
