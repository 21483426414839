import gql from 'graphql-tag';

export const GET_ADDITIONAL_OFFER_DATA = gql`
  query ($webUserId: ID!) {
    getAdditionalOfferData(webUserId: $webUserId) {
      webUserId
      termLifeLastSubmitted
    }
  }
`;

export const SET_ADDITIONAL_OFFER_DATA = gql`
  mutation setAdditionalOfferData($additionalOfferData: AdditionalOfferDataInput!) {
    setAdditionalOfferData(additionalOfferData: $additionalOfferData) {
      webUserId
      termLifeLastSubmitted
    }
  }
`;
