import React from 'react';
import PropTypes from 'prop-types';

import { Label } from 'core';
import { LabelTooltip } from 'core/components/label-tooltip';
import { dateFormatter, numberFormatter } from 'core/helpers/formatters';
import useStyles from './view-schedule.styles';

const ViewSchedule = ({ nextPayments, inline }) => {
  const classes = useStyles();
  return nextPayments.some(({ date }) => date) ? (
    <LabelTooltip
      labelClassName={inline ? classes.inline : undefined}
      tooltipClassName={classes.tooltipLabel}
      tooltip={{
        label: 'View Schedule',
        onHoverText: (
          <>
            <Label type="titleDollarLabel" className={classes.paymentSchedules}>
              Payment schedule:
            </Label>
            <div>
              {nextPayments.map(({ date, remainingMonthly }, i) =>
                date ? (
                  <div key={i}>
                    <Label type="titleDollarLabel" className={classes.tooltip}>{`$${numberFormatter(
                      remainingMonthly,
                      2
                    )} on ${dateFormatter(date)}`}</Label>
                  </div>
                ) : null
              )}
            </div>
          </>
        )
      }}
      placement="bottom"
    />
  ) : null;
};

ViewSchedule.propTypes = {
  inline: PropTypes.bool,
  nextPayments: PropTypes.array.isRequired
};

ViewSchedule.defaultProps = {
  inline: false
};

export default ViewSchedule;
