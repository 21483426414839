import { makeStyles } from '@material-ui/core/styles';

export const styles = (theme) => ({
  cardElementStyle: {
    base: {
      fontSize: 16,
      fontFamily: '"Libre Franklin", sans-serif',
      color: theme.colorPalette.green_10,
      fontWeight: 400,
      backgroundColor: theme.palette.common.white,
      '::placeholder': {
        fontSize: 16,
        color: theme.colorPalette.grey_10_op_38
      }
    },
    invalid: {
      color: theme.palette.error.main
    }
  }
});

const materialStyles = (theme) => ({
  '@global': {
    '#CARD_ELEMENT_ID': {
      backgroundColor: theme.palette.common.white,
      height: 36,
      padding: theme.spacing(1, 2),
      display: 'flex',
      alignItems: 'center'
    }
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2)
  },
  errorMessage: {
    fontSize: 11,
    color: theme.palette.error.main
  },
  error: {
    color: theme.palette.error.main
  }
});

export default makeStyles(materialStyles);
