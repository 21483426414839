import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import {
  paymentMethod as PaymentMethods,
  paymentType as PaymentTypes,
  standaloneRentersStates
} from '@ourbranch/lookups';

import { Label } from 'core';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import getDetailedPrice from 'offer/helpers/detailed-price';
import { getPolicyType } from 'core/helpers/policy-type';
import { nthDayFormatter, currencyFormatter } from 'core/helpers/formatters';
import useStyles from './payments-disclosures.styles';

const MortgageDisclosure = ({ mortgageDetails, amount }) => (
  <>
    invoice your mortgage lender <strong>{mortgageDetails.mortgageHolderName}</strong> in the amount of{' '}
    <strong>{amount}</strong> for your first policy term premium, and for the full premium of each future renewal term.
    You also authorize Branch Insurance to share information about your policy with your mortgage company as needed.{' '}
    <br />
    <br />
    We will contact you if your mortgage company fails to pay, and we may require you to provide further authorization
    as needed.
  </>
);

const CreditCardDisclosure = ({
  isMonthly,
  cardLast4,
  effectiveDate,
  amount,
  billingDayOfMonth,
  remainingPaymentsAmount
}) => (
  <>
    charge your <strong>{cardLast4 ? `credit card ending in ${cardLast4} ` : '(Please fill credit card info) '}</strong>
    for the amount of{' '}
    <strong>
      {amount} on {effectiveDate}
    </strong>{' '}
    {isMonthly && (
      <>
        for the initial payment of your policy and{' '}
        <strong>
          {remainingPaymentsAmount} on the {nthDayFormatter(billingDayOfMonth)} of each month
        </strong>{' '}
        for your current policy term. You also authorize Branch Insurance to charge this method of payment on the{' '}
        <strong>{nthDayFormatter(billingDayOfMonth)} of each month</strong> for each subsequent policy renewal. You will
        receive an email reminder 24-hours in advance of your payments and you can change your method of payment at any
        time through your Branch account or on our mobile app.
      </>
    )}
    {!isMonthly && (
      <>
        for the full amount of your current policy term and for the full amount of each subsequent policy renewal. You
        will receive an email reminder 24-hours in advance of your payments, and you can change your method of payment
        at any time through your Branch account or on our mobile app.
      </>
    )}
  </>
);

const ACHDisclosure = ({ isMonthly, effectiveDate, amount, billingDayOfMonth, remainingPaymentsAmount }) => (
  <>
    debit your account for the amount of{' '}
    <strong>
      {amount} on {effectiveDate}
    </strong>{' '}
    {isMonthly && (
      <>
        for the initial payment of your policy and{' '}
        <strong>
          {remainingPaymentsAmount} on the {nthDayFormatter(billingDayOfMonth)} of each month
        </strong>{' '}
        for your current policy term. You also authorize Branch Insurance to charge this method of payment on the{' '}
        <strong>{nthDayFormatter(billingDayOfMonth)} of each month</strong> for each subsequent policy renewal. You will
        receive an email reminder 24-hours in advance of your payments, and you can change your method of payment at any
        time through your Branch account or on our mobile app.
      </>
    )}
    {!isMonthly && (
      <>
        for the full amount of your current policy term and for the full amount of each subsequent policy renewal. You
        will receive an email reminder 24-hours in advance of your payments, and you can change your method of payment
        at any time through your Branch account or on our mobile app.
      </>
    )}
  </>
);

const Disclosure = ({ paymentMethod, ...props }) => (
  <>
    you authorize Branch Insurance to {paymentMethod === PaymentMethods.Escrow && <MortgageDisclosure {...props} />}
    {paymentMethod === PaymentMethods.CreditCard && <CreditCardDisclosure {...props} />}
    {paymentMethod === PaymentMethods.ACH && <ACHDisclosure {...props} />}
  </>
);

const PaymentsDisclosures = ({ policyType, offer }) => {
  const classes = useStyles();
  const { values } = useFormikContext();
  const { hasHome, hasAuto } = getPolicyType(policyType, offer?.quote?.noBindHome, offer?.quote?.noBindAuto);

  const {
    homeownersPaymentMethod,
    homeownersPaymentType,
    homeEffectiveDate,
    homeBillingDayOfMonth,
    homeDownPayment,
    autoPaymentMethod,
    autoPaymentType,
    autoBillingDayOfMonth,
    autoEffectiveDate,
    autoDownPayment,
    rentersPaymentType,
    rentersPaymentMethod,
    rentersEffectiveDate,
    rentersDownPayment,
    rentersBillingDayOfMonth,
    mortgageDetails,
    cardLast4,
    recoveredPaymentData
  } = values;

  const isHomeMonthly = homeownersPaymentType === PaymentTypes.Monthly;
  const isAutoMonthly = autoPaymentType === PaymentTypes.Monthly;
  const isRentersMonthly = rentersPaymentType === PaymentTypes.Monthly;
  const prices = getDetailedPrice(offer, policyType, { isRentersMonthly, isHomeMonthly, isAutoMonthly });

  const commonProps = {
    mortgageDetails,
    cardLast4: cardLast4 || recoveredPaymentData?.creditCard?.card.last4
  };

  const homeProps = {
    ...commonProps,
    paymentMethod: homeownersPaymentMethod,
    isMonthly: isHomeMonthly,
    effectiveDate: homeEffectiveDate,
    billingDayOfMonth: homeBillingDayOfMonth,
    amount: currencyFormatter(homeDownPayment),
    remainingPaymentsAmount: currencyFormatter(prices.homeRemainingPaymentsAmount)
  };

  const autoProps = {
    ...commonProps,
    paymentMethod: autoPaymentMethod,
    isMonthly: isAutoMonthly,
    billingDayOfMonth: autoBillingDayOfMonth,
    effectiveDate: autoEffectiveDate,
    amount: currencyFormatter(autoDownPayment),
    remainingPaymentsAmount: currencyFormatter(prices.autoRemainingPaymentsAmount)
  };

  const rentersProps = {
    ...commonProps,
    paymentMethod: rentersPaymentMethod,
    isMonthly: isRentersMonthly,
    billingDayOfMonth: rentersBillingDayOfMonth,
    effectiveDate: rentersEffectiveDate,
    amount: currencyFormatter(rentersDownPayment),
    remainingPaymentsAmount: currencyFormatter(prices.rentersRemainingPaymentsAmount)
  };

  const disclosureMapper = (type) => {
    switch (type) {
      case 'H': {
        if (hasHome) {
          return (
            <li>
              <>For your home policy, </>
              <Disclosure {...homeProps} />
            </li>
          );
        }
        break;
      }
      case 'A': {
        if (hasAuto) {
          return (
            <li>
              <>For your auto policy, </>
              <Disclosure {...autoProps} />
            </li>
          );
        }
        break;
      }
      case 'R': {
        if (standaloneRentersStates[offer.quote.correctedAddress.state]) {
          return (
            <li>
              <>For your renters policy, </>
              <Disclosure {...rentersProps} />
            </li>
          );
        }
        break;
      }
      default:
        break;
    }
  };

  return (
    <Section title="Payments Disclosures" type="SubSection">
      <Card type="primary">
        <Grid container item xs={12} className={classes.container}>
          <Label type="greenSmall" className={classes.title}>
            Please go through these carefully with the potential member
          </Label>
          <ul className={classes.list}>{policyType.split('').map(disclosureMapper)}</ul>
        </Grid>
        <Grid item xs={12} className={classes.footer}>
          <FormField
            name="authorizePayments"
            type="checkbox"
            label="Insured member authorizes these payments"
            mode="light"
            fast={false}
          />
        </Grid>
      </Card>
    </Section>
  );
};

PaymentsDisclosures.props = {
  policyType: PropTypes.string.isRequired,
  offer: PropTypes.object.isRequired
};

export default PaymentsDisclosures;
