export const carsWithPassiveRestraint = [
  // Acura
  {
    symbolMake: 'AC',
    symbolModel: 'SL',
    yearStarted: '1996',
    yearEnded: '2002'
  },
  // Buick
  {
    symbolMake: 'BU',
    symbolModel: 'CE',
    yearStarted: '1989',
    yearEnded: '1996'
  },
  {
    symbolMake: 'BU',
    symbolModel: 'EL',
    yearStarted: '1985',
    yearEnded: '1990'
  },
  {
    symbolMake: 'BU',
    symbolModel: 'LE',
    yearStarted: '1987',
    yearEnded: '1991'
  },
  {
    symbolMake: 'BU',
    symbolModel: 'RG',
    yearStarted: '1988',
    yearEnded: '1996'
  },
  {
    symbolMake: 'BU',
    symbolModel: 'RV',
    yearStarted: '1986',
    yearEnded: '1993'
  },
  {
    symbolMake: 'BU',
    symbolModel: 'SH',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  {
    symbolMake: 'BU',
    symbolModel: 'SL',
    yearStarted: '1988',
    yearEnded: '1995'
  },
  // Cadillac
  {
    symbolMake: 'CD',
    symbolModel: 'CR',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  // Chevrolet
  {
    symbolMake: 'CH',
    symbolModel: 'AS',
    yearStarted: '1990',
    yearEnded: '1995'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'BE',
    yearStarted: '1987',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'BL',
    yearStarted: '1992',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'CC',
    yearStarted: '1988',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'CR',
    yearStarted: '1987',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'LM',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'MR',
    yearStarted: '1994',
    yearEnded: '2002'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'BL',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    // AKA GMC Suburban
    symbolModel: 'SA',
    yearStarted: '1992',
    yearEnded: '1994'
  },
  // Chrysler
  {
    symbolMake: 'CY',
    symbolModel: 'CR',
    yearStarted: '1995',
    yearEnded: '1995'
  },
  {
    symbolMake: 'CY',
    symbolModel: 'PT',
    yearStarted: '2008',
    yearEnded: '2008'
  },
  // Dodge
  {
    symbolMake: 'DG',
    symbolModel: 'CV',
    yearStarted: '1991',
    yearEnded: '2000'
  },
  {
    symbolMake: 'DG',
    symbolModel: 'GC',
    yearStarted: '1991',
    yearEnded: '2000'
  },
  {
    symbolMake: 'DG',
    symbolModel: 'CT',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'DG',
    symbolModel: 'SH',
    yearStarted: '1994',
    yearEnded: '1994'
  },
  {
    symbolMake: 'DG',
    symbolModel: 'SP',
    yearStarted: '1994',
    yearEnded: '1995'
  },
  {
    symbolMake: 'DG',
    symbolModel: 'ST',
    yearStarted: '1995',
    yearEnded: '1995'
  },
  // Eagle
  {
    symbolMake: 'EA',
    symbolModel: 'SU',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'EA',
    symbolModel: 'TA',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  // Ford
  {
    symbolMake: 'FD',
    symbolModel: 'EE',
    yearStarted: '2001',
    yearEnded: '2007'
  },
  {
    symbolMake: 'FD',
    symbolModel: 'ES',
    yearStarted: '1985',
    yearEnded: '1996'
  },
  {
    symbolMake: 'FD',
    symbolModel: 'EX',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  {
    symbolMake: 'FD',
    symbolModel: 'FA',
    yearStarted: '1988',
    yearEnded: '1993'
  },
  {
    symbolMake: 'FD',
    symbolModel: 'PR',
    yearStarted: '1993',
    yearEnded: '1994'
  },
  {
    symbolMake: 'FD',
    symbolModel: 'TE',
    yearStarted: '1986',
    yearEnded: '1994'
  },
  {
    symbolMake: 'FD',
    symbolModel: 'TB',
    yearStarted: '1989',
    yearEnded: '1993'
  },
  // Geo
  {
    symbolMake: 'CH',
    symbolModel: 'MR',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'CH',
    symbolModel: 'PR',
    yearStarted: '1989',
    yearEnded: '1991'
  },
  // GMC
  {
    symbolMake: 'GM',
    symbolModel: 'JY',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  {
    symbolMake: 'GM',
    symbolModel: 'SR',
    yearStarted: '1990',
    yearEnded: '1995'
  },
  {
    symbolMake: 'GM',
    symbolModel: 'YB',
    yearStarted: '1992',
    yearEnded: '1994'
  },
  // Honda
  {
    symbolMake: 'HD',
    symbolModel: 'AC',
    yearStarted: '1990',
    yearEnded: '1991'
  },
  {
    symbolMake: 'HD',
    symbolModel: 'CV',
    yearStarted: '1990',
    yearEnded: '1991'
  },
  {
    symbolMake: 'HD',
    symbolModel: 'PP',
    yearStarted: '1994',
    yearEnded: '1997'
  },
  {
    symbolMake: 'HD',
    symbolModel: 'PR',
    yearStarted: '1990',
    yearEnded: '1991'
  },
  // Hyundai
  {
    symbolMake: 'HY',
    symbolModel: 'EX',
    yearStarted: '1986',
    yearEnded: '1994'
  },
  // Isuzu
  {
    symbolMake: 'ZU',
    // AKA Chevy Frontera
    symbolModel: 'AM',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'ZU',
    // AKA Chevy Frontera, GMC(?) Wizard
    symbolModel: 'RD',
    yearStarted: '1991',
    yearEnded: '1997'
  },
  {
    symbolMake: 'ZU',
    // AKA GMC Jackaroo, Honda Horizon, Subaru Bighorn, GMC/Holden Jackaroo
    symbolModel: 'TR',
    yearStarted: '1992',
    yearEnded: '2002'
  },
  // Mazda
  {
    symbolMake: 'MZ',
    symbolModel: 'NA',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  {
    symbolMake: 'MZ',
    symbolModel: '29',
    yearStarted: '1989',
    yearEnded: '1991'
  },
  {
    symbolMake: 'MZ',
    symbolModel: 'PR',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'MX',
    symbolModel: '26',
    yearStarted: '1990',
    yearEnded: '1992'
  },
  {
    symbolMake: 'MZ',
    symbolModel: 'TR',
    yearStarted: '2001',
    yearEnded: '2007'
  },
  // Mercury
  {
    symbolMake: 'MC',
    symbolModel: 'CG',
    yearStarted: '1989',
    yearEnded: '1993'
  },
  {
    symbolMake: 'MC',
    symbolModel: 'LY',
    yearStarted: '1985',
    yearEnded: '1987'
  },
  {
    symbolMake: 'MC',
    symbolModel: 'TP',
    yearStarted: '1986',
    yearEnded: '1994'
  },
  {
    symbolMake: 'MC',
    symbolModel: 'TR',
    yearStarted: '1991',
    yearEnded: '1996'
  },
  {
    symbolMake: 'MC',
    symbolModel: 'VL',
    yearStarted: '1993',
    yearEnded: '1995'
  },
  // Mitsubishi
  {
    symbolMake: 'MT',
    symbolModel: 'EC',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  {
    symbolMake: 'MT',
    symbolModel: 'GA',
    yearStarted: '1989',
    yearEnded: '1993'
  },
  {
    symbolMake: 'MT',
    symbolModel: 'MR',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'MT',
    symbolModel: 'PR',
    yearStarted: '1987',
    yearEnded: '1994'
  },
  // Nissan
  {
    symbolMake: 'NS',
    symbolModel: 'AL',
    yearStarted: '1993',
    yearEnded: '1993'
  },
  {
    symbolMake: 'NS',
    symbolModel: 'MA',
    yearStarted: '1987',
    yearEnded: '1994'
  },
  {
    symbolMake: 'NS',
    symbolModel: 'QU',
    yearStarted: '1993',
    yearEnded: '1995'
  },
  {
    symbolMake: 'NS',
    symbolModel: 'SE',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  {
    symbolMake: 'NS',
    symbolModel: '24',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  // Oldsmobile
  {
    symbolMake: 'LS',
    symbolModel: 'AC',
    yearStarted: '1992',
    yearEnded: '1995'
  },
  {
    symbolMake: 'LD',
    symbolModel: 'BR',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  {
    symbolMake: 'LD',
    symbolModel: 'CL',
    yearStarted: '1988',
    yearEnded: '1995'
  },
  {
    symbolMake: 'LD',
    symbolModel: 'CR',
    yearStarted: '1989',
    yearEnded: '1996'
  },
  {
    symbolMake: 'LD',
    symbolModel: 'E8',
    yearStarted: '1987',
    yearEnded: '1991'
  },
  {
    symbolMake: 'LD',
    symbolModel: 'FR',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'LD',
    symbolModel: 'N8',
    yearStarted: '1985',
    yearEnded: '1990'
  },
  // Plymouth
  {
    symbolMake: 'PL',
    symbolModel: 'AC',
    yearStarted: '1994',
    yearEnded: '1995'
  },
  {
    symbolMake: 'PL',
    symbolModel: 'CT',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  {
    symbolMake: 'PL',
    symbolModel: 'LA',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  {
    symbolMake: 'PL',
    symbolModel: 'SU',
    yearStarted: '1994',
    yearEnded: '1994'
  },
  {
    symbolMake: 'PL',
    symbolModel: 'VG',
    yearStarted: '1991',
    yearEnded: '2000'
  },
  // Pontiac
  {
    symbolMake: 'PT',
    symbolModel: 'BV',
    yearStarted: '1987',
    yearEnded: '1991'
  },
  {
    symbolMake: 'PT',
    symbolModel: 'GA',
    yearStarted: '1988',
    yearEnded: '1996'
  },
  {
    symbolMake: 'PT',
    symbolModel: 'GP',
    yearStarted: '1988',
    yearEnded: '1996'
  },
  {
    symbolMake: 'PT',
    symbolModel: 'ST',
    yearStarted: '1989',
    yearEnded: '1996'
  },
  // Saab
  {
    symbolMake: 'SB',
    symbolModel: 'NT',
    yearStarted: '1987',
    yearEnded: '1989'
  },
  // Saturn
  {
    symbolMake: 'SA',
    symbolModel: 'SC',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  {
    symbolMake: 'SA',
    symbolModel: 'SL',
    yearStarted: '1991',
    yearEnded: '1994'
  },
  // Subaru
  {
    symbolMake: 'SU',
    symbolModel: 'LE',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  // Suzuki
  {
    symbolMake: 'SZ',
    symbolModel: 'SW',
    yearStarted: '1989',
    yearEnded: '1994'
  },
  // Toyota
  {
    symbolMake: 'TY',
    symbolModel: 'CM',
    yearStarted: '1987',
    yearEnded: '1991'
  },
  {
    symbolMake: 'TY',
    symbolModel: 'CL',
    yearStarted: '1988',
    yearEnded: '1992'
  },
  {
    symbolMake: 'TY',
    symbolModel: 'CR',
    yearStarted: '1981',
    yearEnded: '1992'
  },
  {
    symbolMake: 'TY',
    symbolModel: 'TL',
    yearStarted: '1990',
    yearEnded: '1994'
  },
  // Volkswagon
  {
    symbolMake: 'VK',
    symbolModel: 'RA',
    yearStarted: '1975',
    yearEnded: '1984'
  },
  {
    symbolMake: 'VK',
    symbolModel: 'JE',
    yearStarted: '1980',
    yearEnded: '1992'
  },
  {
    symbolMake: 'VK',
    symbolModel: 'PA',
    yearStarted: '1990',
    yearEnded: '1994'
  }
];
