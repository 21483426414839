import React from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { policyType } from '@ourbranch/lookups';

import { ExpandableCard } from 'core/components/expandable-card';
import { useStore } from 'core/store';
import { PolicyTypeOption } from './policy-type-option';
import { ToggleRentersButton } from './toggle-renters-button';

const policyTypeOptionsByState = { AZ: ['H', 'HB', 'HA', 'A', 'AB', 'AR', 'R'] };
const defaultPolicyTypeOptions = ['H', 'HB', 'HA', 'A', 'AB', 'AR'];

const policyTypeLabelMap = {
  H: 'Home',
  HB: 'Home with Bundle Discount',
  HA: 'Home & Auto',
  A: 'Auto',
  AB: 'Auto with Bundle Discount',
  AR: 'Auto & Renters',
  R: 'Renters'
};

const inputOptionsMap = {
  H: [{ value: 'H', label: 'Home' }],
  HA: [{ value: 'HA', label: 'Home & Auto' }],
  HB: [{ value: 'HB', label: 'Home with Bundle Discount' }],
  A: [{ value: 'A', label: 'Auto' }],
  AB: [{ value: 'AB', label: 'Auto with Bundle Discount' }],
  AR: [{ value: 'AR', label: 'Auto & Renters' }],
  R: [{ value: 'R', label: 'Renters' }]
};

const getOption = (type, offer) => {
  const isAutoBundle = type === 'AB';
  const isHomeBundle = type === 'HB';
  const option = offer.options.find((opt) => (isAutoBundle || isHomeBundle ? opt.type === 'HA' : opt.type === type));

  if (!option) {
    return null;
  }

  const autoData = {
    autoPrice: option.autoBill,
    autoFrequency: offer.quote.global.autoPaymentType === 'I' ? 'Avg. Monthly' : '6 months'
  };

  const homeData = {
    homePrice: option.homeBill,
    homeFrequency: offer.quote.global.homeownersPaymentType === 'I' ? 'Avg. Monthly' : '12 months'
  };

  const rentersData = {
    rentersPrice: option.rentersBill,
    rentersFrequency: offer.quote.global.rentersPaymentType === 'I' ? 'Avg. Monthly' : '12 months'
  };

  if (isAutoBundle) {
    return autoData;
  }
  if (isHomeBundle) {
    return homeData;
  }
  return { ...homeData, ...autoData, ...rentersData };
};

const PolicyPickerCard = observer(() => {
  const {
    offer: store,
    offer: {
      offer: {
        quote: {
          existingCustomer,
          offerings: { monolineAutoRejectCode },
          preBindUWCheck
        },
        options
      },
      priorQuoteWithPreBindUWRejections: { ineligibleForHome }
    }
  } = useStore();

  const { values } = useFormikContext();
  const linkedToExistingCustomer = !!existingCustomer?.id;

  const policyTypeOptions = policyTypeOptionsByState[store?.state] || defaultPolicyTypeOptions;

  const hasMonolineAutoOption = options.some((opt) => {
    return opt.type === 'A';
  });

  const onClick = (type, disabled) => {
    if (!disabled) {
      store.setSelectedOption(type);
    }
  };

  return (
    <ExpandableCard title="Type" badge={policyTypeLabelMap[values.selectedOption]}>
      {policyTypeOptions.map((type, ix) => {
        const option = getOption(type, store.offer);
        let noAutoWBundle = false;
        let noAuto = false;
        let noHome = false;
        if (!hasMonolineAutoOption || monolineAutoRejectCode) {
          noAuto = type === policyType.Auto;
          noAutoWBundle = !existingCustomer && type === 'AB';
        }
        if (
          (preBindUWCheck?.homeVerification?.ineligibleForHomeDueToUWSelfReport || ineligibleForHome) &&
          (type === policyType.Home || type === 'HB' || type === policyType.HABundle)
        ) {
          noHome = true;
        }
        if (!option) {
          return null;
        }

        return (
          <PolicyTypeOption
            onClick={onClick}
            key={ix}
            option={option}
            type={type}
            state={store.state}
            label={policyTypeLabelMap[type].toUpperCase()}
            fieldOptions={inputOptionsMap[type]}
            noAutoWBundle={noAutoWBundle}
            noAuto={noAuto}
            noHome={noHome}
            linkedToExistingCustomer={linkedToExistingCustomer}
          />
        );
      })}
      <ToggleRentersButton />
    </ExpandableCard>
  );
});

export default PolicyPickerCard;
