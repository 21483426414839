import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import styles from './label.styles';

const Label = ({
  className,
  children,
  type,
  classes,
  marginBottom,
  fontSize,
  fontWeight,
  paddingLeft,
  component,
  inlineDisplay,
  ...props
}) => {
  const labelClasses = classNames(classes[type], className);
  const inlineStyles = { marginBottom, fontSize, fontWeight, paddingLeft };
  if (inlineDisplay) {
    inlineStyles.display = 'inline';
  }
  const Component = component || 'span';
  return (
    <Component className={labelClasses} style={inlineStyles} {...props}>
      {children}
    </Component>
  );
};

Label.propTypes = {
  classes: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf([
    'label',
    'title',
    'titleLight',
    'titleSecondary',
    'subtitle',
    'subtitleLight',
    'smallSubtitleLight',
    'formButton',
    'formSubTitle',
    'formSubTitleLight',
    'infoLabel',
    'infoValue',
    'infoValueFormatted',
    'infoSubtitle',
    'infoSubtitleWhite',
    'infoSubtitleOrange',
    'infoInnerTitle',
    'infoInnerTitleBold',
    'infoCardTitle',
    'coverageLabel',
    'coverageValue',
    'coverageInnerTitle',
    'coverageSubtitle',
    'coverageParagraph',
    'coverageAmount',
    'bundleLabel',
    'bundleValue',
    'action',
    'addTitle',
    'formTitle',
    'toast',
    'greenSmall',
    'greenExtraSmall',
    'greenBig',
    'darkSmall',
    'darkGreenSmall',
    'darkGreenMedium',
    'whiteMedium',
    'whiteSmall',
    'noteSmall',
    'paymentLabel',
    'titleDollarLabel',
    'policyLabel',
    'infoSubtitleBold',
    'formLabel',
    'formLabelLight',
    'formLabelLightLarge',
    'smallLightLabel',
    'status',
    'statusClosed',
    'statusOpen',
    'statusWarning',
    'statusClosedMedium',
    'statusOpenMedium',
    'statusWarningMedium',
    'smallWarning',
    'appliedDiscountsLabel',
    'discount',
    'discountPromo',
    'smallWhiteBold',
    'smallWhite',
    'largeWarning',
    'modalBody'
  ]),
  marginBottom: PropTypes.number,
  fontSize: PropTypes.string,
  fontWeight: PropTypes.string,
  className: PropTypes.string,
  component: PropTypes.string,
  paddingLeft: PropTypes.string,
  inlineDisplay: PropTypes.bool
};

Label.defaultProps = {
  type: 'label',
  className: null,
  marginBottom: null,
  fontSize: null,
  fontWeight: null,
  component: null,
  paddingLeft: null,
  inlineDisplay: false
};

export default withStyles(styles)(memo(Label));
