import React, { useCallback, useContext, useState } from 'react';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import PropTypes from 'prop-types';

import { AuthContext } from 'core/components/auth';
import { Label } from 'core/components/label';
import { useStore } from 'core/store/store.mobx';
import { dateTimeFormatter } from 'core/helpers/formatters';
import { Loading, SelectableRow, Table } from 'core';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { IconButtonWithTooltip } from 'core/components/icon-button-with-tooltip';
import copyIcon from '../../../../assets/copy.svg';
import viewIcon from '../../../../assets/view.svg';
import { CopyInternalDocToPolicyDocsModal } from '../copy-internal-doc-to-policy-docs-modal';
import { DropzoneContainer } from '../dropzone';
import useStyles from '../../documents.styles';

const InternalDocuments = observer(
  ({
    fetchDocuments,
    sortDocuments,
    getDocName,
    downloadDocument,
    uploaded,
    uploadFile,
    percent,
    error,
    deleting,
    file
  }) => {
    const classes = useStyles();
    const [copyDocsModalOpen, setCopyDocsModalOpen] = useState(false);
    const [documentToCopy, setDocumentToCopy] = useState(null);

    const {
      account: {
        policies: { documents, loadingDocuments: loading }
      }
    } = useStore();
    const { canUploadDocuments } = useContext(AuthContext);

    const handleCopyDocument = useCallback((document) => {
      setDocumentToCopy(document);
      setCopyDocsModalOpen(true);
    }, []);

    return (
      <>
        <Section
          title="Internal Documents"
          rightLabel="Total"
          rightValue={`${loading || !documents ? '...' : documents.internal.length} documents`}
        >
          {loading || !documents ? (
            <Loading />
          ) : (
            <Card>
              {documents.internal.length || uploaded.length ? (
                <Table
                  selfContained={false}
                  header={
                    <>
                      <TableCell>FILE NAME</TableCell>
                      <TableCell>LAST MODIFICATION</TableCell>
                      {/* need the following empty tablecell header for the view or copy action buttons */}
                      <TableCell />
                    </>
                  }
                  body={
                    <>
                      {sortDocuments(documents.internal.slice() || []).map((d) => (
                        <TableRow key={d.path} id={d.path}>
                          <TableCell className={classes.docTitle}>{getDocName(d.path)}</TableCell>
                          <TableCell>{dateTimeFormatter(d.lastModified)}</TableCell>
                          <TableCell>
                            <div className={classes.iconContainer}>
                              {canUploadDocuments && (
                                <IconButtonWithTooltip
                                  tooltip="Copy file to policy documents"
                                  onClick={() => handleCopyDocument(d)}
                                  icon={copyIcon}
                                  alt="copy internal doc to customer"
                                />
                              )}
                              <IconButtonWithTooltip
                                tooltip="View file"
                                onClick={() => downloadDocument(d.path)}
                                icon={viewIcon}
                                alt="view document"
                              />
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                      {sortDocuments(uploaded).map((d) => (
                        <SelectableRow key={d.path} id={d.path} onClick={downloadDocument}>
                          <TableCell className={classNames(classes.highlighted, classes.docTitle)}>
                            {getDocName(d.path)}
                          </TableCell>
                          <TableCell className={classes.highlighted}>{dateTimeFormatter(d.lastModified)}</TableCell>
                        </SelectableRow>
                      ))}
                    </>
                  }
                />
              ) : (
                <p className={classes.noDocs}>No internal documents for this customer</p>
              )}
              {canUploadDocuments ? (
                <DropzoneContainer
                  onUpload={uploadFile}
                  progress={percent}
                  error={error}
                  deleting={deleting}
                  uploading={file ? file.path : undefined}
                />
              ) : (
                <div className={classes.cantUploadContainer}>
                  <Label className={classes.cantUpload}>
                    To provide a document for this member, please send the document to Branch at{' '}
                    <a className={classes.support} href="mailto:support@ourbranch.com" rel="noopener noreferrer">
                      support@ourbranch.com
                    </a>
                  </Label>
                </div>
              )}
            </Card>
          )}
        </Section>
        {copyDocsModalOpen && (
          <CopyInternalDocToPolicyDocsModal
            open={copyDocsModalOpen}
            getDocName={getDocName}
            document={documentToCopy}
            refetchDocuments={fetchDocuments}
            onClose={() => {
              setCopyDocsModalOpen(false);
            }}
          />
        )}
      </>
    );
  }
);

InternalDocuments.propTypes = {
  sortDocuments: PropTypes.func.isRequired,
  fetchDocuments: PropTypes.func.isRequired,
  getDocName: PropTypes.func.isRequired,
  downloadDocument: PropTypes.func.isRequired,
  uploaded: PropTypes.array,
  uploadFile: PropTypes.func.isRequired,
  percent: PropTypes.number,
  error: PropTypes.string,
  deleting: PropTypes.bool,
  file: PropTypes.object
};

InternalDocuments.defaultProps = {
  percent: 0,
  uploaded: [],
  deleting: false,
  error: undefined,
  file: undefined
};

export default InternalDocuments;
