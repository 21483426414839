import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  price: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column'
  },
  priceNote: {
    color: theme.colorPalette.beige_10_op_75,
    textTransform: 'uppercase'
  },
  disabled: {
    color: theme.colorPalette.beige_10_op_13
  }
});

export default makeStyles(styles);
