import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  iconButtonRoot: {
    color: theme.colors.button__background_secondary,
    padding: 0,
    '&:disabled': {
      color: theme.colorPalette.green_20,
      opacity: '50%'
    }
  },
  iconButtonLabel: {
    height: 24,
    width: 24,
    '& > svg': {
      height: 24,
      width: 24
    }
  },
  'big-iconButtonRoot': {
    color: theme.colors.button__background_secondary,
    padding: 8,
    '&:disabled': {
      color: theme.colorPalette.orange_50,
      opacity: '50%'
    }
  },
  'big-iconButtonLabel': {
    height: 32,
    width: 32,
    '& > svg': {
      height: 32,
      width: 32
    }
  }
}));

export default useStyles;
