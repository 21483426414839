import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  row: {
    // sorry
    marginBottom: `16px !important`,
    height: 'auto'
  }
}));

export default useStyles;
