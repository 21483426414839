import { types, flow, getEnv } from 'mobx-state-tree';
import { GET_ADDITIONAL_OFFER_DATA, SET_ADDITIONAL_OFFER_DATA } from './additional-offers.queries';

export const AdditionalOffersStore = types
  .model({
    webUserId: types.maybeNull(types.string),
    termLifeLastSubmitted: types.maybeNull(types.string)
  })
  .actions((self) => ({
    getAdditionalOfferData: flow(function* getAdditionalOfferData (webUserId) {
      const { client } = getEnv(self);

      try {
        const { data } = yield client.query({
          query: GET_ADDITIONAL_OFFER_DATA,
          variables: {
            webUserId
          }
        });
        const {
          getAdditionalOfferData: { termLifeLastSubmitted }
        } = data;
        self.termLifeLastSubmitted = termLifeLastSubmitted;
        self.webUserId = webUserId;
      } catch (error) {
        // error here means it is not in the table which is ok.
      }
    }),
    setAdditionalOfferData: flow(function* setAdditionalOfferData(webUserId, termLifeLastSubmitted) {
      const { client } = getEnv(self);
      try {
        const { data } = yield client.mutate({
          mutation: SET_ADDITIONAL_OFFER_DATA,
          variables: {
            additionalOfferData: {
              webUserId,
              termLifeLastSubmitted
            }
          }
        });
        const { setAdditionalOfferData } = data;
        self.termLifeLastSubmitted = setAdditionalOfferData.termLifeLastSubmitted;
        self.webUserId = webUserId;
      } catch (e) {}
    })
  }));
