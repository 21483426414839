import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  chip: {
    marginLeft: 10,
    marginTop: 10
  },
  phonesWrapper: {
    marginTop: 25,
    marginBottom: 10
  },
  secondaryPhone: {
    marginBottom: 15
  }
});

export default useStyles;
