import { withStyles } from '@material-ui/core/styles';
import { Label } from 'core';
import { AuthContext } from 'core/components/auth';
import { withStore } from 'core/store';
import flowRight from 'lodash-es/flowRight';
import { getSnapshot } from 'mobx-state-tree';
import PropTypes from 'prop-types';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { withToast } from '../../../core/components/toast/context';
import { mapRevisedQuoteDetails } from '../../../core/helpers/quoter.service';
import { useErrors } from '../../helpers/error-hooks';
import { Errors } from '../errors';
import FooterRight from './footer-right';

import styles from './footer.styles';

const getOnlyErrors = (code) => code < 10000;

const Footer = ({ classes, hasRejections, toast, store }) => {
  const {
    quote: { errors: quoteErrors, offer, sendClusterLink }
  } = store;
  const session = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const errors = useErrors(getSnapshot(quoteErrors), getOnlyErrors);
  const availableQuote = useMemo(() => {
    return !errors.length && quoteErrors.length < 2;
  }, [quoteErrors, errors]);

  const handleSubmitLink = useCallback(
    (quote) => {
      setLoading(true);
      const agentInfo = {
        username: session.user.username,
        email: session.user.email,
        phoneNumber: session.user.phoneNumber
      };

      sendClusterLink(offer.id, quote.email, mapRevisedQuoteDetails(quote), agentInfo)
        .then(({ data }) => {
          if (data) {
            toast.notify({
              type: 'success',
              message: `Email sent to ${quote.email}`
            });
            setLoading(false);
          }
        })
        .catch(() => {
          toast.notify({
            type: 'error',
            message: `Failed to send email to ${quote.email}`
          });
          setLoading(false);
        });
    },
    [sendClusterLink, offer, setLoading, toast, session]
  );
  return (
    <div className={classes.floatingContainer}>
      {hasRejections && <Errors errors={quoteErrors} rejection />}
      {offer && (
        <div className={classes.footerContainer}>
          <div className={classes.footerContent}>
            <Label className={classes.title} type="subtitle">
              {availableQuote ? 'We got a new offer!' : 'Offer not available!'}
            </Label>
            <FooterRight handleSubmit={handleSubmitLink} disabled={!availableQuote} loadingEmail={loading} />
          </div>
        </div>
      )}
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  hasRejections: PropTypes.bool.isRequired,
  toast: PropTypes.shape({
    notify: PropTypes.func.isRequired
  }).isRequired,
  store: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withToast, withStore)(Footer);
