import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  icon: {
    height: 24,
    width: 24
  },
  tooltip: {
    backgroundColor: theme.colorPalette.green_20
  }
});

export default makeStyles(styles);
