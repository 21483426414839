import React from 'react';
import PropTypes from 'prop-types';
import LayoutWithSidebar from 'core/components/layout-with-sidebar';
import { Policy } from '../policy';
import { PolicyHeader } from '../policy/header';
import { PolicySidebar } from '../policy-sidebar';
import { CustomerHeader } from '../header';
import { CustomerSidebar } from '../sidebar';
import Customer from 'customer/customer';
import { useCustomerProvider } from './useCustomerProvider';

export const PolicyRoute = ({ accountId, policyId, history }) => {
  useCustomerProvider({ accountId, policyId });
  return (
    <LayoutWithSidebar
      header={PolicyHeader}
      content={Policy}
      side={PolicySidebar}
      history={history}
      accountId={accountId}
      policyId={policyId}
    />
  );
};

PolicyRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  policyId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export const CustomerRoute = ({ accountId, history }) => {
  useCustomerProvider({ accountId });
  return (
    <LayoutWithSidebar
      header={CustomerHeader}
      content={Customer}
      side={CustomerSidebar}
      history={history}
      accountId={accountId}
    />
  );
};

CustomerRoute.propTypes = {
  accountId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};
