import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Loading } from 'core';
import { withStore } from 'core/store';
import FrontTicketSection from './components/front-ticket-section';
import SendgridTicketSection from './components/sendgrid-ticket-section';
import Five9TicketSection from './components/five9-ticket-section';

const Tickets = ({
  store: {
    account: {
      fname,
      lname,
      email,
      phoneNumber,
      getAllTickets,
      getFrontTickets,
      getSendgridTickets,
      getFive9Tickets,
      fetchTickets,
      fetchAccount,
      accountLoading,
      ticketsLoading
    }
  }
}) => {
  // @NOTE this is separate because its kind of slow, and not rendered when the page loads
  useEffect(() => {
    if (!getAllTickets() && !accountLoading) {
      if (fname && lname && email && phoneNumber) {
        fetchTickets();
      }
    }
  }, [fetchAccount, accountLoading, fname, lname, email, phoneNumber, fetchTickets, getAllTickets, getFrontTickets]);

  if (ticketsLoading || accountLoading || !getAllTickets()) {
    return <Loading />;
  }
  return (
    <>
      <FrontTicketSection tickets={getFrontTickets()} />
      <SendgridTicketSection tickets={getSendgridTickets()} />
      <Five9TicketSection tickets={getFive9Tickets()} />
    </>
  );
};

Tickets.propTypes = {
  store: PropTypes.object.isRequired
};
export default withStore(Tickets);
