import { makeStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
  container: {
    marginTop: theme.spacing(4),
    gap: theme.spacing(2)
  },
  label: {
    color: theme.colorPalette.green_20
  },
  link: {
    alignItems: 'center',
    fontSize: 14,
    cursor: 'pointer',
    fontWeight: 500
  },
  inviter: {
    alignItems: 'center',
    fontSize: 14,
    fontWeight: 500
  }
});

export default makeStyles(useStyles);
