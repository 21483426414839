import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  modal: {
    height: '350px',
    width: '720px',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: '48px 115px!important',
    alignItems: 'center'
  },

  title: {
    textAlign: 'left'
  },
  subtitle: {
    marginTop: '32px',
    composes: '$title'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px'
  }
});

export default makeStyles(styles);
