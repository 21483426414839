import React, { useState, useContext, useCallback } from 'react';
import { discountPaperlessStates } from '@ourbranch/lookups';
import { Grid } from '@material-ui/core';
import { Form, useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import {
  autoMultiPolicyDiscountOptions,
  homeMultiPolicyDiscountOptions,
  formattedAffinityOptions
} from 'core/helpers/lookups';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import Switch from 'core/components/switch';
import { NEW_CUSTOMER_HOME_SECURITY } from 'core/helpers/constants';

import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import Section from 'core/components/section';
import { useDisabledState } from 'common/disabled-context/context';
import { ConnectedHomeSettings } from './connected-home-settings/index';
import { useConnectedHomeSecurityProviderHelpers, getMonitoredStatus } from './hooks/connected-home.hooks';
import useStyles from './discounts.styles';

const Discounts = observer(function Discounts({
  disabled,
  homeDiscounts,
  rentersDiscounts,
  showConnectedHome,
  onConnectedHomeChange,
  isBix,
  allowLicensedActions
}) {
  const session = useContext(AuthContext);
  const { disabled: disabledContext } = useDisabledState();
  const { canModifyAffinityAndLeadSource, isInternalSales, canToggleEmployeeDiscount } = session;
  const {
    affinityLookups,
    account: {
      policies: { policy: store }
    }
  } = useStore();
  const affinityOptions = formattedAffinityOptions(affinityLookups, 'No Affinity Code');
  const classes = useStyles();
  const multiPolicyDiscountOptions = homeDiscounts ? homeMultiPolicyDiscountOptions : autoMultiPolicyDiscountOptions;
  const { values } = useFormikContext();
  return (
    <Section title="Discounts" type="SubSection">
      <Card type="secondary" className={classes.secondary}>
        <Form disabled={disabled}>
          <Grid container alignItems="flex-end" justify="space-between" spacing={2}>
            {canModifyAffinityAndLeadSource && (
              <FormField
                id="global.affinity"
                name="global.affinity"
                label="Affinity Code"
                type="affinity"
                mode="dark"
                options={affinityOptions}
                xs={6}
                fast={false}
              />
            )}
            {!rentersDiscounts && (
              <FormField
                id="multiPolicyDiscount"
                name="multiPolicyDiscount"
                label="Multiline Discount"
                type="select"
                mode="dark"
                options={multiPolicyDiscountOptions}
                xs={6}
              />
            )}
            <FormField
              id="global.discountPaperless"
              name="global.discountPaperless"
              label="Paperless Discount"
              type="switch"
              mode="dark"
              disabled={!isBix && !discountPaperlessStates[store.policy.state].isNotBix}
              xs={12}
              permissions={{ isLicensedAction: false }}
            />
            {canToggleEmployeeDiscount && (
              <FormField label="Employee Discount" xs={12} type="switch" name="global.employeeDiscount" mode="dark" />
            )}
            {homeDiscounts && (
              <>
                <FormField
                  id="global.discountInventoryScore"
                  name="global.discountInventoryScore"
                  label="Inventory Score"
                  type="switch"
                  mode="dark"
                  xs={12}
                />
                {isInternalSales && values.global.discountInventoryScore && (
                  <Grid className={classes.innerCheckbox}>
                    <FormField
                      id="global.manualInventorySubmission"
                      name="global.manualInventorySubmission"
                      type="checkbox"
                      label="I have received the member's inventory by email instead of through the app"
                      mode="dark"
                    />
                  </Grid>
                )}
              </>
            )}
            {(homeDiscounts || rentersDiscounts) && (
              <Switch
                id="discountConnectedHome"
                name="discountConnectedHome"
                label="Connected Home"
                mode="dark"
                value={showConnectedHome}
                onChange={onConnectedHomeChange}
                xs={12}
                disabled={!allowLicensedActions || disabledContext} // have to manually disable because this is not a FormField component that would handle it for us
              />
            )}
          </Grid>
        </Form>
        {showConnectedHome && <ConnectedHomeSettings />}
      </Card>
    </Section>
  );
});

const DiscountsFormikProvider = observer(({ disabled, homeDiscounts, rentersDiscounts }) => {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    account: {
      policies: {
        policy: {
          isAdvancedConnectedHome,
          getAllowLicensedActions,
          policy: { isBix: policyIsBix }
        }
      }
    },
    affinityLookups: affinityLookupsStore
  } = useStore();

  const isBix = policyIsBix || values.isBix; // policy isBix stored on policy level, this is segment context form
  const [showConnectedHome, setShowConnectedHome] = useState(
    !!values.connectedHome && values.connectedHome?.monitored !== 'N'
  );
  const allowLicensedActions = getAllowLicensedActions(session);

  const { isMonitoredByPartner } = useConnectedHomeSecurityProviderHelpers(
    { isAdvancedConnectedHome },
    affinityLookupsStore
  );
  const monitored = getMonitoredStatus({ isAdvancedConnectedHome, isMonitoredByPartner });

  // clear the values when changed
  const onConnectedHomeChange = useCallback(
    (e) => {
      setShowConnectedHome(e.target.checked);
      if (e.target.checked) {
        setFieldValue('connectedHome', {
          moistureDevices: undefined,
          motionDetectingDevices: undefined,
          smokeDetectingDevices: undefined,
          theftPreventionDevices: undefined,
          autoWaterShutoffDevices: false,
          providerName: undefined,
          ...values.connectedHome,
          monitored: values.connectedHome?.providerName ? monitored : 'N'
        });
      } else {
        setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
        setFieldValue('connectedHome', { ...values.connectedHome, monitored: 'N' });
      }
      setFieldTouched('connectedHome');
    },
    [setFieldValue, setFieldTouched, values.connectedHome]
  );

  return (
    <Discounts
      classes={classes}
      disabled={disabled}
      homeDiscounts={homeDiscounts}
      rentersDiscounts={rentersDiscounts}
      showConnectedHome={showConnectedHome}
      onConnectedHomeChange={onConnectedHomeChange}
      isBix={isBix}
      allowLicensedActions={allowLicensedActions}
    />
  );
});

DiscountsFormikProvider.propTypes = {
  disabled: PropTypes.bool.isRequired,
  rentersDiscounts: PropTypes.bool.isRequired,
  homeDiscounts: PropTypes.bool
};

DiscountsFormikProvider.defaultProps = {
  homeDiscounts: false
};

Discounts.defaultProps = {
  isAdvancedConnectedHome: false,
  isBix: false
};

Discounts.propTypes = {
  homeDiscounts: PropTypes.bool.isRequired,
  rentersDiscounts: PropTypes.bool.isRequired,
  showConnectedHome: PropTypes.bool.isRequired,
  onConnectedHomeChange: PropTypes.func.isRequired,
  isBix: PropTypes.bool
};

export default DiscountsFormikProvider;
