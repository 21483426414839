import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import useStyles from './icon-button-with-tooltip.styles';

const IconButtonWithTooltip = ({ tooltip, icon, alt, onClick }) => {
  const classes = useStyles();
  return (
    <Tooltip title={tooltip} placement="top" classes={{ tooltip: classes.tooltip }}>
      <IconButton onClick={onClick}>
        <img className={classes.icon} src={icon} alt={alt} />
      </IconButton>
    </Tooltip>
  );
};

IconButtonWithTooltip.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string,
  onClick: PropTypes.func.isRequired
};

IconButtonWithTooltip.defaultProps = {
  alt: ''
};

export default IconButtonWithTooltip;
