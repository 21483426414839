import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';

import backIcon from './go-to.svg';
import { Label } from '../label';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';

import useStyles from './screen-header.styles';

function ScreenHeader({ title, subtitles, children, onBack, rightValue, rightLink, options }) {
  const classes = useStyles();
  return (
    <Grid container className={classes.header} justify="space-between">
      <Grid item className={classes.container}>
        {!options.hideBackButton && (
          <IconButton classes={MapClassesToElem(ElemType.IconButton, classes)} onClick={onBack}>
            <img src={backIcon} className={classes.icon} alt="back" />
          </IconButton>
        )}
        {subtitles ? (
          <Grid container direction="column">
            <Label type="title">{title}</Label>
            {subtitles}
          </Grid>
        ) : (
          <Label type="title">{title}</Label>
        )}
      </Grid>
      <Grid item>
        {rightValue && (
          <div className={classes.rightContainer}>
            <Label type="infoValue" className={classes.rightValue}>
              {rightLink ? (
                <a className={classes.link} href={rightLink} target="_blank" rel="noopener noreferrer">
                  {rightValue}
                </a>
              ) : (
                rightValue
              )}
            </Label>
          </div>
        )}
        {children}
      </Grid>
    </Grid>
  );
}

ScreenHeader.propTypes = {
  title: PropTypes.node.isRequired,
  subtitles: PropTypes.node,
  onBack: PropTypes.func.isRequired,
  rightValue: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
  rightLink: PropTypes.string,
  options: PropTypes.object
};

ScreenHeader.defaultProps = {
  children: null,
  rightValue: null,
  rightLink: undefined,
  subtitles: undefined,
  options: { hideBackButton: false }
};

export default ScreenHeader;
