export const styles = (theme) => ({
  textElementStyleLight: {
    base: {
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      color: theme.colorPalette.green_10,
      fontWeight: 400,
      backgroundColor: theme.palette.common.white,
      '::placeholder': {
        fontSize: 14,
        color: theme.colorPalette.grey_10_op_38
      },
      padding: 10
    },
    invalid: {
      color: theme.palette.error.main
    }
  },
  textElementStyleDark: {
    base: {
      fontSize: 14,
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.common.white,
      fontWeight: 400,
      backgroundColor: theme.colorPalette.white_10_op_15,
      '::placeholder': {
        fontSize: 14,
        color: theme.colorPalette.beige_10_op_75
      },
      padding: 10
    },
    invalid: {
      color: theme.palette.error.main
    }
  }
});
