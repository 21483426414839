import React from 'react';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';

import { TextField } from '../text-field';
import search from 'core/assets/svg/member-search.svg';
import close from 'core/assets/svg/close2.svg';
import useStyles from './search-bar.styles';

const SearchBar = ({ value, onInputFocus, onCloseIconClick, onInputChange, label }) => {
  const classes = useStyles();
  const searchIcon = (
    <InputAdornment position="start" className={classes.adornment}>
      <img src={search} alt="" width="32" />
    </InputAdornment>
  );

  const closeIcon = (
    <InputAdornment position="end" style={{ zIndex: 2 }}>
      <IconButton onClick={onCloseIconClick} size="small">
        <img src={close} alt="close" width="32" />
      </IconButton>
    </InputAdornment>
  );

  return (
    <>
      <TextField
        mode="light"
        className={classes.searchBar}
        InputProps={{
          endAdornment: value ? closeIcon : undefined,
          startAdornment: searchIcon,
          value
        }}
        label={label}
        name="usersSearch"
        id="usersSearch"
        value={value}
        onChange={onInputChange}
        onFocus={onInputFocus}
        placeholder="Search for a member by account ID, name, or address"
      />
    </>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onInputFocus: PropTypes.func,
  onCloseIconClick: PropTypes.func.isRequired,
  onInputChange: PropTypes.func,
  label: PropTypes.string
};

SearchBar.defaultProps = {
  value: '',
  onInputFocus: null,
  onInputChange: null,
  label: ''
};
export default SearchBar;
