import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field, getIn, useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { disableExcludeDriverStates } from '@ourbranch/lookups';

import { AuthContext } from 'core/components/auth';
import { Tooltip } from 'core/components/tooltip';
import { FormField } from 'core/components/form';
import Checkbox from 'core/components/checkbox';
import SwapButton from 'core/components/swap-button';
import { useStore } from 'core/store/store.mobx';
import { tooltipHoverTexts } from 'core/helpers/constants';
import useStyles from '../person.styles';

const PersonActions = ({
  index,
  id,
  fieldName,
  item = {},
  removeFromList,
  onRemove,
  disabled,
  swapPrimaryApplicant,
  showApplicantSwapButton,
  disableSwapButton,
  allowLicensedActions,
  fromPolicy,
  fromHomePolicy
}) => {
  const classes = useStyles();

  const {
    offer: offerStore,
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const session = useContext(AuthContext);
  const { canChangeExclusions } = session;

  const { setFieldValue: formikSetFieldValue, values } = useFormikContext();
  const { drivers } = values;

  const driverIsNotInStore = !fromHomePolicy ? offerStore.isCoApplicantButtonDisabled(item.id, values) : null;

  const coAppSelected = drivers?.some((d) => {
    return d.isCoApplicant;
  });

  const state = fromPolicy ? policyStore?.geographicState : offerStore?.state;

  const driverExcluded = item.excludeDriver;

  const disableCoApplicantCheckBox =
    disabled ||
    (coAppSelected && !item.isCoApplicant) ||
    (!fromPolicy && driverIsNotInStore) ||
    item.excludeDriver ||
    !allowLicensedActions;

  const disableExcludeDriverCheckBox =
    (fromPolicy && !canChangeExclusions) ||
    disabled ||
    offerStore?.drivers?.get(item.id)?.excludeDriverDisabled ||
    item.isCoApplicant ||
    item.isPrimary ||
    disableExcludeDriverStates[state] ||
    !allowLicensedActions;

  const disableRemovePersonButton = fromHomePolicy
    ? disabled || item.isPrimary
    : disabled || item.isPrimary || driverExcluded;

  return (
    <>
      {item.isPrimary && showApplicantSwapButton ? (
        <>
          <Grid container direction="column" alignItems="flex-end" spacing={1}>
            <span
              className={classNames(classes.swapButtonHelperText, {
                [classes.swapButtonDisabled]: disableSwapButton
              })}
            >
              Swap Main and Co-Applicant
            </span>
            {disableSwapButton && (
              <Tooltip
                text={
                  !allowLicensedActions
                    ? tooltipHoverTexts.swapButtonDisabledForLicenseOrPermissions
                    : tooltipHoverTexts.swapButtonDisabledNeedsSaved
                }
                label="More Info"
                placement="bottom"
              />
            )}
          </Grid>
          <SwapButton disabled={disableSwapButton} mode="big" onClick={swapPrimaryApplicant} />
        </>
      ) : (
        <>
          {!fromHomePolicy && (
            <>
              {!item.isPrimary && (
                <>
                  <Field name={`${fieldName}.isCoApplicant`}>
                    {({ form: { values, setFieldValue, setFieldTouched } }) => (
                      <Checkbox
                        id="primary"
                        mode="light"
                        label="Co Applicant?"
                        classes={{
                          container: classes.coApplicant
                        }}
                        value={!!getIn(values, `${fieldName}.isCoApplicant`)}
                        onChange={(e) => {
                          if (showApplicantSwapButton) {
                            if (offerStore.drivers.get(item.id)) {
                              offerStore.drivers.get(item.id).toggleCoApplicantStatus();
                            }
                          }
                          setFieldValue(`${fieldName}.isCoApplicant`, e.target.checked);
                          setFieldTouched(id);
                        }}
                        disabled={disableCoApplicantCheckBox}
                      />
                    )}
                  </Field>
                  <Field name={`${fieldName}.excludeDriver`}>
                    {({ form: { values, setFieldValue, setFieldTouched, touched, errors } }) => (
                      <Checkbox
                        id="primary"
                        mode="light"
                        label={`Exclude ${id.includes('driver') ? 'Driver' : 'Applicant'} `}
                        classes={{
                          container: classes.coApplicant,
                          helperText: classes.coApplicantHelperText
                        }}
                        className={classNames({
                          [classes.excludeDriverCheckboxDisabled]: disableExcludeDriverStates[state]
                        })}
                        error={touched[id] && !!getIn(errors, `${fieldName}.excludeDriver`)}
                        helperText={touched[id] ? getIn(errors, `${fieldName}.excludeDriver`) : undefined}
                        value={!!getIn(values, `${fieldName}.excludeDriver`)}
                        onChange={(e) => {
                          setFieldValue(`${fieldName}.excludeDriver`, e.target.checked);
                          setFieldTouched(id);
                          formikSetFieldValue('includeUmbrella', false);
                          formikSetFieldValue('umbrellaCoverage', null);
                        }}
                        disabled={disableExcludeDriverCheckBox}
                      />
                    )}
                  </Field>
                </>
              )}
              {disableExcludeDriverStates[state] && (
                <Tooltip text={`${state} does not allow changing excluded driver status`} placement="top" />
              )}
              {item.isPrimary && !showApplicantSwapButton && (
                <Tooltip
                  text={
                    'Cannot remove primary driver. To remove, please select a co-applicant, then select Swap Main and Co-Applicant'
                  }
                  placement="top"
                />
              )}
            </>
          )}
          <FormField
            type="remove"
            id={fieldName}
            name={fieldName}
            disabled={disableRemovePersonButton}
            mode="big"
            onClick={() => onRemove(index, removeFromList)}
            fast={false}
          />
        </>
      )}
    </>
  );
};

PersonActions.propTypes = {
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  item: PropTypes.shape({
    isCoApplicant: PropTypes.bool,
    isPrimary: PropTypes.bool
  }).isRequired,
  id: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  onRemove: PropTypes.func.isRequired,
  removeFromList: PropTypes.func.isRequired,
  swapPrimaryApplicant: PropTypes.func.isRequired,
  showApplicantSwapButton: PropTypes.bool.isRequired,
  disableSwapButton: PropTypes.bool.isRequired,
  allowLicensedActions: PropTypes.bool.isRequired,
  fromPolicy: PropTypes.bool,
  fromHomePolicy: PropTypes.bool
};

PersonActions.defaultProps = {
  disabled: false,
  fromPolicy: false,
  fromHomePolicy: false
};

export default observer(PersonActions);
