import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  card: {
    backgroundColor: theme.colorPalette.green_20,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4)
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  header: {
    padding: theme.spacing(6, 6, 0)
  },
  button: {
    fontWeight: 600,
    fontSize: 16,
    width: 125
  },
  bottomMessage: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingLeft: 32,
    paddingRight: 32,
    marginBottom: 32
  },
  icon: {
    width: 16
  },
  lowerLabel: {
    color: theme.colors.card_primary_separator,
    fontSize: 12,
    marginLeft: 10
  }
});

export default makeStyles(styles);
