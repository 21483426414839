import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  }
});

export default makeStyles(styles);
