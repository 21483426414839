import { useBasisTheory } from '@basis-theory/basis-theory-react';

const CARD_ELEMENT_ID = 'CARD_ELEMENT_ID';

export const useBasisTheoryToken = () => {
  const { bt } = useBasisTheory();
  const getBasisTheoryCCToken = async () => {
    const { data, id } = await bt.tokens.create({
      type: 'card',
      data: bt.getElement(CARD_ELEMENT_ID)
    });
    const { expiration_month: expirationMonth, expiration_year: expirationYear, number } = data;
    return {
      card: {
        expirationMonth,
        expirationYear,
        last4: number.slice(-4)
      },
      token: id
    };
  };

  const getBasisTheoryACHToken = async ({
    routingNumberTextElementId,
    accountNumberTextElementId,
    accountHolderNameTextElementId
  }) => {
    const routingNumber = bt.getElement(routingNumberTextElementId);
    const accountNumber = bt.getElement(accountNumberTextElementId);
    const accountHolderName = bt.getElement(accountHolderNameTextElementId);
    return bt.tokens.create({
      type: 'token',
      privacy: {
        classification: 'bank',
        impact_level: 'high',
        restriction_policy: 'mask'
      },
      mask: {
        routing_number: '{{ data.routing_number }}',
        account_number: '{{ data.account_number | reveal_last: 4 }}'
      },
      data: {
        routing_number: routingNumber,
        account_number: accountNumber,
        account_holder_name: accountHolderName
      }
    });
  };

  return { cardElementId: CARD_ELEMENT_ID, getBasisTheoryCCToken, getBasisTheoryACHToken };
};
