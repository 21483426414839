import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    minWidth: 720,
    minHeight: 290,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: 20
  },
  closeButton: {
    position: 'absolute',
    right: 10,
    top: 10
  },
  title: {
    color: '#717832',
    fontSize: 36,
    fontWeight: 300,
    padding: 0
  },
  input: {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    color: theme.colors.text_secondary,
    zIndex: 2,
    height: 36,
    padding: '0 7px',
    fontSize: 14,
    boxSizing: 'border-box'
  },
  button: {
    fontSize: 16,
    width: 198
  },
  text: {
    color: theme.colors.green_15,
    marginTop: 0
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  header: {
    padding: theme.spacing(6, 6, 0)
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(7),
    padding: theme.spacing(0, 6, 2)
  },
  sentText: {
    color: theme.colors.green_15,
    fontWeight: 600,
    fontSize: 14,
    marginRight: 'auto'
  },
  footer: {
    marginTop: 38,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
});

export default makeStyles(styles);
