import React from 'react';
import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';

import { Label } from 'core/components/label';
import { BasisTheoryText } from 'core/components/basis-theory';
import useStyles from './ach-field.styles';

const ACHFields = ({ mode }) => {
  const classes = useStyles();
  const labelType = mode === 'dark' ? 'formLabel' : 'formLabelLight';
  return (
    <Grid container spacing={4}>
      <Grid item xs={12} className={classes.row}>
        <Label type={labelType}>Account Holder Name</Label>
        <BasisTheoryText id="accountHolderName" placeholder="Account Holder Name" mode={mode} />
      </Grid>
      <Grid item xs={6} className={classes.row}>
        <Label type={labelType}>Routing Number</Label>
        <BasisTheoryText id="routingNumber" placeholder="Routing Number" mode={mode} />
      </Grid>
      <Grid item xs={6} className={classes.row}>
        <Label type={labelType}>Account Number</Label>
        <BasisTheoryText id="accountNumber" placeholder="Account Number" mode={mode} />
      </Grid>
    </Grid>
  );
};

ACHFields.defaultProps = {
  mode: 'light'
};

ACHFields.propTypes = {
  mode: PropTypes.oneOf(['light', 'dark'])
};

export default ACHFields;
