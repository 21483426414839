import { basementTypes } from '@ourbranch/lookups';

export const getUWPreBindHomeVerificationInitialValues = (home) => {
  if (!home) {
    return {};
  }
  const propertyUWValues = {
    initialValues: { signedPropertyUWAttestation: false },
    conditions: {}
  };

  //  siding conditions
  if (home.yearBuilt < 1960) {
    propertyUWValues.initialValues.noAsbestosCheck = 'No';
    propertyUWValues.conditions.noAsbestosCheck = true;
  }

  // electric and plumbing
  if (home.yearBuilt < 1940) {
    // electric conditions
    propertyUWValues.initialValues.homeWiringCheck = 'Yes';
    propertyUWValues.conditions.homeWiringCheck = true;
    // plumbing conditions
    propertyUWValues.initialValues.homePlumbingCheck = 'Yes';
    propertyUWValues.conditions.homePlumbingCheck = true;
  }

  // raised foundation conditions
  if (home.basementType === basementTypes.Raised) {
    propertyUWValues.initialValues.noOpenFoundationCheck = 'Yes';
    propertyUWValues.conditions.noOpenFoundationCheck = true;
  }

  return propertyUWValues;
};

export default getUWPreBindHomeVerificationInitialValues;
