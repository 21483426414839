import { makeStyles } from '@material-ui/core';

const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.page__background_primary,
    overflow: 'hidden'
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 32
  },
  containerInner: {
    backgroundColor: theme.colors.card_primary_separator,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24
  },
  addressFormField: {
    paddingBottom: 8
  }
});

export default makeStyles(styles);
