import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import getDate from 'date-fns/getDate';
import parseISO from 'date-fns/parseISO';
import { observer } from 'mobx-react';

import { paymentMethod, originalGSNICStates } from '@ourbranch/lookups';
import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import useStyles from './settings.styles';

const RentersSettings = observer(function RentersSettings({
  paymentMethodOptions,
  billingDaysOptions,
  paymentTypeOptions
}) {
  const { offer } = useStore();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { isBix } = offer;

  const state = offer.state;

  const showCreditCardNote =
    values.global.rentersPaymentMethod === paymentMethod.ACH && !isBix && originalGSNICStates.includes(state);

  // if renters billing day of month is null, default select the effective day of the policy
  // or the 28th if the effectiveDay is past the allowed options of days 1 - 28
  useEffect(() => {
    const rentersEffectiveDay = getDate(parseISO(values.global.rentersEffectiveDate));
    if (!values?.global?.rentersBillingDayOfMonth && values?.global?.rentersBillingDayOfMonth !== rentersEffectiveDay) {
      if (rentersEffectiveDay > 28) {
        setFieldValue('global.rentersBillingDayOfMonth', 28);
      } else {
        setFieldValue('global.rentersBillingDayOfMonth', rentersEffectiveDay);
      }
    }
  }, []);

  return (
    <Section title="Renters Policy Settings" type="SubSection">
      <Card className={classes.container} type="secondary">
        <Label className={classes.subtitle} type="formSubTitle">
          Policy Term
        </Label>
        <Grid className={classes.datesContainer} container spacing={4} alignItems="center">
          <FormField
            type="date"
            name="global.rentersEffectiveDate"
            label="Renters policy effective date"
            xs={4}
            mode="dark"
            disableFuture={false}
            maxDate={addDays(new Date(), 60)}
            fast={false}
          />
        </Grid>
        <Grid container xs={12} spacing={2} alignItems="center">
          <Label className={classes.subtitle} type="formSubTitle">
            Billing Details
          </Label>

          {showCreditCardNote && (
            <Label type="noteSmall">Note: There is no additional fee for paying with credit card.</Label>
          )}
        </Grid>
        <Grid container className={classes.datesContainer}>
          <Grid container item xs={12} spacing={4}>
            <FormField
              type="select"
              name="global.rentersBillingDayOfMonth"
              label="Renters Billing Day"
              mode="dark"
              options={billingDaysOptions}
              xs={4}
            />
            <FormField
              label="Renters payment method"
              xs={4}
              type="select"
              name="global.rentersPaymentMethod"
              options={paymentMethodOptions}
              mode="dark"
            />
            <FormField
              label="Renters payment frequency"
              xs={4}
              type="select"
              name="global.rentersPaymentType"
              options={paymentTypeOptions}
              mode="dark"
            />
          </Grid>
        </Grid>
      </Card>
    </Section>
  );
});

RentersSettings.propTypes = {
  paymentMethodOptions: PropTypes.array.isRequired,
  billingDaysOptions: PropTypes.array.isRequired,
  paymentTypeOptions: PropTypes.array.isRequired
};

export default withDatePicker(RentersSettings);
