import React, { useState, useMemo, useEffect } from 'react';
import { useFormikContext, FieldArray } from 'formik';
import { Grid } from '@material-ui/core';
import { isEqual } from 'lodash-es';
import { observer } from 'mobx-react';

import { ABODE_PRO_PLAN_AFFINITY, ABODE_PRO_PLAN_PROVIDER_NAME, tooltipHoverTexts } from 'core/helpers/constants';
import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import RemoveButton from 'core/components/remove-button';
import AddButton from 'core/components/add-button';
import { Card } from 'core/components/card';
import useFormattedAddress from 'core/hooks/use-formatted-address';
import { useStore } from 'core/store';
import { ACHFields } from 'common/components/payment/ach-fields';
import { getPaymentMethods } from 'offer/components/checkout/helper';
import { CreditCardField } from '../credit-card-field';
import { Attestation } from '../../attestation';
import RestoreCheckoutDataDialog from 'offer/components/dialog/restore-checkout-data';

import useStyles from './billing-form.styles';

const BillingForm = observer(function BillingForm() {
  const classes = useStyles();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const {
    offer: { offer, checkoutFormData, saveCheckoutFormData, selectedOption }
  } = useStore();

  const [openRestoreDataModal, setOpenRestoreDataModal] = useState(false);

  const { hasCreditCard, hasEscrow, hasACH } = useMemo(
    () =>
      getPaymentMethods({
        paymentMethods: values,
        policyType: selectedOption,
        noBindHome: offer.quote.noBindHome,
        noBindAuto: offer.quote.noBindAuto,
        hasAbodeProPlan:
          offer?.quote?.global?.affinity === ABODE_PRO_PLAN_AFFINITY &&
          offer?.quote?.connectedHome?.providerName === ABODE_PRO_PLAN_PROVIDER_NAME
      }),
    [values, offer]
  );

  const checkPayments = () => {
    if (!hasCreditCard && checkoutFormData?.creditCardInfo) {
      setFieldValue('recoveredPaymentData', null);
      saveCheckoutFormData({ id: values.address, creditCardInfo: null });
    }
  };

  useEffect(() => {
    checkPayments();
    const canRestoreCheckoutData =
      !!checkoutFormData?.id &&
      isEqual(checkoutFormData.id, values.address) &&
      !!(checkoutFormData.phone || checkoutFormData.email);
    const canRestorePaymentMethod =
      (checkoutFormData?.ach && hasACH) || !!(checkoutFormData?.creditCardInfo && hasCreditCard);
    if (canRestoreCheckoutData || canRestorePaymentMethod) {
      setOpenRestoreDataModal(true);
    }

    // we only want to show the modal on first render
    // eslint-disable-next-line
  }, []);

  const mortgageAddress = useFormattedAddress(values?.mortgageDetails?.mortgageHolderAddress);

  useEffect(() => {
    const { email, phone, address } = values;
    if (email || phone) {
      saveCheckoutFormData({ id: address, email, phone });
    }
  }, [values, saveCheckoutFormData]);

  return (
    <>
      <Card className={classes.container} type="primaryEditable">
        <Grid container item xs={12} spacing={4}>
          <FormField
            type="string"
            name="firstName"
            label="First Name"
            placeholder="Enter your First Name"
            mode="light"
            xs={6}
          />
          <FormField
            type="string"
            name="lastName"
            label="Last Name"
            placeholder="Enter your Last Name"
            mode="light"
            xs={6}
          />
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <FormField
            type="string"
            name="address.address"
            placeholder="Enter your Mailing Address"
            label="Address"
            mode="light"
            xs={8}
          />
          <FormField type="string" name="address.address2" label="Floor, unit, building, etc." mode="light" xs={4} />
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <FormField type="string" name="address.city" label="City" mode="light" xs={4} />
          <FormField type="string" name="address.state" label="State" mode="light" xs={4} />
          <FormField type="string" name="address.zip" label="Zip" mode="light" xs={4} />
        </Grid>
        <Grid container item xs={12} spacing={4}>
          <FormField type="string" name="email" label="Email" placeholder="Email" mode="light" xs={6} />
          <FormField type="string" name="phone" label="Phone" placeholder="Phone Number" mode="light" xs={6} />
        </Grid>
        <FieldArray
          name="additionalPhoneNumbers"
          render={(arrayHelpers) => (
            <Grid container alignItems="center" spacing={2}>
              {values.additionalPhoneNumbers?.length !== 0 &&
                values.additionalPhoneNumbers?.map((_, index) => (
                  <Grid
                    container
                    key={`addtl-phone-${index}`}
                    className={classes.secondaryPhoneContainer}
                    alignItems="flex-end"
                    spacing={2}
                  >
                    <FormField
                      mode="light"
                      name={`additionalPhoneNumbers[${index}].phoneNumber`}
                      id={`additionalPhoneNumbers[${index}].phoneNumber`}
                      label="Secondary Phone"
                      type="string"
                      xs={4}
                    />
                    <FormField
                      mode="light"
                      name={`additionalPhoneNumbers[${index}].note`}
                      id={`additionalPhoneNumbers[${index}].note`}
                      label="Description"
                      type="string"
                      xs={7}
                    />
                    <RemoveButton
                      xs={2}
                      mode="big"
                      onClick={() => {
                        arrayHelpers.remove(index);
                        setFieldTouched('additionalPhoneNumbers');
                      }}
                    />
                    <FormField
                      mode="light"
                      name={`additionalPhoneNumbers[${index}].canText`}
                      id={`additionalPhoneNumbers[${index}].canText`}
                      label="Does the member agree to receiving texts at this number?"
                      type="checkbox"
                      className={classes.textCheckbox}
                    />
                  </Grid>
                ))}
              <AddButton
                type="full"
                mode="xl"
                onClick={() => {
                  arrayHelpers.push({ phoneNumber: '', note: '', canText: false });
                  setFieldTouched('additionalPhoneNumbers');
                }}
                label="Add an additional phone number"
              />
            </Grid>
          )}
        />
        <Grid className={classes.sectionWithLine}>
          {hasEscrow && (
            <Grid container item xs={12} className={classes.paymentBlock}>
              {/* MORTGAGE DETAILS READ ONLY VALUES */}
              <FormField type="value" name="mortgageDetails.mortgageHolderName" label="Lender Name" xs={4} />
              <FormField
                type="value"
                name="mortgageDetails.loanNumber"
                label="Loan Number"
                xs={4}
                value={values.mortgageDetails.loanNumber || '-'}
              />
              <FormField
                type="value"
                name="mortgageDetails.mortgageHolderAddress"
                label="Lender Address"
                xs={4}
                value={mortgageAddress || '-'}
              />
              <Grid item xs={6} className={classes.loanOfficerField}>
                <LabelTooltip
                  mode="light"
                  label="Loan Officer Email (optional)"
                  tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.loanOfficerEmail }}
                >
                  <FormField mode="light" type="string" name="loanOfficerEmail" placeholder="agent@example.com" />
                </LabelTooltip>
              </Grid>
            </Grid>
          )}
          {hasACH && <ACHFields />}
          {hasCreditCard && (
            <Grid container item xs={12}>
              <CreditCardField />
            </Grid>
          )}
        </Grid>
        <Grid className={classes.sectionWithLine}>
          <Attestation offer={offer} />
        </Grid>
      </Card>
      <RestoreCheckoutDataDialog
        open={openRestoreDataModal}
        hasCreditCard={hasCreditCard}
        onClose={() => setOpenRestoreDataModal(false)}
      />
    </>
  );
});

export default BillingForm;
