import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  container: {
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 16
  }
});

export default makeStyles(styles);
