export function clean(testIn: Record<string, any>) {
  const test = testIn;
  for (const i in test) {
    if (test[`${i}ActiveStatus`] === false) {
      test[i] = null;
    } else if (test[i] === null || test[i] === undefined || test[i] === '') {
      delete test[i];
    } else if (typeof test[i] === 'object') {
      clean(test[i]);
    }
  }
  return test;
}
