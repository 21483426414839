import React, { useCallback, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'formik';
import flowRight from 'lodash-es/flowRight';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';
import { lookupsJson } from '@ourbranch/lookups';
import { policyTypes as pTypes } from '@ourbranch/policy-types';

import { useStore } from 'core/store';
import People, {
  AddInterestedPartyForm,
  AddLienHoldersForm,
  InterestedParty,
  LienHolders
} from 'common/components/people';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { Button } from 'core/components/button';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import Loading from 'core/components/loading/loading';
import withDatePicker from 'core/components/with-date-picker';
import { dateFormatter } from 'core/helpers/formatters';
import { AuthContext } from 'core/components/auth';
import { useDisabledState } from 'common/disabled-context';
import { BillingDetails } from './billing-details';
import useStyles from './policy-settings.styles';

const padWithZeros = (str) => str.toString().padStart(3, 0);

function getLastModifiedBy(policy) {
  return Array.isArray(policy.versionHistory)
    ? `${dateFormatter(new Date(policy.versionHistory[policy.versionHistory.length - 1].updatedDateTime))} by ${
        policy.versionHistory[policy.versionHistory.length - 1].username
      }`
    : 'Unknown';
}

const PolicySettings = ({
  cancelPolicy,
  handleCancellation,
  changed,
  segment,
  formik,
  handleAddHoldCard,
  minDate,
  maxDate,
  goToPaymentTab
}) => {
  const classes = useStyles();
  const {
    account: {
      policies: {
        policy: { allActiveHoldCards, policy: policyFromStore, getAllowLicensedActions }
      }
    }
  } = useStore();
  const { values: policy, setFieldValue } = formik;

  const minEndDate = new Date(formik.values.effectiveDate);
  minEndDate.setDate(new Date(formik.values.effectiveDate).getDate() + 1);

  const hasHoldCards = !!allActiveHoldCards().length;
  const cancelled = new Date(policy.endDate) < new Date(policy.fullTermPolicyEndDate);

  /* Only disable when checkbox checked or policy loaded with those dates
    or if there are any hold cards on the policy
  */
  const session = useContext(AuthContext);
  const disabledForm = formik.values.cancel || (cancelled && !changed) || !session.canEdit;

  // only allow users in CanAutoRenew cognito group to allow toggling off auto renew
  const disableAutoRenewToggle = policyFromStore?.autoRenew && !session.canAutoRenew;
  const showCancelPolicyButton = !cancelled && getAllowLicensedActions(session);

  const { disabled, setDisabled } = useDisabledState();

  useEffect(() => {
    setDisabled(disabledForm || hasHoldCards);
  }, [disabledForm, hasHoldCards, setDisabled]);

  const onCancel = useCallback(() => {
    formik.setFieldValue('cancel', !formik.values.cancel);
    formik.setFieldTouched('cancel');
  }, [formik]);

  useEffect(() => {
    if (!cancelPolicy && cancelled && !changed) {
      handleCancellation(cancelled, false);
    }
  }, [cancelPolicy, cancelled, changed, handleCancellation]);

  useEffect(() => {
    if (!formik.values.expirationDate) {
      const vals = {
        renew: policy.autoRenew,
        expirationDate: policy.endDate,
        lastModified: getLastModifiedBy(policy),
        cancel: disabled
      };
      formik.setValues({ ...formik.values, ...vals });
    }

    if (typeof formik.values.sendPaperDocuments === 'undefined') {
      const segments = [...policy.segments].reverse();
      const segmentWithDiscountInfo = segments.find((item) => typeof item?.global?.discountPaperless === 'boolean');
      const sendPaperDocuments = !segmentWithDiscountInfo.global.discountPaperless;

      formik.setValues({ ...formik.values, sendPaperDocuments });
    }
  }, [formik, policy, disabled]);

  const endDateDisabled = useCallback(
    (effectiveDate) => {
      if (session.canBackDate) return false;
      const currentDateTime = new Date(new Date().toISOString().slice(0, 10)).getTime();
      const effectiveDateTime = new Date(effectiveDate).getTime();
      if (effectiveDateTime >= currentDateTime) {
        return false;
      }
      return true;
    },
    [session.canBackDate]
  );

  if (!segment || !formik.values.expirationDate) {
    return <Loading type="secondary" />;
  }

  return (
    <>
      <Card key={`settings-${disabled}`} type="secondary" className={classes.card}>
        {formik.values.expirationDate && (
          <>
            <Grid container justify="space-around" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} key="labelPolicyTerm">
                <Label key="lblTop" type="formSubTitle" className={classes.label}>
                  Policy Term
                </Label>
              </Grid>
              <Grid container key="row1" justify="space-between" spacing={2} className={classes.row}>
                <FormField
                  name="effectiveDate"
                  type="date"
                  label="Start Date"
                  mode="dark"
                  xs={3}
                  disableFuture={false}
                  minDate={minDate}
                  maxDate={maxDate}
                />
                <FormField
                  name="endDate"
                  type="date"
                  label="End Date"
                  mode="dark"
                  minDate={minEndDate}
                  xs={3}
                  disabled={endDateDisabled(policy.effectiveDate)}
                  disableFuture={false}
                  fast={false}
                />
                <FormField
                  name="rewriteReason"
                  type="select"
                  label="Rewrite"
                  mode="dark"
                  xs={6}
                  options={lookupsJson.rewriteReason}
                />
              </Grid>

              <BillingDetails policyType={policy.policyType} isCancelled={cancelled} goToPaymentTab={goToPaymentTab} />
              <Grid item xs={12} key="labelPolicyInformation">
                <Label key="lblPolicyInformation" type="formSubTitle" className={classes.label}>
                  Policy Information
                </Label>
              </Grid>
              <Grid container key="policyInformation" className={classes.row} justify="space-between">
                <FormField name="state" type="value" label="State" mode="dark" xs={4} />
                <FormField name="term" type="value" label="Term" mode="dark" xs={1} formatter={padWithZeros} />
                <FormField name="version" type="value" label="Version" mode="dark" xs={2} formatter={padWithZeros} />
                <FormField name="lastModified" type="value" label="Last Modified" mode="dark" xs={5} />
              </Grid>
              <Grid container key="policyOfferInformation" className={classes.row}>
                <FormField
                  name="offer.quote.rep"
                  type="value"
                  label="Producer"
                  mode="dark"
                  xs={4}
                  formatter={(producer) => producer || 'N/A'}
                />
                <FormField
                  name="offer.quote.leadSource"
                  type="select"
                  label="Lead Source"
                  mode="dark"
                  options={lookupsJson.leadSources}
                  xs={4}
                />
              </Grid>
              <Grid item xs={12} key="labelPolicyActions">
                <Label key="lblPolicyActions" type="formSubTitle" className={classes.labelWithDivider}>
                  Policy Actions
                </Label>
              </Grid>
              <Grid container key="policyActions" justify="space-between" className={classes.row}>
                <Grid container item xs={8} key="policyActionsLeft">
                  <FormField
                    xs={12}
                    type="switch"
                    name="renew"
                    label="Auto Renew"
                    mode="dark"
                    className={classes.toggleRow}
                    disabled={disableAutoRenewToggle}
                    permissions={{
                      isLicensedAction: false
                    }}
                    onChange={(_, val) => {
                      if (!val) {
                        // only change if turning off auto renew
                        setFieldValue('isNonRenew', true);
                      }
                    }}
                  />
                  <FormField
                    xs={12}
                    type="switch"
                    name="renewalCreditPull"
                    label="Run Credit/Insurance Score at Renewal"
                    mode="dark"
                    className={classes.toggleRow}
                    permissions={{
                      edit: { groups: [CognitoPermissionGroups.isService] }
                    }}
                  />
                  <FormField
                    xs={12}
                    type="switch"
                    name="sendPaperDocuments"
                    label="Send Paper Documents"
                    mode="dark"
                    className={classes.toggleRow}
                  />
                </Grid>
                <Grid container item xs={4} key="policyActionsRight" justify="flex-end">
                  <Button className={classes.button} disabled={!session?.canAddHoldCards} onClick={handleAddHoldCard}>
                    Add Hold Card
                  </Button>
                  {showCancelPolicyButton && (
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={onCancel}
                      className={classes.secondaryButton}
                      disabled={disabled}
                    >
                      Cancel Policy
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Card>
      {policy.policyType === pTypes.Home ? (
        <People
          key={`parties-${disabled}`}
          id="additionalParties"
          person={InterestedParty}
          addForm={AddInterestedPartyForm}
          title="Additional interested party"
          addLabel="Add People"
          disabled={disabled || session.viewOnly}
        />
      ) : (
        <People
          key={`lien-holders-${disabled}`}
          id="additionalParties"
          person={LienHolders}
          addForm={AddLienHoldersForm}
          title="Interested Party or Lienholder"
          addLabel="Add Lien Holder"
          disabled={disabled || session.viewOnly}
        />
      )}
    </>
  );
};

PolicySettings.propTypes = {
  cancelPolicy: PropTypes.bool.isRequired,
  handleCancellation: PropTypes.func.isRequired,
  changed: PropTypes.bool.isRequired,
  formik: PropTypes.object.isRequired,
  accountId: PropTypes.string.isRequired,
  policy: PropTypes.shape({
    effectiveDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    billingDayOfMonth: PropTypes.number.isRequired,
    autoRenew: PropTypes.bool,
    state: PropTypes.string.isRequired,
    term: PropTypes.number.isRequired,
    version: PropTypes.number.isRequired
  }).isRequired,
  goToPaymentTab: PropTypes.func.isRequired,
  segment: PropTypes.object,
  handleAddHoldCard: PropTypes.func.isRequired
};

PolicySettings.defaultProps = {
  segment: null
};

export default flowRight(withDatePicker, connect, observer)(PolicySettings);
