import React, { useState, useContext, useEffect } from 'react';
import * as yup from 'yup';
import { format } from 'date-fns';
import { Dialog, DialogTitle, DialogContent, Button } from '@material-ui/core';
import { parsePhoneNumber } from 'libphonenumber-js/max';
import { AuthContext } from 'core/components/auth';
import { useToast } from 'core/components/toast';
import { TextField } from 'core/components/text-field';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { ActionButton } from 'core/components/action-button';
import Checkbox from 'core/components/checkbox';
import Grid from '@material-ui/core/Grid';
import closeIcon from 'core/assets/svg/x.svg';
import useStyles from './term-life-modal.styles';
import { track } from 'core/helpers/analytics';

const schema = yup.object().shape({
  email: yup.string().email().required(),
  phone: yup
    .string()
    .required('phone number is required')
    .test('is-valid-phone', 'Invalid phone number', (phone) => {
      try {
        return parsePhoneNumber(phone, { defaultCountry: 'US' }).isValid();
      } catch {
        return false;
      }
    }),
  accepted: yup.boolean().test('is-accepted', 'must accept', (accepted) => accepted)
});

const TermLifeModal = ({ open, onClose, fromAccount }) => {
  const {
    offer: { offer },
    account,
    additionalOffers
  } = useStore();
  const webUserId = fromAccount ? account.policies.list[0].webUserId : offer.webUserId;
  useEffect(() => {
    if (webUserId !== additionalOffers.webUserId) {
      additionalOffers.getAdditionalOfferData(webUserId);
    }
  }, []);

  const [email, setEmail] = useState(fromAccount ? account.email : offer.quote.email);
  const [phone, setPhone] = useState(fromAccount ? account.phoneNumber : offer.quote.phone);
  const [accepted, setAccepted] = useState(false);
  const [errors, setErrors] = useState({ email: false, phone: false, accepted: false });
  const classes = useStyles();
  const toast = useToast();
  const name = fromAccount ? `${account.fname} ${account.lnam}` : `${offer.quote.fname} ${offer.quote.lname}`;
  const session = useContext(AuthContext);

  const sendLink = async () => {
    const now = new Date();
    try {
      await schema.validate({ email, phone, accepted }, { abortEarly: false });
      track('Term Life Interest Submitted - Staff', {
        webUserId,
        email,
        phone,
        name,
        staffUser: session.user.username,
        timeStamp: now.toISOString()
      });
      const timeStampString = `LINK SENT ON ${format(now, 'MM/dd/yyyy @ h:mm a')} BY ${session.user.username}`;
      additionalOffers.setAdditionalOfferData(webUserId, timeStampString);
      toast.notify({
        type: 'success',
        message: `A link has been sent to ${email}`
      });
      onClose();
    } catch (e) {
      const errorString = e?.errors?.join(',');
      const errors = {
        email: errorString?.includes('email'),
        phone: errorString?.includes('phone'),
        accepted: errorString?.includes('accept')
      };
      setErrors(errors);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.container }}>
      <ActionButton type="edit" size="big" icon={closeIcon} className={classes.closeButton} onClick={onClose} />
      <DialogTitle>
        <Label className={classes.title}>Term Life Insurance</Label>
      </DialogTitle>
      <DialogContent>
        <p className={classes.text}>
          Branch has partnered with Assurity to offer easy term life insurance. Our members and potential members can
          apply online in minutes without a medical exam and receive an instant approval decision in most cases. Details
          including a link to the quote and apply portal will be sent to the email and phone number provided below.
        </p>
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <TextField
              type="email"
              name="email"
              mode="light"
              label="EMAIL"
              value={email}
              fullWidth
              onChange={(e) => {
                setErrors({ ...errors, email: false });
                setEmail(e.target.value);
              }}
              error={errors.email}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="string"
              name="phone-number"
              mode="light"
              label="PHONE NUMBER"
              fullWidth
              value={phone}
              error={errors.phone}
              onChange={(e) => {
                setErrors({ ...errors, phone: false });
                setPhone(e.target.value);
              }}
            />
          </Grid>
        </Grid>
        <Checkbox
          id="member-agrees"
          mode="light"
          name="member-agrees"
          label="This member agrees to recieve text messages and emails regarding Term Life Insurance"
          value={accepted}
          onChange={(e) => {
            setErrors({ ...errors, accepted: false });
            setAccepted(e.target.checked);
          }}
          error={errors.accepted}
        />
        <div className={classes.footer}>
          <p className={classes.sentText}>{additionalOffers.termLifeLastSubmitted}</p>
          <Button variant="contained" color="secondary" className={classes.button} onClick={sendLink}>
            Send Link
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
export default observer(TermLifeModal);
