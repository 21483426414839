import React, { useEffect, useRef, useState } from 'react';
import { lookupsJson } from '@ourbranch/lookups';
import flowRight from 'lodash-es/flowRight';
import { getSnapshot } from 'mobx-state-tree';
import { Form } from 'formik';
import PropTypes from 'prop-types';

import Field from 'core/components/form/form.v2';
import withDatePicker from 'core/components/with-date-picker';
import { withStore } from 'core/store';
import { useErrors } from '../../helpers/error-hooks';
import { personalDetailsCode } from '../quote-forms/quote-forms.constants';
import { Collapsible } from '../collapsible';
import { Errors } from '../errors';

const personalDetailsCondition = (code) => personalDetailsCode.includes(code);

const PersonalDetails = ({ store, sectionsToShow }) => {
  const {
    quote: { errors: quoteErrors }
  } = store;

  const errors = useErrors(getSnapshot(quoteErrors), personalDetailsCondition);
  const personalDetailsErrors = useRef(null);
  const [shouldShow, setShouldShow] = useState(false);

  useEffect(() => {
    if (errors.length && personalDetailsErrors.current) {
      personalDetailsErrors.current.scrollIntoView({ block: 'end', behvior: 'smooth' });
    }
  }, [errors]);

  useEffect(() => {
    if (sectionsToShow && sectionsToShow?.length > 0) {
      const shouldBeShown = personalDetailsCode.every((element) => sectionsToShow.includes(element));
      setShouldShow(shouldBeShown);
    }
  }, [sectionsToShow]);

  return (
    <Form>
      {(errors.length > 0 || shouldShow) && (
        <>
          <Errors errors={errors} ref={personalDetailsErrors} />
          <Collapsible title="Personal Details">
            <>
              <Field
                xs={4}
                type="autocomplete"
                options={lookupsJson.usStates}
                id="driversLicenseState"
                name="driversLicenseState"
                mode="light"
                label="Drivers License State"
                stateSelectForm
              />
              <Field
                xs={8}
                type="string"
                id="driversLicenseNumber"
                name="driversLicenseNumber"
                mode="light"
                label="Drivers License Number"
              />
              <Field
                xs={4}
                type="date"
                id="dateOfBirth"
                name="dateOfBirth"
                mode="light"
                label="Date of birth"
                defaultToEmpty
              />
            </>
          </Collapsible>
        </>
      )}
    </Form>
  );
};

PersonalDetails.propTypes = {
  store: PropTypes.object.isRequired
};

export default flowRight(withDatePicker, withStore)(PersonalDetails);
