import Grid from '@material-ui/core/Grid';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useStore } from 'core/store/store.mobx';
import { lookupsJson } from '@ourbranch/lookups';
import { Select } from 'core/components/select';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';

const id = 'autoCoverage';

const SplitLiabilityLimit = ({ disabled }) => {
  const { offer: store } = useStore();
  const { values, setFieldValue, setFieldTouched } = useFormikContext();

  const { policyLimitBIPD } = values[id];

  const [biPerPerson, biPerAccident, pd] = policyLimitBIPD.split('/');

  const currentOption = store?.offer?.options?.find((offer) => offer.type === values.selectedOption);
  const setBI = useCallback(
    (_, value) => {
      const nextValue = `${value}/${pd}`;
      setFieldValue(`${id}.policyLimitBIPD`, nextValue);
      setFieldTouched(`${id}.policyLimitBIPD`);
    },
    [pd, setFieldValue, setFieldTouched]
  );
  const setPD = useCallback(
    (_, value) => {
      const nextValue = `${biPerPerson}/${biPerAccident}/${value}`;
      setFieldValue(`${id}.policyLimitBIPD`, nextValue);
      setFieldTouched(`${id}.policyLimitBIPD`);
    },
    [biPerPerson, biPerAccident, setFieldValue, setFieldTouched]
  );

  const biOptions =
    store.offer &&
    currentOption?.alternateAutoOptionsByBIPD &&
    Object.values(currentOption?.alternateAutoOptionsByBIPD?.bi || {}).length > 0
      ? Object.values(currentOption?.alternateAutoOptionsByBIPD?.bi || {})
      : lookupsJson.splitPolicyLimitBIPDState.MI.bi;
  const pdOptions = lookupsJson.splitPolicyLimitBIPDState.MI.pd;

  return (
    <>
      <Grid item xs={6}>
        <LabelTooltip
          label="Bodily Injury Damage Limit"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitBIPD }}
        >
          <Select
            fullWidth
            mode="dark"
            name={`${id}.policyLimitBI`}
            id={`${id}.policyLimitBI`}
            value={`${biPerPerson}/${biPerAccident}`}
            options={biOptions}
            disabled={disabled}
            onChange={setBI}
          />
        </LabelTooltip>
      </Grid>
      <Grid item xs={6}>
        <LabelTooltip
          label="Property Damage Limit"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.policyLimitBIPD }}
        >
          <Select
            fullWidth
            mode="dark"
            name={`${id}.policyLimitPD`}
            id={`${id}.policyLimitPD`}
            value={pd}
            options={pdOptions}
            disabled={disabled}
            onChange={setPD}
          />
        </LabelTooltip>
      </Grid>
    </>
  );
};

SplitLiabilityLimit.propTypes = {
  disabled: PropTypes.bool
};

SplitLiabilityLimit.defaultProps = {
  disabled: false
};

export default SplitLiabilityLimit;
