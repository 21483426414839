import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  spacing: {
    padding: '20px 0 20px 0'
  },
  wrapText: {
    wordBreak: 'break-word'
  }
}));

export default useStyles;
