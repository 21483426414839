import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { stripeBankStatus } from '@ourbranch/lookups';
import { observer } from 'mobx-react';

import { Loading } from 'core';
import { withToast } from 'core/components/toast';
import { Card } from 'core/components/card';
import { useStore } from 'core/store/store.mobx';
import MicroDepositsForm from './micro-deposits-form';
import useStyles from './microdeposits.styles';

const MicroDeposits = observer(({ toast, stripeCustomerId, policyId, accountId }) => {
  const classes = useStyles();
  const { account } = useStore();
  const { policies: store } = account;
  const [loading, setLoading] = useState(false);
  const alreadyVerified = store.getStripeBankInfo(policyId)?.achStatus === stripeBankStatus.Verified;

  useEffect(() => {
    async function fetchStripeInfo() {
      if (
        account.id === accountId && // has the store completely refreshed?
        !!store.achPolicies.length &&
        !store.getStripeBankInfo(policyId)?.achStatus && // has this reaction already run for current policy?
        !loading
      ) {
        setLoading(true);
        try {
          await store.generateStripeBankInfo(accountId, policyId, stripeCustomerId);
        } catch (error) {
          toast.notify({
            type: 'error',
            message: error.message
          });
        }
        setLoading(false);
      }
    }

    // avoiding call strip when is already verified
    if (!alreadyVerified && !loading) {
      fetchStripeInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alreadyVerified, loading, store]);

  if (alreadyVerified) {
    return null;
  }

  if (loading && !alreadyVerified) {
    return (
      <Card className={classes.loadingCard}>
        <Loading noBackground />
      </Card>
    );
  }

  return <MicroDepositsForm stripeCustomerId={stripeCustomerId} policyId={policyId} />;
});

MicroDeposits.propTypes = {
  toast: PropTypes.object.isRequired,
  stripeCustomerId: PropTypes.string,
  policyId: PropTypes.string,
  accountId: PropTypes.string
};

export default withToast(MicroDeposits);
