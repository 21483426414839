import React, { useContext } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store/store.mobx';
import { MyCommunity } from '../my-community';
import { Pledges } from '../pledges';
import AdditionalOffers from '../additional-offers';
import { MicroDeposits } from '../micro-deposits';
import { AccountDetails } from '../account-details';
import { AgentDetails } from '../agent-details';
import useStyles from './sidebar.styles';

const CustomerSidebar = observer((props) => {
  const {
    account,
    affinityLookups,
    account: {
      policies,
      policies: { achPolicies }
    }
  } = useStore();
  const recentPolicy = policies.getRecentPolicy();
  const affinity = recentPolicy?.segments?.[0]?.global.affinity;
  const affinityData = affinityLookups.data.find((aff) => aff.affinity.startsWith(affinity));
  const isAgentSold = affinity && affinityData?.isAgency;
  const classes = useStyles();
  const { canEdit, isAgency, isExternalSales } = useContext(AuthContext);
  return (
    <Grid container>
      <Grid item className={classes.bottomSpacing}>
        <AccountDetails {...props} />
      </Grid>
      {canEdit && !!achPolicies.length && account.id === props.accountId && (
        <>
          {achPolicies?.map((policy) => {
            return (
              <Grid item key={`${policy.id}-microdeposits-grid`} className={classes.bottomSpacing}>
                <MicroDeposits
                  key={`${policy.id}-microdeposits`}
                  stripeCustomerId={policy.stripeCustomerId}
                  policyId={policy.id}
                  {...props}
                />
              </Grid>
            );
          })}
        </>
      )}
      <Grid item className={classes.gridItem}>
        <AgentDetails {...props} />
      </Grid>
      <Grid item className={classes.gridItem}>
        <MyCommunity {...props} />
      </Grid>
      <Grid item className={classes.gridItem}>
        <Pledges {...props} />
      </Grid>
      {!isAgentSold && !isAgency && !isExternalSales && (
        <Grid item className={classes.gridItem}>
          <AdditionalOffers />
        </Grid>
      )}
    </Grid>
  );
});

export default CustomerSidebar;
