const styles = () => ({
  container: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    height: 24,
    width: 24,
    margin: -10
  },
  iconButtonRoot: {
    marginLeft: 10
  },
  label: {
    padding: '-10px 0px',
    margin: '-10px 0px'
  }
});

export default styles;
