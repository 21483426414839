import { paymentType as PaymentType } from '@ourbranch/lookups';

/**
 * Given and offer a policy type returns a formatted string with
 * the calculated monthly and total prices with all their variations
 * @param {object} offer
 * @param {string} policyType
 * @param {function} usePriceCalculator
 */
const getPlanPrice = (offer, policyType, getDetailedPrice) => {
  const isHomeMonthly = offer ? offer.quote.global.homeownersPaymentType === PaymentType.Monthly : undefined;
  const isAutoMonthly = offer ? offer.quote.global.autoPaymentType === PaymentType.Monthly : undefined;
  const isRentersMonthly = offer ? offer.quote.global.rentersPaymentType === PaymentType.Monthly : undefined;

  const priceData = getDetailedPrice(offer, policyType, { isHomeMonthly, isAutoMonthly, isRentersMonthly });

  return { isHomeMonthly, isAutoMonthly, isRentersMonthly, ...priceData };
};

export default getPlanPrice;
