import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { addDays } from 'date-fns';
import getDate from 'date-fns/getDate';
import parseISO from 'date-fns/parseISO';
import { observer } from 'mobx-react';

import { paymentMethod, originalGSNICStates } from '@ourbranch/lookups';
import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import useStyles from './settings.styles';

const AutoSettings = observer(function AutoSettings({ aPaymentMethodOptions, billingDaysOptions, paymentTypeOptions }) {
  const { offer } = useStore();
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();
  const { isBix } = offer;

  // this is used to default the billing day of the month to the day that the policy is effective
  const autoEffectiveDay = useMemo(() => {
    if (values?.global?.autoEffectiveDate) {
      return getDate(parseISO(values.global.autoEffectiveDate));
    }
  }, [values]);

  const state = offer.state;

  const showCreditCardNote =
    values.global.autoPaymentMethod === paymentMethod.ACH && !isBix && originalGSNICStates.includes(state);

  // if auto billing day of month is null, default select the effective day of the policy
  // or the 28th if the effectiveDay is past the allowed options of days 1 - 28
  useEffect(() => {
    if (!values?.global?.autoBillingDayOfMonth && values?.global?.autoBillingDayOfMonth !== autoEffectiveDay) {
      if (autoEffectiveDay > 28) {
        setFieldValue('global.autoBillingDayOfMonth', 28);
      } else {
        setFieldValue('global.autoBillingDayOfMonth', autoEffectiveDay);
      }
    }
  });

  return (
    <Section title="Auto Policy Settings" type="SubSection">
      <Card className={classes.container} type="secondary">
        <Label className={classes.subtitle} type="formSubTitle">
          Policy Term
        </Label>
        <Grid className={classes.datesContainer} container spacing={4} alignItems="center">
          <FormField
            type="date"
            name="global.autoEffectiveDate"
            label="Auto policy effective date"
            xs={4}
            mode="dark"
            disableFuture={false}
            maxDate={addDays(new Date(), 60)}
            fast={false}
          />
        </Grid>
        <Grid container xs={12} spacing={2} alignItems="center">
          <Label className={classes.subtitle} type="formSubTitle">
            Billing Details
          </Label>

          {showCreditCardNote && (
            <Label type="noteSmall">Note: There is no additional fee for paying with credit card.</Label>
          )}
        </Grid>
        <Grid container className={classes.datesContainer}>
          <Grid container item xs={12} spacing={4}>
            <FormField
              type="select"
              name="global.autoBillingDayOfMonth"
              label="Auto Billing Day"
              mode="dark"
              options={billingDaysOptions}
              xs={4}
            />
            <FormField
              label="Auto payment method"
              xs={4}
              type="select"
              name="global.autoPaymentMethod"
              options={aPaymentMethodOptions}
              mode="dark"
            />
            <FormField
              label="Auto payment frequency"
              xs={4}
              type="select"
              name="global.autoPaymentType"
              options={paymentTypeOptions}
              mode="dark"
            />
          </Grid>
        </Grid>
      </Card>
    </Section>
  );
});

AutoSettings.propTypes = {
  aPaymentMethodOptions: PropTypes.array.isRequired,
  billingDaysOptions: PropTypes.array.isRequired,
  paymentTypeOptions: PropTypes.array.isRequired
};

export default withDatePicker(AutoSettings);
