import React from 'react';
import PropTypes from 'prop-types';
import MuiCard from '@material-ui/core/Card';

import useStyles from './card.styles';

const Card = ({ children, type, scrollable, noBorder, ...props }) => {
  const classes = useStyles();
  return (
    <MuiCard
      className={scrollable ? classes.scrollable : ''}
      classes={{
        root: classes[type]
      }}
      {...props}
    >
      {children}
    </MuiCard>
  );
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  scrollable: PropTypes.bool,
  type: PropTypes.oneOf([
    'primary',
    'primaryEditable',
    'secondary',
    'tertiary',
    'quatrinary',
    'refund',
    'highlight',
    'outline',
    'noBorders',
    'noBordersTop',
    'scrollable',
    'noData',
    'error'
  ])
};

Card.defaultProps = {
  type: 'primary',
  scrollable: false
};

export default Card;
