import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import TableCell from '@material-ui/core/TableCell';

import { useStore } from 'core/store';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { dateFormatter } from 'core/helpers/formatters';
import { Table, SelectableRow, Loading } from 'core';
import { NoDataCard } from '../../no-data-card';
import TicketDetail from './ticket-detail';

const ITEMS_PER_PAGE = 5;

const FrontTicketSection = ({ tickets }) => {
  const {
    account: { fetchTickets, frontTicketsLoading }
  } = useStore();
  const [ticketDetailModalOpen, setTicketDetailModalOpen] = useState(false);
  const [selectedTicket, setSelectedticket] = useState({});
  const [page, setPage] = useState(0);

  const onChangePage = useCallback(
    (_, newPage) => {
      setPage(newPage);
      fetchTickets({
        frontTicketsSearchConfig: {
          page: newPage,
          itemsPerPage: ITEMS_PER_PAGE
        }
      });
    },
    [page]
  );

  const onSelectTicket = useCallback(
    (ticket) => {
      setTicketDetailModalOpen(true);
      setSelectedticket(ticket);
    },
    [setTicketDetailModalOpen]
  );

  if (frontTicketsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Section
        title="Member Conversations and Messages"
        rightLabel="total"
        rightValue={`${tickets ? tickets.total : 0} Item(s)`}
      >
        {tickets.items && tickets.items.length > 0 ? (
          <Card type="scrollable">
            <Table
              paginationEnabled={tickets.items.length < tickets.total}
              count={tickets.total}
              rowsPerPage={ITEMS_PER_PAGE}
              currentPage={page}
              onChangePage={onChangePage}
              selfContained={false}
              header={
                <>
                  <TableCell>Date</TableCell>
                  <TableCell>Subject</TableCell>
                </>
              }
              body={
                <>
                  {tickets.items.map((ticket, index) => (
                    <SelectableRow key={`${ticket.subject}-${index}`} id={index} onClick={() => onSelectTicket(ticket)}>
                      <TableCell>{dateFormatter(ticket.date)}</TableCell>
                      <TableCell>{ticket.subject}</TableCell>
                    </SelectableRow>
                  ))}
                </>
              }
            />
          </Card>
        ) : (
          <NoDataCard />
        )}
      </Section>
      {ticketDetailModalOpen && (
        <TicketDetail
          ticketDetail={selectedTicket}
          ticketDetailModalOpen={ticketDetailModalOpen}
          setTicketDetailModalOpen={setTicketDetailModalOpen}
        />
      )}
    </>
  );
};

FrontTicketSection.propTypes = {
  tickets: PropTypes.object
};

FrontTicketSection.defaultProps = {
  tickets: null
};

export default FrontTicketSection;
