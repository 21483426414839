import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { TableCell, IconButton } from '@material-ui/core';

import { localToUtcTime } from 'customer/components/policy/dates';
import { policyType } from '@ourbranch/lookups';
import { SelectableRow } from 'core/components/table/selectable-row';
import { AuthContext } from 'core/components/auth';
import { timeFormatter, awsDateToDateFormatter } from 'core/helpers/formatters';
import editIcon from './edit.svg';
import styles from './offer-results.styles';
import { FormField } from '../../form';

const OfferItem = ({ offer, classes, isDialogSearch }) => {
  const policyTypes = policyType;
  const { canQuote, viewOnly } = useContext(AuthContext);

  const date = localToUtcTime(offer.updatedDateTime, offer.quote.submittedAddress.state);

  const onOfferEdit = useCallback(
    (e) => {
      const { clusterId, options, id } = offer;
      const { selectedOption } = offer.quote;
      e.stopPropagation();
      if (clusterId) {
        const fallbackOption =
          options.find((o) => o.type === policyTypes.HABundle || o.type === policyTypes.ARBundle) || options[0];
        if (fallbackOption) {
          window.open(`/offer/${id}?option=${selectedOption || fallbackOption.type}`, '_blank');
          return;
        }
      }
      window.open(`/offer/${id}`, '_blank');
    },
    [offer, policyTypes]
  );

  return (
    <SelectableRow hover id={offer.id} onClick={() => null} data-cy="row" style={{ cursor: 'default' }}>
      {isDialogSearch && (
        <TableCell>
          <FormField
            defaultValue={offer?.id}
            type="radio"
            name="selectedOffer"
            mode="light"
            ignoreGlobalDisabledState
            fast={false}
            options={[{ value: offer?.id }]}
          />
        </TableCell>
      )}
      <TableCell>{offer.quote.fname}</TableCell>
      <TableCell>{offer.quote.lname}</TableCell>
      <TableCell>{offer.quote.submittedAddress ? offer.quote.submittedAddress.address : ''}</TableCell>
      <TableCell>{offer.quote.submittedAddress ? offer.quote.submittedAddress.city : ''}</TableCell>
      <TableCell>{offer.quote.submittedAddress ? offer.quote.submittedAddress.state : ''}</TableCell>
      <TableCell>{offer.quote.submittedAddress ? offer.quote.submittedAddress.zip : ''}</TableCell>
      <TableCell>
        {offer.updatedDateTime && (
          <>
            <span className={classes.dateTime}>{awsDateToDateFormatter(date)}</span>
            <span className={classes.dateTime}>{timeFormatter(date)}</span>
          </>
        )}
      </TableCell>
      {(canQuote || viewOnly) && (
        <TableCell>
          <IconButton onClick={onOfferEdit} className={classes.icon}>
            <img className={classes.editIcon} src={editIcon} alt="edit" />
          </IconButton>
        </TableCell>
      )}
    </SelectableRow>
  );
};

OfferItem.propTypes = {
  offer: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  isDialogSearch: PropTypes.bool
};

OfferItem.defaultProps = {
  isDialogSearch: false
};

export default withStyles(styles)(OfferItem);
