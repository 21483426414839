import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  addressFormField: {
    paddingBottom: 8,
    paddingLeft: 4,
    paddingRight: 4
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: theme.colors.card_primary_separator,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24
  }
});

export default makeStyles(styles);
