export default (theme) => ({
  mContent: {
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    padding: 42,
    backgroundColor: theme.colors.form__background_primary,
    '&:first-child': {
      paddingTop: 42
    }
  },
  blockContent: {
    position: 'relative',
    padding: 42,
    backgroundColor: theme.colors.form__background_primary,
    '&:first-child': {
      paddingTop: 42
    }
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  }
});
