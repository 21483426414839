import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  styledText: {
    display: 'block',
    lineHeight: 1.3
  },
  verifyBtn: {
    height: 30,
    width: 80
  },
  UWContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center'
  },
  subText: {
    fontSize: 12,
    marginTop: 4,
    marginRight: 10
  },
  title: {
    fontSize: 16,
    color: theme.colorPalette.white_10
  }
}));

export default useStyles;
