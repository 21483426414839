import AWS from 'aws-sdk';
import { documentTypes, docIdToFriendlyName } from '@ourbranch/be-constants';
import { paymentMethod } from '@ourbranch/lookups';

const lambda = new AWS.Lambda({ apiVersion: '2015-03-31' });

type PolicyDocument = {
  docType: number;
  data: { [key: string]: Record<string, any> };
  paths?: string;
};

const getPolicyDocuments = (
  policies: Array<Record<string, any>>,
  bindDetails: Record<string, any>,
  paidToDate: number
): PolicyDocument[] => {
  console.log(`Generation policy documents for policies: `, JSON.stringify(policies));

  // @TODO this will generate the invoice twice in some cases
  const generateInvoice = policies.some((policy: Record<string, any>) => policy.paymentMethod === paymentMethod.Escrow);

  const docs: any[] = [
    { docType: documentTypes.DECLARATION, data: { policies, bindDetails } },
    { docType: documentTypes.ID_CARDS, data: { policies, bindDetails } },
    { docType: documentTypes.POLICY_JACKET, data: { policies, bindDetails } }
  ];
  if (generateInvoice) {
    docs.push({ docType: documentTypes.ESCROW_INVOICE, data: { policies, bindDetails, paidToDate } });
    docs.push({ docType: documentTypes.REPLACEMENT_COST_ESTIMATE, data: { policies, bindDetails } });
  }

  return docs;
};

const _generate = (docs: Record<string, any>[], docsRenderer: string) => {
  console.log(`running generate to ${docsRenderer}`);

  const documentsPromise = docs.map((documentData) =>
    lambda
      .invoke({
        FunctionName: docsRenderer,
        InvocationType: 'RequestResponse',
        Payload: JSON.stringify(documentData)
      })
      .promise()
  );
  return Promise.all(documentsPromise);
};

export const generatePolicyDocuments = async (
  policies: Array<Record<string, any>>,
  bindDetails: Record<string, any>,
  docsRenderer: string,
  paidToDate: number
) => {
  try {
    console.log('Generating policy documents for: ', JSON.stringify(policies));
    const policyDocs = getPolicyDocuments(policies, bindDetails, paidToDate);
    console.log(`Generating ${policyDocs.map((x) => x.docType).join(', ')} documents`);
    console.log(`Sending bindDetails of ${JSON.stringify(bindDetails, null, 2)}`);

    const generatedDocs = await _generate(policyDocs, docsRenderer);
    const docs = policyDocs.map((doc, i) => {
      if (generatedDocs[i]) {
        doc.paths =
          typeof generatedDocs[i].Payload === 'string'
            ? JSON.parse(generatedDocs[i].Payload as string)
            : generatedDocs[i].Payload;
      }
      return doc;
    });
    console.log('Policy documents generated', docs);
    return docs;
  } catch (er) {
    console.log('Error generating policy documents', er);
    throw er;
  }
};

export const generateCancellationDocuments = async (
  policies: Array<Record<string, any>>,
  bindDetails: Record<string, any>,
  docsRenderer: string,
  paidToDate: number
) => {
  try {
    const cancellationDocs = getPolicyDocuments(policies, bindDetails, paidToDate);
    cancellationDocs.push({ docType: documentTypes.CANCELLATION, data: { policies, bindDetails } });
    console.log(
      `Generating ${cancellationDocs.length} documents with ${docsRenderer} (${JSON.stringify(docsRenderer, null, 4)})`
    );

    const generatedDocs = await _generate(cancellationDocs, docsRenderer);
    console.log('Cancellation documents generated', generatedDocs);
  } catch (er) {
    console.log('Error generating cancellation documents', er);
    throw er;
  }
};

export const generateFriendlyName = (fileName: string) => {
  let docId = fileName // '2019-2-14-19-29-A_IDCD.pdf'
    .split('-') // ['2019', '2', '14', '19', '29', 'A_IDCD.pdf']
    .slice(5) // ['A_IDCD.pdf']
    .join('_') // 'A_IDCD.pdf'
    .split('.')[0] // ['A_IDCD', 'pdf'] -> 'A_IDCD'
    .toUpperCase(); // 'A_IDCD'
  // also drop out "BF_" if it starts with that.
  docId = docId.replace('BF_', '');
  // handle case where id has _CW and ends with stuff after _CW
  docId = !docId.includes('_CW_') ? docId : docId.split('_CW_')[0].concat('_CW');
  // strip off _XY if it ends that way
  docId = docId.match(/_[A-Z][A-Z]$/) ? docId.replace(/_[A-Z][A-Z]$/, '') : docId;
  return docIdToFriendlyName[docId as keyof typeof docIdToFriendlyName] || fileName;
};
