import React from 'react';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { FormField } from 'core/components/form';
import { awsDateToJs } from 'core/helpers/formatters';
import withDatePicker from 'core/components/with-date-picker';
import styles from './reinstate-form.styles';

const ReinstateForm = ({ classes }) => {
  const { values, setFieldValue } = useFormikContext();
  const maxDate = awsDateToJs(values.fullTermPolicyEndDate);
  if (values.reinstateDate !== values.fullTermPolicyEndDate) {
    setFieldValue('reinstateDate', values.fullTermPolicyEndDate);
  }

  return (
    <Grid container item xs={8} key="details" justify="flex-end">
      <FormField
        name="reinstateDate"
        type="date"
        label="Coverage End Date"
        mode="light"
        xs={5}
        disableFuture={false}
        maxDate={maxDate}
        initialFocusedDate={values.fullTermPolicyEndDate}
        className={classes.endDate}
        ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
      />
      <FormField
        type="checkbox"
        id="applyFee"
        name="applyFee"
        mode="light"
        label="Apply Fee"
        xs={3}
        className={classes.checkbox}
        ignoreGlobalDisabledState
      />
    </Grid>
  );
};

ReinstateForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withDatePicker)(ReinstateForm);
