import { useMemo } from 'react';
import { Storage } from 'aws-amplify';

const useStorage = ({ onUpload, onDownload, onProgress }) => {
  const defaultConfig = { customPrefix: { public: '' }, level: 'public' };
  const uploadConfig = {
    ...defaultConfig,
    resumable: true,
    progressCallback: onProgress
  };
  return useMemo(
    () => ({
      upload: (path, file) =>
        Storage.put(path, file, { ...uploadConfig, contentType: file.type, completeCallback: () => onUpload(path) }),
      download: async (path) => {
        const url = await Storage.get(path, defaultConfig);
        window.open(url, '_blank');
      }
    }),
    [onUpload, onDownload, onProgress]
  );
};

export default useStorage;
