import React from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { useDisabledState } from 'common/disabled-context';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { SchedulePP } from 'common/components/schedule-pp';
import { RentersAddressForm } from './renters-address-form';
import { RentersCoverage } from './renters-coverage';
import useStyles from './renters.styles';

function RentersPolicy() {
  const classes = useStyles();
  const { disabled } = useDisabledState();
  const { offer } = useStore();

  return (
    <>
      <Section title="Renters Property Details" type="SubSection">
        <Card className={classes.container}>
          <RentersAddressForm />
        </Card>
      </Section>
      <Section title="Renters Property Coverage" type="SubSection">
        <Card>
          <RentersCoverage state={offer.state} />
        </Card>
      </Section>
      <SchedulePP disabled={disabled} />
    </>
  );
}

export default observer(RentersPolicy);
