import React from 'react';
import { observer } from 'mobx-react';
import Chip from '@material-ui/core/Chip';
import Grid from '@material-ui/core/Grid';
import ValueField from 'core/components/value-field';

import { useStore } from 'core/store';
import useStyles from './additional-phone-numbers.styles';

const AdditionalPhoneNumbers = () => {
  const { account } = useStore();
  const classes = useStyles();

  return (
    account.additionalPhoneNumbers?.length !== 0 && (
      <Grid container className={classes.phonesWrapper}>
        {account.additionalPhoneNumbers?.map((phoneDetail, index) => (
          <Grid key={`addtl-phone-number-${index}`} className={classes.secondaryPhone} container>
            <Grid container alignItems="center">
              <ValueField label="Secondary Phone" value={phoneDetail?.phoneNumber} xs={12} />
              {phoneDetail.canText && (
                <Chip label="Text Msg Enabled" color="secondary" size="small" className={classes.chip} xs={6} />
              )}
            </Grid>
            {phoneDetail.note?.length > 0 && <ValueField label="note" value={phoneDetail?.note} xs={12} />}
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default observer(AdditionalPhoneNumbers);
