import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { Button, Grid, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { getOptions } from '@ourbranch/lookups';

import { Label } from 'core';
import Field from 'core/components/form/form.v2';
import { withToast } from 'core/components/toast';
import { withStore } from 'core/store';
import flowRight from 'lodash-es/flowRight';
import { NotificationCard } from 'core/components/notification-card';
import { AuthContext } from 'core/components/auth';
import { validationSchema } from './manual-charge-form.validation';
import styles from '../payment.styles';

const ManualChargeForm = ({
  hasBillingHold,
  classes,
  toast,
  store: {
    account: {
      fetchFullAccountAndPolicy,
      policies: { policy: policyInfo }
    }
  }
}) => {
  const {
    policy,
    runManualCharge,
    billingDetails: { allPaymentMethods }
  } = policyInfo;
  const paymentMethodOptions = allPaymentMethods
    ? allPaymentMethods.map((paymentMethod) => {
        const { id, brand, last4, bankName } = paymentMethod;
        return { id, value: `${bankName ? bankName.toUpperCase() : brand.toUpperCase()} ****${last4}` };
      })
    : [];

  const session = useContext(AuthContext);
  const {
    user: { username },
    canManuallyChargeFee
  } = session;
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(
    async (values) => {
      setLoading(true);
      const accountId = policy.id.slice(0, 9);
      const manualPaymentText = `Manual payment made by ${username}. `;
      values.internalDescription = manualPaymentText.concat(values.internalDescription.trim());
      try {
        const res = await runManualCharge(
          policy.id,
          accountId,
          values.paymentMethod,
          values.amount,
          values.internalDescription,
          values.billType
        );
        setLoading(false);
        if (res.data.runManualCharge.success) {
          toast.notify({
            type: 'success',
            message: `Manually charged ${res.data.runManualCharge.type} ending with ${res.data.runManualCharge.last4}`
          });
          fetchFullAccountAndPolicy(policy.id, accountId);
        } else {
          toast.notify({
            type: 'error',
            message: res.data.runManualCharge.error || 'There was an error during the manual charge'
          });
        }
      } catch (e) {
        setLoading(false);
        toast.notify({
          type: 'error',
          message: e.message || 'There was an error during the manual charge'
        });
      }
    },
    [runManualCharge, policy.id, toast, fetchFullAccountAndPolicy, username]
  );

  const billTypes = canManuallyChargeFee
    ? getOptions('billTypes')
    : getOptions('billTypes').filter((billType) => billType.id !== 'fee');

  return (
    <>
      <div className={classes.secondRow}>
        <Grid container alignItems="center" justify="flex-start" className={classes.rowTitle}>
          <Grid item xs={3}>
            <Label type="coverageValue">Run a manual charge</Label>
          </Grid>
          {hasBillingHold && (
            <Grid item xs={9}>
              <NotificationCard className={classes.warningText} type="secondary" noBackground>
                You must remove the payment hold before making a manual charge.
              </NotificationCard>
            </Grid>
          )}
        </Grid>

        <Formik
          initialValues={{ paymentMethod: '', amount: null, billType: '', internalDescription: '' }}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={onSubmit}
        >
          {({ handleSubmit }) => {
            return (
              <div className={classes.manualChargeContainer}>
                <Grid container key="row1" spacing={4}>
                  <Field
                    name="paymentMethod"
                    type="select"
                    label="Payment Method"
                    xs={4}
                    mode="dark"
                    options={paymentMethodOptions}
                    ignoreGlobalDisabledState
                  />
                  <Field
                    name="amount"
                    type="numeric"
                    label="Amount"
                    xs={4}
                    format={{
                      thousandSeparator: true,
                      prefix: '$',
                      allowNegative: false,
                      fixedDecimalScale: true,
                      decimalScale: 2
                    }}
                    ignoreGlobalDisabledState
                  />
                  <Field
                    name="billType"
                    type="select"
                    label="Bill Type"
                    xs={4}
                    options={billTypes}
                    mode="dark"
                    ignoreGlobalDisabledState
                  />
                </Grid>
                <Grid container key="row2" spacing={4} className={classes.spaceBetweenRow}>
                  <Field
                    name="internalDescription"
                    type="string"
                    label="Description"
                    xs={8}
                    ignoreGlobalDisabledState
                  />
                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.submit}
                    onClick={handleSubmit}
                    disabled={loading || hasBillingHold}
                  >
                    Bill Client
                  </Button>
                </Grid>
              </div>
            );
          }}
        </Formik>
      </div>
      {loading && <LinearProgress color="secondary" className={classes.loadingBar} />}
    </>
  );
};

ManualChargeForm.propTypes = {
  classes: PropTypes.object.isRequired,
  store: PropTypes.object.isRequired,
  toast: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withStore, withToast)(ManualChargeForm);
