import { localToUtcTime } from 'customer/components/policy/dates';
import { isBefore } from 'date-fns';

export const PolicyStatus = {
  Cancelled: 'Cancelled',
  InCancellation: 'In Cancellation',
  Inactive: 'Inactive',
  Future: 'Future',
  Active: 'Active'
};

export const getPolicyStatus = (policy) => {
  const { endDate, effectiveDate, state, fullTermPolicyEndDate } = policy;
  const now = new Date();
  const endDateFromPolicy = localToUtcTime(`${endDate}T23:59:59.99`, state);

  const originalEndDate = localToUtcTime(fullTermPolicyEndDate.concat('T23:59:59.99'), state);
  // if the policy has been cancelled then use the cancelledEndDate variable, if the policy wasn't cancelled the variable will be undefined
  // and the condition will not be reached
  const cancelledEndDate = isBefore(endDateFromPolicy, originalEndDate)
    ? localToUtcTime(`${endDate}T00:01:00.00`, state)
    : undefined;
  const startDateFromPolicy = localToUtcTime(effectiveDate, state);
  if (cancelledEndDate) {
    if (isBefore(cancelledEndDate, now)) {
      return PolicyStatus.Cancelled;
    }
    return PolicyStatus.InCancellation;
  }

  if (endDateFromPolicy < now) {
    return PolicyStatus.Inactive;
  }

  if (startDateFromPolicy > now) {
    return PolicyStatus.Future;
  }

  return PolicyStatus.Active;
};
