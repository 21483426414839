import React, { useContext } from 'react';

import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';

const StripeCustomerIdField = () => {
  const { canModifyBillingId } = useContext(AuthContext);
  if (!canModifyBillingId) {
    return null;
  }
  return <FormField name="stripeCustomerId" xs={4} type="string" mode="dark" label="Billing ID" />;
};

export default StripeCustomerIdField;
