const styles = (theme) => ({
  iconButtonRoot: {
    color: theme.colors.button__background_secondary,
    padding: 0,
    boxShadow: '0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12)'
  },
  iconButtonLabel: {
    height: 24,
    width: 24,
    '& > img': {
      height: 24,
      width: 24
    }
  },
  'big-iconButtonRoot': {
    color: theme.colors.button__background_secondary,
    padding: 8
  },
  'big-iconButtonLabel': {
    height: 32,
    width: 32
  },
  'sm-iconButtonLabel': {
    height: 36,
    width: 36,
    '& > img': {
      height: 36,
      width: 36
    }
  },
  'xl-iconButtonRoot': {
    color: theme.colors.button__background_secondary,
    padding: 8
  },
  'xl-iconButtonLabel': {
    height: 35,
    width: 35,
    '& > img': {
      height: 37,
      width: 35
    }
  },
  buttonAndLabelWrapper: {
    width: 'unset',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  disabledButtonAndLabelWrapper: {
    width: 'unset'
  }
});

export default styles;
