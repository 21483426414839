/* eslint-disable func-names */
import * as Yup from 'yup';

export const validationSchema = (isRefund, skipImmediateBillOrRefund) =>
  Yup.object().shape({
    notes: Yup.string()
      .ensure()
      .trim()
      .test('minLength20Prod', 'Internal notes must be 20 characters in length or more.', (value) => {
        return process.env.NODE_ENV === 'development' || window.location.hostname.includes('dev') || value.length >= 20;
      })
      .required('Internal notes are required!'),
    allowRefund: Yup.boolean().test(
      'do not delay refunds check',
      'Do not delay refunds unless necessary',
      function (value) {
        if (!isRefund) return true;
        if (!skipImmediateBillOrRefund) return true;
        if (skipImmediateBillOrRefund && isRefund) return value;
      }
    )
  });
