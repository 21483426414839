import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  title: {
    paddingBottom: 15
  },
  contentText: {
    paddingBottom: 40
  }
});

export default makeStyles(styles);
