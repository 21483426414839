const overrideTabs = (theme) => {
  // eslint-disable-next-line no-param-reassign
  theme.overrides.MuiTabs = {
    flexContainer: {
      justifyContent: 'center'
    },
    indicator: {
      height: 4
    }
  };
  // eslint-disable-next-line no-param-reassign
  theme.overrides.MuiTab = {
    root: {
      fontSize: 24,
      fontStretch: 'normal',
      lineHeight: 1.25,
      color: `${theme.colors.text_primary} !important`,
      textTransform: 'none',
      '&:hover': {
        backgroundColor: theme.colorPalette.orange_10_op_08
      }
    },
    wrapper: {
      '@media (min-width: 0px)': {
        padding: '27px 20px'
      }
    },
    selected: {
      fontWeight: 500
    }
  };
};

export default overrideTabs;
