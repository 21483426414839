import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import SetPasswordForm from './set-user-password-form';
import useStyles from './set-user-password.styles';

const SetPassword = () => {
  const [open, setOpen] = useState(false);
  const closeModal = () => setOpen(false);
  const classes = useStyles();
  const { account } = useStore();

  return (
    <>
      <Button className={classes.setPasswordTrigger} variant="text" color="secondary" onClick={() => setOpen(true)}>
        <LockOutlinedIcon />
        &nbsp; Set password
      </Button>
      <Modal open={open} onClose={closeModal}>
        <div className={classNames(classes.container)}>
          <IconButton edge="start" color="inherit" onClick={closeModal} className={classes.close} aria-label="close">
            <CloseIcon fontSize="inherit" />
          </IconButton>
          <h2 className={classes.modalTitle}>Set Password</h2>
          <span className={classes.info}>
            This will remove the client&#39;s current password and set a new one for them to use
          </span>
          <p className={classes.confirm}>
            Please make sure you have confirmed your client&#39;s identity before proceeding with this change
          </p>
          <SetPasswordForm username={account.id} classes={classes} onSuccess={closeModal} />
        </div>
      </Modal>
    </>
  );
};

export default observer(SetPassword);
