import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Label } from 'core';

const PotentialMatches = ({ matchLabel, classes, fromCheckout, setMatchesResult, offerId, history, hits }) => (
  <Grid item className={hits ? classes.potentialMatches : classes.noPotentialMatches}>
    <Label className={classes.matchLabel}>{matchLabel}</Label>
    {hits && (
      <Label
        type="action"
        className={hits ? classes.matchAction : classes.matchAction2}
        onClick={() => {
          setMatchesResult(true);
          if (fromCheckout) {
            history.push(`/offer/${offerId}`);
          }
        }}
      >
        {fromCheckout ? 'Go back to modify offer to see all matches' : 'See all matches'}
      </Label>
    )}
  </Grid>
);

PotentialMatches.propTypes = {
  matchLabel: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  fromCheckout: PropTypes.bool.isRequired,
  setMatchesResult: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  offerId: PropTypes.string.isRequired
};

export default PotentialMatches;
