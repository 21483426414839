/* eslint-disable no-underscore-dangle */
import { types, flow } from 'mobx-state-tree';
import _uniqBy from 'lodash-es/uniqBy';

import { affinityLookupsRequest, agencyInformationRequest } from './core-resources.service';

const GET_ALL = 'ALL';
/**  This store will be use for general metadata
 * we need to ask in parallel of the policy data
 */
export const AffinityLookupsStore = types
  .model({
    // we use loading for page blocking operations
    loading: types.boolean,
    // we use partial loading, for component blocking operations
    partialLoading: types.boolean,
    data: types.optional(types.frozen(), []),
    agencyInformation: types.optional(types.frozen(), [])
  })
  .views((self) => ({
    get securityProviders() {
      return _uniqBy(
        self.data.filter((aff) => aff.data.homeSecurity),
        'name'
      ).map((aff) => ({ id: aff.name, value: aff.name }));
    }
  }))
  .actions((self) => ({
    getAll: flow(function* getAll() {
      if (!self.data.length) {
        self.loading = true;
        try {
          const response = yield affinityLookupsRequest(null, GET_ALL);
          self.data = self.data.concat(response);
          self.loading = false;
        } catch (e) {
          self.loading = false;
          throw new Error("couldn't retrieve affinityLookups");
        }
      }
    }),
    // @NOTE For V2 will be desired to get only the affinities we need, as we need it.
    // Right now I decided to postpone this for a different refactor since there are many unknows that needs to be resolved
    getByAffinity: flow(function* getByAffinity(affinity) {
      if (!self.data.find((aff) => aff.affinity === affinity)) {
        self.partialLoading = true;
        try {
          const response = yield affinityLookupsRequest({ affinity });
          self.data = self.data.concat(response);
          self.partialLoading = false;
        } catch (e) {
          self.partialLoading = false;
        }
      }
    }),
    getByAffinityList: flow(function* getByAffinityList(list) {
      if (list.length) {
        self.partialLoading = true;
        try {
          const response = yield affinityLookupsRequest({ affinities: list });
          self.data = _uniqBy(self.data.concat(response), 'affinity');
          self.partialLoading = false;
        } catch (e) {
          self.partialLoading = false;
        }
      }
    }),
    getSecurityProviders: flow(function* getSecurityProviders() {
      self.partialLoading = true;
      try {
        const response = yield affinityLookupsRequest({ data: { homeSecurity: true } }, GET_ALL);
        self.data = _uniqBy(self.data.concat(response), 'affinity');
        self.partialLoading = false;
      } catch (e) {
        self.partialLoading = false;
      }
    }),
    getAgencyInformation: flow(function* getAgencyInformation(code) {
      self.partialLoading = true;
      try {
        const response = yield agencyInformationRequest(code);
        // if is not found, just store an empty object to fix the de-sync between cognito users and our data
        self.agencyInformation = { ...self.agencyInformation, [code]: response.length > 0 ? response[0] : {} };
        self.partialLoading = false;
      } catch (e) {
        self.partialLoading = false;
      }
    })
  }));
