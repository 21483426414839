import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import { ActionButton } from 'core/components/action-button';
import Section from 'core/components/section';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';
import { currencyFormatter } from 'core/helpers/formatters';
import closeIcon from './icon-orange-close.svg';
import useStyles from './breakdown.preview.styles';
import useAutoPremiumBreakdown from './use-auto-premium-breakdown';

const formatCoverage = (coverage) => {
  if (typeof coverage === 'boolean') {
    return coverage ? 'Purchased' : 'Not Purchased';
  }
  if (coverage === 'true') {
    return 'Purchased';
  }
  return isNaN(coverage) ? coverage || 'NONE' : currencyFormatter(coverage);
};

function AutoBreakdownPreview({ offer, policyType, cars, autoCoverage, triggerText, premiumBreakdown }) {
  const { viewOnly } = useContext(AuthContext);
  const { offer: offerStore } = useStore();
  const [showAutoBreakdown, setShouldShowAutoBreakdown] = useState(false);
  const classes = useStyles();

  const { downloadOffer } = offerStore;

  const { premiumCarsCoverages, premiumAutoCoverage } = useAutoPremiumBreakdown(
    offer,
    policyType,
    premiumBreakdown,
    cars
  );

  const getPremiumCoverage = (vin, prop) =>
    typeof premiumCarsCoverages?.[vin] === 'object' ? premiumCarsCoverages?.[vin]?.[prop] || 'N/A' : 'N/A';

  const getAutoCoverageWithPremium = (prop) =>
    `${
      autoCoverage[prop]?.split('/')?.reduce((s, v, i) => `${s}${i !== 0 ? '/' : ''}${currencyFormatter(v)}`, '') ||
      'NONE'
    } | ${premiumAutoCoverage[prop] || 'N/A'}`;

  return (
    <>
      <Section>
        <Grid>
          <Button
            color="secondary"
            variant="text"
            onClick={() => {
              setShouldShowAutoBreakdown(true);
            }}
          >
            {triggerText}
          </Button>
        </Grid>
      </Section>
      <Dialog open={showAutoBreakdown} fullWidth maxWidth="md">
        <DialogContent className={classes.modal}>
          <ActionButton
            type="edit"
            size="big"
            icon={closeIcon}
            className={classes.closeButton}
            onClick={() => setShouldShowAutoBreakdown(false)}
          />
          <Label className={classes.title} type="greenBig">
            Auto Premium Breakdown
            {!viewOnly && (
              <Button
                color="secondary"
                variant="text"
                onClick={() => {
                  downloadOffer({
                    offerId: offer?.id,
                    clusterId: offer?.clusterId,
                    code: offer?.code,
                    policyType
                  });
                }}
              >
                Download PDF
              </Button>
            )}
          </Label>
          <Formik initialValues={{}}>
            {() => (
              <>
                <Section
                  title="Cars"
                  type="SubSection"
                  rightLabel="Total"
                  rightValue={`${cars.length} ${cars.length === 1 ? 'car' : 'cars'}`}
                >
                  {cars.map((car, i) => (
                    <div className={classes.container} key={i}>
                      <Grid container className={classes.containerDark} spacing={4}>
                        <Grid item xs={12}>
                          <Label className={[classes.subtitle, classes.largeLabel].join(' ')} type="whiteMedium">
                            {`${car?.year} ${car?.make} ${car?.model}`}
                          </Label>
                        </Grid>
                        <FormField
                          mode="dark"
                          name={`Collision-Deductible-${i}`}
                          label="Collision Deductible"
                          type="value"
                          value={`${formatCoverage(car.deductibleCollision)} | ${getPremiumCoverage(
                            car.VIN,
                            'deductibleCollision'
                          )}`}
                          xs={4}
                        />
                        <FormField
                          mode="dark"
                          name={`Comprehensive-Deductible-${i}`}
                          label="Comprehensive Deductible"
                          type="value"
                          value={`${formatCoverage(car.deductibleComprehensive)} | ${getPremiumCoverage(
                            car.VIN,
                            'deductibleComprehensive'
                          )}`}
                          xs={4}
                        />
                        <FormField
                          mode="dark"
                          name={`Additional-Custom-Parts-Limit-${i}`}
                          label="Additional Custom Parts Limit"
                          type="value"
                          value={`${formatCoverage(car.limitACPE)} | ${getPremiumCoverage(car.VIN, 'limitACPE')}`}
                          xs={4}
                        />
                        <FormField
                          mode="dark"
                          name={`Umuim-Property-Damage-Limit-${i}`}
                          label="UM/UIM Property Damage Limit"
                          type="value"
                          value={`${formatCoverage(car.limitUMPD)} | ${getPremiumCoverage(car.VIN, 'limitUMPD')}`}
                          xs={4}
                        />
                        <FormField
                          mode="dark"
                          name={`Daily-Rental-Car-Limit-${i}`}
                          label="Daily Rental Car Limit"
                          type="value"
                          value={`${car.limitRental || 'NONE'} | ${getPremiumCoverage(car.VIN, 'limitRental')}`}
                          xs={8}
                        />
                        <FormField
                          mode="dark"
                          name={`Roadside-Assistance-${i}`}
                          label="Roadside Assistance"
                          type="value"
                          value={`${car.roadsideAssistance ? 'Yes' : 'No'} | ${getPremiumCoverage(
                            car.VIN,
                            'roadsideAssistance'
                          )}`}
                          xs={4}
                        />
                        <FormField
                          mode="dark"
                          name={`Ride-Sharing-${i}`}
                          label="Ride Sharing"
                          type="value"
                          value={`${car.rideSharing ? 'Yes' : 'No'} | ${getPremiumCoverage(car.VIN, 'rideSharing')}`}
                          xs={4}
                        />
                        <FormField
                          mode="dark"
                          name={`LL-Gap-Coverage-${i}`}
                          label="Lease/Loan Gap Coverage"
                          type="value"
                          value={`${formatCoverage(car.coverageLoan)} | ${getPremiumCoverage(car.VIN, 'coverageLoan')}`}
                          xs={4}
                        />
                      </Grid>
                    </div>
                  ))}
                </Section>
                <Section title="Auto Policy Coverage" type="SubSection">
                  <div className={classes.container}>
                    <Grid container className={classes.containerDark} spacing={4}>
                      <FormField
                        mode="dark"
                        name={`Property-Damage-Limit`}
                        label="Bodily Injury/ Property Damage Limit"
                        type="value"
                        value={getAutoCoverageWithPremium('policyLimitBIPD')}
                        xs={6}
                      />
                      <FormField
                        mode="dark"
                        name={`Medical-Payment-Per-Person`}
                        label="Medical Payment Per Person"
                        type="value"
                        value={getAutoCoverageWithPremium('policyLimitMedicalPayments')}
                        xs={6}
                      />
                      <FormField
                        mode="dark"
                        name={`Uninsured-Motorists`}
                        label="Uninsured Motorists BI"
                        type="value"
                        value={getAutoCoverageWithPremium('policyLimitUMBI')}
                        xs={6}
                      />
                      <FormField
                        mode="dark"
                        name={`Under-Insured-Motorists`}
                        label="Underinsured Motorists BI"
                        type="value"
                        value={getAutoCoverageWithPremium('policyLimitUIMBI')}
                        xs={6}
                      />
                    </Grid>
                  </div>
                </Section>
              </>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}

AutoBreakdownPreview.propTypes = {
  offer: PropTypes.object,
  policyType: PropTypes.string,
  cars: PropTypes.array,
  autoCoverage: PropTypes.object,
  triggerText: PropTypes.string,
  premiumBreakdown: PropTypes.object
};

AutoBreakdownPreview.defaultProps = {
  policyType: 'A',
  cars: [],
  autoCoverage: {},
  offer: {},
  triggerText: 'See Auto Premium Breakdown',
  premiumBreakdown: undefined
};

export default AutoBreakdownPreview;
