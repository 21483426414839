import { makeStyles } from '@material-ui/core';

const materialStyles = (theme) => ({
  '@global': {
    '#CARD_ELEMENT_ID': {
      backgroundColor: theme.palette.common.white,
      padding: theme.spacing(0, 2),
      height: 32,
      display: 'flex',
      alignItems: 'center'
    }
  },
  cardContainer: {
    flexGrow: 1,
    marginBottom: 0,
    marginRight: theme.spacing(2)
  },
  submit: {
    width: 168,
    marginRight: theme.spacing(6)
  },
  container: {
    padding: theme.spacing(2, 7),
    backgroundColor: 'rgba(255, 255, 255, 0.1)'
  },
  label: {
    lineHeight: '14px',
    marginBottom: theme.spacing(1)
  },
  loadingBar: {
    height: 2
  }
});

export const styles = (theme) => ({
  cardElementStyle: {
    base: {
      fontSize: 14,
      fontFamily: '"Libre Franklin", sans-serif',
      color: theme.colorPalette.green_10,
      fontWeight: '400',
      backgroundColor: theme.palette.common.white,
      '::placeholder': {
        fontSize: 14,
        color: theme.colorPalette.grey_10_op_38
      }
    },
    invalid: {
      color: theme.palette.error.main
    }
  }
});

export default makeStyles(materialStyles);
