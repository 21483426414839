import React from 'react';
import PropTypes from 'prop-types';

import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import coffeeIcon from './assets/coffee.svg';
import useStyles from './no-data-card.styles';

const NoDataCard = ({ message }) => {
  const classes = useStyles();
  return (
    <Card type="noData">
      <div className={classes.container}>
        <img src={coffeeIcon} alt="Coffee mug icon" />
        <Label type="darkGreenMedium">{message}</Label>
      </div>
    </Card>
  );
};

NoDataCard.propTypes = {
  message: PropTypes.string
};

NoDataCard.defaultProps = {
  message: 'Relax, there are no items here.'
};

export default NoDataCard;
