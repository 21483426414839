import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';

const Five9NotesModal = ({ ticket, onClose, isOpen }) => {
  const notes = ticket.notes ? ticket.notes : 'No notes were recorded for this interaction';
  const subject = ticket.subject ? ticket.subject : 'No Subject';
  return (
    <BaseDialog open={isOpen} onClose={onClose} size="lg">
      <Label type="subtitle">{subject}</Label>
      <br />
      <Label type="infoValueFormatted">{notes}</Label>
    </BaseDialog>
  );
};
Five9NotesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

export default Five9NotesModal;
