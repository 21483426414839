import { USAStatesByAbbv } from '@ourbranch/utility-types';

import largeAgencyCodes from './largeAgencyCodes';

type AffinityGroups = {
  [key: string]: {
    hash: string;
    homeSecurity?: boolean;
    name?: string;
    states?: { [Key in USAStatesByAbbv]?: number };
    agency?: boolean;
    disableChat?: boolean;
    additional?: number;
    signUpThroughBranch?: boolean;
    informationId?: string;
    ccEmail?: string;
    achWildcard?: boolean;
    shouldBeNotified?: boolean;
    mortgageLender?: boolean;
    featuredProvider?: boolean;
    skipScheduledCall?: boolean;
    serviceAgreement?: boolean;
    canBeRemoved?: boolean;
    allowRestricted?: { [Key in USAStatesByAbbv]?: boolean };
    hideFromOffers?: boolean;
    mortgageClause?: string;
    splitLeadID?: boolean;
  };
};

const affinityGroups: AffinityGroups = {
  A360: {
    hash: 'fdsa360ee',
    homeSecurity: true,
    name: 'Alert 360',
    states: {
      AZ: 2,
      OH: 2,
      IL: 2,
      IN: 2,
      MD: 2,
      MO: 2,
      PA: 2,
      TX: 2
    }
  },
  A360E: {
    hash: 'fda360eeeeemp',
    homeSecurity: true,
    name: 'Alert 360',
    states: {
      AZ: 2,
      OH: 2,
      IL: 2,
      IN: 2,
      MD: 2,
      MO: 2,
      TX: 2
    }
  },
  AAA1: {
    hash: 'f98j1kljzc',
    name: 'American Automobile Association',
    agency: true,
    disableChat: true,
    informationId: 'AAA1'
  },
  AA0001_1P: {
    hash: 'AA0001_1Phash',
    name: 'All American Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'AA0001_1P'
  },
  AA9P: {
    hash: 'AA9Phash',
    name: 'Giles & Iten Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AA9P'
  },
  AA10P: {
    hash: 'AA10Phash',
    name: 'Mabey Insurance LLC',
    agency: true,
    disableChat: true,
    informationId: 'AA10P'
  },
  AA12P: {
    hash: 'AA12Phash',
    name: 'SunBow Marketing LLC DBA Foundation Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'AA12P'
  },
  AA14_1: {
    hash: 'AA14_1hash',
    name: 'Asmann Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AA14_1'
  },
  AB0001: {
    hash: 'AB0001hash',
    name: 'Above All Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AB0001'
  },
  AB0001_1: {
    hash: 'AB0001_1hash',
    name: 'Absolute Integrity Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AB0001_1'
  },
  AB0002: {
    hash: 'AB0002hash',
    name: 'Absolute Insurance Group, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AB0002'
  },
  AB0003: {
    hash: 'AB0003hash',
    name: 'Abbe Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AB0003'
  },
  AB0004_1P: {
    hash: 'AB0004_1Phash',
    name: 'Abacus Insurance and Financial Services, LLC',
    agency: true,
    disableChat: true,
    informationId: 'AB0004_1P'
  },
  AB1: {
    hash: 'fdsvu4e',
    homeSecurity: true,
    canBeRemoved: true,
    name: 'Abode Pro Plan',
    additional: 100,
    states: {
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WV: 2,
      WI: 2
    }
  },
  /** For existing Abode Pro Plan customers who shouldn't be charged for it */
  AB2: {
    hash: '4jhif9818v',
    homeSecurity: true,
    name: 'Abode Pro Plan (Existing Customers)',
    states: {
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      TX: 2,
      VA: 2,
      WV: 2,
      WI: 2
    }
  },
  ABC1: {
    hash: 'fdsvabcu4e',
    name: 'Action Behavior Centers',
    states: {
      AZ: 2,
      CO: 2,
      IL: 2,
      IN: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      OH: 2,
      OK: 2,
      PA: 2,
      TX: 2
    }
  },
  AC1: {
    hash: 'fdscccce',
    name: 'Acorns',
    states: {
      AZ: 0,
      OH: 0,
      IL: 0,
      IN: 0,
      MO: 0,
      TX: 0
    }
  },
  AC0001: {
    hash: 'AC0001has',
    name: 'Angell & Co LLC',
    agency: true,
    disableChat: true,
    informationId: 'AC0001'
  },
  AC0002: {
    hash: 'AC0002hash',
    name: 'Dixie LLC DBA ACI',
    agency: true,
    disableChat: true,
    informationId: 'AC0002'
  },
  AC0003: {
    hash: 'AC0003hash',
    name: 'Action East Financial Agency',
    agency: true,
    disableChat: true,
    informationId: 'AC0003'
  },
  AC0004_1P: {
    hash: 'AC0004_1Phash',
    name: 'Acoma Insurance Professionals, LLC',
    agency: true,
    disableChat: true,
    informationId: 'AC0004_1P'
  },
  ACR0001P: {
    hash: 'ACR0001Phash',
    name: 'Frates Irwin Risk Management',
    agency: true,
    disableChat: true,
    informationId: 'ACR0001P'
  },
  AD0001: {
    hash: 'AD0001hash',
    name: 'Marcum Insurance LLC DBA Advantage Insurance Agency of Alabama',
    agency: true,
    disableChat: true,
    informationId: 'AD0001'
  },
  AD0001P: {
    hash: 'AD0001Phash',
    name: 'Marcum Insurance LLC DBA Advantage Insurance Agency of Alabama',
    agency: true,
    disableChat: true,
    informationId: 'AD0001P'
  },
  AD0003: {
    hash: 'AD0003hash',
    name: 'Advantage Partners Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AD0003'
  },
  AD0004_1: {
    hash: 'AD0004_1hash',
    name: 'West Valley Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AD0004_1'
  },
  AD0004_1P: {
    hash: 'AD0004_1Phash',
    name: 'West Valley Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AD0004_1P'
  },
  AD0005_1: {
    hash: 'AD0005_1hash',
    name: 'Pro Insurance Services, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'AD0005_1'
  },
  AD0007_1P: {
    hash: 'AD0007_1Phash',
    name: 'Advantage Insurance Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AD0007_1P'
  },

  AD1: {
    hash: 'addhv8',
    featuredProvider: true,
    homeSecurity: true,
    name: 'ADT',
    signUpThroughBranch: true,
    states: {
      AL: 2,
      AR: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },
  ADTBLUE: {
    hash: 'addhv8A',
    featuredProvider: true,
    homeSecurity: true,
    name: 'ADT',
    signUpThroughBranch: true,
    states: {
      AL: 2,
      AR: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },
  ADE: {
    hash: 'addhv8EA',
    featuredProvider: true,
    homeSecurity: true,
    name: 'ADT Employee',
    states: {
      AL: 2,
      AR: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },
  AE0001: {
    hash: 'AE0001has',
    name: 'Aegis Group, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'AE0001'
  },
  AGD0001: {
    hash: 'AGD0001has',
    name: 'A. G. D. Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'AGD0001'
  },
  AG0001: {
    hash: 'AG0001hash',
    name: 'Agent Insurance Group, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'AG0001'
  },
  AGHRO: {
    hash: 'baager',
    name: 'Agent Hero',
    agency: true,
    disableChat: true
  },
  AGHRO48P: {
    hash: 'AGHRO48Phash',
    name: 'Hometown Insurance Services, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AGHRO48P'
  },
  AGHRO34P: {
    hash: 'AGHRO34Phash',
    name: 'Kevin S. Dougherty Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AGHRO34P'
  },
  AGHRO113P: {
    hash: 'AGHRO113Phash',
    name: 'The Todd D Tucker Agency, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'AGHRO113P'
  },
  AGM1: {
    name: 'Affinity Group Mortgage',
    hash: '7dhdf8vc'
  },
  AHG0001P: {
    hash: 'AHG0001Phash',
    name: 'Auto Home Group',
    agency: true,
    disableChat: true,
    informationId: 'AHG0001P'
  },
  AGHRO107P: {
    hash: 'AGHRO107Phash',
    name: 'Insuragy, LLC',
    agency: true,
    disableChat: true,
    informationId: 'AGHRO107P'
  },
  AGHRO111P: {
    hash: 'AGHRO111Phash',
    name: 'Scheer Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'AGHRO111P'
  },
  AGRO226: {
    hash: 'AGRO226hash',
    name: 'Concrete Insurance',
    disableChat: true,
    informationId: 'AGRO226'
  },
  AGRO247: {
    hash: 'AGRO247hash',
    name: 'Sergios Insurance Agency',
    disableChat: true,
    informationId: 'AGRO247'
  },
  AGHRO266P: {
    hash: 'AGHRO266Phash',
    name: 'Golden Oak Insurance Services Inc',
    disableChat: true,
    informationId: 'AGHRO266P',
    agency: true
  },
  AGHRO425P: {
    hash: 'AGHRO425Phash',
    name: 'McMichael Insurance',
    disableChat: true,
    informationId: 'AGHRO425P',
    agency: true
  },
  AGHRO453_1: {
    hash: 'AGHRO453_1hash',
    name: 'Charles Insurance Agency, LLC',
    disableChat: true,
    informationId: 'AGHRO453_1',
    agency: true
  },
  AGHRO454_1: {
    hash: 'AGHRO454_1hash',
    name: 'Mintz Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO454_1',
    agency: true
  },
  AGHRO454_2: {
    hash: 'AGHRO454_2hash',
    name: 'Mintz Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO454_2',
    agency: true
  },
  AGHRO455_1: {
    hash: 'AGHRO455_1hash',
    name: 'Richmond & Associates, LLC.',
    disableChat: true,
    informationId: 'AGHRO455_1',
    agency: true
  },
  AGHRO456_1: {
    hash: 'AGHRO456_1hash',
    name: 'Integrity Insrouce Group NE LLC',
    disableChat: true,
    informationId: 'AGHRO456_1',
    agency: true
  },
  AGHRO456_2: {
    hash: 'AGHRO456_2hash',
    name: 'Integrity Insrouce Group NE LLC',
    disableChat: true,
    informationId: 'AGHRO456_2',
    agency: true
  },
  AGHRO456_3: {
    hash: 'AGHRO456_3hash',
    name: 'Integrity Insrouce Group NE LLC',
    disableChat: true,
    informationId: 'AGHRO456_3',
    agency: true
  },
  AGHRO457_1: {
    hash: 'AGHRO457_1hash',
    name: 'Youngbar Agency',
    disableChat: true,
    informationId: 'AGHRO457_1',
    agency: true
  },
  AGHRO457_2: {
    hash: 'AGHRO457_2hash',
    name: 'Youngbar Agency',
    disableChat: true,
    informationId: 'AGHRO457_2',
    agency: true
  },
  AGHRO458_1: {
    hash: 'AGHRO458_1hash',
    name: '247 Quality Controll LLC',
    disableChat: true,
    informationId: 'AGHRO458_1',
    agency: true
  },
  AGHRO459_1: {
    hash: 'AGHRO459_1hash',
    name: 'Robert J Feest & Associates',
    disableChat: true,
    informationId: 'AGHRO459_1',
    agency: true
  },
  AGHRO460_1: {
    hash: 'AGHRO460_1hash',
    name: 'The Chase Agency LLC DBA Chase Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO460_1',
    agency: true
  },
  AGHRO460_2: {
    hash: 'AGHRO460_2hash',
    name: 'The Chase Agency LLC DBA Chase Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO460_2',
    agency: true
  },
  AGHRO460_3: {
    hash: 'AGHRO460_3hash',
    name: 'The Chase Agency LLC DBA Chase Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO460_3',
    agency: true
  },
  AGHRO461_1: {
    hash: 'AGHRO461_1hash',
    name: 'Irin Savage Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO461_1',
    agency: true
  },
  AGHRO462_1: {
    hash: 'AGHRO462_1hash',
    name: 'All Assured SOlutions',
    disableChat: true,
    informationId: 'AGHRO462_1',
    agency: true
  },
  AGHRO463_1: {
    hash: 'AGHRO463_1hash',
    name: 'The Coeho Insurance Agency LLC',
    disableChat: true,
    informationId: 'AGHRO463_1',
    agency: true
  },
  AGHRO464_1: {
    hash: 'AGHRO464_1hash',
    name: 'Thomas A Mathews',
    disableChat: true,
    informationId: 'AGHRO464_1',
    agency: true
  },
  AGHRO467_1: {
    hash: 'AGHRO467_1hash',
    name: 'Hells Canyon Insurance LLC',
    disableChat: true,
    informationId: 'AGHRO467_1',
    agency: true
  },
  AGHRO468_1: {
    hash: 'AGHRO468_1hash',
    name: 'The DeMarti Group',
    disableChat: true,
    informationId: 'AGHRO468_1',
    agency: true
  },
  AGHRO470_1: {
    hash: 'AGHRO470_1hash',
    name: 'Sword and Shield Family Insurance',
    disableChat: true,
    informationId: 'AGHRO470_1',
    agency: true
  },
  AGHRO470_1P: {
    hash: 'AGHRO470_1Phash',
    name: 'Sword and Shield Family Insurance',
    disableChat: true,
    informationId: 'AGHRO470_1P',
    agency: true
  },
  AGHRO471_1: {
    hash: 'AGHRO471_1hash',
    name: 'The Coverage Cartel',
    disableChat: true,
    informationId: 'AGHRO471_1',
    agency: true
  },
  AGHRO471_1P: {
    hash: 'AGHRO471_1Phash',
    name: 'The Coverage Cartel',
    disableChat: true,
    informationId: 'AGHRO471_1P',
    agency: true
  },
  AGHRO472_1: {
    hash: 'AGHRO472_1hash',
    name: 'Marlo Professional Services, LLC.',
    disableChat: true,
    informationId: 'AGHRO472_1',
    agency: true
  },
  AGHRO473_1: {
    hash: 'AGHRO473_1hash',
    name: 'Value Insurance',
    disableChat: true,
    informationId: 'AGHRO473_1',
    agency: true
  },
  AGHRO473_1P: {
    hash: 'AGHRO473_1Phash',
    name: 'Value Insurance',
    disableChat: true,
    informationId: 'AGHRO473_1P',
    agency: true
  },
  AGHRO477_1: {
    hash: 'AGHRO477_1hash',
    name: 'A Plus Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO477_1',
    agency: true
  },
  AGHRO478_1: {
    hash: 'AGHRO478_1hash',
    name: 'Kididis Insurance Group',
    disableChat: true,
    informationId: 'AGHRO478_1',
    agency: true
  },
  AGHRO478_1P: {
    hash: 'AGHRO478_1Phash',
    name: 'Kididis Insurance Group',
    disableChat: true,
    informationId: 'AGHRO478_1P',
    agency: true
  },
  AGHRO479_1: {
    hash: 'AGHRO479_1hash',
    name: 'Premier Insurance Group',
    disableChat: true,
    informationId: 'AGHRO479_1',
    agency: true
  },
  AGHRO480_1: {
    hash: 'AGHRO480_1hash',
    name: 'Sonya Ambrosia DBA Another Level Agency',
    disableChat: true,
    informationId: 'AGHRO480_1',
    agency: true
  },
  AGHRO480_1P: {
    hash: 'AGHRO480_1Phash',
    name: 'Sonya Ambrosia DBA Another Level Agency',
    disableChat: true,
    informationId: 'AGHRO480_1P',
    agency: true
  },
  AGHRO482_1: {
    hash: 'AGHRO482_1hash',
    name: 'First Choice Insurance Group, LLC.',
    disableChat: true,
    informationId: 'AGHRO482_1',
    agency: true
  },
  AGHRO482_1P: {
    hash: 'AGHRO482_1Phash',
    name: 'First Choice Insurance Group, LLC.',
    disableChat: true,
    informationId: 'AGHRO482_1P',
    agency: true
  },
  AGHRO483_1: {
    hash: 'AGHRO483_1hash',
    name: 'Moore Insurance Team LLC.',
    disableChat: true,
    informationId: 'AGHRO483_1',
    agency: true
  },
  AGHRO486_1: {
    hash: 'AGHRO486_1hash',
    name: 'Messina Insurance Group',
    disableChat: true,
    informationId: 'AGHRO486_1',
    agency: true
  },
  AGHRO487_1P: {
    hash: 'AGHRO487_1Phash',
    name: 'THE J DAVIS AGENCY',
    disableChat: true,
    informationId: 'AGHRO487_1P',
    agency: true
  },
  AGHRO490_1P: {
    hash: 'AGHRO490_1Phash',
    name: 'US AMERICA INSURANCE AGENCY INC',
    disableChat: true,
    informationId: 'AGHRO490_1P',
    agency: true
  },
  AGHRO492_1P: {
    hash: 'AGHRO492_1Phash',
    name: 'Calibre Insurance Advisors LLC',
    disableChat: true,
    informationId: 'AGHRO492_1P',
    agency: true
  },
  AGHRO493_1P: {
    hash: 'AGHRO493_1Phash',
    name: 'Clem Insurance Group',
    disableChat: true,
    informationId: 'AGHRO493_1P',
    agency: true
  },
  AGHRO496_1P: {
    hash: 'AGHRO496_1Phash',
    name: 'R PEREZ INSURANCE AGENCY LLC',
    disableChat: true,
    informationId: 'AGHRO496_1P',
    agency: true
  },
  AGHRO497_1P: {
    hash: 'AGHRO497_1Phash',
    name: 'ABOVE Asset Protection LLC',
    disableChat: true,
    informationId: 'AGHRO497_1P',
    agency: true
  },
  AGHRO498_1P: {
    hash: 'AGHRO498_1Phash',
    name: 'Valencia Insurance Agency',
    disableChat: true,
    informationId: 'AGHRO498_1P',
    agency: true
  },
  AGHRO500_1P: {
    hash: 'AGHRO500_1Phash',
    name: 'Midtown Coverage',
    disableChat: true,
    informationId: 'AGHRO500_1P',
    agency: true
  },
  AGHRO502_1P: {
    hash: 'AGHRO502_1Phash',
    name: 'Common Carrier Insurance Agency LLC',
    disableChat: true,
    informationId: 'AGHRO502_1P',
    agency: true
  },
  AGHRO503_1P: {
    hash: 'AGHRO503_1Phash',
    name: 'INSURANCE GENIE LLC',
    disableChat: true,
    informationId: 'AGHRO503_1P',
    agency: true
  },
  AGHRO504_1P: {
    hash: 'AGHRO504_1Phash',
    name: 'PMI INSURANCE AGENCY LLC',
    disableChat: true,
    informationId: 'AGHRO504_1P',
    agency: true
  },
  AGHRO506_1P: {
    hash: 'AGHRO506_1Phash',
    name: 'GUARDED AGENCY',
    disableChat: true,
    informationId: 'AGHRO506_1P',
    agency: true
  },
  AGHRO508_1P: {
    hash: 'AGHRO508_1Phash',
    name: 'Valley Insurance Consultants LLC',
    disableChat: true,
    informationId: 'AGHRO508_1P',
    agency: true
  },
  AGHRO509_1P: {
    hash: 'AGHRO509_1Phash',
    name: 'INS~R LLC',
    disableChat: true,
    informationId: 'AGHRO509_1P',
    agency: true
  },
  AIA0001_1P: {
    hash: 'AIA0001_1Phash',
    name: 'Atlas Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'AIA0001_1P'
  },
  AIA0001_10P: {
    hash: 'AIA0001_10Phash',
    name: 'Alabama Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AIA0001_10P'
  },
  AIB0002: {
    hash: 'AIB0002hash',
    name: 'Insurance Resource Group',
    agency: true,
    disableChat: true,
    informationId: 'AIB0002'
  },
  AIB0002D: {
    hash: 'aib0002dhash',
    name: 'Insurance Resource Group'
  },
  AIB0001_1P: {
    hash: 'AIB0001_1Phash',
    name: 'Atlas Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'AIB0001_1P'
  },
  AIB0001_7P: {
    hash: 'AIB0001_7Phash',
    name: 'Lucas Flood Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'AIB0001_7P'
  },
  AIB0003: {
    hash: 'AIB0003hash',
    name: 'Greg Ganyo Agency',
    agency: true,
    disableChat: true,
    informationId: 'AIB0003'
  },

  AIB0003P: {
    hash: 'AIB0003Phash',
    name: 'Greg Ganyo Agency',
    agency: true,
    disableChat: true,
    informationId: 'AIB0003P'
  },
  AIB0004: {
    hash: 'AIB0004hash',
    name: 'Lucas Flood Agency',
    agency: true,
    disableChat: true,
    informationId: 'AIB0004'
  },
  AIM0001: {
    hash: 'AIM0001has',
    name: 'Alabama Insurance Markets',
    agency: true,
    disableChat: true,
    informationId: 'AIM0001'
  },
  AIS: {
    hash: 'AIShash',
    name: 'American Insurance Strategies'
  },
  AIS1: {
    hash: 'AIS1hash',
    agency: true,
    name: 'American Insurance Strategies',
    disableChat: true
  },
  AL0001_1: {
    hash: 'AL0001_1hash',
    agency: true,
    name: 'Alpha One Insurance Agency',
    informationId: 'AL0001_1',
    disableChat: true
  },
  AL0002: {
    hash: 'AL0002hash',
    agency: true,
    name: 'Allenbrook Insurance LLC',
    informationId: 'AL0002',
    disableChat: true
  },
  AL0003: {
    hash: 'AL0003hash',
    agency: true,
    name: 'Allegiance Insurance Services, Inc.',
    informationId: 'AL0003',
    disableChat: true
  },
  AL0004_1: {
    hash: 'AL0004_1hash',
    agency: true,
    name: 'InsureYou, Inc.',
    informationId: 'AL0004_1',
    disableChat: true
  },
  AL0004_1P: {
    hash: 'AL0004_1Phash',
    agency: true,
    name: 'InsureYou, Inc.',
    informationId: 'AL0004_1P',
    disableChat: true
  },
  AL0005_1: {
    hash: 'AL0005_1hash',
    agency: true,
    name: 'Lonny Brittner Insurance PLLC, DBA Independent Brokers Arizona',
    informationId: 'AL0005_1',
    disableChat: true
  },
  AL0005_1P: {
    hash: 'AL0005_1Phash',
    agency: true,
    name: 'Lonny Brittner Insurance',
    informationId: 'AL0005_1P',
    disableChat: true
  },
  AL0006_1: {
    hash: 'AL0006_1hash',
    agency: true,
    name: 'Weston Mock Agency Inc.',
    informationId: 'AL0006_1',
    disableChat: true
  },
  AL0006_2: {
    hash: 'AL0006_2hash',
    agency: true,
    name: 'Weston Mock Agency Inc.',
    informationId: 'AL0006_2',
    disableChat: true
  },
  AL0006_3: {
    hash: 'AL0006_3hash',
    agency: true,
    name: 'Weston Mock Agency Inc.',
    informationId: 'AL0006_3',
    disableChat: true
  },
  AL0007_1: {
    hash: 'AL0007_1hash',
    agency: true,
    name: 'Mikos Insurance Group LLC',
    informationId: 'AL0007_1',
    disableChat: true
  },
  AL0007_1P: {
    hash: 'AL0007_1Phash',
    agency: true,
    name: 'Mikos Insurance Group LLC',
    informationId: 'AL0007_1P',
    disableChat: true
  },
  AL0008_1: {
    hash: 'AL0008_1hash',
    agency: true,
    name: 'Premier Choice Insurance LLC',
    informationId: 'AL0008_1',
    disableChat: true
  },
  AL0009_1: {
    hash: 'AL0009_1hash',
    agency: true,
    name: 'Asset Insurance LLC',
    informationId: 'AL0009_1',
    disableChat: true
  },
  AL0010_1: {
    hash: 'AL0010_1hash',
    agency: true,
    name: 'Better Buyer Insurance LLC',
    informationId: 'AL0010_1',
    disableChat: true
  },
  AL0011_1: {
    hash: 'AL0011_1hash',
    agency: true,
    name: 'Pinecrest Insurance',
    informationId: 'AL0011_1',
    disableChat: true
  },
  AL0011_2: {
    hash: 'AL0011_2hash',
    agency: true,
    name: 'Pinecrest Insurance',
    informationId: 'AL0011_2',
    disableChat: true
  },
  AL0011_3: {
    hash: 'AL0011_3hash',
    agency: true,
    name: 'Pinecrest Insurance',
    informationId: 'AL0011_3',
    disableChat: true
  },
  AL0012_1: {
    hash: 'AL0012_1hash',
    agency: true,
    name: 'VT Insurance Solutions',
    informationId: 'AL0012_1',
    disableChat: true
  },
  AL0012_1P: {
    hash: 'AL0012_1Phash',
    agency: true,
    name: 'VT Insurance Solutions',
    informationId: 'AL0012_1P',
    disableChat: true
  },
  AL0013_1: {
    hash: 'AL0013_1hash',
    agency: true,
    name: 'Streeter Insurance Services LLC.',
    informationId: 'AL0013_1',
    disableChat: true
  },
  AL0013_2: {
    hash: 'AL0013_2hash',
    agency: true,
    name: 'Streeter Insurance Services LLC.',
    informationId: 'AL0013_2',
    disableChat: true
  },
  AL0013_3: {
    hash: 'AL0013_3hash',
    agency: true,
    name: 'Streeter Insurance Services LLC.',
    informationId: 'AL0013_3',
    disableChat: true
  },
  AL0014_1: {
    hash: 'AL0014_1hash',
    agency: true,
    name: 'Briteline Insurance',
    informationId: 'AL0014_1',
    disableChat: true
  },
  AL0015_1: {
    hash: 'AL0015_1hash',
    agency: true,
    name: 'Rick Wagner Insurance Agency LLC',
    informationId: 'AL0015_1',
    disableChat: true
  },
  AL0016_1: {
    hash: 'AL0016_1hash',
    agency: true,
    name: 'Primar Insurance Brokers',
    informationId: 'AL0016_1',
    disableChat: true
  },
  AL0017_1: {
    hash: 'AL0017_1hash',
    agency: true,
    name: 'Norman D Maul Insurance Agency Inc.',
    informationId: 'AL0017_1',
    disableChat: true
  },
  AL0018_1: {
    hash: 'AL0018_1hash',
    agency: true,
    name: 'Connect Insurance Group LLC',
    informationId: 'AL0018_1',
    disableChat: true
  },
  AL0018_1P: {
    hash: 'AL0018_1Phash',
    agency: true,
    name: 'Connect Insurance Group LLC',
    informationId: 'AL0018_1P',
    disableChat: true
  },
  AL0019_1: {
    hash: 'AL0019_1hash',
    agency: true,
    name: 'Westside Insurance Agency LLC',
    informationId: 'AL0019_1',
    disableChat: true
  },
  AL0020_1: {
    hash: 'AL0020_1hash',
    agency: true,
    name: 'Gebharft Insurance Group LLC.',
    informationId: 'AL0020_1',
    disableChat: true
  },
  AL0021_1: {
    hash: 'AL0021_1hash',
    agency: true,
    name: 'Mizzona Insurance Consultants',
    informationId: 'AL0021_1',
    disableChat: true
  },
  AL0023_1: {
    hash: 'AL0023_1hash',
    agency: true,
    name: 'Niko Insurance Group',
    informationId: 'AL0023_1',
    disableChat: true
  },
  AL0024_1: {
    hash: 'AL0024_1hash',
    agency: true,
    name: 'Allegiance Insurance Agency LLC.',
    informationId: 'AL0024_1',
    disableChat: true
  },
  AL0024_1P: {
    hash: 'AL0024_1Phash',
    agency: true,
    name: 'Allegiance Insurance Agency LLC.',
    informationId: 'AL0024_1P',
    disableChat: true
  },

  AL0025_1: {
    hash: 'AL0025_1hash',
    agency: true,
    name: 'Rightway Insurance LLC.',
    informationId: 'AL0025_1',
    disableChat: true
  },
  AL0025_1P: {
    hash: 'AL0025_1Phash',
    agency: true,
    name: 'Rightway Insurance LLC.',
    informationId: 'AL0025_1P',
    disableChat: true
  },
  AL0026_1: {
    hash: 'AL0026_1hash',
    agency: true,
    name: 'Quigley Insurance Group LLC.',
    informationId: 'AL0026_1',
    disableChat: true
  },
  AL0026_2: {
    hash: 'AL0026_2hash',
    agency: true,
    name: 'Quigley Insurance Group LLC.',
    informationId: 'AL0026_2',
    disableChat: true
  },
  AL0026_3: {
    hash: 'AL0026_3hash',
    agency: true,
    name: 'Quigley Insurance Group LLC.',
    informationId: 'AL0026_3',
    disableChat: true
  },
  AL0027_1: {
    hash: 'AL0027_1hash',
    agency: true,
    name: 'Properity North Advisors',
    informationId: 'AL0027_1',
    disableChat: true
  },
  AL0028_1: {
    hash: 'AL0028_1hash',
    agency: true,
    name: 'Carme, Inc. DBA Aster Insurance',
    informationId: 'AL0028_1',
    disableChat: true
  },
  AL0029_1: {
    hash: 'AL0029_1hash',
    agency: true,
    name: 'Nick Hall Agency LLC.',
    informationId: 'AL0029_1',
    disableChat: true
  },
  AL0030_1: {
    hash: 'AL0030_1hash',
    agency: true,
    name: 'The Griffith Insurance Group LLC',
    informationId: 'AL0030_1',
    disableChat: true
  },
  AL0031_1: {
    hash: 'AL0031_1hash',
    agency: true,
    name: 'Bob Scholl Insurance LLC',
    informationId: 'AL0031_1',
    disableChat: true
  },
  AL0032_1: {
    hash: 'AL0032_1hash',
    agency: true,
    name: 'Covenant Insurance Services LLC',
    informationId: 'AL0032_1',
    disableChat: true
  },
  AL0033_1: {
    hash: 'AL0033_1hash',
    agency: true,
    name: 'Total Insurance Solutions',
    informationId: 'AL0033_1',
    disableChat: true
  },
  AL0035_1: {
    hash: 'AL0035_1hash',
    agency: true,
    name: 'Hemingway Insurance Services',
    informationId: 'AL0035_1',
    disableChat: true
  },
  AL0043_1P: {
    hash: 'AL0043_1Phash',
    agency: true,
    name: 'Bryant Insurance Agency, LLC',
    informationId: 'AL0043_1P',
    disableChat: true
  },
  AL0044_1: {
    hash: 'AL0044_1hash',
    agency: true,
    name: 'RK Green Inc. dba Rick Green Insurance',
    informationId: 'AL0044_1',
    disableChat: true
  },
  AL0045_1P: {
    hash: 'AL0045_1Phash',
    agency: true,
    name: 'Rachel Walter Agency, Inc. DBA Bedrock Insurance Group',
    informationId: 'AL0045_1P',
    disableChat: true
  },
  AL0046_1: {
    hash: 'AL0046_1hash',
    agency: true,
    name: 'Fox Agency PIB, LLC',
    informationId: 'AL0046_1',
    disableChat: true
  },
  AL0047_1P: {
    hash: 'AL0047_1Phash',
    agency: true,
    name: 'Alliance Insurance Partners',
    informationId: 'AL0047_1P',
    disableChat: true
  },
  AL0048_1P: {
    hash: 'AL0048_1Phash',
    agency: true,
    name: 'Vans Insurance Co, LLC.',
    informationId: 'AL0048_1P',
    disableChat: true
  },
  AL0048_1: {
    hash: 'AL0048_1hash',
    agency: true,
    name: 'Vans Insurance Co, LLC.',
    informationId: 'AL0048_1',
    disableChat: true
  },
  AL0049_1: {
    hash: 'AL0049_1hash',
    agency: true,
    name: 'I360 Insurance LLC.',
    informationId: 'AL0049_1',
    disableChat: true
  },
  AL0050_1: {
    hash: 'AL0050_1hash',
    agency: true,
    name: 'Jadwin Insurance Services',
    informationId: 'AL0050_1',
    disableChat: true
  },
  AL0051_1: {
    hash: 'AL0051_1hash',
    agency: true,
    name: 'Family First Insurance Agency, LLC.',
    informationId: 'AL0051_1',
    disableChat: true
  },
  AL0052_1P: {
    hash: 'AL0052_1Phash',
    agency: true,
    name: 'Miller Advantage Group LLC',
    informationId: 'AL0052_1P',
    disableChat: true
  },
  AL0052_1: {
    hash: 'AL0052_1hash',
    agency: true,
    name: 'Miller Advantage Group LLC',
    informationId: 'AL0052_1',
    disableChat: true
  },
  AL0056_1P: {
    hash: 'AL0056_1Phash',
    agency: true,
    name: 'Aloha Insurance LLc',
    informationId: 'AL0056_1P',
    disableChat: true
  },
  AL0058_1P: {
    hash: 'AL0058_1Phash',
    agency: true,
    name: 'All East Valley Insurance',
    informationId: 'AL0058_1P',
    disableChat: true
  },
  AL0060_1P: {
    hash: 'AL0060_1Phash',
    agency: true,
    name: 'Everything Insurance group LLC',
    informationId: 'AL0060_1P',
    disableChat: true
  },
  AL0061_1P: {
    hash: 'AL0061_1Phash',
    agency: true,
    name: 'Bator Insurance Group LLC',
    informationId: 'AL0061_1P',
    disableChat: true
  },
  AL0064_1P: {
    hash: 'AL0064_1Phash',
    agency: true,
    name: 'Kreisman Insurance Group',
    informationId: 'AL0064_1P',
    disableChat: true
  },
  ALP0001: {
    hash: 'ALP0001hash',
    agency: true,
    name: 'Alpine Life Planning Strategies',
    informationId: 'ALP0001',
    disableChat: true
  },
  ALNK1: {
    hash: 'alnk1hash',
    agency: true,
    name: 'ALINK Insurance Service',
    disableChat: true
  },
  ANSFIN1: {
    name: 'Answer Financial',
    hash: '0jkl298lsdf',
    agency: true,
    disableChat: true
  },
  AM0001_1: {
    hash: 'AM0001_1hash',
    name: 'American Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'AM0001_1'
  },
  AM0001_2: {
    hash: 'AM0001_2hash',
    name: 'American Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'AM0001_2'
  },
  AM0002_1P: {
    hash: 'AM0002_1Phash',
    name: 'Amana Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AM0002_1P'
  },
  AM0004_1P: {
    hash: 'AM0004_1Phash',
    name: 'America Insurance Brokers, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AM0002_1P'
  },
  AM0005_1P: {
    hash: 'AM0005_1Phash',
    name: 'Amity Insurance Group L.L.C.',
    agency: true,
    disableChat: true,
    informationId: 'AM0005_1P'
  },
  AMEX1: {
    hash: 'xe6Tpyame',
    name: 'American Express',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      SD: 1,
      UT: 1,
      VA: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  AMS37_1: {
    hash: 'AMS37_1hash',
    name: 'Blue River Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'AMS37_1'
  },
  AMS37_1P: {
    hash: 'AMS37_1Phash',
    name: 'Blue River Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'AMS37_1P'
  },
  AMS38_1: {
    hash: 'AMS38_1hash',
    name: 'Bluecoat Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'AMS38_1'
  },
  AMS40_1: {
    hash: 'AMS40_1hash',
    name: 'Pathos Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AMS40_1'
  },
  AMS41_1P: {
    hash: 'AMS41Phash',
    name: 'Quantum World Wide Inc',
    disableChat: true,
    informationId: 'AMS41P',
    agency: true
  },
  AMS44_1P: {
    hash: 'AMS44_1Phash',
    name: 'Regent Insurance',
    disableChat: true,
    informationId: 'AMS44_1P',
    agency: true
  },
  AMS46_1P: {
    hash: 'AMS46_1Phash',
    name: 'RJ RUPP & ASSOCIATES',
    disableChat: true,
    informationId: 'AMS46_1P',
    agency: true
  },
  AN0001_1P: {
    hash: 'AN0001_1Phash',
    name: 'Andrew Schoch Agency LLC.',
    agency: true,
    disableChat: true,
    informationId: 'AN0001_1P'
  },
  AN0002_1: {
    hash: 'AN0002_1hash',
    name: 'Andrew Schoch Agency LLC.',
    agency: true,
    disableChat: true,
    informationId: 'AN0002_1'
  },
  AN0002_1P: {
    hash: 'AN0002_1Phash',
    name: 'Anderson Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'AN0002_1P'
  },

  AP1: {
    hash: '7dhv8',
    name: 'Alert Protective',
    states: {
      IL: 2
    }
  },
  APE: {
    hash: '7dhv8E',
    name: 'Alert Protective',
    states: {
      IL: 2
    }
  },
  APHW: {
    hash: 'ahfgdspew',
    name: "America's Preferred Home Warranty",
    informationId: 'AHPW',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  APRG1: {
    name: 'The Savings Group (AutoPay/RateGenius)',
    hash: 'aprg1hash'
  },
  AR0001: {
    hash: 'ar0001hash',
    agency: true,
    name: 'Arizona Insurance Guy',
    informationId: 'AR0001',
    disableChat: true
  },

  AR0001P: {
    hash: 'AR0001Phash',
    agency: true,
    name: 'Arizona Insurance Guy',
    informationId: 'AR0001P',
    disableChat: true
  },
  AR0002: {
    hash: 'AR0002hash',
    agency: true,
    name: 'Archibald Insurance Agency',
    informationId: 'AR0002',
    disableChat: true
  },
  AR0003_1: {
    hash: 'AR0003_1hash',
    agency: true,
    name: 'Aria Insurance Agency Inc.',
    informationId: 'AR0003_1',
    disableChat: true
  },
  AR0003_2: {
    hash: 'AR0003_2hash',
    agency: true,
    name: 'Aria Insurance Agency Inc.',
    informationId: 'AR0003_2',
    disableChat: true
  },
  AR0004: {
    hash: 'AR0004hash',
    agency: true,
    name: 'Arbor Bank DBA First Express Insurance',
    informationId: 'AR0004',
    disableChat: true
  },
  AR0004P: {
    hash: 'AR0004Phash',
    agency: true,
    name: 'Arbor Bank DBA First Express Insurance',
    informationId: 'AR0004P',
    disableChat: true
  },
  AR0005_1: {
    hash: 'AR0005_1hash',
    agency: true,
    name: 'Arkansas Insurance Partners, Inc.',
    informationId: 'AR0005_1',
    disableChat: true
  },
  AR0005_2: {
    hash: 'AR0005_2hash',
    agency: true,
    name: 'Arkansas Insurance Partners, Inc.',
    informationId: 'AR0005_2',
    disableChat: true
  },
  AR0005_3: {
    hash: 'AR0005_3hash',
    agency: true,
    name: 'Arkansas Insurance Partners, Inc.',
    informationId: 'AR0005_3',
    disableChat: true
  },
  AR0007_1P: {
    hash: 'AR0007_1Phash',
    agency: true,
    name: 'Arthur Blaszczyszyn Insurance Agency Inc. dba One Street Insurance Agency',
    informationId: 'AR0007_1P',
    disableChat: true
  },
  ARCH1: {
    hash: 'arch1hash',
    name: 'Archer Insurance Solutions',
    agency: true,
    informationId: 'ARCH1',
    disableChat: true
  },
  AS0002: {
    hash: 'AS0002hash',
    agency: true,
    disableChat: true,
    name: 'Aspire Insurance Advisors',
    informationId: 'AS0002'
  },
  AS0003: {
    hash: 'AS0003hash',
    name: 'Ascent Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'AS0003'
  },
  AS0003P: {
    hash: 'AS0003Phash',
    name: 'Ascent Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'AS0003P'
  },
  AS0004: {
    hash: 'AS0004hash',
    name: 'Associated Insurors, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AS0004'
  },
  AS0004P: {
    hash: 'AS0004Phash',
    name: 'Associated Insurors, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'AS0004P'
  },
  AS0005_1: {
    hash: 'AS0005_1hash',
    name: 'Williams Insurance & Financial, LLC DBA Asshield Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AS0005_1'
  },
  AS0005_1P: {
    hash: 'AS0005_1Phash',
    name: 'Asshield Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AS0005_1P'
  },
  AS0005_2: {
    hash: 'AS0005_2hash',
    name: 'Williams Insurance & Financial, LLC DBA Asshield Insurance',
    agency: true,
    disableChat: true,
    informationId: 'AS0005_2'
  },
  AS0006_1P: {
    hash: 'AS0006_1Phash',
    name: 'Assured Insurance Consultants, LLC',
    agency: true,
    disableChat: true,
    informationId: 'AS0006_1P'
  },
  ASKOTTO: {
    hash: '7ask88otto',
    name: 'AskOtto',
    informationId: 'ASKOTTO'
  },
  ASQ1: {
    hash: 'asq1hash',
    name: 'ASQ Technologies LLC',
    agency: true,
    disableChat: true
  },
  ASQ2: {
    hash: 'asq2hash',
    name: 'Grace Affordable Insurance, Inc.',
    agency: true,
    disableChat: true
  },
  ASQ3: {
    hash: 'asq3hash',
    name: 'Clark-Vivens Insurance Agency dba Renegade Insurance',
    agency: true,
    disableChat: true
  },
  ASQ4: {
    hash: 'asq4hash',
    name: 'ASQ Technologies LLC',
    agency: true,
    disableChat: true
  },
  ASQ5: {
    hash: 'asq5hash',
    name: 'ASQ Technologies LLC',
    agency: true,
    disableChat: true
  },
  ASQ6: {
    hash: 'asq6hash',
    name: 'ASQ Technologies LLC',
    agency: true,
    disableChat: true
  },
  ASQ8: {
    hash: 'asq8hash',
    name: 'ASQ Technologies LLC',
    agency: true,
    informationId: 'ASQ8',
    disableChat: true
  },
  ASQ9: {
    hash: 'asq9hash',
    name: 'ASQ Technologies LLC',
    agency: true,
    informationId: 'ASQ9',
    disableChat: true
  },
  AST1: {
    hash: 'ast1hash',
    name: 'Asterion Insurance Services LLC',
    agency: true
  },
  AURE1: {
    hash: 'ytQym4cF4rGAGhy6',
    name: 'Benchmark Insurance Group'
  },
  AU0001_1P: {
    hash: 'AU0001_1Phash',
    name: 'Axis Insurance Agency',
    agency: true,
    informationId: 'AX0001_1P',
    disableChat: true
  },
  AX0001: {
    hash: 'AX0001hash',
    name: 'Axis Insurance Agency',
    agency: true,
    informationId: 'AX0001',
    disableChat: true
  },
  AX0002: {
    hash: 'AX0002hash',
    name: 'Axcess Insurance Group, LLC.',
    agency: true,
    informationId: 'AX0002',
    disableChat: true
  },

  AZL1: {
    hash: 'azlesdfisg',
    name: 'Arizona Lending Group',
    states: {
      AZ: 2
    }
  },
  BA0001P: {
    hash: 'BA0001Phash',
    name: 'Beasy Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'BA0001P'
  },
  BA0002P: {
    hash: 'BA0002hashP',
    name: 'Barton Insurance Group, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'BA0002P'
  },
  BA0003_1P: {
    hash: 'BA0003_1PhashP',
    name: 'Barry Winstead',
    agency: true,
    disableChat: true,
    informationId: 'BA0003_1P'
  },
  BA0005_1P: {
    hash: 'BA0005_1PhashP',
    name: 'Bare and Swett Agency Inc',
    agency: true,
    disableChat: true,
    informationId: 'BA0005_1P'
  },
  BA0006_1P: {
    hash: 'BA0006_1PhashP',
    name: 'Baltimore Insurance Network LLC',
    agency: true,
    disableChat: true,
    informationId: 'BA0006_1P'
  },
  BA1: {
    hash: 'babetter',
    name: 'Betters Agency',
    agency: true,
    disableChat: true
  },
  BAD: {
    hash: 'baddab',
    name: 'BAD Agency from QTB API',
    agency: true,
    disableChat: true
  },
  BAX1: {
    hash: 'bax1hash',
    name: 'Baxter Insurance Agency',
    agency: true,
    disableChat: true
  },
  BCA: {
    name: 'Bob Caldwell Chrysler Jeep',
    hash: 'bdcz4xvy'
  },
  BDDG1: {
    hash: 'bddg1hash',
    name: 'Bird Dog Insurance Services, LLC',
    agency: true,
    disableChat: true
  },
  BE0001: {
    hash: 'BE0001hash',
    name: 'Better.com',
    agency: true,
    informationId: 'BE0001',
    disableChat: true
  },
  BE0002: {
    hash: 'BE0002hash',
    agency: true,
    name: 'Benefield & Tabb Insurance Inc.',
    informationId: 'BE0002',
    disableChat: true
  },
  BE0002P: {
    hash: 'BE0002Phash',
    name: 'Benefield & Tabb Insurance Inc.',
    agency: true,
    disableChat: true,
    informationId: 'BE0002P'
  },
  BE0004_1P: {
    hash: 'BE0004_1Phash',
    name: 'Bennett Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'BE0004_1P'
  },
  BE0005_1P: {
    hash: 'BE0005_1Phash',
    name: 'BetterState Insurance Inc.',
    agency: true,
    disableChat: true,
    informationId: 'BE0005_1P'
  },
  BE0006_1P: {
    hash: 'BE0006_1Phash',
    name: 'Beacon Point Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'BE0006_1P'
  },
  BF0001: {
    hash: 'bf0001hash',
    name: 'Boomer Financial Group',
    agency: true,
    disableChat: true
  },
  BH1: {
    name: 'Buckeye Honda',
    hash: 'gf5subh18g'
  },
  BIG1: {
    hash: 'fsdbuds87',
    name: 'Benchmark Insurance Group'
  },
  BI0001_1: {
    hash: 'BI0001_1hash',
    agency: true,
    name: 'BIG OX TRUCKING INSURANCE & SERVICES DBA BIG OX INSURANCE',
    informationId: 'BI0001_1',
    disableChat: true
  },
  BL0001: {
    hash: 'BL0001hash',
    agency: true,
    name: 'Blue Insurance Group LLC',
    informationId: 'BL0001',
    disableChat: true
  },
  BK0001: {
    hash: 'BK0001hash',
    agency: true,
    name: 'Bharat Kothari',
    informationId: 'BK0001',
    disableChat: true
  },
  BL0002: {
    hash: 'BL0002hash',
    agency: true,
    name: 'Blackford and Associates, Inc.',
    informationId: 'BL0002',
    disableChat: true
  },
  BL0003: {
    hash: 'BL0003hash',
    agency: true,
    name: 'Blue Tiger Insurance',
    informationId: 'BL0003',
    disableChat: true
  },
  BL0003P: {
    hash: 'BL0003hashP',
    agency: true,
    name: 'Blue Tiger Insurance',
    informationId: 'BL0003P',
    disableChat: true
  },
  BLRDG1: {
    hash: 'blrdg1hash',
    name: 'Blue Ridge Insurance',
    agency: true,
    disableChat: true
  },
  BN1: {
    name: 'Buckeye Nissan',
    hash: 'basj6kdvu'
  },
  BN0001_1P: {
    hash: 'BN0001_1Phash',
    name: 'BNA Insurance Group, LLC',
    agency: true,
    informationId: 'BN0001_1P',
    disableChat: true
  },
  BOBB: {
    name: 'Bobb Automotive',
    hash: 'lsdy76v5'
  },
  BO0002: {
    hash: 'BO0002hash',
    name: 'Boring Insurance Company',
    agency: true,
    informationId: 'BO0002',
    disableChat: true
  },
  BO0003P: {
    hash: 'BO0003Phash',
    name: 'Boersma Insurance Service',
    agency: true,
    informationId: 'BO0003P',
    disableChat: true
  },
  BO0004_1P: {
    hash: 'BO0004_1Phash',
    name: 'Bouslog Insurance',
    agency: true,
    informationId: 'BO0004_1P',
    disableChat: true
  },
  BP0001: {
    hash: 'bp0001hash',
    name: 'BluePrint Risk LLC',
    agency: true,
    informationId: 'BP0001',
    disableChat: true
  },
  BR0003: {
    hash: 'br0003hash',
    name: 'Bret Dean Agency',
    agency: true,
    informationId: 'BR0003',
    disableChat: true
  },
  BR0004_1: {
    hash: 'BR0004_1hash',
    name: 'Brem Insurance, LLC.',
    agency: true,
    informationId: 'BR0004_1',
    disableChat: true
  },
  BR0005_1P: {
    hash: 'BR0005_1Phash',
    name: 'Bridgemark Insurance Solutions, Inc. / Key Insurance',
    agency: true,
    informationId: 'BR0005_1P',
    disableChat: true
  },
  BR0007_1P: {
    hash: 'BR0007_1Phash',
    name: 'Bronco Insurance Group',
    agency: true,
    informationId: 'BR0007_1P',
    disableChat: true
  },
  BU0001_1: {
    hash: 'BU0001_1hash',
    agency: true,
    disableChat: true,
    name: 'Burgett Insurance Group, Inc.',
    informationId: 'BU0001_1'
  },
  BU0001_1P: {
    hash: 'BU0001_1Phash',
    agency: true,
    disableChat: true,
    name: 'Burgett Insurance Group, Inc.',
    informationId: 'BU0001_1P'
  },
  BR0001_28P: {
    hash: 'BR0001_28Phash',
    name: 'Brightway Insurance - The River Bend Agency',
    agency: true,
    disableChat: true,
    informationId: 'BR0001_28P'
  },
  BR0001_45P: {
    hash: 'BR0001_45Phash',
    name: 'Brightway Insurance,LLC',
    agency: true,
    disableChat: true,
    informationId: 'BR0001_45P'
  },
  BR0002: {
    hash: 'BR0002hash',
    name: 'Broken Arrow Insurance Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'BR0002'
  },
  BR0008_1P: {
    hash: 'BR0008_1Phash',
    name: 'Breathe Insurance Solutions, LLC',
    agency: true,
    disableChat: true,
    informationId: 'BR0008_1P'
  },
  BRANS1: {
    hash: 'brans1hash',
    name: 'Branscomb and Associates, LLC',
    agency: true,
    disableChat: true
  },
  BRIJ1: {
    hash: 'brij1hash',
    name: 'InsureUs',
    agency: true,
    disableChat: true
  },
  BPRS1: {
    hash: 'bprs1hash',
    name: 'Bespros Insurance and Financial Services LLC',
    agency: true,
    disableChat: true
  },
  BRPT1: {
    hash: 'brpt1hash',
    name: 'Bridgepoint Insurance Solutions',
    agency: true,
    disableChat: true
  },
  BRPT1_1: {
    hash: 'BRPT1_1hash',
    name: 'Bridgepoint Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'BRPT1_1'
  },
  BW0001: {
    hash: 'BW0001hash',
    name: 'BainsWest',
    agency: true,
    disableChat: true,
    informationId: 'BW0001'
  },
  BW0001_1: {
    hash: 'BW0001_1hash',
    agency: true,
    disableChat: true,
    name: 'Raleigh Insurance Agency, Inc.',
    informationId: 'BW0001_1'
  },
  BW0001_2: {
    hash: 'BW0001_2hash',
    agency: true,
    disableChat: true,
    name: 'Cole, Paine and Carlin Insurance Agency, Inc.',
    informationId: 'BW0001_2'
  },
  BWB: {
    hash: 'babwer',
    name: 'Baker Welman Brown',
    agency: true,
    disableChat: true
  },
  BY0001_1P: {
    hash: 'BY0001_1Phash',
    agency: true,
    disableChat: true,
    name: 'Byers Insurance Group',
    informationId: 'BY0001_1P'
  },
  CA0001P: {
    hash: 'CA0001Phash',
    agency: true,
    disableChat: true,
    name: 'Careington Benefit Solutions',
    informationId: 'CA0001P'
  },
  CA0002_1: {
    hash: 'CA0002_1hash',
    agency: true,
    disableChat: true,
    name: 'Canopy Insurance Group, LLC',
    informationId: 'CA0002_1'
  },
  CA0003_1P: {
    hash: 'CA0003_1Phash',
    agency: true,
    disableChat: true,
    name: 'Capitol Insurance',
    informationId: 'CA0003_1P'
  },
  CA0004_1: {
    hash: 'CA0004_1hash',
    agency: true,
    disableChat: true,
    name: 'Caribou Insurance',
    informationId: 'CA0004_1'
  },
  CA0004_1P: {
    hash: 'CA0004_1Phash',
    agency: true,
    disableChat: true,
    name: 'Caribou Insurance',
    informationId: 'CA0004_1P'
  },
  CA0005_1: {
    hash: 'CA0005_1hash',
    agency: true,
    disableChat: true,
    name: 'Carlee Davis Insurance Agency',
    informationId: 'CA0005_1'
  },
  CA0005_1P: {
    hash: 'CA0005_1Phash',
    agency: true,
    disableChat: true,
    name: 'Carlee Davis Insurance Agency',
    informationId: 'CA0005_1P'
  },
  CA0006_1P: {
    hash: 'CA0006_1Phash',
    agency: true,
    disableChat: true,
    name: 'Canon Insurance Advisers LLC',
    informationId: 'CA0006_1P'
  },
  CA0009_1P: {
    hash: 'CA0009_1Phash',
    agency: true,
    disableChat: true,
    name: 'Ownership Insurance',
    informationId: 'CA0009_1P'
  },
  CA0010_1P: {
    hash: 'CA0010_1Phash',
    agency: true,
    disableChat: true,
    name: 'Hyde Group Wealth Management & Insurance Solutions',
    informationId: 'CA0010_1P'
  },
  CA0011_1P: {
    hash: 'CA0011_1Phash',
    agency: true,
    disableChat: true,
    name: 'Seamless Insurance',
    informationId: 'CA0011_1P'
  },
  CA0012_1P: {
    hash: 'CA0012_1Phash',
    agency: true,
    disableChat: true,
    name: 'Traditions Insurance Group LLC',
    informationId: 'CA0012_1P'
  },
  CAF1: {
    name: 'CarsFast',
    hash: '12jlkjvasd3',
    informationId: 'CAF1'
  },
  CANARY1: {
    name: 'Canary',
    hash: 'canary1hash'
  },
  CAPK: {
    name: 'Capital Kia',
    hash: 'gf5ckiad98g'
  },
  CATN1: {
    hash: 'catn1hash',
    name: 'Catanzaro Insurance',
    agency: true,
    disableChat: true
  },
  CAV: {
    hash: 'nocavf1',
    agency: true,
    name: 'Cavender Insurance',
    informationId: 'CAV',
    disableChat: true
  },
  CC1: {
    hash: 'cochdcolisd',
    name: 'Coach Collins Insurance',
    agency: true,
    informationId: 'CC1',
    disableChat: true
  },
  CCM1: {
    hash: '9jlkj109puisd',
    name: 'Cherry Creek Mortgage'
  },
  CCT: {
    name: 'Columbus Car Trader',
    hash: 'gfcard98g'
  },
  CE0001: {
    hash: 'CE0001hash',
    agency: true,
    name: 'Centennial Insurance Group, Inc.',
    informationId: 'CE0001',
    disableChat: true
  },
  CE0002: {
    hash: 'CE0002hash',
    agency: true,
    disableChat: true,
    name: 'Central States Insurance Brokers',
    informationId: 'CE0002'
  },
  CF0001_1: {
    hash: 'CF0001_1hash',
    name: 'CareFirst Insurance',
    agency: true,
    disableChat: true,
    informationId: 'CF0001_1'
  },
  CF0001_2: {
    hash: 'CF0001_2hash',
    name: 'Carefirst Insurance',
    agency: true,
    disableChat: true,
    informationId: 'CF0001_2'
  },
  CFG1: {
    hash: 'dfcfgad87',
    name: 'Crossfit Grandview'
  },
  CH0002: {
    hash: 'CH0002hash',
    name: 'Chesapeake Insurance Specialists',
    agency: true,
    disableChat: true,
    informationId: 'CH0002'
  },
  CH0003: {
    hash: 'CH0003hash',
    name: 'Champions Insurance Team',
    agency: true,
    disableChat: true,
    informationId: 'CH0003'
  },
  CH0003P: {
    hash: 'CH0003Phash',
    name: 'Champions Insurance Team',
    agency: true,
    disableChat: true,
    informationId: 'CH0003P'
  },
  CH0005_1P: {
    hash: 'CH0005_1Phash',
    name: 'Chittick Advisory Group Inc.',
    agency: true,
    disableChat: true,
    informationId: 'CH0005_1P'
  },
  CHIME1: {
    hash: 'chdimeow',
    name: 'Chime',
    states: {
      AZ: 2,
      CO: 2,
      IL: 2,
      IN: 2,
      KY: 2,
      MD: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      OH: 2,
      OK: 2,
      TX: 2,
      UT: 2,
      WI: 2
    }
  },
  CI1: {
    hash: 'cidufds9',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    informationId: 'CI1',
    disableChat: true
  },
  CI2: {
    hash: 'cidufds92',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    informationId: 'CI1',
    disableChat: true
  },
  CI3: {
    hash: 'cidufds93',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    informationId: 'CI1',
    disableChat: true
  },
  CI4: {
    hash: 'cidufds94',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    informationId: 'CI1',
    disableChat: true
  },
  CI5: {
    hash: 'cidufds95',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    informationId: 'CI1',
    disableChat: true
  },
  CI7: {
    hash: 'CI7hash',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    informationId: 'CI7',
    disableChat: true
  },
  CI5P: {
    hash: 'CI5Phash',
    name: 'Cleveland Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'CI5P'
  },
  CI0001: {
    hash: 'CI0001hash',
    name: 'Christensen Insurance',
    agency: true,
    disableChat: true,
    informationId: 'CI0001'
  },
  CI0002: {
    hash: 'CI0002hash',
    name: 'CIS Financial Services Inc. DBA CIS Insurance Agency',
    agency: true,
    informationId: 'CI0002',
    disableChat: true
  },
  CI0003_1: {
    hash: 'CI0003_1hash',
    name: 'YESS First, Inc.',
    agency: true,
    informationId: 'CI0003_1',
    disableChat: true
  },
  CI0004_1P: {
    hash: 'CI0004_1Phash',
    name: 'CIC Insurance Brokers LLC dba InsureCherokee',
    agency: true,
    informationId: 'CI0004_1P',
    disableChat: true
  },
  CIG0001: {
    hash: 'cig0001hash',
    agency: true,
    name: 'Community Insurance Group',
    informationId: 'CIG0001',
    disableChat: true
  },
  CIP0001: {
    hash: 'cip0001hash',
    agency: true,
    disableChat: true,
    name: 'Chicago Insurance Professionals',
    informationId: 'CIP0001'
  },
  CINS1: {
    hash: 'cins1hash',
    agency: true,
    name: 'C1 Insurance Group',
    disableChat: true
  },
  CISS: {
    hash: 'nciss3f1',
    agency: true,
    name: 'Colorado Insurance',
    informationId: 'CISS',
    disableChat: true
  },
  CKIA: {
    name: 'Celebration Kia',
    hash: 'gf5ckcelg'
  },
  CL0002: {
    hash: 'CL0002hash',
    name: 'Clary Insurance LLC',
    agency: true,
    disableChat: true,
    informationId: 'CL0002'
  },
  CL0003: {
    hash: 'CL0003hash',
    name: 'Clarks Insurance Ctr. of Talladega',
    agency: true,
    disableChat: true,
    informationId: 'CL0003'
  },
  CL0004_1: {
    hash: 'CL0004_1hash',
    name: 'Clients Choice Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'CL0004_1'
  },
  CL0004_1P: {
    hash: 'CL0004_1Phash',
    name: 'Clients Choice Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'CL0004_1P'
  },
  CLEND1: {
    hash: 'dclesdfisg',
    name: 'Coastal Lending Group',
    states: {
      MD: 2
    }
  },
  CM0002: {
    hash: 'CM0002hash',
    name: 'CMC Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'CM0002'
  },
  CMRG1: {
    hash: 'cmrg1hash',
    name: 'Camargo Insurance',
    agency: true,
    disableChat: true
  },
  CN0001: {
    hash: 'CN0001hash',
    name: '1st Cannon',
    agency: true,
    disableChat: true,
    informationId: 'CN0001'
  },
  CO0001: {
    hash: 'CO0001hash',
    name: 'Cornerstone Insurance Agency, LLC',
    agency: true,
    informationId: 'CO0001',
    disableChat: true
  },
  CO0002_1: {
    hash: 'CO0002_1hash',
    name: 'Cohen Insurance',
    agency: true,
    disableChat: true,
    informationId: 'CO0002_1'
  },
  CO0002_1P: {
    hash: 'CO0002_1Phash',
    name: 'Cohen Insurance',
    agency: true,
    disableChat: true,
    informationId: 'CO0002_1P'
  },
  CO0003: {
    hash: 'CO0003hash',
    name: 'Contreras Legacy Brokers',
    agency: true,
    informationId: 'CO0003',
    disableChat: true
  },
  CO0004: {
    hash: 'CO0004hash',
    name: 'Covva Insurance LLC.',
    agency: true,
    informationId: 'CO0004',
    disableChat: true
  },
  CO0005_1: {
    hash: 'CO0005_1hash',
    name: 'Cornhusker Insurance Agency',
    agency: true,
    informationId: 'CO0005_1',
    disableChat: true
  },
  CO0005_2: {
    hash: 'CO0005_2hash',
    name: 'Cornhusker Insurance Agency',
    agency: true,
    informationId: 'CO0005_2',
    disableChat: true
  },
  CO0006P: {
    hash: 'CO0006Phash',
    name: 'Commonwealth Insurance',
    agency: true,
    informationId: 'CO0006P',
    disableChat: true
  },
  CO0009_1P: {
    hash: 'CO0009_1Phash',
    name: 'Commonwealth Insurance',
    agency: true,
    informationId: 'CO0009_1P',
    disableChat: true
  },
  CO0010_1P: {
    hash: 'CO0010_1Phash',
    name: 'Conley Insurance Agency',
    agency: true,
    informationId: 'CO0010_1P',
    disableChat: true
  },

  COVSAGE: {
    hash: 'cusage294tree',
    agency: true,
    disableChat: true
  },
  CP0002: {
    hash: 'CP0002hash',
    name: 'CPAWS Insurance and Financial Services',
    agency: true,
    disableChat: true,
    informationId: 'CP0002'
  },
  CP0002P: {
    hash: 'CP0002Phash',
    name: 'CPAWS Insurance and Financial Services',
    agency: true,
    disableChat: true,
    informationId: 'CP0002P'
  },
  CR0002_2P: {
    hash: 'CR0002_2Phash',
    name: 'Cribb Insurance Group Inc.',
    agency: true,
    disableChat: true,
    informationId: 'CR0002_2P'
  },
  CT1: {
    hash: 'cudngeu294tree',
    agency: true,
    disableChat: true
  },
  COM1: {
    hash: '2xjv2m5u',
    name: 'Compare.com'
  },
  COV1: {
    hash: 'dfsjkwww3sfa7',
    name: 'Cover.com',
    agency: true,
    disableChat: true
  },
  CPN: {
    name: 'Cedar Park Nissan',
    hash: 'gfcpncvtg'
  },
  CRLS1: {
    hash: 'crls1hash',
    name: 'CHARALES INSURANCE AGY INC',
    agency: true,
    disableChat: true
  },
  CV0001: {
    hash: 'cv0001hash',
    name: 'Clear View Insurance Services LLC',
    agency: true,
    disableChat: true
  },
  CROL1: {
    hash: 'crol1hash',
    name: 'Carroll Agency Inc',
    agency: true,
    disableChat: true,
    informationId: 'CROL1'
  },
  CROL1P: {
    hash: 'crol1phash',
    name: 'Carroll Agency Inc',
    agency: true,
    disableChat: true,
    informationId: 'CROL1P'
  },
  CU0002_1P: {
    hash: 'CU0002_1Phash',
    name: 'CU INsurance Services of TN, LLC DBA 7 Insurance',
    agency: true,
    disableChat: true,
    informationId: 'CU0002_1P'
  },

  CVT1: {
    name: 'Covert CJDR',
    hash: 'gf5sucvtg'
  },
  CY0001_P: {
    hash: 'cy0001_Phash',
    name: 'Coyle Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'CY0001_P'
  },
  DA0001P: {
    hash: 'DA0001Phash',
    name: 'Dorsey and Associates LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0001P'
  },
  DA0001_2P: {
    hash: 'DA0001_2Phash',
    name: 'Dorsey and Associates LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0001_2P'
  },
  DA0001_3P: {
    hash: 'DA0001_3Phash',
    name: 'Dorsey and Associates LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0001_3P'
  },
  DA0001_5P: {
    hash: 'DA0001_5Phash',
    name: 'Dorsey and Associates LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0001_5P'
  },
  DA0002: {
    hash: 'DA0002hash',
    name: 'Davis & Associates Inc.',
    agency: true,
    disableChat: true,
    informationId: 'DA0002'
  },
  DA0002P: {
    hash: 'DA0002Phash',
    name: 'Davis & Associates Inc.',
    agency: true,
    disableChat: true,
    informationId: 'DA0002P'
  },
  DA0003: {
    hash: 'DA0003hash',
    name: 'Dario Garcia Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'DA0003'
  },
  DA0004_1: {
    hash: 'DA0004_1hash',
    name: 'DALACS Insurance Team',
    agency: true,
    disableChat: true,
    informationId: 'DA0004_1'
  },
  DA0004_2: {
    hash: 'DA0004_2hash',
    name: 'DALACS Insurance Team',
    agency: true,
    disableChat: true,
    informationId: 'DA0004_2'
  },
  DA0005_1P: {
    hash: 'DA0005_1Phash',
    name: 'Dagley Insurance',
    agency: true,
    disableChat: true,
    informationId: 'DA0005_1P'
  },
  DA0006_1: {
    hash: 'DA0006_1hash',
    name: 'David Pope Insurance Services LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0006_1'
  },
  DA0006_1P: {
    hash: 'DA0006_1Phash',
    name: 'David Pope Insurance Services LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0006_1P'
  },
  DA0006_2: {
    hash: 'DA0006_2hash',
    name: 'David Pope Insurance Services LLC.',
    agency: true,
    disableChat: true,
    informationId: 'DA0006_2'
  },
  DAA: {
    name: 'Don Ayres Acura',
    hash: 'sfd4145et'
  },
  DAH: {
    name: 'Don Ayres Honda',
    hash: '5sdf14gr'
  },
  DE0002: {
    hash: 'DE0002hash',
    name: 'Derek Hunter Agency, Inc. ',
    agency: true,
    disableChat: true,
    informationId: 'DE0002'
  },
  DE0003_1P: {
    hash: 'DE0003_1Phash',
    name: 'Design Risk',
    agency: true,
    disableChat: true,
    informationId: 'DE0003_1P'
  },
  DGC: {
    name: 'Dave Gill Chevrolet',
    hash: 'gfdgcd98g'
  },
  DG1: {
    hash: 'iewdgs532',
    agency: true,
    name: 'DG Agency',
    informationId: 'DG1',
    disableChat: true
  },
  DH1: {
    name: 'Dennis Hyundai East',
    hash: '87asd5fgh'
  },
  DI0001_1: {
    hash: 'DI0001_1hash',
    name: 'Danker Insurance',
    agency: true,
    disableChat: true,
    informationId: 'DI0001_1'
  },
  DI0001_2: {
    hash: 'DI0001_2hash',
    name: 'Danker Insurance',
    agency: true,
    disableChat: true,
    informationId: 'DI0001_2'
  },
  DI0006_1P: {
    hash: 'DI006_1Phash',
    name: 'Direct Insurance Services LLC',
    agency: true,
    disableChat: true,
    informationId: 'DI0006_1P'
  },
  DMF: {
    name: 'Dick Masheter Ford',
    hash: 'gfddmf98g'
  },
  DO0001: {
    hash: 'DO0001hash',
    name: 'Dolphin Angel Inc. Dba ABC Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'DO0001'
  },
  DO0002_1: {
    hash: 'DO0002_1hash',
    name: 'Dorado Insurance Services, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'DO0002_1'
  },
  DO0002_2: {
    hash: 'DO0002_2hash',
    name: 'Dorado Insurance Services, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'DO0002_2'
  },
  DP1: {
    hash: 'dfsdp1sfa7',
    name: 'Dealer Policy',
    agency: true,
    disableChat: true
  },
  DS0001_1P: {
    hash: 'DS0001_1Phash',
    name: 'Acrisure, LLC dba DSD Insurance',
    agency: true,
    disableChat: true,
    informationId: 'DS0001_1P'
  },
  DT1: {
    hash: '2a48d',
    name: 'Driver Technologies',
    states: {
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      NJ: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      RI: 2,
      SC: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2
    }
  },
  DTE: {
    hash: '2a48dE',
    name: 'Driver Technologies',
    states: {
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      NJ: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      RI: 2,
      SC: 2,
      TX: 2,
      VA: 2,
      WI: 2,
      WV: 2
    }
  },
  DTM: {
    name: 'Drive Time',
    hash: '65fds16df4g'
  },
  DWJ: {
    name: 'Driving with Janae',
    hash: 'df4d562as'
  },
  DY0001: {
    hash: 'DY0001hash',
    name: 'Dynasty Insurance Group',
    agency: true,
    informationId: 'DY0001',
    disableChat: true
  },
  EC0001: {
    hash: 'ec00011hash',
    name: 'Ed Fowls Insurance',
    agency: true,
    informationId: 'EC0001',
    disableChat: true
  },
  EF0001: {
    hash: 'ef00011hash',
    name: 'Ed Fowls Insurance',
    agency: true,
    disableChat: true
  },
  EI0001: {
    hash: 'ei00011hash',
    name: 'EIA',
    agency: true,
    disableChat: true
  },
  EI1: {
    hash: 'eid7vn238fdas',
    name: 'Eades Insurance',
    agency: true,
    informationId: 'EI1',
    disableChat: true
  },
  EI2: {
    hash: 'eid7vn238fdas2',
    name: 'Eades Insurance',
    agency: true,
    informationId: 'EI1',
    disableChat: true
  },
  EIC0001: {
    hash: 'EIC0001hash',
    name: 'Elite Insuranec Company Inc.',
    agency: true,
    disableChat: true,
    informationId: 'EIC0001'
  },
  EIG0001: {
    hash: 'eig0001hash',
    name: 'Eddys Independent Group, LLC',
    agency: true,
    informationId: 'EIG0001'
  },
  EIG0001_1: {
    hash: 'eig0001_1hash',
    name: 'Eddys Independent Group, LLC',
    informationId: 'EIG0001_1'
  },
  EL0001: {
    hash: 'EL0001hash',
    name: 'Elite Insurance Services LLC',
    agency: true,
    informationId: 'EL0001',
    disableChat: true
  },
  EL0003_1P: {
    hash: 'EL0003_1Phash',
    name: 'ELEMENT INSURANCE GROUP',
    agency: true,
    informationId: 'EL0003_1P',
    disableChat: true
  },
  ELD1: {
    hash: 'dfkjvfusadeld',
    name: 'Elder Insurance',
    informationId: 'ELD1'
  },
  EM1: {
    hash: '4389dfsnj8d'
  },
  EN0001: {
    hash: 'EN0001hash',
    name: 'Endgame Insurance',
    agency: true,
    informationId: 'EN0001',
    disableChat: true
  },
  EN0002_1: {
    hash: 'EN0002_1hash',
    name: 'Enid Loyalty Insurance LLC',
    agency: true,
    informationId: 'EN0002_1',
    disableChat: true
  },
  EN0002_1P: {
    hash: 'EN0002_1Phash',
    name: 'Enid Loyalty Insurance LLC',
    agency: true,
    informationId: 'EN0002_1P',
    disableChat: true
  },
  EPLR1: {
    hash: 'eplr1hash',
    agency: true,
    name: 'Eppler Insurance Group, LLC',
    disableChat: true
  },
  EQ0001: {
    hash: 'EQ0001hash',
    name: 'Equity One',
    agency: true,
    informationId: 'EQ0001',
    disableChat: true
  },
  EQ0004_1P: {
    hash: 'EQ0004_1Phash',
    name: 'Winderl/Kaminsky Insurance Agency Co., LTD',
    agency: true,
    informationId: 'EQ0004_1P',
    disableChat: true
  },
  EQ0009_1: {
    hash: 'EQ0009_1hash',
    name: 'Jason Wilson Ins, LLC',
    agency: true,
    informationId: 'EQ0009_1',
    disableChat: true
  },
  EQ00012_1P: {
    hash: 'EQ00012_1Phash',
    name: 'Wong Insurance Agency',
    agency: true,
    informationId: 'EQ00012_1P',
    disableChat: true
  },
  EQ00020_1: {
    hash: 'EQ00020_1hash',
    name: 'The Ryan Borland Agency, LLC.',
    agency: true,
    informationId: 'EQ00020_1',
    disableChat: true
  },

  ERFY1: {
    hash: 'erfy1hash',
    name: 'Eversify Insurance',
    agency: true,
    informationId: 'ERFY1',
    disableChat: true
  },
  EQ0001_1: {
    hash: 'EQ0001_1hash',
    name: 'Equity One Ins Agency LLC dba GlobalGreen Ins Agency',
    agency: true,
    informationId: 'EQ0001_1',
    disableChat: true
  },
  EQ0003_1: {
    hash: 'EQ0003_1hash',
    name: 'Goetz Insurance Agency, LLC.',
    agency: true,
    informationId: 'EQ0003_1',
    disableChat: true
  },
  EQ0003_2: {
    hash: 'EQ0003_2hash',
    name: 'Goetz Insurance Agency, LLC.',
    agency: true,
    informationId: 'EQ0003_2',
    disableChat: true
  },
  EQ0012_1: {
    hash: 'EQ00012_1hash',
    name: 'wong Insurance Agency, PLLC',
    agency: true,
    informationId: 'EQ00012_1',
    disableChat: true
  },
  EQ0012_2: {
    hash: 'EQ00012_2hash',
    name: 'wong Insurance Agency, PLLC',
    agency: true,
    informationId: 'EQ00012_2',
    disableChat: true
  },
  EQ0021_1P: {
    hash: 'EQ00021_1Phash',
    name: 'American Mortgage and Financial Services, LLC',
    agency: true,
    informationId: 'EQ00021_1P',
    disableChat: true
  },
  EXM: {
    name: 'Exclusive Motorcars',
    hash: '8kjf75l798'
  },
  FA0001: {
    hash: 'FA0001hash',
    name: 'Falkner Insurance',
    agency: true,
    informationId: 'FA0001',
    disableChat: true
  },
  FA0002: {
    hash: 'FA0002hash',
    name: 'Falk Agency, LLC',
    agency: true,
    disableChat: true,
    informationId: 'FA0002'
  },
  FA0003: {
    hash: 'FA0003hash',
    name: 'FailSafe Advisers',
    agency: true,
    disableChat: true,
    informationId: 'FA0003'
  },
  FA0004_1: {
    hash: 'FA0004_1hash',
    name: 'Family Direct Insurance Group LLC.',
    agency: true,
    disableChat: true,
    informationId: 'FA0004_1'
  },
  FA0004_1P: {
    hash: 'FA0004_1Phash',
    name: 'Family Direct Insurance Group LLC.',
    agency: true,
    disableChat: true,
    informationId: 'FA0004_1P'
  },
  FE0001: {
    hash: 'FE0001hash',
    name: 'Fellers Insurance Agency',
    agency: true,
    informationId: 'FE0001',
    disableChat: true
  },

  FAHW: {
    hash: 'ffahfgdspew',
    name: 'First American Home Warranty',
    states: {
      AZ: 1,
      CO: 1,
      IL: 1,
      IN: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      OH: 1,
      OK: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VT: 1,
      WI: 1
    }
  },
  FF0001_1P: {
    hash: 'ff0001_phash',
    name: 'Family First Insurance Alliance, LLC',
    agency: true,
    informationId: 'FF0001_1P',
    disableChat: true
  },
  FI0002: {
    hash: 'FI0002hash',
    agency: true,
    disableChat: true,
    name: 'First Community Insurance and Annuity Center',
    informationId: 'FI0002'
  },
  FI0002P: {
    hash: 'FI0002Phash',
    agency: true,
    disableChat: true,
    name: 'First Community Insurance and Annuity Center',
    informationId: 'FI0002P'
  },
  FI0003_1: {
    hash: 'FI0003_1hash',
    agency: true,
    disableChat: true,
    name: 'F.I.S Inc.',
    informationId: 'FI0003_1'
  },
  FOF0001_4P: {
    hash: 'FOF0001_4Phash',
    name: '405 Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'FOF0001_4P'
  },
  FOF0001_5P: {
    hash: 'FOF0001_5Phash',
    name: '405 Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'FOF0001_5P'
  },
  FOF0001_1P: {
    hash: 'FOF0001_1Phash',
    name: '405 Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'FOF0001_1P'
  },
  FO0001: {
    hash: 'FO0001hash',
    agency: true,
    name: 'Four Point Insurance Agency',
    informationId: 'FO0001',
    disableChat: true
  },
  FO0002: {
    hash: 'FO0002hash',
    agency: true,
    name: 'Fourrier Insurance Agency',
    informationId: 'FO0002',
    disableChat: true
  },
  FE0002: {
    hash: 'FE0002hash',
    name: 'Fourrier Agency, Inc.',
    agency: true,
    informationId: 'FE0002',
    disableChat: true
  },
  FH1: {
    hash: 'h2O0W',
    name: 'Fischer Homes',
    states: {
      KY: 2,
      MD: 1,
      OH: 1,
      OK: 1
    }
  },
  FHE: {
    hash: 'h2O0WE',
    name: 'Fischer Homes',
    states: {
      KY: 2,
      IN: 1,
      OH: 1,
      OK: 1
    }
  },
  FLO1: {
    hash: 'BXrw5z',
    name: 'Floify'
  },
  FLO2: {
    hash: 'BXrw5z2',
    name: 'Floify',
    states: {
      CO: 2,
      IA: 2,
      IL: 2,
      TX: 2
    }
  },
  FLOE: {
    hash: 'BXrw5z3',
    name: 'Floify Employees',
    states: {
      CO: 2,
      IA: 2,
      IL: 2,
      TX: 2
    }
  },
  FL0001: {
    hash: 'FL0001hash',
    agency: true,
    name: 'FloodSimple Insurance Services',
    informationId: 'FL0001',
    disableChat: true
  },
  FL0001P: {
    hash: 'FL0001Phash',
    agency: true,
    name: 'FloodSimple Insurance Services',
    informationId: 'FL0001P',
    disableChat: true
  },
  FLE1: {
    hash: 'Fj4kzxckel1iv',
    name: 'Fletch.co'
  },
  FMA1: {
    hash: 'fma74593d',
    name: 'First & Main',
    states: {
      IL: 2,
      OH: 2,
      MO: 2
    }
  },
  FOUND: {
    hash: 'fffound5',
    name: 'Found It',
    states: {
      AZ: 1,
      CO: 1,
      IA: 1,
      IL: 1,
      IN: 1,
      KY: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VT: 1,
      WI: 1
    }
  },
  FREF1: {
    hash: 'fref1hash',
    name: 'Firefly Agency LLC',
    agency: true,
    informationId: 'FREF1',
    disableChat: true
  },
  FT0001_1: {
    hash: 'f3ifiuoxkljlsj',
    name: 'Fat Agent',
    agency: true,
    disableChat: true
  },
  FW1: {
    hash: 'fopi1o23k',
    name: 'Fairway Independent Mortgage'
  },
  GA0001: {
    hash: 'ga00011hash',
    name: 'Gantz (Chance Kornegay Agency, Inc.)',
    agency: true
  },
  GA0002_1P: {
    hash: 'ga0002_1Phash',
    name: 'Gary Thacker Insurance',
    agency: true,
    informationId: 'GA0002_1P',
    disableChat: true
  },
  GABI1: {
    hash: 'gabigobble',
    name: 'Gabi',
    agency: true,
    disableChat: true
  },
  GE0001: {
    hash: 'GE0001hash',
    name: 'General Southwest Insurance Agency Inc.',
    agency: true,
    informationId: 'GE0001',
    disableChat: true
  },
  GF0001: {
    hash: 'GF0001hash',
    name: 'GFC Insurance LLC.',
    agency: true,
    informationId: 'GF0001',
    disableChat: true
  },
  GF0001P: {
    hash: 'GF0001Phash',
    name: 'GFC Insurance LLC.',
    agency: true,
    informationId: 'GF0001P',
    disableChat: true
  },

  GG0001: {
    hash: 'GG0001hash',
    name: 'GG Insurance Services Inc. ',
    agency: true,
    informationId: 'GG0001',
    disableChat: true
  },
  GH1: {
    hash: 'sdafhg74',
    name: 'Goosehead',
    agency: true,
    ccEmail: 'mailbox@goosehead.com',
    informationId: 'GH1',
    disableChat: true
  },
  GH1P: {
    hash: 'GH1Phash',
    name: 'Goosehead',
    agency: true,
    ccEmail: 'mailbox@goosehead.com',
    informationId: 'GH1P',
    disableChat: true
  },
  GH2: {
    hash: 'sdafhg742',
    name: 'Goosehead',
    agency: true,
    ccEmail: 'mailbox@goosehead.com',
    informationId: 'GH1',
    disableChat: true
  },
  GH3: {
    hash: 'sdafhg3742',
    name: 'Goosehead',
    agency: true,
    ccEmail: 'mailbox@goosehead.com',
    informationId: 'GH1',
    disableChat: true
  },
  GHD: {
    hash: 'GHDhash',
    name: 'Goosehead',
    ccEmail: 'mailbox@goosehead.com',
    informationId: 'GHD'
  },
  GI0002_1: {
    hash: 'GI0002_1hash',
    name: 'Gill Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'GI0002_1'
  },
  GJ0001: {
    hash: 'gj0001hash',
    name: 'GJ Insurance LLC',
    agency: true,
    informationId: 'GJ0001',
    disableChat: true
  },
  GL0001: {
    hash: 'gl0001hash',
    name: 'Glenn Harris & Associates',
    agency: true,
    informationId: 'GL0001',
    disableChat: true
  },
  GL0002: {
    hash: 'gl0002hash',
    name: 'Gleasons Insurance Solutions',
    agency: true,
    informationId: 'GL0002',
    disableChat: true
  },
  GI0001: {
    hash: 'GI0001hash',
    name: 'Goodman Insurance Solutions',
    agency: true,
    informationId: 'GI0001',
    disableChat: true
  },
  GI0001P: {
    hash: 'gi0001phash',
    name: 'Goodman Insurance Solutions',
    agency: true,
    informationId: 'GI0001P',
    disableChat: true
  },
  GI0003: {
    hash: 'GI0003hash',
    name: 'Gila Insurance Group LLC',
    agency: true,
    informationId: 'GI0003',
    disableChat: true
  },
  GI0003P: {
    hash: 'GI0003Phash',
    name: 'Gila Insurance Group LLC',
    agency: true,
    informationId: 'GI0003P',
    disableChat: true
  },
  GO0001_1: {
    hash: 'GO0001_1hash',
    name: 'Goggans Fort Payne Inc. DBA Goggans Insurance ',
    agency: true,
    informationId: 'GO0001_1',
    disableChat: true
  },
  GO0001_2: {
    hash: 'GO0001_2hash',
    name: 'Goggans Fort Payne Inc. DBA Goggans Insurance ',
    agency: true,
    informationId: 'GO0001_2',
    disableChat: true
  },
  GO0003_1P: {
    hash: 'GO0003_1Phash',
    name: 'Goodhue Nolte Insurance',
    agency: true,
    informationId: 'GO0003_1P',
    disableChat: true
  },
  GO0004_1P: {
    hash: 'GO0004_1Phash',
    name: 'Goodman Family Insurance',
    agency: true,
    informationId: 'GO0004_1P',
    disableChat: true
  },
  GO0005_1P: {
    hash: 'GO0005_1Phash',
    name: 'GOINS & GOINS INSURANCE AGENCY INC.',
    agency: true,
    informationId: 'GO0005_1P',
    disableChat: true
  },
  GN1: {
    name: 'Georgesville Nissan',
    hash: 'axs1s5s5sxw2'
  },
  GR0001: {
    hash: 'gr00011hash',
    name: 'Graziano Lorince Insurance',
    agency: true,
    informationId: 'GR0001',
    disableChat: true
  },
  GR0003_1P: {
    hash: 'gr0003_1Phash',
    name: 'GREGORY D PETERSON LLC',
    agency: true,
    informationId: 'GR0003_1P',
    disableChat: true
  },
  GR0004_1P: {
    hash: 'gr0004_1Phash',
    name: 'Grace - Lee Insurance Agency LLC',
    agency: true,
    informationId: 'GR0004_1P',
    disableChat: true
  },
  GRTRATE: {
    hash: '3kljv90adjc',
    agency: true,
    informationId: 'GRTRATE',
    disableChat: true
  },
  GRZO1: {
    hash: 'grzo1hash',
    name: 'Graziano Lorince Insurance, LLC',
    agency: true,
    disableChat: true
  },
  GU0001: {
    hash: 'gu0001hash',
    name: 'Guided Insurance Solutions',
    agency: true,
    informationId: 'GU0001',
    disableChat: true
  },
  GU0001P: {
    hash: 'gu0001Phash',
    name: 'Guided Insurance Solutions',
    agency: true,
    informationId: 'GU0001P',
    disableChat: true
  },
  GU0002: {
    hash: 'gu0002hash',
    name: 'Guaranteed Rate Insurance',
    agency: true,
    informationId: 'GU0002',
    disableChat: true
  },
  GUE0001: {
    hash: 'gue0001hash',
    name: 'Guenette Financial, Inc',
    agency: true,
    disableChat: true
  },
  HA0001: {
    hash: 'ha00011hash',
    agency: true,
    name: 'Harbinson Agency',
    disableChat: true
  },
  HB0001: {
    hash: 'hb0001phash',
    name: 'Hubbard Insurance Group',
    agency: true,
    disableChat: true
  },
  HA0002_5P: {
    hash: 'HA0002_5Phash',
    name: 'Handzel & Associates LTD',
    agency: true,
    informationId: 'HA0002_5P',
    disableChat: true
  },
  HA0003: {
    hash: 'HA0003hash',
    name: 'Hashley Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'HA0003'
  },
  HAVN1: {
    hash: 'hAVN1hash',
    name: 'Haven Portal',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      SD: 1,
      UT: 1,
      VA: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  HAVN2: {
    hash: 'HAVN2hash',
    name: 'Haven Email',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      SD: 1,
      UT: 1,
      VA: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  HB1: {
    hash: 'hbudsfnsa7f65',
    name: 'Heartland Bank',
    states: {
      IN: 1,
      OH: 1,
      OK: 1
    }
  },
  HENNA: {
    name: 'Henna Chevrolet',
    hash: 'gfhenna18g'
  },
  HE0001: {
    hash: 'he0001phash',
    name: 'Heritage Insurance',
    agency: true,
    informationId: 'HE0001',
    disableChat: true
  },
  HE0002: {
    hash: 'he0002phash',
    name: 'Hello Covered LLC.',
    agency: true,
    informationId: 'HE0002',
    disableChat: true
  },
  HE0003: {
    hash: 'he0003phash',
    name: 'Herbert Rovner Associates',
    agency: true,
    informationId: 'HE0003',
    disableChat: true
  },
  HE0006_1P: {
    hash: 'HE0006_1Phash',
    name: 'Hees Insurance Agency LLC',
    agency: true,
    informationId: 'HE0006_1P',
    disableChat: true
  },
  HF0001: {
    hash: 'hf0001phash',
    name: 'Hennessey Financial Services',
    agency: true,
    informationId: 'HF0001',
    disableChat: true
  },
  HI0001: {
    hash: 'HI0001hash',
    name: 'Humphries Insurance Group',
    agency: true,
    informationId: 'HI0001',
    disableChat: true
  },
  HI0002: {
    hash: 'HI0002hash',
    name: 'Hicks Watson Ins Agency',
    agency: true,
    informationId: 'HI0002',
    disableChat: true
  },
  HI0002P: {
    hash: 'HI0002Phash',
    name: 'Hicks Watson Ins Agency',
    agency: true,
    informationId: 'HI0002P',
    disableChat: true
  },
  HI0003: {
    hash: 'HI0003hash',
    name: 'Hicks Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'HI0003'
  },
  HMPT1: {
    hash: 'hmpta7f65',
    name: 'Homepoint',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPT2: {
    hash: 'hmptb319a',
    name: 'Homepoint API',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPT3: {
    hash: 'hmpt3jzlk1z',
    name: 'Homepoint Inbound Transfer Call',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPT4: {
    hash: 'hmpt4jzlk4z',
    name: 'Homepoint OB Callback',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPT5: {
    hash: 'hmpt3jzlk5zmail',
    name: 'Homepoint Monthly Mailer',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPT6: {
    hash: 'hmpt3jzlk5zip',
    name: 'Homepoint Investment Property',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPT8: {
    hash: 'HMPT8hash',
    name: 'Homepoint - outbound campaign',
    states: {
      AL: 1,
      AZ: 1,
      CO: 1,
      GA: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NJ: 1,
      NM: 1,
      NV: 1,
      NY: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      RI: 1,
      SC: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1
    }
  },
  HMPTE: {
    hash: 'hmpte319a',
    name: 'Homepoint Employees',
    states: {
      AZ: 2,
      IA: 2,
      ID: 2,
      ME: 2,
      MI: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NM: 2,
      SD: 2,
      TX: 2,
      VT: 2
    }
  },
  HOME1: {
    hash: 'home1hash',
    name: 'Homeowners Insurance Group',
    agency: true,
    disableChat: true
  },
  HO0001_1P: {
    hash: 'HO0001_1Phash',
    name: 'HomeFirst Agency',
    agency: true,
    informationId: 'HO0001_1P',
    disableChat: true
  },
  HO0002_1P: {
    hash: 'HO0002_1Phash',
    name: 'Holly McMillin Insurance Group',
    agency: true,
    informationId: 'HO0002_1P',
    disableChat: true
  },
  HOMER: {
    name: 'Homer Lending',
    hash: 'fjo39zlkjlz2'
  },
  HOP: {
    name: 'Hopper Motorplex',
    hash: 'gf5shopg'
  },
  HR1: {
    hash: 'NHMvhrrKMSmTL',
    name: 'HR Benefits'
  },
  HRZ: {
    hash: 'hrz533',
    agency: true,
    name: 'HRZ',
    disableChat: true
  },
  HRZ54P: {
    hash: 'HRZ54Phash',
    agency: true,
    informationId: 'HRZ54P',
    name: 'The Kilgore Agency, LLC.',
    disableChat: true
  },
  HRZ55_1: {
    hash: 'HRZ55_1hash',
    agency: true,
    informationId: 'HRZ55_1',
    name: 'The Martin Agency',
    disableChat: true
  },
  HRZ55_1P: {
    hash: 'HRZ55_1Phash',
    agency: true,
    informationId: 'HRZ55_1P',
    name: 'The Martin Agency',
    disableChat: true
  },
  HRZ56_1P: {
    hash: 'HRZ56_1Phash',
    agency: true,
    informationId: 'HRZ56_1P',
    name: 'Patriot Insurance Group LLC',
    disableChat: true
  },
  HRZ57_1P: {
    hash: 'HRZ57_1Phash',
    agency: true,
    informationId: 'HRZ57_1P',
    name: 'Duckworth Insurance Group, LLC',
    disableChat: true
  },
  HTAP: {
    name: 'Hometap',
    hash: 'htpdfds5tap',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      SD: 1,
      UT: 1,
      VA: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  HU0001_1P: {
    hash: 'HU0001_1Phash',
    name: 'Hubler Financial Services II, LLC.',
    agency: true,
    informationId: 'HU0001_1P',
    disableChat: true
  },
  HUWL1: {
    hash: 'huwl1hash',
    name: 'Huwel Insurance Agency',
    agency: true,
    disableChat: true
  },
  HVSU1: {
    hash: 'hvsu1hash',
    name: 'Havasu Insurance',
    agency: true,
    informationId: 'HVSU1',
    disableChat: true
  },
  HY0001: {
    hash: 'HY0001hash',
    name: 'Hylant Group Inc.',
    agency: true,
    informationId: 'HY0001',
    disableChat: true
  },
  HWBC: {
    name: 'Hugh White Buick Chevy',
    hash: 'gf5chwbcd98g'
  },
  IA0001: {
    hash: 'IA0001hash',
    name: 'The Insurance Advisor Group',
    agency: true,
    disableChat: true
  },
  IAL1: {
    hash: 'ial1hash',
    agency: true,
    name: 'IAL Insurance',
    disableChat: true
  },
  IB0001: {
    hash: 'IB0001hash',
    agency: true,
    name: 'Insurance Brokers Group LLC',
    disableChat: true
  },
  IB0005_1: {
    hash: 'IB0005_1hash',
    name: 'IBARRA Agency LLC.',
    agency: true,
    disableChat: true,
    informationId: 'IB0005_1'
  },
  ID0001: {
    hash: 'ID0001hash',
    name: 'IDEAL Solutions Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'ID0001'
  },
  IF_10: {
    hash: 'IF_10hash',
    name: 'Integrity 1st, INC DBA Integrity First Insurance'
  },
  IL1: {
    hash: 'NHMv4jkPqrKMSmTL',
    name: 'iLendingDIRECT',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  IN1: {
    hash: 'ind79f',
    agency: true,
    name: 'Insurify',
    informationId: 'IN1',
    disableChat: true
  },
  IM0001: {
    hash: 'IM0001hash',
    agency: true,
    name: 'Independent Market Solutions',
    informationId: 'IM0001',
    disableChat: true
  },
  IN0002: {
    hash: 'IN0002hash',
    agency: true,
    name: 'Integrity First Insurance Services',
    informationId: 'IN0002',
    disableChat: true
  },
  IN0003: {
    hash: 'IN0003hash',
    name: 'Insurance Professional Agency INC',
    agency: true,
    disableChat: true,
    informationId: 'IN0003'
  },
  IN0003_3: {
    hash: 'IN0003_3hash',
    name: 'Insurance Professional Agency INC',
    agency: true,
    disableChat: true,
    informationId: 'IN0003_3'
  },
  IN0003P: {
    hash: 'IN0003Phash',
    name: 'Insurance Professional Agency INC',
    agency: true,
    disableChat: true,
    informationId: 'IN0003P'
  },
  IN0004: {
    hash: 'IN0004hash',
    name: 'Insurance Source Solutions LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0004'
  },
  IN0005_1: {
    hash: 'IN0005_1hash',
    name: 'Insurance Amount Services LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0005_1'
  },
  IN0006: {
    hash: 'IN0006hash',
    name: '3000 Insurance Group, LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0006'
  },
  IN0008: {
    hash: 'IN0008hash',
    name: 'Insure Right Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0008'
  },
  IN0009: {
    hash: 'IN0009hash',
    name: 'Infinitus Inc. DBA TMJ Group',
    agency: true,
    disableChat: true,
    informationId: 'IN0009'
  },
  IN00012: {
    hash: 'IN00012hash',
    name: 'Intrepid Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'IN00012'
  },
  IN00013: {
    hash: 'IN00013hash',
    name: 'Insurance by Bruce',
    agency: true,
    disableChat: true,
    informationId: 'IN00013'
  },
  IN00013P: {
    hash: 'IN00013Phash',
    name: 'Insurance by Bruce',
    agency: true,
    disableChat: true,
    informationId: 'IN00013P'
  },
  IN00014: {
    hash: 'IN00014hash',
    name: 'Indium',
    agency: true,
    serviceAgreement: true,
    informationId: 'IN00014'
  },
  IN00014_1P: {
    hash: 'IN00014_1Phash',
    name: 'Indium',
    agency: true,
    serviceAgreement: true,
    informationId: 'IN00014_1P'
  },
  IN0016_4P: {
    hash: 'IN0016_4Phash',
    name: 'Just Protect',
    agency: true,
    disableChat: true,
    informationId: 'IN0016_4P'
  },
  IN0016_5P: {
    hash: 'IN0016_5Phash',
    name: 'Coverage With Aloha LLC DBA Ogden Utah Insurance',
    agency: true,
    disableChat: true,
    informationId: 'IN0016_5P'
  },
  IN0017_1: {
    hash: 'IN0017_1hash',
    name: 'Insurance Solutions Group of Troy, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'IN0017_1'
  },
  IN0017_2: {
    hash: 'IN0017_2hash',
    name: 'Insurance Solutions Group of Troy, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'IN0017_2'
  },
  IN0018_1P: {
    hash: 'IN0018_1Phash',
    name: 'Alabama Insurance Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'IN0018_1P'
  },
  IN0018_10P: {
    hash: 'IN0018_10Phash',
    name: 'Alabama Insurance Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'IN0018_10P'
  },
  IN0020_1: {
    hash: 'IN0020_1hash',
    name: 'Just Protect',
    agency: true,
    disableChat: true,
    informationId: 'IN0020_1'
  },
  IN0021_1P: {
    hash: 'IN0021_1Phash',
    name: 'INSURANCE EXPRESS, LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0021_1P'
  },
  IN0022_1P: {
    hash: 'IN0022_1Phash',
    name: 'Insurance Services of Southern Oregon LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0022_1P'
  },
  IN0023_1P: {
    hash: 'IN0023_1Phash',
    name: 'Integrity Insurance Group, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'IN0023_1P'
  },
  IN0024_1P: {
    hash: 'IN0024_1Phash',
    name: 'Neverman Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'IN0024_1P'
  },
  IN0026_1P: {
    hash: 'IN0026_1Phash',
    name: 'Insurance Pros Inc DBA Maine',
    agency: true,
    disableChat: true,
    informationId: 'IN0026_1P'
  },
  IN0027_1: {
    hash: 'IN0027_1hash',
    name: 'Hilltown Insurance Group, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'IN0027_1'
  },
  IN0028_1: {
    hash: 'IN0028_1hash',
    name: 'Rechtenbach Insurance Group LLC.',
    agency: true,
    disableChat: true,
    informationId: 'IN0028_1'
  },
  IN0028_1P: {
    hash: 'IN0028_1Phash',
    name: 'Rechtenbach Insurance Group LLC.',
    agency: true,
    disableChat: true,
    informationId: 'IN0028_1P'
  },
  IN0029_1P: {
    hash: 'IN0029_1Phash',
    name: 'Insurance Unlimited of Bozeman',
    agency: true,
    disableChat: true,
    informationId: 'IN0029_1P'
  },
  IN0031_1P: {
    hash: 'IN0031_1Phash',
    name: 'Insurance Gurus LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0031_1P'
  },
  IN0032_1P: {
    hash: 'IN0032_1Phash',
    name: 'Outcalt Kerns Insurance LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0032_1P'
  },
  IN0033_1P: {
    hash: 'IN0033_1Phash',
    name: 'Strong Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0033_1P'
  },
  IN0034_1P: {
    hash: 'IN0034_1Phash',
    name: 'Veal Insurance Agency, Inc',
    agency: true,
    disableChat: true,
    informationId: 'IN0034_1P'
  },
  IN0036_1P: {
    hash: 'IN0036_1Phash',
    name: 'Choices Independent Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'IN0036_1P'
  },
  IN0038_1P: {
    hash: 'IN0038_1Phash',
    name: 'Seibt Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'IN0038_1P'
  },
  IN0039_1P: {
    hash: 'IN0039_1Phash',
    name: 'Duffy Insurance Partners LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0039_1P'
  },
  IN0040_1P: {
    hash: 'IN0040_1Phash',
    name: 'Integrity Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'IN0040_1P'
  },
  IN0041_1P: {
    hash: 'IN0041_1Phash',
    name: 'Insure It All',
    agency: true,
    disableChat: true,
    informationId: 'IN0041_1P'
  },
  IN0042_1P: {
    hash: 'IN0042_1Phash',
    name: 'AgriPeril Risk managment',
    agency: true,
    disableChat: true,
    informationId: 'IN0042_1P'
  },
  IN0043_1P: {
    hash: 'IN0043_1Phash',
    name: 'Dunn Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'IN0043_1P'
  },
  IN0044_1P: {
    hash: 'IN0044_1Phash',
    name: 'Hearthstone Insurance Group LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0044_1P'
  },
  IN0046_1P: {
    hash: 'IN0046_1Phash',
    name: 'Insurance Express Agency, LLC',
    agency: true,
    disableChat: true,
    informationId: 'IN0046_1P'
  },
  INSMRT1: {
    hash: 'insmmmm',
    name: 'Insuremart, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'INSMRT1'
  },
  INT: {
    hash: 'intd79f',
    agency: true,
    name: 'Integra Insurance Solutions',
    disableChat: true
  },
  INT27P: {
    hash: 'int27phash',
    name: 'Montgomery Integra',
    agency: true,
    informationId: 'INT27P',
    disableChat: true
  },
  INT0001_1: {
    hash: 'int0001_1hash',
    name: 'Integrity First Insurance Services',
    agency: true,
    disableChat: true
  },
  IP0002: {
    hash: 'IP0002hash',
    name: 'iProtect Insurance & Financial Services, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'IP0002'
  },
  IS0002: {
    hash: 'IS0002hash',
    name: 'ISU Premier Insurance Partners',
    agency: true,
    informationId: 'IS0002',
    disableChat: true
  },
  ISI0001: {
    hash: 'ISI0001hash',
    name: 'Indy Select Insurance',
    agency: true,
    disableChat: true
  },
  ISG1: {
    hash: 'ISG1hash',
    name: 'ISG United',
    agency: true,
    informationId: 'ISG1',
    disableChat: true
  },
  ISG7P: {
    hash: 'ISG7Phash',
    name: 'Alabama Insurance Markets',
    agency: true,
    informationId: 'ISG7P',
    disableChat: true
  },
  INSP1: {
    hash: 'dfsjkga678',
    name: 'Inspectify'
  },
  INSTM1: {
    hash: 'instm1hash',
    agency: true,
    name: 'The Insurance Team',
    disableChat: true
  },
  IOA0001: {
    hash: 'ioa0001hash',
    name: 'Simply IOA',
    agency: true,
    disableChat: true,
    informationId: 'IOA0001'
  },
  IS0003_1P: {
    hash: 'is0003_1phash',
    name: 'ISU Insurance Planning Services',
    agency: true,
    disableChat: true,
    informationId: 'IS0003_1P'
  },
  IY1: {
    hash: 'iewmvds532',
    agency: true,
    name: 'Insuredly',
    informationId: 'IY1',
    disableChat: true
  },
  IY2: {
    hash: 'iewmvds533',
    agency: true,
    name: 'Insuredly',
    informationId: 'IY1',
    disableChat: true
  },
  IY3: {
    hash: 'iewmvds534',
    agency: true,
    name: 'Insuredly',
    informationId: 'IY1',
    disableChat: true
  },
  IY4: {
    hash: 'iewmvds5354',
    agency: true,
    name: 'Insuredly',
    informationId: 'IY1',
    disableChat: true
  },
  IY5: {
    hash: 'iewmvds53554',
    agency: true,
    name: 'Insuredly',
    informationId: 'IY1',
    disableChat: true
  },
  JA0001: {
    hash: 'ja0001hash',
    name: 'Jason Rubin Agency',
    agency: true,
    informationId: 'JA0001',
    disableChat: true
  },
  JC1: {
    hash: 'iewjjc53',
    agency: true,
    name: 'JC Smith Insurance',
    informationId: 'JC1',
    disableChat: true
  },
  JCBS1: {
    hash: 'jcbs1hash',
    name: 'Jacobs Insurance Solutions',
    agency: true,
    informationId: 'JCBS1',
    disableChat: true
  },
  JCBS1D: {
    hash: 'jcbs1Dhash',
    name: 'Jacobs Insurance Solutions',
    informationId: 'JCBS1',
    disableChat: true
  },
  JD1: {
    hash: 'dfsjkvjd1',
    agency: true,
    name: 'Jared Reynolds Insurance',
    informationId: 'JD1',
    disableChat: true
  },
  JE0001: {
    hash: 'je00011hash',
    name: 'Jacobs Insurance Solutions',
    agency: true,
    disableChat: true
  },
  JE0002_1: {
    hash: 'JE0002_1hash',
    name: 'Jeff Johnson Agency',
    agency: true,
    disableChat: true,
    informationId: 'JE0002_1'
  },
  JI0001: {
    hash: 'ji00011hash',
    name: 'JIM HORNE INSURANCE AGENCY,INC',
    agency: true,
    disableChat: true
  },
  JL1: {
    hash: 'iewjlllds53',
    name: 'Jennifer Lombardo',
    agency: true,
    informationId: 'JL1',
    disableChat: true
  },
  JL0001_1P: {
    hash: 'JL0001_1Phash',
    name: 'John Letson Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'JL0001_1P'
  },
  JLEE1: {
    hash: 'jlee1hash',
    agency: true,
    name: 'John Lee Insurance Agency LLC',
    disableChat: true
  },
  JO0001: {
    hash: 'jo0001hash',
    agency: true,
    name: 'John Shawareb Agency LLC',
    informationId: 'JO0001',
    disableChat: true
  },
  JO0002: {
    hash: 'jo0002hash',
    agency: true,
    name: 'Jordan River Insurance',
    informationId: 'JO0002',
    disableChat: true
  },
  JO0003: {
    hash: 'jo0003hash',
    agency: true,
    name: 'Jordan Barnett Agency',
    informationId: 'JO0003',
    disableChat: true
  },
  JO0004_1P: {
    hash: 'JO0004_1Phash',
    agency: true,
    name: 'Jones Insurance Group, LLC',
    informationId: 'JO0004_1P',
    disableChat: true
  },
  JO0005_1P: {
    hash: 'JO0005_1Phash',
    agency: true,
    name: 'Jose Vargas Insurance Agency LLC',
    informationId: 'JO0005_1P',
    disableChat: true
  },
  JO0006_1P: {
    hash: 'JO0006_1Phash',
    agency: true,
    name: 'Joey Proffitt Agency,Ind',
    informationId: 'JO0006_1P',
    disableChat: true
  },
  JO0007_1P: {
    hash: 'JO0007_1Phash',
    agency: true,
    name: 'Joel Rieth Ins Agency Inc',
    informationId: 'JO0007_1P',
    disableChat: true
  },
  JL0002_1P: {
    hash: 'JL0002_1Phash',
    agency: true,
    name: 'JLB Consulting Inc. dba Advantage Insurance Agency ',
    informationId: 'JL0002_1P',
    disableChat: true
  },
  JM0001: {
    hash: 'JM0001hash',
    name: 'J M Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'JM0001'
  },
  JN0001: {
    hash: 'JN0001hash',
    name: 'JNR Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'JN0001'
  },
  JR1: {
    hash: 'iewjrds53',
    name: 'Joseph W. Reeves Insurance',
    agency: true,
    informationId: 'JR1',
    disableChat: true
  },
  JS0001P: {
    hash: 'JS0001Phash',
    name: 'Justice Couch Insurance, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'JS0001P'
  },
  JU0001: {
    hash: 'JU0001hash',
    name: 'Just Protect Insurance',
    agency: true,
    informationId: 'JU0001',
    disableChat: true
  },
  JU0001P: {
    hash: 'JU0001Phash',
    name: 'Just Protect Insurance',
    agency: true,
    disableChat: true,
    informationId: 'JU0001P'
  },
  JU0002P: {
    hash: 'JU0002Phash',
    name: 'Jupiter Auto Insurance, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'JU0002P'
  },
  JU0003: {
    hash: 'JU0003hash',
    name: 'Justin Terry Agency Inc.',
    agency: true,
    informationId: 'JU0003',
    disableChat: true
  },
  JW0001_1P: {
    hash: 'JW0001_1Phash',
    name: 'J Wood Insurance',
    agency: true,
    informationId: 'JW0001_1P',
    disableChat: true
  },
  KA0001: {
    hash: 'KA0001hash',
    name: 'Kansas Preferred Insurance Group',
    agency: true,
    informationId: 'KA0001',
    disableChat: true
  },
  KA0002_1: {
    hash: 'KA0002_1hash',
    name: 'Kaltenecker Insurance LLC.',
    agency: true,
    informationId: 'KA0002_1',
    disableChat: true
  },

  KC0001: {
    hash: 'KC0001hash',
    name: 'Kcraft Insurance Agency',
    agency: true,
    informationId: 'KC0001',
    disableChat: true
  },
  KE0001: {
    hash: 'KE0001hash',
    name: 'Kevin Dennis Insurance Agency',
    agency: true,
    informationId: 'KE0001',
    disableChat: true
  },
  KR0001P: {
    hash: 'KR0001Phash',
    name: 'Kries Insurance Agency',
    agency: true,
    informationId: 'KR0001P',
    disableChat: true
  },
  CK1: {
    hash: 'CK1hash',
    name: 'Karma Insurance Services',
    informationId: 'CK1'
  },
  KG1: {
    hash: 'dfsakvs7fsdkg',
    name: 'Kangaroo',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    homeSecurity: true,
    signUpThroughBranch: true
  },
  KG2: {
    hash: 'dfsakvs7fsdkg2',
    name: 'Kangaroo',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    homeSecurity: true,
    signUpThroughBranch: true
  },
  KG3: {
    hash: 'dfsakvs7fsdkg3',
    name: 'Kangaroo',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    homeSecurity: true,
    signUpThroughBranch: true
  },
  KG4: {
    hash: 'dfsakvs7fsdkg4',
    name: 'Kangaroo',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    homeSecurity: true,
    signUpThroughBranch: true
  },
  KI0002_1P: {
    hash: 'KI0002_1Phash',
    name: 'Knisely Insurance Agency Inc',
    agency: true,
    informationId: 'KI0002_1P',
    disableChat: true
  },
  KLT1: {
    hash: 'kdfuiwft1',
    name: 'KLT Insurance',
    agency: true,
    informationId: 'KLT1',
    disableChat: true
  },
  KLT2: {
    hash: 'kdfuiwft12',
    name: 'KLT Insurance',
    agency: true,
    informationId: 'KLT2',
    disableChat: true
  },
  KM0001: {
    hash: 'KM0001hash',
    name: 'Lorenz Agency LLC',
    agency: true,
    disableChat: true
  },
  KN0001: {
    hash: 'KN0001hash',
    name: 'Knights Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'KN0001'
  },
  KO0001: {
    hash: 'KO0001hash',
    name: 'Kozuch Insurance Services',
    agency: true,
    informationId: 'KO0001',
    disableChat: true
  },
  KO0002_1P: {
    hash: 'KO0002_1Phash',
    name: 'Kouri Insurance Agency',
    agency: true,
    informationId: 'KO0002_1P',
    disableChat: true
  },
  KORT1: {
    hash: 'kort1hash',
    name: 'Kortsen & Associates LLC',
    agency: true,
    disableChat: true
  },
  KR0001: {
    hash: 'KR0001hash',
    name: 'Kreis Insruance Agency',
    agency: true,
    informationId: 'KR0001',
    disableChat: true
  },
  KST1: {
    hash: 'M99dVc',
    name: 'Kissterra'
  },
  KW0001: {
    hash: 'KW0001hash',
    name: 'Kwikinsureu Inc.',
    agency: true,
    disableChat: true,
    informationId: 'KW0001'
  },
  LA0001: {
    hash: 'la00011hash',
    agency: true,
    name: 'Landmark Risk Management & Insurance',
    disableChat: true
  },
  LB1: {
    hash: 'ieddsxds53',
    name: 'Liberty Tax',
    states: {
      IN: 0,
      OH: 0
    }
  },
  LBV1: {
    hash: 'lbv1hash',
    name: 'LBV INSURANCE',
    agency: true,
    disableChat: true
  },
  LE0003_1: {
    hash: 'LE0003_1hash',
    agency: true,
    name: 'Leprechaun Insurance',
    informationId: 'LE0003_1',
    disableChat: true
  },
  LE0003_2: {
    hash: 'LE0003_2hash',
    agency: true,
    name: 'Leprechaun Insurance',
    informationId: 'LE0003_2',
    disableChat: true
  },
  LE0004_1P: {
    hash: 'LE0004_1Phash',
    agency: true,
    name: 'Leap Insurance',
    informationId: 'LE0004_1P',
    disableChat: true
  },
  LF0001: {
    hash: 'LF0001hash',
    agency: true,
    name: 'Leggett Insurance & Financial Services Inc. DBA Hapbridge',
    informationId: 'LF0001',
    disableChat: true
  },
  LD0001_1P: {
    hash: 'LD0001_1Phash',
    agency: true,
    name: 'L & D Insurance Services, LLC.',
    informationId: 'LD0001_1P',
    disableChat: true
  },
  LG0001: {
    hash: 'LG0001hash',
    name: 'Lagrave Agency Inc.',
    agency: true,
    disableChat: true,
    informationId: 'LG0001'
  },
  LG1: {
    hash: 'fddddsv4d',
    name: 'Lendgo',
    states: {
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      MI: 2,
      MO: 2,
      NE: 2,
      NJ: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      RI: 2,
      SC: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2
    }
  },
  LGI1: {
    hash: 'lgsdfdw',
    agency: true,
    name: 'Legacy Insures',
    disableChat: true
  },
  LI0002_1: {
    hash: 'LI0002_1hash',
    name: 'Lincolnway Insurance Services, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'LI0002_1'
  },
  LI0002_1P: {
    hash: 'LI0002_1Phash',
    name: 'Lincolnway Insurance Services, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'LI0002_1P'
  },
  LOGIC: {
    hash: 'logggic',
    name: 'Logic Auto Sales'
  },
  LOUI1: {
    hash: 'loui1hash',
    name: 'Lou Insurance & Financial Services Inc',
    agency: true,
    disableChat: true
  },
  LNSTR1: {
    hash: 'lnstr1hash',
    name: 'Lone Star Insurers',
    agency: true,
    disableChat: true
  },
  LU0001: {
    hash: 'LU0001hash',
    name: 'Lumenasa Insurance Agency LLC.',
    agency: true,
    disableChat: true,
    informationId: 'LU0001'
  },
  LU0002: {
    hash: 'LU0002hash',
    name: 'Luxe Insurance Brokers LLC',
    agency: true,
    disableChat: true,
    informationId: 'LU0002'
  },
  LU0002P: {
    hash: 'LU0002Phash',
    name: 'Luxe Insurance Brokers LLC',
    agency: true,
    disableChat: true,
    informationId: 'LU0002P'
  },
  LU0003_1: {
    hash: 'LU0003_1hash',
    name: 'Lunas Multi Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'LU0003_1'
  },
  LU0003_1P: {
    hash: 'LU0003_1Phash',
    name: 'Lunas Multi Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'LU0003_1P'
  },
  LT0001: {
    hash: 'LT0001hash',
    name: 'The Layton Company',
    agency: true,
    disableChat: true,
    informationId: 'LT0001'
  },
  LW0001P: {
    hash: 'LW0001Phash',
    name: 'Loftis & Wetzel Corp',
    agency: true,
    disableChat: true,
    informationId: 'LW0001P'
  },
  MATIC1: {
    hash: 'maticabs',
    name: 'Matic',
    agency: true,
    informationId: 'MATIC1',
    disableChat: true
  },
  MA0001: {
    hash: 'ma0001hashxx',
    name: 'Midwest Insurance Agency',
    agency: true,
    informationId: 'MA0001',
    disableChat: true
  },
  MA0002_1: {
    hash: 'MA0002_1hash',
    name: 'Matt Witsken LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0002_1'
  },
  MA0003_1: {
    hash: 'MA0003_1hash',
    name: 'Madison Insurance Association LLC dba Madison Independent Agency Network LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0003_1'
  },
  MA0003P: {
    hash: 'MA0003Phash',
    name: 'Madison Insurance Association LLC dba Madison Independent Agency Network LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0003P'
  },
  MA0004: {
    hash: 'MA0004hash',
    name: 'Mary Andrews Ins. Agency Inc. ',
    agency: true,
    disableChat: true,
    informationId: 'MA0004'
  },
  MA0005_1: {
    hash: 'MA0005_1hash',
    name: 'Martinek Insurance Services Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MA0005_1'
  },
  MA0005_1P: {
    hash: 'MA0005_1Phash',
    name: 'Martinek Insurance Services Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MA0005_1P'
  },
  MA0005_2: {
    hash: 'MA0005_2hash',
    name: 'Martinek Insurance Services Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MA0005_2'
  },
  MA0014_1: {
    hash: 'MA0014_1hash',
    name: 'Swan Insurance Services, LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0014_1'
  },
  MA0015_1: {
    hash: 'MA0015_1hash',
    name: 'Securitas Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0015_1'
  },
  MA0019_1: {
    hash: 'MA0019_1hash',
    name: 'Mark Skorusa Insurance Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MA0019_1'
  },
  MA0019_1P: {
    hash: 'MA0019_1Phash',
    name: 'Mark Skorusa Insurance Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MA0019_1P'
  },
  MA0020_1P: {
    hash: 'MA0020_1Phash',
    name: 'Marble City Insurance LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0020_1P'
  },
  MA0021_1P: {
    hash: 'MA0021_1Phash',
    name: 'Mayne Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'MA0021_1P'
  },
  MARBLE1: {
    hash: 'MARBLE1hash',
    name: 'Marble'
  },
  MC0001P: {
    hash: 'MC0001Phash',
    name: 'McCluskey Insurance Services Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MC0001P'
  },
  MC0001: {
    hash: 'mc0001hash',
    name: 'McCluskey Insurance Services Inc.',
    agency: true,
    informationId: 'MC0001',
    disableChat: true
  },
  MC0001D: {
    hash: 'mc0001Dhash',
    name: 'McCluskey Insurance Services Inc.',
    informationId: 'MC0001D',
    disableChat: true
  },

  MCI0001: {
    hash: 'mci0001hash',
    name: 'Magic City Insurance Group, LLC',
    agency: true,
    informationId: 'MCI0001',
    disableChat: true
  },
  MC0003: {
    hash: 'mc0003hash',
    name: 'McCartan Insurance Group, Inc.',
    agency: true,
    informationId: 'MC0003',
    disableChat: true
  },
  MC0003_1: {
    hash: 'mc0003_1hash',
    name: 'Downtown Insurance LLC.',
    agency: true,
    informationId: 'MC0003_1',
    disableChat: true
  },
  MC0004_1: {
    hash: 'mc0004_1hash',
    name: 'McDonald Insurance Group of Colorado LLC.',
    agency: true,
    informationId: 'MC0004_1',
    disableChat: true
  },
  MC0004_1P: {
    hash: 'mc0004_1Phash',
    name: 'McDonald Insurance Group of Colorado LLC.',
    agency: true,
    informationId: 'MC0004_1P',
    disableChat: true
  },
  MCR1: {
    hash: 'mcr1hash',
    name: 'Mike Crise Agency',
    agency: true,
    disableChat: true
  },
  MJ0001: {
    hash: 'MJ0001hash',
    agency: true,
    name: 'MJB Financial & Insurance Advisors',
    informationId: 'MJ0001',
    disableChat: true
  },
  ME0001: {
    hash: 'ME0001hash',
    name: 'Melissa Probst DBA Sincerity Insurance',
    agency: true,
    disableChat: true,
    informationId: 'ME0001'
  },
  ME0002: {
    hash: 'ME0002hash',
    name: 'Meraki Insurance Agency, LLC.',
    agency: true,
    informationId: 'ME0002',
    disableChat: true
  },
  ME0003_1P: {
    hash: 'ME0003_1Phash',
    name: 'Meloniece Gaskin Insurance Agency LLC',
    agency: true,
    informationId: 'ME0003_1P',
    disableChat: true
  },
  ME0004_1P: {
    hash: 'ME0004_1Phash',
    name: 'Merit Insurance Advisors',
    agency: true,
    informationId: 'ME0004_1P',
    disableChat: true
  },
  ME0005_1P: {
    hash: 'ME0005_1Phash',
    name: 'Metro Insurance',
    agency: true,
    informationId: 'ME0005_1P',
    disableChat: true
  },
  MG0001: {
    hash: 'MG0001hash',
    name: 'MG Agency',
    agency: true,
    disableChat: true,
    informationId: 'MG0001'
  },
  MI0001: {
    hash: 'MI0001hashs',
    name: 'THE MITCHELL AGENCY LLC',
    agency: true,
    disableChat: true,
    informationId: 'MI0001'
  },
  MI0002_1: {
    hash: 'MI0002_1hash',
    name: 'Mike Lambert Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0002_1'
  },
  MI0003_1: {
    hash: 'MI0003_1hash',
    name: 'MidCoast Insrance Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0003_1'
  },
  MI0003_1P: {
    hash: 'MI0003_1Phash',
    name: 'MidCoast Insrance Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0003_1P'
  },
  MI0003_2: {
    hash: 'MI0003_2hash',
    name: 'MidCoast Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0003_2'
  },
  MI0004: {
    hash: 'MI0004hash',
    name: 'Mid-State Farm Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'MI0004'
  },
  MI0004P: {
    hash: 'MI0004Phash',
    name: 'Mid-State Farm Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'MI0004P'
  },
  MI0005P: {
    hash: 'MI0005Phash',
    name: 'Mid-State Farm Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'MI0005P'
  },
  MI0006_1: {
    hash: 'MI0006_1hash',
    name: 'Midwest Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0006_1'
  },
  MI0007_1: {
    hash: 'MI0007_1hash',
    name: 'Michael Robert Smith dba Micheal Smith Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0007_1'
  },
  MI0007_1P: {
    hash: 'MI0007_1Phash',
    name: 'Michael Robert Smith dba Micheal Smith Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'MI0007_1P'
  },
  MI0008_1P: {
    hash: 'MI0008_1Phash',
    name: 'MidArk Insurance Group Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MI0008_1P'
  },
  MI0009_1P: {
    hash: 'MI0009_1Phash',
    name: 'Miller Insurance Team, llc',
    agency: true,
    disableChat: true,
    informationId: 'MI0009_1P'
  },
  MIL: {
    hash: 'idnmiltas',
    agency: true,
    name: 'Milnor Insurance',
    informationId: 'MIL',
    disableChat: true
  },
  MILO1: {
    hash: 'milo1hash',
    name: 'MILOSEVIC INSURANCE AGENCY',
    agency: true,
    disableChat: true
  },
  MMS1: {
    hash: '4mmsssGIA7',
    name: 'Midwest Motor Supply',
    states: {
      AZ: 2,
      IL: 2,
      IN: 2,
      MD: 2,
      MO: 2,
      OH: 2,
      OK: 2,
      TX: 2
    }
  },
  MMTCH1: {
    hash: 'mmtch1hash',
    name: 'Mix & Match Insurance Brokerage LLC',
    agency: true,
    disableChat: true
  },
  MNSTY1: {
    name: 'Mainstay Insurance Agency',
    agency: true,
    hash: 'eui983lamnstyi',
    disableChat: true
  },
  MO0001: {
    hash: 'MO0001hash',
    name: 'Morrison Insurance',
    agency: true,
    disableChat: true,
    informationId: 'MO0001'
  },
  MO0001P: {
    hash: 'MO0001Phash',
    name: 'Morrison Insurance',
    agency: true,
    disableChat: true,
    informationId: 'MO0001P'
  },
  MO0002: {
    hash: 'MO0002hash',
    name: 'Model City Insurance',
    agency: true,
    disableChat: true,
    informationId: 'MO0002'
  },
  MO0002P: {
    hash: 'MO0002Phash',
    name: 'Model City Insurance',
    agency: true,
    disableChat: true,
    informationId: 'MO0002P'
  },
  MO0003: {
    hash: 'MO0003hash',
    name: 'Monarch Insurance Associates',
    agency: true,
    disableChat: true,
    informationId: 'MO0003'
  },
  MO0005_1P: {
    hash: 'MO0005_1Phash',
    name: 'Montgomery and Associates',
    agency: true,
    disableChat: true,
    informationId: 'MO0005_1P'
  },
  MQX0001: {
    hash: 'mqx0001phash',
    name: 'MQX LLC',
    agency: true,
    disableChat: true
  },
  MT0001: {
    hash: 'mt0001hash',
    name: 'Mtran Insurance and Associates',
    agency: true,
    disableChat: true
  },
  MT0002: {
    hash: 'MT0002hash',
    name: 'Mt. Franklin Insurance Agency, LLC',
    agency: true,
    informationId: 'MT0002',
    disableChat: true
  },
  MTRO1: {
    hash: 'mtro1hash',
    name: 'Metroplex Insurance & Financial Group LLC',
    agency: true,
    disableChat: true
  },
  MU0001_1P: {
    hash: 'MU0001_1Phash',
    name: 'Muir Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'MU0001_1P'
  },
  MU0003_1P: {
    hash: 'MU0003_1Phash',
    name: 'MURPHY INSURANCE AGENCY',
    agency: true,
    disableChat: true,
    informationId: 'MU0003_1P'
  },
  MY0002: {
    hash: 'MY0002hash',
    name: 'My Family Insurance',
    agency: true,
    disableChat: true,
    informationId: 'MY0002'
  },
  MY0001_9: {
    hash: 'MY0001_9hash',
    name: 'My Insurance Agent Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MY0001_9'
  },
  MY0004_1P: {
    hash: 'MY0004_1Phash',
    name: 'My Insurance Agent Inc.',
    agency: true,
    disableChat: true,
    informationId: 'MY0001_9'
  },
  MVP: {
    name: 'MVP Insurance Agency',
    agency: true,
    hash: 'qe9df91d5jh',
    disableChat: true
  },
  MWBG: {
    name: 'Mark Wahlberg Buick GMC',
    hash: '8fed6e5er'
  },
  MWC: {
    name: 'Mark Wahlberg Chevy',
    hash: 'qwhj8zd21s'
  },
  MYLO1: {
    hash: 'klzujoi1jk',
    name: 'Mylo Insurance Solutions',
    agency: true,
    disableChat: true
  },
  MY0001_P: {
    hash: 'my0001Phash',
    name: 'My Insurance Agent, Inc',
    agency: true,
    disableChat: true,
    informationId: 'MY0001_P'
  },
  NE0001_1: {
    hash: 'NE0001_1hash',
    name: 'New Beginnings Insruance Agency',
    agency: true,
    disableChat: true,
    informationId: 'NE0001_1'
  },
  NE0001_2: {
    hash: 'NE0001_2hash',
    name: 'New Beginnings Insruance Agency',
    agency: true,
    disableChat: true,
    informationId: 'NE0001_2'
  },
  NE0001_1P: {
    hash: 'NE0001_1Phash',
    name: 'New Beginnings Insruance Agency',
    agency: true,
    disableChat: true,
    informationId: 'NE0001_1P'
  },
  NE0002_1P: {
    hash: 'NE0002_1Phash',
    name: 'New Eagle Insurance',
    agency: true,
    disableChat: true,
    informationId: 'NE0002_1P'
  },
  NE0003_1P: {
    hash: 'NE0003_1Phash',
    name: 'Next Level Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'NE0003_1P'
  },
  NEX1: {
    hash: 'fdsanexe',
    name: 'Nexar',
    states: {
      AZ: 2,
      CO: 2,
      IA: 2,
      IL: 2,
      IN: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      OH: 2,
      OK: 2,
      PA: 2,
      SD: 2,
      UT: 2,
      VT: 2,
      WI: 2
    }
  },
  NEXTB1: {
    hash: 'dfvnyds3',
    name: 'Nextbase',
    states: {
      AZ: 2,
      CO: 2,
      IA: 2,
      IL: 2,
      IN: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      OH: 2,
      OK: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VT: 2,
      WI: 2
    }
  },
  NI0001: {
    hash: 'ni0001hash',
    name: 'Nicia Insurance',
    agency: true,
    disableChat: true
  },
  NMIB1: {
    hash: 'nmibd33f1',
    agency: true,
    name: 'NMH Insurance Agency',
    informationId: 'NMIB1',
    disableChat: true
  },
  NMIB2: {
    hash: 'nmib2hash',
    agency: true,
    name: 'NMH Insurance Agency',
    informationId: 'NMIB2',
    disableChat: true
  },
  NN1: {
    name: 'Nissan North',
    hash: 'ml1dsazd6s4'
  },
  NO0001: {
    hash: 'NO0001hash',
    name: 'North American Insurance Agency of Louisiana, Inc',
    agency: true,
    disableChat: true,
    informationId: 'NO0001'
  },
  NS0001: {
    hash: 'ns0001phash',
    name: 'Nsure Insurance Services Inc.',
    agency: true,
    disableChat: true
  },
  NV0001: {
    hash: 'nv0001hash',
    name: 'Nevada Insurance',
    agency: true,
    disableChat: true
  },
  NV0001P: {
    hash: 'nv0001phash',
    name: 'Nevada Insurance',
    agency: true,
    disableChat: true
  },
  NW1: {
    hash: '4GIA7',
    name: 'NWOSS',
    homeSecurity: true,
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  NWE: {
    hash: '4GIA7E',
    name: 'NWOSS',
    homeSecurity: true,
    states: {
      IN: 2,
      OH: 2,
      OK: 2
    }
  },
  NYLE: {
    name: 'Nyle Maxwell CJDR',
    hash: 'gf5nyletg'
  },
  OAC: {
    hash: 'noac33f1',
    agency: true,
    name: 'AAA Ohio Auto Club',
    informationId: 'OAC',
    disableChat: true
  },
  OBB: {
    hash: 'ruobbiri',
    name: 'Olive Branch Brokerage, LLC',
    agency: true,
    disableChat: true,
    informationId: 'OBB'
  },
  OB0001: {
    hash: 'ob0001hash',
    name: 'OBrien Agency',
    agency: true,
    disableChat: true
  },
  OBB1: {
    hash: 'OBB1hash',
    name: 'Olive Branch Brokerage',
    agency: true,
    informationId: 'OBB1',
    disableChat: true
  },
  OBB2: {
    hash: 'OBB2hash',
    name: 'Olive Branch Brokerage',
    informationId: 'OBB2',
    disableChat: true
  },
  OBIE1: {
    hash: 'xe6TpyameOBIE',
    name: 'Obie',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OR: 1,
      OK: 1,
      PA: 1,
      SD: 1,
      UT: 1,
      VA: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  OBIEM: {
    hash: 'xe6TpyameOBIEM',
    name: 'Obie Employees',
    states: {
      AR: 2,
      AZ: 2,
      CO: 2,
      IA: 2,
      ID: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NM: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      SD: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },

  OL0001: {
    hash: 'ol0001hash',
    name: 'Old Line Insurance Agency LLC',
    agency: true,
    disableChat: true
  },
  OL0002: {
    hash: 'OL0002hash',
    name: 'Old National Insurance Inc.',
    agency: true,
    disableChat: true,
    informationId: 'OL0002'
  },
  OL0003_1: {
    hash: 'OL0003_1hash',
    name: 'Old Market Insurance',
    agency: true,
    disableChat: true,
    informationId: 'OL0003_1'
  },
  OL0003_2: {
    hash: 'OL0003_2hash',
    name: 'Old Market Insurance',
    agency: true,
    disableChat: true,
    informationId: 'OL0003_2'
  },
  OL0004_1: {
    hash: 'OL0004_1hash',
    name: 'Old Pueblo Benefit Advisors',
    agency: true,
    disableChat: true,
    informationId: 'OL0004_1'
  },
  OK0001: {
    hash: 'ok0001hash',
    name: 'Oklahoma Insurance Connection',
    agency: true,
    disableChat: true,
    informationId: 'OK0001'
  },
  OK0002_1P: {
    hash: 'ok0002_1Phash',
    name: 'Oklahoma Insurance Professionals, LLC',
    agency: true,
    disableChat: true,
    informationId: 'OK0002_1P'
  },
  ON0001: {
    hash: 'on0001hash',
    name: 'OneStop Insurance Solutions LLC',
    agency: true,
    disableChat: true,
    informationId: 'ON0001'
  },
  ON0002_1P: {
    hash: 'ON0002_1Phash',
    name: 'Onstot and Neal Inurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'ON0002_1P'
  },
  OP0001_1P: {
    hash: 'op0001_1Phash',
    name: 'Optimal Insurance AZ',
    agency: true,
    disableChat: true,
    informationId: 'OP0001_1P'
  },
  ORL1: {
    name: 'OpenRoad Lending',
    hash: '1mq7mds98rf',
    hideFromOffers: true
  },
  ORL2: {
    name: 'OpenRoad Lending',
    hash: '1mq7mds98rf2',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  ORL3: {
    name: 'OpenRoad Lending',
    hash: '1mq7mds98rf3',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  ORL4: {
    name: 'OpenRoad Lending',
    hash: '1mq7mds98rf4',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IL: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  ORLE: {
    name: 'OpenRoad Lending Employees',
    hash: '1mq7mds98rfE',
    states: {
      AR: 2,
      AZ: 2,
      CO: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NM: 2,
      OH: 2,
      OK: 2,
      OR: 2,
      PA: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },
  OU0001_1: {
    hash: 'OU0001_1hash',
    name: 'Outdoor AdvINSURE Agency LLC.',
    agency: true,
    disableChat: true,
    informationId: 'OU0001_1'
  },
  OW0001_1P: {
    hash: 'OW0001_1Phash',
    name: 'Ownership Insurance',
    agency: true,
    disableChat: true,
    informationId: 'OW0001_1P'
  },
  P30001: {
    hash: 'p300011hash',
    agency: true,
    name: 'P3 Insurance Solutions',
    informationId: 'P30001',
    disableChat: true
  },
  PA0001: {
    hash: 'pa0001hash',
    agency: true,
    name: 'Paragon Insurance',
    informationId: 'PA0001',
    disableChat: true
  },
  PA0003: {
    hash: 'pa0003hash',
    agency: true,
    name: 'PAKT Insurance Agency, LLC.',
    informationId: 'PA0003',
    disableChat: true
  },
  PA0003P: {
    hash: 'pa0003Phash',
    agency: true,
    name: 'PAKT Insurance Agency, LLC.',
    informationId: 'PA0003P',
    disableChat: true
  },
  PA0003_1P: {
    hash: 'pa0003_1Phash',
    agency: true,
    name: 'PAKT Insurance Agency, LLC.',
    informationId: 'PA0003_1P',
    disableChat: true
  },
  PA0003_2P: {
    hash: 'pa0003_2Phash',
    agency: true,
    name: 'PAKT Insurance Agency, LLC.',
    informationId: 'PA0003_2P',
    disableChat: true
  },
  PA0003_3P: {
    hash: 'pa0003_3Phash',
    agency: true,
    name: 'PAKT-Do615',
    informationId: 'PA0003_3P',
    disableChat: true
  },
  PA0003_4P: {
    hash: 'pa0003_4Phash',
    agency: true,
    name: 'PAKT-DO303',
    informationId: 'PA0003_4P',
    disableChat: true
  },
  PA0003_5P: {
    hash: 'pa0003_5Phash',
    agency: true,
    name: 'PAKT-DO312',
    informationId: 'PA0003_5P',
    disableChat: true
  },
  PA0003_6P: {
    hash: 'pa0003_6Phash',
    agency: true,
    name: 'PAKT-DO317',
    informationId: 'PA0003_6P',
    disableChat: true
  },
  PA0003_7P: {
    hash: 'pa0003_7Phash',
    agency: true,
    name: 'PAKT-Throtl',
    informationId: 'PA0003_7P',
    disableChat: true
  },
  PA0004_1: {
    hash: 'PA0004_1hash',
    agency: true,
    name: 'Pacific Northwest Professional Insurance Agency',
    informationId: 'PA0004_1',
    disableChat: true
  },
  PA0007_1P: {
    hash: 'PA0007_1Phash',
    agency: true,
    name: 'Patriot Insurance Group LLC',
    informationId: 'PA0007_1P',
    disableChat: true
  },
  PA0008_1P: {
    hash: 'PA0008_1Phash',
    agency: true,
    name: 'PATRICK INSURANCE GROUP INC.',
    informationId: 'PA0008_1P',
    disableChat: true
  },
  PC0003_1P: {
    hash: 'pc0003_1Phash',
    agency: true,
    name: 'Elson Insurance Agency',
    informationId: 'PC0003_1P',
    disableChat: true
  },
  PC0004_1P: {
    hash: 'pc0004_1Phash',
    agency: true,
    name: 'Hankins Insurance Group',
    informationId: 'PC0004_1P',
    disableChat: true
  },
  PC0005_1P: {
    hash: 'pc0005_1Phash',
    agency: true,
    name: 'Safeguard Insuranced LLC',
    informationId: 'PC0005_1P',
    disableChat: true
  },
  PC0013_1P: {
    hash: 'pc0013_1Phash',
    agency: true,
    name: 'Hart Insurance Services LLC',
    informationId: 'PC0013_1P',
    disableChat: true
  },
  PC0014_1P: {
    hash: ' PC0014_1Phash',
    agency: true,
    name: 'Fredette Insurance Agency',
    informationId: 'PC0014_1P',
    disableChat: true
  },
  PCF0001: {
    hash: 'pcf0001hash',
    name: 'Blue Ridge National',
    agency: true,
    disableChat: true
  },
  PE0001_1P: {
    hash: 'PE0001_1Phash',
    name: 'Peopeles Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PE0001_1P'
  },
  PE0002: {
    hash: 'PE0002hash',
    name: 'Peck-Glasgow Agency, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PE0002'
  },
  PH0001_1P: {
    hash: 'PH0001_1Phash',
    name: 'Philleo Agency Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PH0001_1P'
  },
  PI0001: {
    hash: '12k3jlakzvqenhc',
    agency: true,
    name: 'Pinnacle Insurance',
    disableChat: true
  },
  PI0003_1P: {
    hash: 'PI0003_1Phash',
    name: 'Guard Home and Auto LLC',
    agency: true,
    disableChat: true,
    informationId: 'PI0003_1P'
  },
  PI0004_1P: {
    hash: 'PI0004_1Phash',
    name: 'Pisk Insurance LLC',
    agency: true,
    disableChat: true,
    informationId: 'PI0004_1P'
  },
  PI0005_1P: {
    hash: 'PI0005_1Phash',
    name: 'Gateway Home and Auto Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PI0005_1P'
  },
  PC1: {
    hash: '8dhjv63jndv',
    name: 'Panda ECS',
    states: {
      AZ: 0,
      OH: 0,
      IL: 0,
      IN: 0,
      MO: 0,
      TX: 0
    }
  },
  PCA1: {
    hash: 'pf7chjaca',
    name: 'Protection Connection Agency',
    agency: true,
    disableChat: true
  },
  PCA2: {
    hash: 'pf7chjaca2',
    name: 'Protection Connection Agency',
    agency: true,
    disableChat: true
  },
  PCJD: {
    name: 'Performance Chrysler Jeep Dodge',
    hash: 'fds46nuw332'
  },
  PG1: {
    hash: 'pg18df',
    name: 'Pogo Technologies LLC'
  },
  PI1: {
    hash: 'pi1hash',
    name: 'Partner Insurance',
    agency: true,
    informationId: 'PI1',
    disableChat: true
  },
  PI0002: {
    hash: 'pi0002hash',
    agency: true,
    name: 'Pinnacle Insurance Agency',
    informationId: 'PI0002',
    disableChat: true
  },
  PI0002P: {
    hash: 'pi0002Phash',
    agency: true,
    name: 'Pinnacle Insurance Agency',
    informationId: 'PI0002P',
    disableChat: true
  },
  PG0001: {
    hash: 'pg00011hash',
    agency: true,
    name: 'Pagel Insurance',
    informationId: 'PG0001',
    disableChat: true
  },
  PMC: {
    hash: 'dfsjiopdmp',
    name: 'Protect My Car',
    informationId: 'PMC'
  },
  PL0001_1P: {
    hash: 'PL0001_1Phash',
    name: 'Planners Insurance Group',
    agency: true,
    informationId: 'PL0001_1P',
    disableChat: true
  },
  PL0002_1P: {
    hash: 'PL0002_1Phash',
    name: 'Platinum Insurance Group',
    agency: true,
    informationId: 'PL0002_1P',
    disableChat: true
  },
  PN0001: {
    hash: 'PN0001hash',
    name: 'Pinnacle Insurance and Investments',
    agency: true,
    informationId: 'PN0001',
    disableChat: true
  },
  PN0001P: {
    hash: 'PN0001Phash',
    name: 'Pinnacle Insurance & Investment',
    agency: true,
    disableChat: true,
    informationId: 'PN0001P'
  },
  PO0001: {
    hash: 'PO0001hash',
    name: 'ProWest Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PO0001'
  },
  PO0001P: {
    hash: 'PO0001Phash',
    name: 'ProWest Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PO0001P'
  },
  PR0002: {
    hash: 'PR0002hash',
    name: 'Premier Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0002'
  },
  PR0004: {
    hash: 'PR0004hash',
    name: 'Premier Group Insurance, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0004'
  },
  PR0004_1: {
    hash: 'PR0004_1hash',
    name: 'Warfighter Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0004_1'
  },
  PR0004_1P: {
    hash: 'PR0004_1Phash',
    name: 'Warfighter Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0004_1P'
  },
  PR0005_1: {
    hash: 'PR0005_1hash',
    name: 'Mountain Valley Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0005_1'
  },
  PR0006_1: {
    hash: 'PR0006_1hash',
    name: 'Good Apple Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0006_1'
  },
  PR0007_1: {
    hash: 'PR0007_1hash',
    name: 'Rocky Mountain Insurance of Colorado',
    agency: true,
    disableChat: true,
    informationId: 'PR0007_1'
  },
  PR0008_1: {
    hash: 'PR0008_1hash',
    name: 'Aspen Insurance Partner, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0008_1'
  },
  PR0009_1: {
    hash: 'PR0009_1hash',
    name: 'Tactical Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'PR0009_1'
  },
  PR0009_2: {
    hash: 'PR0009_2hash',
    name: 'Tactical Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'PR0009_2'
  },
  PR0011_1: {
    hash: 'PR0011_1hash',
    name: 'Wolf Financial Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0011_1'
  },
  PR0012_1: {
    hash: 'PR0012_1hash',
    name: 'Insure918, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0012_1'
  },
  PR0013_1: {
    hash: 'PR0013_1hash',
    name: '212 Insurance Group LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0013_1'
  },
  PR0014_1: {
    hash: 'PR0014_1hash',
    name: 'S&K Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0014_1'
  },
  PR0014_1P: {
    hash: 'PR0014_1Phash',
    name: 'S&K Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0014_1P'
  },
  PR0015_1: {
    hash: 'PR0015_1hash',
    name: 'Tristar Insurance Group LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0015_1'
  },
  PR0016_1: {
    hash: 'PR0016_1hash',
    name: 'Disch Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'PR0016_1'
  },
  PR0017_1: {
    hash: 'PR0017_1hash',
    name: 'CYA Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0017_1'
  },
  PR0018_1: {
    hash: 'PR0018_1hash',
    name: 'Premier Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'PR0018_1'
  },
  PR0019_1: {
    hash: 'PR0019_1hash',
    name: 'Green Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0019_1'
  },
  PR0019_1P: {
    hash: 'PR0019_1Phash',
    name: 'Green Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0019_1P'
  },
  PR0020_1: {
    hash: 'PR0020_1hash',
    name: 'Hometown Insurance Advisors',
    agency: true,
    disableChat: true,
    informationId: 'PR0020_1'
  },
  PR0021_1: {
    hash: 'PR0021_1hash',
    name: 'Captain Insurance and Tax LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0021_1'
  },
  PR0022_1: {
    hash: 'PR0022_1hash',
    name: 'PGI Hammer Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0022_1'
  },
  PR0023_1: {
    hash: 'PR0023_1hash',
    name: 'InsuranceOwls',
    agency: true,
    disableChat: true,
    informationId: 'PR0023_1'
  },
  PR0024_1: {
    hash: 'PR0024_1hash',
    name: 'Gutierrez Financial Services',
    agency: true,
    disableChat: true,
    informationId: 'PR0024_1'
  },
  PR0025_1: {
    hash: 'PR0025_1hash',
    name: 'ATL Consultant LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0025_1'
  },
  PR0026_1: {
    hash: 'PR0026_1hash',
    name: 'Blackline Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0026_1'
  },
  PR0027_1: {
    hash: 'PR0027_1hash',
    name: 'Neighbors First Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0027_1'
  },
  PR0027_2: {
    hash: 'PR0027_2hash',
    name: 'Neighbors First Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0027_2'
  },
  PR0028_1: {
    hash: 'PR0028_1hash',
    name: 'Better Buy Insurance PLLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0028_1'
  },
  PR0029_1: {
    hash: 'PR0029_1hash',
    name: 'The Region Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0029_1'
  },
  PR0029_1P: {
    hash: 'PR0029_1Phash',
    name: 'The Region Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0029_1P'
  },
  PR0030_1: {
    hash: 'PR0030_1hash',
    name: 'Marsala Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0030_1'
  },
  PR0031_1: {
    hash: 'PR0031_1hash',
    name: 'SF Manson Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0031_1'
  },
  PR0032_1: {
    hash: 'PR0032_1hash',
    name: 'Greenzweig Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0032_1'
  },
  PR0033_1: {
    hash: 'PR0033_1hash',
    name: 'Steel City Insurance, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0033_1'
  },
  PR0034_1: {
    hash: 'PR0034_1hash',
    name: 'Premier Wealth Concepts',
    agency: true,
    disableChat: true,
    informationId: 'PR0034_1'
  },
  PR0035_1: {
    hash: 'PR0035_1hash',
    name: 'The Elvins Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0035_1'
  },
  PR0036_1: {
    hash: 'PR0036_1hash',
    name: 'Joe McCloskey Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0036_1'
  },
  PR0037_1: {
    hash: 'PR0037_1hash',
    name: 'MM Advisory',
    agency: true,
    disableChat: true,
    informationId: 'PR0037_1'
  },
  PR0038_1: {
    hash: 'PR0038_1hash',
    name: 'Brightside Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0038_1'
  },
  PR0039_1: {
    hash: 'PR0039_1hash',
    name: 'Hometown Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0039_1'
  },
  PR0043_1P: {
    hash: 'PR0043_1Phash',
    name: 'HomeOwners Advantage Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0043_1P'
  },
  PR0044_1: {
    hash: 'PR0044_1hash',
    name: 'Smith Family Insurance LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0044_1'
  },
  PR0045_1: {
    hash: 'PR0045_1hash',
    name: 'Diamond Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0045_1'
  },
  PR0046_1: {
    hash: 'PR0046_1hash',
    name: 'C and C Insurance Services LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0046_1'
  },
  PR0047_1: {
    hash: 'PR0047_1hash',
    name: 'ARK Independent Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'PR0047_1'
  },
  PR0048_1: {
    hash: 'PR0048_1hash',
    name: 'Booth Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0048_1'
  },
  PR0049_1: {
    hash: 'PR0049_1hash',
    name: 'InsurVisors',
    agency: true,
    disableChat: true,
    informationId: 'PR0049_1'
  },
  PR0049_1P: {
    hash: 'PR0049_1)hash',
    name: 'InsurVisors',
    agency: true,
    disableChat: true,
    informationId: 'PR0049_1P'
  },
  PR0050_1: {
    hash: 'PR0050_1hash',
    name: 'The Mitchell Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'PR0050_1'
  },
  PR0051_1: {
    hash: 'PR0051_1hash',
    name: 'Bradley Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0051_1'
  },
  PR0052_1: {
    hash: 'PR0052_1hash',
    name: 'Total Protection Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0052_1'
  },
  PR0053_1: {
    hash: 'PR0053_1hash',
    name: 'Colorado Insurance Pros',
    agency: true,
    disableChat: true,
    informationId: 'PR0053_1'
  },
  PR0054_1: {
    hash: 'PR0054_1hash',
    name: 'Jack Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0054_1'
  },
  PR0055_1: {
    hash: 'PR0055_1hash',
    name: 'Medina Seguros Insurance Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0055_1'
  },
  PR0056_1: {
    hash: 'PR0056_1hash',
    name: 'Sword Insurance LLC.',
    agency: true,
    disableChat: true,
    informationId: 'PR0056_1'
  },
  PR0057_1: {
    hash: 'PR0057_1hash',
    name: 'Potomac Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0057_1'
  },
  PR0058_1P: {
    hash: 'PR0058_1Phash',
    name: 'Professional Insurance Management Agency Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0058_1P'
  },
  PR0059_1: {
    hash: 'PR0059_1hash',
    name: 'Red Letter Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'PR0059_1'
  },
  PR0059_1P: {
    hash: 'PR0059_1Phash',
    name: 'Red Letter Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'PR0059_1P'
  },
  PR0060_1P: {
    hash: 'PR0060_1Phash',
    name: 'JSH Insurance Solutions',
    agency: true,
    disableChat: true,
    informationId: 'PR0060_1P'
  },
  PR0062_1P: {
    hash: 'PR0062_1Phash',
    name: 'Jacks Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0062_1P'
  },
  PR0063_1P: {
    hash: 'PR0063_1Phash',
    name: 'Big Bear Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0063_1P'
  },
  PR0064_1P: {
    hash: 'PR0064_1Phash',
    name: 'The Willow Oaks Group LLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0064_1P'
  },
  PR0065_1P: {
    hash: 'PR0065_1Phash',
    name: 'JWR Insurance Group Inc',
    agency: true,
    disableChat: true,
    informationId: 'PR0065_1P'
  },
  PR0066_1P: {
    hash: 'PR0066_1Phash',
    name: 'Sentinel Advisors',
    agency: true,
    disableChat: true,
    informationId: 'PR0066_1P'
  },
  PR0067_1P: {
    hash: 'PR0067_1Phash',
    name: 'Deanna Vega Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0067_1P'
  },
  PR0068_1P: {
    hash: 'PR0068_1Phash',
    name: 'Jay A. Hines Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0068_1P'
  },
  PR0069_1P: {
    hash: 'PR0069_1Phash',
    name: 'Graham Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'PR0069_1P'
  },
  PR0071_1P: {
    hash: 'PR0071_1Phash',
    name: 'CASTANEDA INSURANCE AGENCY',
    agency: true,
    disableChat: true,
    informationId: 'PR0071_1P'
  },
  PR0072_1P: {
    hash: 'PR0072_1Phash',
    name: 'Premier Insurance',
    agency: true,
    disableChat: true,
    informationId: 'PR0072_1P'
  },
  PR0074_1P: {
    hash: 'PR0074_1Phash',
    name: 'Prizm Insurance, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'PR0074_1P'
  },
  PR0075_1P: {
    hash: 'PR0075_1Phash',
    name: 'Dynasty Insurance Services LLC',
    agency: true,
    disableChat: true,
    informationId: 'PR0075_1P'
  },
  PR0077_1P: {
    hash: 'PR0077_1Phash',
    name: 'Rise Insurance Ohio',
    agency: true,
    disableChat: true,
    informationId: 'PR0077_1P'
  },
  PR0078_1P: {
    hash: 'PR0078_1Phash',
    name: 'MVS Insurance and Investments Team',
    agency: true,
    disableChat: true,
    informationId: 'PR0078_1P'
  },
  PR0079_1P: {
    hash: 'PR0079_1Phash',
    name: 'Haws Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'PR0079_1P'
  },
  PRM1: {
    hash: 'prm1hash',
    name: 'Priority Risk Management',
    agency: true,
    disableChat: true
  },
  PUP1: {
    hash: 'pup58df',
    name: 'Puptown Lounge',
    states: {
      OH: 2
    }
  },
  PVI0001: {
    hash: 'PVI0001hash',
    name: 'Providence Insurance',
    agency: true,
    informationId: 'PVI0001'
  },
  PIV0001: {
    hash: 'PIV0001hash',
    name: 'Providence Insurance',
    informationId: 'PIV0001'
  },
  PVD1: {
    hash: 'pdjvdspvd1',
    name: 'Provide Financial',
    informationId: 'PVD1'
  },
  PX1: {
    hash: 'fdsklbvudpx',
    name: 'PX'
  },
  QAONLINE: {
    hash: 'qaodlneid',
    agency: true,
    name: 'Quantum Assurance Online',
    informationId: 'QAONLINE',
    disableChat: true
  },
  QB0001: {
    hash: 'QB0001hash',
    agency: true,
    name: 'QB Insurance LLC',
    informationId: 'QB0001',
    disableChat: true
  },
  QB0001P: {
    hash: 'QB0001Phash',
    agency: true,
    disableChat: true,
    name: 'QB Insurance LLC',
    informationId: 'QB0001P'
  },
  QA3P: {
    hash: 'QA3Phash',
    agency: true,
    disableChat: true,
    name: 'Advantage Insurance Group',
    informationId: 'QA3P'
  },
  QI1: {
    hash: 'qifdsnkl;734nd',
    agency: true,
    name: 'QuickInsured',
    informationId: 'QI1',
    disableChat: true
  },
  QI2: {
    hash: 'qifdsnkl;734nd22',
    name: 'QuickInsured',
    agency: true,
    informationId: 'QI2',
    disableChat: true
  },
  QL1: {
    hash: '6Tpye',
    name: 'Rocket Mortgage',
    shouldBeNotified: true,
    mortgageLender: true,
    featuredProvider: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 1,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },
  QLE: {
    hash: '6TpyeE',
    name: 'Rocket Mortgage',
    shouldBeNotified: true,
    mortgageLender: true,
    featuredProvider: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 1,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    }
  },
  QWZ1: {
    hash: 'f90o1ljlsxk',
    name: 'QuoteWizard'
  },
  QWZS: {
    hash: 'QWZShash',
    name: 'Quote Wizard Solutions',
    agency: true,
    informationId: 'QWZS',
    disableChat: true
  },
  QWZE: {
    hash: 'QWZEhash',
    name: 'Quote Wizard Solutions',
    agency: true,
    informationId: 'QWZE',
    disableChat: true
  },
  RA0001: {
    hash: 'RA0001hash',
    name: 'Rain Tree Group',
    agency: true,
    disableChat: true,
    informationId: 'RA0001'
  },
  RA0002: {
    hash: 'RA0002hash',
    name: 'RAJ AUTO TAGS & INSURANCE, LLC',
    agency: true,
    disableChat: true,
    informationId: 'RA0002'
  },
  RA0003: {
    hash: 'RA0003hash',
    name: 'RaisinBread LLC.',
    agency: true,
    disableChat: true,
    informationId: 'RA0003'
  },
  RC0001P: {
    hash: 'RC0001Phash',
    name: 'Rich & Cartmill',
    agency: true,
    informationId: 'RC0001P',
    disableChat: true
  },
  RC0003_1: {
    hash: 'RC0003_1hash',
    name: 'RC Fason Insurance',
    agency: true,
    informationId: 'RC0003_1',
    disableChat: true
  },
  RACE: {
    hash: 'dsfsd8ewf',
    name: 'Race Day Mortgage',
    states: {
      OH: 1
    }
  },
  RDRS1: {
    hash: 'rdrs1hash',
    name: 'Red Rose Insurance LLC',
    agency: true,
    disableChat: true
  },
  RE0001_1P: {
    hash: 'RE0001_1Phash',
    name: 'Reliable Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'RE0001_1P'
  },
  RE0001_1: {
    hash: 'RE0001_1hash',
    name: 'Reliable Insurance Group',
    agency: true,
    disableChat: true,
    informationId: 'RE0001_1'
  },
  RE0003: {
    hash: 'RE0003hash',
    name: 'Reliable Risk Management',
    agency: true,
    disableChat: true,
    informationId: 'RE0003'
  },
  RE0004_1P: {
    hash: 'RE0004_1Phash',
    name: 'Redwood Agency Group',
    agency: true,
    disableChat: true,
    informationId: 'RE0004_1P'
  },
  RE0005_1P: {
    hash: 'RE0005_1Phash',
    name: 'Redstone Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'RE0005_1P'
  },
  RGD1: {
    hash: 'rgd1hash',
    name: 'Renegade Insurance LLC',
    agency: true,
    disableChat: true
  },
  RGTLP1: {
    hash: 'rgtlp1hash',
    name: 'Reliance Group Texas, L.P.',
    agency: true,
    informationId: 'RGTLP1',
    disableChat: true
  },
  RGTLP2: {
    hash: 'rgtlp2hash',
    name: 'Reliance Group Texas, L.P.',
    agency: true,
    informationId: 'RGTLP2',
    disableChat: true
  },
  RGTLP3: {
    hash: 'rgtlp3hash',
    name: 'Reliance Group Texas, L.P.',
    agency: true,
    informationId: 'RGTLP3',
    disableChat: true
  },
  RGTLP4: {
    hash: 'rgtlp4hash',
    name: 'Reliance Group Texas, L.P.',
    agency: true,
    informationId: 'RGTLP4',
    disableChat: true
  },
  RGTLP5: {
    hash: 'rgtlp5hash',
    name: 'Reliance Group Texas, L.P.',
    agency: true,
    informationId: 'RGTLP5',
    disableChat: true
  },
  RH1: {
    name: 'Roush Honda',
    hash: 'gf5rh1d98g'
  },
  RHBS1: {
    hash: 'rhbs1hash',
    name: 'R Hobbs Insurance Agency Inc',
    agency: true,
    disableChat: true
  },
  RI1: {
    hash: 'rutldiri',
    name: 'Rutledge Insurance Group',
    agency: true,
    informationId: 'RI1',
    disableChat: true
  },
  RI0004_1P: {
    hash: 'ri0004_1phash',
    name: 'Risk Management Partners',
    agency: true,
    informationId: 'RI0004_1P',
    disableChat: true
  },
  RIC1: {
    name: 'Ricart Auto',
    informationId: 'RIC1',
    hash: 'gf5su6dd98g'
  },
  RJ0001_1P: {
    hash: 'RJ0001_1Phash',
    name: 'Risk Management Partners',
    agency: true,
    informationId: 'RJ0001_1P',
    disableChat: true
  },
  RL0001: {
    hash: 'rl00011hash',
    name: 'RLTI Family Insurance',
    agency: true,
    informationId: 'RL0001',
    disableChat: true
  },
  RO0003: {
    hash: 'RO0003hash',
    name: 'Ross Insurance Brokers, Inc.',
    agency: true,
    disableChat: true,
    informationId: 'RO0003'
  },
  ROND1P: {
    hash: 'rond1Phash',
    name: 'Rondon Insurance Service',
    agency: true,
    informationId: 'ROND1P',
    disableChat: true
  },
  RM1: {
    hash: 'iwp1y',
    name: 'Revolution Mortgage',
    mortgageLender: true,
    mortgageClause: 'RM1',
    splitLeadID: true,
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    states: {
      AL: 2,
      CO: 2,
      IA: 2,
      ID: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      NM: 2,
      OH: 2,
      OK: 2,
      PA: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2
    }
  },
  RM2: {
    hash: 'iwp2y',
    name: 'Revolution Mortgage',
    mortgageLender: true,
    mortgageClause: 'RM1',
    splitLeadID: true,
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    states: {
      AL: 2,
      CO: 2,
      ID: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      NM: 2,
      OH: 2,
      OK: 2,
      PA: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2
    }
  },
  RME: {
    hash: 'iwp1yE',
    name: 'Revolution Mortgage',
    mortgageLender: true,
    mortgageClause: 'RM1',
    splitLeadID: true,
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    states: {
      AL: 2,
      CO: 2,
      ID: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      NM: 2,
      OH: 2,
      OK: 2,
      PA: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      WI: 2,
      WV: 2
    }
  },
  ROGRSK1: {
    hash: 'rogrsk1hash',
    name: 'Rogue Risk LLC',
    agency: true,
    disableChat: true
  },
  RO0005: {
    hash: 'RO0005hash',
    name: 'Rod E. Cottle Insurance Agency Inc.',
    agency: true,
    informationId: 'RO0005',
    disableChat: true
  },
  RO0006: {
    hash: 'RO0006hash',
    name: 'Ron Meredith Agency',
    agency: true,
    informationId: 'RO0006',
    disableChat: true
  },
  RO0007_1: {
    hash: 'RO0007_1hash',
    name: 'Beaver State Insurance Agency, LLC.',
    agency: true,
    informationId: 'RO0007_1',
    disableChat: true
  },
  ROND1_1: {
    hash: 'rond1_1hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROND1_2: {
    hash: 'rond1_2hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROND1_3: {
    hash: 'rond1_3hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROND1_4: {
    hash: 'rond1_4hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROND1_5: {
    hash: 'rond1_5hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROND1_6: {
    hash: 'rond1_6hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROND1: {
    hash: 'rond1hash',
    name: 'Rondon Insurance Services',
    agency: true,
    disableChat: true
  },
  ROYLT1: {
    hash: 'roylt1hash',
    name: 'Royalty Insurance Agency',
    agency: true,
    disableChat: true
  },
  ROYLT1D: {
    hash: 'roylt1dhash',
    name: 'Royalty Insurance Agency',
    disableChat: true
  },
  RN0001: {
    hash: 'RN0001hash',
    name: 'RNI Financial, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'RN0001'
  },
  RP0001_1P: {
    hash: 'RP0001_1Phash',
    name: 'RPM Agency',
    agency: true,
    informationId: 'RP0001_1P',
    disableChat: true
  },
  RS1: {
    hash: 'sd9nver89',
    name: "Ritchie's Security"
  },
  RSWL1: {
    hash: 'rswl1hash',
    name: 'RiskWell',
    agency: true
  },
  RVE: {
    hash: 'klfdsuiwef8',
    name: 'RevelIT',
    states: {
      AZ: 2,
      IL: 2,
      IN: 2,
      MD: 2,
      MO: 2,
      OH: 2,
      OK: 2,
      PA: 2,
      TX: 2
    }
  },
  SA1: {
    name: 'Superior Auto',
    hash: '17sdad97gh7e'
  },
  SALTY: {
    hash: 'saltY4d',
    name: 'Salty',
    agency: true,
    skipScheduledCall: true,
    disableChat: true
  },
  SALTYP: {
    hash: 'saltYP4d',
    name: 'Salty',
    agency: true,
    skipScheduledCall: true,
    informationId: 'SALTYP',
    disableChat: true
  },

  SAS1: {
    hash: 'dfkjvfusad87sas',
    name: 'Shook Auto Sales'
  },
  SC0001: {
    hash: 'SC0001hash',
    name: 'Scott Richards Agency dba Save On Insurance',
    agency: true,
    informationId: 'SC0001',
    disableChat: true
  },
  SC0001P: {
    hash: 'SC0001Phash',
    name: 'Scott Richards Agency dba Save On Insurance',
    agency: true,
    informationId: 'SC0001P',
    disableChat: true
  },
  SA0001_1: {
    hash: 'SA0001_1hash',
    name: 'Safeguard Legacy Group',
    agency: true,
    informationId: 'SA0001_1',
    disableChat: true
  },
  SC0002_1: {
    hash: 'SC0002_1hash',
    name: 'Schwartz Tax and Insurance Services',
    agency: true,
    informationId: 'SC0002_1',
    disableChat: true
  },
  SC0002_2: {
    hash: 'SC0002_2hash',
    name: 'Schwartz Tax and Insurance Services',
    agency: true,
    informationId: 'SC0002_2',
    disableChat: true
  },
  SE0002P: {
    hash: 'SE0002Phash',
    name: 'Sessions Insurance',
    agency: true,
    informationId: 'SE0002P',
    disableChat: true
  },
  SE0002: {
    hash: 'SE0002hash',
    name: 'Sessions Insurance',
    agency: true,
    informationId: 'SE0002',
    disableChat: true
  },
  SE0003: {
    hash: 'SE0003hash',
    name: 'Sea to Sky Insurance',
    agency: true,
    informationId: 'SE0003',
    disableChat: true
  },
  SE0004: {
    hash: 'SE0004hash',
    name: 'Sentinel Insurance Group, LLC.',
    agency: true,
    informationId: 'SE0004',
    disableChat: true
  },
  SE0005: {
    hash: 'SE0005hash',
    name: 'Sebans Insurance Solutions',
    agency: true,
    informationId: 'SE0005',
    disableChat: true
  },
  SE0006_1P: {
    hash: 'SE0006_1Phash',
    name: 'Sebans Insurance Solutions',
    agency: true,
    informationId: 'SE0006_1P',
    disableChat: true
  },
  SGIA1: {
    hash: 'SGIA1hash',
    agency: true,
    name: 'Southern Group Insurance Agency, Inc.',
    disableChat: true
  },
  SHP1: {
    hash: 'shp3789jv7d',
    name: 'Shepherd Insurance',
    agency: true,
    disableChat: true
  },
  SH0001_1: {
    hash: 'SH0001_1hash',
    name: 'Shield Insurance Service, Inc.',
    agency: true,
    informationId: 'SH0001_1',
    disableChat: true
  },
  SH0002: {
    hash: 'SH0002hash',
    name: 'Shah Insurance Agency',
    agency: true,
    informationId: 'SH0002',
    disableChat: true
  },
  SH0003_1P: {
    hash: 'SH0003_1Phash',
    name: 'Shannon Jennings Insurance, LLC',
    agency: true,
    informationId: 'SH0003_1P',
    disableChat: true
  },
  SI1: {
    hash: 'dugrndk5',
    agency: true,
    name: 'Obie',
    informationId: 'SI1',
    disableChat: true
  },
  SI2: {
    hash: 'dugrndk52',
    name: 'Obie',
    agency: true,
    informationId: 'SI1',
    disableChat: true
  },
  SI3: {
    hash: 'dugrndk534',
    name: 'Obie',
    informationId: 'SI1',
    disableChat: true
  },
  SI4: {
    hash: 'dugrndk541',
    name: 'Obie',
    informationId: 'SI1',
    disableChat: true
  },
  SI0001: {
    hash: 'SI0001hash',
    name: 'Strong Insurance Inc.',
    agency: true,
    informationId: 'SI0001',
    disableChat: true
  },
  SI0002: {
    hash: 'SI0002hash',
    name: 'Siska Insurance',
    agency: true,
    informationId: 'SI0002',
    disableChat: true
  },
  SI0002_2: {
    hash: 'SI0002_2hash',
    name: 'Siska Insurance',
    agency: true,
    informationId: 'SI0002_2',
    disableChat: true
  },
  SI0002_3: {
    hash: 'SI0002_3hash',
    name: 'Siska Insurance',
    agency: true,
    informationId: 'SI0002_3',
    disableChat: true
  },
  SI0003P: {
    hash: 'SI0003Phash',
    name: 'Siskiyou Insurance',
    agency: true,
    informationId: 'SI0003P',
    disableChat: true
  },
  SI0004_1: {
    hash: 'SI0004_1hash',
    name: 'Siskiyou Insurance Marketplace',
    agency: true,
    informationId: 'SI0004_1',
    disableChat: true
  },
  SI0005_1: {
    hash: 'SI0005_1hash',
    name: 'Siskiyou Insurance Marketplace',
    agency: true,
    informationId: 'SI0005_1',
    disableChat: true
  },

  SIG1: {
    hash: 'ddfdsiwesdmd',
    name: 'Steven Insurance Group'
  },
  SIGM1: {
    hash: 'dfkl44sdfisg',
    name: 'Signature Mortgage',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    states: {
      CO: 2,
      IN: 2,
      OH: 2,
      TX: 2
    }
  },
  SIM1: {
    hash: 'sim1hash',
    name: 'Simply Insured, LLC',
    agency: true,
    disableChat: true
  },
  SIMIOA1: {
    hash: 'ckeviuenvujyhibvy',
    name: 'Simply IOA'
  },
  SIMPLY1: {
    hash: 'sbnid134',
    name: 'SimpliSafe',
    informationId: 'SIMPLY1',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    },
    homeSecurity: true,
    signUpThroughBranch: true
  },
  SK0003_1P: {
    hash: 'SK0003_1Phash',
    name: 'Skogman Insurance',
    agency: true,
    informationId: 'SK0003_1P',
    disableChat: true
  },
  SKYLGHT1: {
    hash: 'dkfdhksd',
    agency: true,
    name: 'Skylight Insurance',
    informationId: 'SKYLGHT1',
    disableChat: true
  },
  SM0001: {
    hash: 'SM0001hash',
    name: 'Smart Insured Corp',
    agency: true,
    informationId: 'SM0001',
    disableChat: true
  },
  SM0002_1P: {
    hash: 'SM0002_1Phash',
    name: 'Smith & Company Insurance, Inc.',
    agency: true,
    informationId: 'SM0002_1P',
    disableChat: true
  },
  SMAFIN1: {
    name: 'SmartFinancial',
    hash: 'cjliej1290clkj'
  },
  SMAR7P: {
    hash: 'SMAR7Phash',
    name: 'Tucker Campbell',
    agency: true,
    disableChat: true,
    informationId: 'SMAR7P'
  },
  SMAR14P: {
    hash: 'SMAR14Phash',
    name: 'Ragsdale Insurance INc',
    agency: true,
    disableChat: true,
    informationId: 'SMAR14P'
  },
  SMAR15P: {
    hash: 'SMAR15Phash',
    name: 'Bill Bledsoe Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'SMAR15P'
  },
  SMAR16P: {
    hash: 'SMAR16Phash',
    name: 'John Hall',
    agency: true,
    disableChat: true,
    informationId: 'SMAR16P'
  },
  SMAR17P: {
    hash: 'SMAR17Phash',
    name: 'R McCallister LLC dba Heartland Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'SMAR17P'
  },
  SMAR18P: {
    hash: 'SMAR18Phash',
    name: 'Insurance Pros of Central Arkansas',
    agency: true,
    disableChat: true,
    informationId: 'SMAR18P'
  },
  SMAR19P: {
    hash: 'SMAR19Phash',
    name: 'Brad Brooks DBA The Brooks Agency',
    agency: true,
    disableChat: true,
    informationId: 'SMAR19P'
  },
  SMAR20P: {
    hash: 'SMAR20Phash',
    name: 'Rawls-Campbell Agency Inc',
    agency: true,
    disableChat: true,
    informationId: 'SMAR20P'
  },
  SMAZ: {
    hash: 'dfskazxusscs',
    name: 'SmartChoice AZ',
    agency: true,
    informationId: 'SMAZ',
    disableChat: true
  },
  SMAZ02: {
    hash: 'smaz021hash',
    name: 'Safe Harbor Insurance',
    agency: true,
    disableChat: true
  },
  SMAZ8P: {
    hash: 'SMAZ8Phash',
    name: 'Wishwell Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'SMAZ8P'
  },
  SMAZ8PA: {
    hash: 'SMAZ8PAhash',
    name: 'Wishwell Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'SMAZ8PA'
  },
  SMAZ8PH: {
    hash: 'SMAZ8PHhash',
    name: 'Wishwell Insurance Brokers',
    agency: true,
    disableChat: true,
    informationId: 'SMAZ8PH'
  },
  SMIN: {
    hash: 'dfskincxusscs',
    name: 'SmartChoice IN',
    agency: true,
    informationId: 'SMIN',
    disableChat: true
  },
  SMIN19P: {
    hash: 'SMIN19Phash',
    name: 'Bilingual Consulting',
    agency: true,
    informationId: 'SMIN19P',
    disableChat: true
  },
  SMIN2P: {
    hash: 'SMIN2Phash',
    name: 'Gehlhausen Insurance Agency LLC',
    agency: true,
    informationId: 'SMIN2P',
    disableChat: true
  },
  SMIN22P: {
    hash: 'SMIN22Phash',
    name: 'George C Rogge Agency',
    agency: true,
    informationId: 'SMIN22P',
    disableChat: true
  },
  SMIN53P: {
    hash: 'SMIN53Phash',
    name: 'Alford Insurance Agency',
    agency: true,
    informationId: 'SMIN53P',
    disableChat: true
  },
  SMIN56P: {
    hash: 'SMIN56Phash',
    name: 'Chilcote Family Insurance Agency, LLC',
    agency: true,
    informationId: 'SMIN56P',
    disableChat: true
  },
  SMKY39P: {
    hash: 'SMKY39Phash',
    name: 'Bluegrass Insurance Group, Inc',
    agency: true,
    informationId: 'SMKY39P',
    disableChat: true
  },
  SMMI1: {
    hash: 'smmi1hash',
    name: 'Clement Insurance, LLC',
    agency: true,
    disableChat: true
  },
  SMMD38P: {
    hash: 'SMMD38Phash',
    name: 'The Hutcherson Agency LLC',
    agency: true,
    informationId: 'SMMD38P',
    disableChat: true
  },
  SMMD39P: {
    hash: 'SMMD39Phash',
    name: 'Superior Insurance',
    agency: true,
    informationId: 'SMMD39P',
    disableChat: true
  },
  SMMO8P: {
    hash: 'SMMO8Phash',
    name: 'Moyer & Moyer Insurance Agency, Inc. DBA Moyer & DesCombes Insurance Agency',
    agency: true,
    informationId: 'SMMO8P',
    disableChat: true
  },
  SMOH: {
    hash: 'dfskj;vcxusscs',
    name: 'SmartChoice',
    agency: true,
    disableChat: true
  },
  SMOH4P: {
    hash: 'smoh4Phash',
    name: 'Taylor & Associates Insurance Agency',
    agency: true,
    informationId: 'SMOH4P',
    disableChat: true
  },
  SMOH68P: {
    hash: 'smoh68Phash',
    name: 'Remedy Insurance Agency, LLC.',
    agency: true,
    informationId: 'SMOH68P',
    disableChat: true
  },
  SMOH88P: {
    hash: 'smoh88Phash',
    name: 'Securus Insurance Agency, LLC.',
    agency: true,
    informationId: 'SMOH88P',
    disableChat: true
  },
  SMOH90P: {
    hash: 'smoh90Phash',
    name: 'Blake Insurance Group, LLC.',
    agency: true,
    informationId: 'SMOH90P',
    disableChat: true
  },
  SMOH103P: {
    hash: 'smoh103Phash',
    name: 'Finch Insurance Agency',
    agency: true,
    informationId: 'SMOH103P',
    disableChat: true
  },
  SMOH104P: {
    hash: 'smoh104Phash',
    name: 'Nancy Martin',
    agency: true,
    informationId: 'SMOH104P',
    disableChat: true
  },
  SMOH105P: {
    hash: 'smoh104Phash',
    name: 'Closing Time Services, LLC ',
    agency: true,
    informationId: 'SMOH104P',
    disableChat: true
  },
  SMOK: {
    hash: 'dfskiokusscs',
    name: 'SmartChoice OK',
    agency: true,
    informationId: 'SMOK',
    disableChat: true
  },
  SMOK3P: {
    hash: 'SMOK3Phash',
    name: 'Gerding Insurance Agency LLC',
    agency: true,
    informationId: 'SMOK3P',
    disableChat: true
  },
  SMOR1P: {
    hash: 'SMOR1Phash',
    name: 'SHOPMYINSURANCENW.COM LLC',
    agency: true,
    informationId: 'SMOR1P',
    disableChat: true
  },
  SMOR10P: {
    hash: 'SMOR10Phash',
    name: 'Lily B Holdings, LLC DBA Lily B Consultants',
    agency: true,
    informationId: 'SMOR10P',
    disableChat: true
  },
  SMOR16P: {
    hash: 'SMOR16Phash',
    name: 'Molina Insurance Group Inc.',
    agency: true,
    informationId: 'SMOR16P',
    disableChat: true
  },
  SMOR17P: {
    hash: 'SMOR17Phash',
    name: 'Daryl Boliba dba Boliba Insurance',
    agency: true,
    informationId: 'SMOR17P',
    disableChat: true
  },
  SMNM6P: {
    hash: 'SMNM6Phash',
    name: 'Orion Insurance & Financial Services',
    agency: true,
    informationId: 'SMNM6P',
    disableChat: true
  },
  SMPA52P: {
    hash: 'SMPA52Phash',
    name: 'The John Carr Insurance Agency',
    agency: true,
    informationId: 'SMPA52P',
    disableChat: true
  },
  SMPA53P: {
    hash: 'SMPA53Phash',
    name: 'Hello Insurance',
    agency: true,
    informationId: 'SMPA53P',
    disableChat: true
  },
  SMRT1: {
    hash: 'smrt1hash',
    name: 'Smart Insured Solutions, LLC',
    agency: true,
    disableChat: true
  },
  SMTN5P: {
    hash: 'SMTN5Phash',
    name: 'Dekalb County Insurance',
    agency: true,
    disableChat: true,
    informationId: 'SMTN5P'
  },
  SMTN9P: {
    hash: 'SMTN9Phash',
    name: 'Middle TN Insurance Group',
    agency: true,
    informationId: 'SMTN9P',
    disableChat: true
  },
  SMTN22P: {
    hash: 'SMTN22Phash',
    name: 'ReduceMyInsurance.net',
    agency: true,
    informationId: 'SMTN22P',
    disableChat: true
  },
  SMTX: {
    hash: 'sctx1234',
    agency: true,
    name: 'SmartChoice Texas',
    disableChat: true
  },
  SMVA3P: {
    hash: 'SMVA3Phash',
    name: 'ROC Insurance Group, LLC',
    agency: true,
    informationId: 'SMVA3P',
    disableChat: true
  },
  SMVA4P: {
    hash: 'SMVA4Phash',
    name: 'Debra Ianucci Insurance Agency dba Distinctive Financial Group',
    agency: true,
    informationId: 'SMVA4P',
    disableChat: true
  },
  SMVA5P: {
    hash: 'SMVA5Phash',
    name: 'Ballard Insurance Agency, INC',
    agency: true,
    informationId: 'SMVA5P',
    disableChat: true
  },
  SMWI1P: {
    hash: 'SMWI1Phash',
    name: 'Bronson Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'SMWI1P'
  },
  SMWI3P: {
    hash: 'SMWI3Phash',
    name: 'Harper Tax & Financial Literacy',
    agency: true,
    informationId: 'SMWI3P',
    disableChat: true
  },
  SMWI11P: {
    hash: 'SMWI11Phash',
    name: 'Zip Insurance Agency, LLC.',
    agency: true,
    informationId: 'SMWI11P',
    disableChat: true
  },
  SMUN1: {
    hash: 'smun1hash',
    name: 'Smart Universal Insurance',
    agency: true,
    disableChat: true
  },
  SO0001: {
    hash: 'SO0001hash',
    name: 'Somers Insurance Agency, Inc.',
    agency: true,
    informationId: 'SO0001',
    disableChat: true
  },
  SO0002_1P: {
    hash: 'SO0002_1Phash',
    name: 'Southwest Insurance Center',
    agency: true,
    informationId: 'SO0002_1P',
    disableChat: true
  },
  SO0003_1P: {
    hash: 'SO0003_1Phash',
    name: 'Sound Ventures Inc',
    agency: true,
    informationId: 'SO0003_1P',
    disableChat: true
  },
  SO0004_1P: {
    hash: 'SO0004_1Phash',
    name: 'SORGIUS INSURANCE AGENCY',
    agency: true,
    informationId: 'SO0004_1P',
    disableChat: true
  },
  SO0005_1P: {
    hash: 'SO0005_1Phash',
    name: 'SouthWest Professional Insurance Consultants, inc.',
    agency: true,
    informationId: 'SO0005_1P',
    disableChat: true
  },
  SO0006_1P: {
    hash: 'SO0006_1Phash',
    name: 'SOUTHERN OAKS INSURANCE GROUP',
    agency: true,
    informationId: 'SO0006_1P',
    disableChat: true
  },
  SP0001: {
    hash: 'SP0001hash',
    name: 'Springs Insure, LLC.',
    agency: true,
    informationId: 'SP0001',
    disableChat: true
  },
  SP0001P: {
    hash: 'SP0001Phash',
    name: 'Springs Insure, LLC.',
    agency: true,
    informationId: 'SP0001P',
    disableChat: true
  },
  SP0002: {
    hash: 'SP0002hash',
    name: 'Sparks Insurance Agency',
    agency: true,
    informationId: 'SP0002',
    disableChat: true
  },
  SP0003_1P: {
    hash: 'SP0003_1Phash',
    name: 'SPRING GROVE INSURANCE',
    agency: true,
    informationId: 'SP0003_1P',
    disableChat: true
  },
  SS0002_2: {
    hash: 'SS0002_2hash',
    name: 'S&S Insurance Services, Inc.',
    agency: true,
    informationId: 'SS0002_2',
    disableChat: true
  },
  SS0002_1: {
    hash: 'SS0002_1hash',
    name: 'S&S Insurance Services, Inc.',
    agency: true,
    informationId: 'SS0002_1',
    disableChat: true
  },
  ST0002_1P: {
    hash: 'ST0002_1Phash',
    name: 'Stanley Insurance Group',
    agency: true,
    informationId: 'ST0002_1P',
    disableChat: true
  },
  ST0003: {
    hash: 'ST0003hash',
    name: 'Stavely Insurance Group',
    agency: true,
    informationId: 'ST0003',
    disableChat: true
  },
  ST0003P: {
    hash: 'ST0003Phash',
    name: 'Stavely Insurance Group',
    agency: true,
    informationId: 'ST0003P',
    disableChat: true
  },
  ST0004_1P: {
    hash: 'ST0004_1Phash',
    name: 'Sterling Group Insurance, LLC',
    agency: true,
    informationId: 'ST0004_1P',
    disableChat: true
  },
  SU0002: {
    hash: 'SU0002hash',
    name: 'Sunset Valley Insurance Agency, LLC',
    agency: true,
    disableChat: true,
    informationId: 'SU0002'
  },
  SU0002P: {
    hash: 'SU0002Phash',
    name: 'Sunset Valley Insurance Agency, LLC',
    agency: true,
    disableChat: true,
    informationId: 'SU0002P'
  },
  SU0003: {
    hash: 'SU0003hash',
    name: 'Summit Risk Advisors LLC dba Mappus Insurance Agency',
    agency: true,
    informationId: 'SU0003',
    disableChat: true
  },
  SU0004_1P: {
    hash: 'SU0004_1Phash',
    name: 'Susan Shollenberger Insurance Agency LLC',
    agency: true,
    informationId: 'SU0004_1P',
    disableChat: true
  },
  SUN0001: {
    hash: 'SUN0001hash',
    name: 'Sunnyside Insurance Agency LLC',
    agency: true,
    disableChat: true,
    informationId: 'SUN0001'
  },
  SYNC: {
    hash: 'syncash',
    name: 'SYNC'
  },
  TA0001_1P: {
    hash: 'TA0001_1Phash',
    name: 'Taylor & Assoc Ins, LLC dba King & Associates Insurance',
    agency: true,
    informationId: 'TA0001_1P',
    disableChat: true
  },
  TAC0008_1P: {
    hash: 'TAC0008_1Phash',
    name: 'Sylvia A. Garrett & Associates Agency, LLC.',
    agency: true,
    informationId: 'TAC0008_1P',
    disableChat: true
  },
  TAC0009_1: {
    hash: 'TAC0009_1hash',
    name: 'Found It',
    agency: true,
    informationId: 'TAC0009_1',
    disableChat: true
  },
  TAC0010: {
    hash: 'TAC0010hash',
    name: 'Summit West Insurance Group, Inc.',
    agency: true,
    informationId: 'TAC0010',
    disableChat: true
  },
  TAC0012: {
    hash: 'TAC0012hash',
    name: 'Hometown Insurance Agency',
    agency: true,
    informationId: 'TAC0012',
    disableChat: true
  },
  TAC0013_1: {
    hash: 'TAC0013_1hash',
    name: 'Stanley Insurance Group',
    agency: true,
    informationId: 'TAC0013_1',
    disableChat: true
  },
  TAC0014_1: {
    hash: 'TAC0014_1hash',
    name: 'Holly McMillin Insurance Group',
    agency: true,
    informationId: 'TAC0014_1',
    disableChat: true
  },
  TAC0014_1P: {
    hash: 'TAC0014_1Phash',
    name: 'Holly McMillin Insurance Group',
    agency: true,
    informationId: 'TAC0014_1P',
    disableChat: true
  },
  TAC0014_2: {
    hash: 'TAC0014_2hash',
    name: 'Holly McMillin Insurance Group',
    agency: true,
    informationId: 'TAC0014_2',
    disableChat: true
  },
  TAC001_15P: {
    hash: 'TAC0001_15Phash1',
    name: 'Guardian Insurance Partners, LLC',
    agency: true,
    informationId: 'TAC0001_15P',
    disableChat: true
  },
  TAC0016: {
    hash: 'TAC0016hash',
    name: 'Mid-State Farm Insurance, LLC',
    agency: true,
    informationId: 'TAC0016',
    disableChat: true
  },
  TAC0016P: {
    hash: 'TAC0016Phash',
    name: 'Mid-State Farm Insurance, LLC',
    agency: true,
    informationId: 'TAC00P',
    disableChat: true
  },
  TAC0001_20P: {
    hash: 'TAC0001_20Phash',
    name: 'The Churchill Agency',
    agency: true,
    informationId: 'TAC0001_20P',
    disableChat: true
  },
  TAC0001_26P: {
    hash: 'TAC0001_26Phash',
    name: 'Waterton Insurance Agency',
    agency: true,
    informationId: 'TAC0001_26P',
    disableChat: true
  },
  TAC0001_35P: {
    hash: 'TAC0001_35Phash',
    name: 'JB Victor Insurance LLC',
    agency: true,
    informationId: 'TAC0001_35P',
    disableChat: true
  },
  TAC0003_1: {
    hash: 'TAC0003_1hash',
    name: 'John Russo Insurance Agency',
    agency: true,
    informationId: 'TAC0003_1',
    disableChat: true
  },
  TAC0019_1: {
    hash: 'TAC0019_1hash',
    name: 'Blue Insurance Group LLC.',
    agency: true,
    informationId: 'TAC0019_1',
    disableChat: true
  },
  TAC0020_1P: {
    hash: 'TAC0020_1Phash',
    name: 'John Russo Insurance Agency',
    agency: true,
    informationId: 'TAC0020_1P',
    disableChat: true
  },
  TAC0001_30P: {
    hash: 'TAC0001_30Phash',
    name: 'Outdoor AdvINSURE Agency LLC',
    agency: true,
    informationId: 'TAC0001_30P',
    disableChat: true
  },
  TAC0032_1P: {
    hash: 'TAC0032_1Phash',
    name: 'Ace Insurance LLC.',
    agency: true,
    informationId: 'TAC0032_1P',
    disableChat: true
  },
  TAC0032_1: {
    hash: 'TAC0032_1hash',
    name: 'Ace Insurance LLC.',
    agency: true,
    informationId: 'TAC0032_1',
    disableChat: true
  },
  TAC0032_2: {
    hash: 'TAC0032_2hash',
    name: 'Ace Insurance LLC.',
    agency: true,
    informationId: 'TAC0032_2',
    disableChat: true
  },
  TAC0035_1: {
    hash: 'TAC0035_1hash',
    name: 'Old National Insurance Inc.',
    agency: true,
    informationId: 'TAC0035_1',
    disableChat: true
  },
  TAC0037_1P: {
    hash: 'TAC0037_1Phash',
    name: 'Patrick Daugherty Agency Inc. dba Appletree Insurance ',
    agency: true,
    informationId: 'TAC0037_1P',
    disableChat: true
  },
  TAC0038_1P: {
    hash: 'TAC0038_1Phash',
    name: 'The Golden Rooster LLC',
    agency: true,
    informationId: 'TAC0038_1P',
    disableChat: true
  },
  TAC0039_1: {
    hash: 'TAC0039_1hash',
    name: 'Green Country Insurance of Oklahoma',
    agency: true,
    informationId: 'TAC0039_1',
    disableChat: true
  },
  TAC0039_1P: {
    hash: 'TAC0039_1Phash',
    name: 'Green Country Insurance of Oklahoma',
    agency: true,
    informationId: 'TAC0039_1P',
    disableChat: true
  },
  TAC0040_1: {
    hash: 'TAC0040_1hash',
    name: 'AJ Sinha DBA Sinha Financial Services',
    agency: true,
    informationId: 'TAC0040_1',
    disableChat: true
  },
  TAC0044_1P: {
    hash: 'TAC0044_1Phash',
    name: 'Thai Chang Agency Inc',
    agency: true,
    informationId: 'TAC0044_1P',
    disableChat: true
  },
  TAC0045_1P: {
    hash: 'TAC0045_1Phash',
    name: 'BARRY WINSTEAD',
    agency: true,
    informationId: 'TAC0045_1P',
    disableChat: true
  },
  TAC0047_1P: {
    hash: 'TAC0047_1Phash',
    name: 'Your Choice Insurance',
    agency: true,
    informationId: 'TAC0047_1P',
    disableChat: true
  },
  TAC0048_1P: {
    hash: 'TAC0048_1Phash',
    name: 'Tipp Insurance Agency, LLC',
    agency: true,
    informationId: 'TAC0048_1P',
    disableChat: true
  },
  TAC0049_1P: {
    hash: 'TAC0049_1Phash',
    name: 'Scott Scales Insurance Agency INC DBA S SQAURED INSURANCE AGENCY',
    agency: true,
    informationId: 'TAC0049_1P',
    disableChat: true
  },
  TAC0051_1P: {
    hash: 'TAC0051_1Phash',
    name: 'The Loeber Agency',
    agency: true,
    informationId: 'TAC0051_1P',
    disableChat: true
  },
  TAC0053_1P: {
    hash: 'TAC0053_1Phash',
    name: 'SOUTHERN OAKS INSURANCE GROUP',
    agency: true,
    informationId: 'TAC0053_1P',
    disableChat: true
  },
  TAC0054_1P: {
    hash: 'TAC0054_1Phash',
    name: 'Frank Insurance Management',
    agency: true,
    informationId: 'TAC0054_1P',
    disableChat: true
  },
  TAC0055_1P: {
    hash: 'TAC0055_1Phash',
    name: 'Arc Insurance',
    agency: true,
    informationId: 'TAC0055_1P',
    disableChat: true
  },
  TAC0056_1P: {
    hash: 'TAC0056_1Phash',
    name: 'Advantage Insurance Agency, Inc.',
    agency: true,
    informationId: 'TAC0056_1P',
    disableChat: true
  },
  TAP1: {
    name: 'Hometap',
    hash: 'htpdfds5',
    states: {
      AR: 1,
      CO: 1,
      AZ: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  TAP2: {
    name: 'Hometap',
    hash: 'htpdfds52',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      UT: 1,
      VA: 1,
      VT: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  TAP3: {
    name: 'Hometap',
    hash: 'htpdfds53',
    states: {
      AR: 1,
      AZ: 1,
      CO: 1,
      IA: 1,
      ID: 1,
      IN: 1,
      KS: 1,
      KY: 1,
      MD: 1,
      ME: 1,
      MI: 1,
      MO: 1,
      MT: 1,
      ND: 1,
      NE: 1,
      NH: 1,
      NM: 1,
      OH: 1,
      OK: 1,
      OR: 1,
      PA: 1,
      SD: 1,
      TX: 1,
      VT: 1,
      UT: 1,
      VA: 1,
      WI: 1,
      WV: 1,
      WY: 1
    }
  },
  TAS: {
    hash: 'idnfusitas',
    name: 'Insuritas',
    agency: true,
    informationId: 'TAS',
    disableChat: true
  },
  TAS2: {
    hash: 'idnfusitas2',
    name: 'Insuritas',
    agency: true,
    informationId: 'TAS',
    disableChat: true
  },
  TATUM1: {
    hash: '3kjs9tatum1',
    agency: true,
    informationId: 'TATUM1',
    disableChat: true
  },
  TATUM2: {
    hash: '3kjs9tatum2',
    agency: true,
    informationId: 'TATUM2',
    disableChat: true
  },
  TATUM3: {
    hash: '3kjs9tatum3',
    agency: true,
    informationId: 'TATUM3',
    disableChat: true
  },
  TBDS1: {
    hash: 'tbds1hash',
    name: 'Tisha Brandes Insurance Services LLC',
    agency: true,
    disableChat: true
  },
  TBG: {
    name: 'Twins Buick GMC',
    hash: '8eqwi9sd4fg5'
  },
  TC0001: {
    hash: 'TC0001hash',
    name: 'TCDS Insurance Agency',
    agency: true,
    informationId: 'TC0001',
    disableChat: true
  },
  TC1: {
    hash: 'Riumk',
    name: 'Total Choice',
    mortgageLender: true,
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  TCP: {
    name: 'Toyota of Cedar Park',
    hash: 'gf5stcptg'
  },
  TCP1: {
    hash: 'tcp1hash',
    name: 'The Coverage Pro',
    agency: true,
    disableChat: true,
    informationId: 'TCP1'
  },
  TD0001_1: {
    hash: 'TD0001_1hash',
    name: 'TD3 Insurance Agency & Associates',
    agency: true,
    disableChat: true,
    informationId: 'TD0001_1'
  },
  TD0001_2: {
    hash: 'TD0001_2hash',
    name: 'TD3 Insurance Agency & Associates',
    agency: true,
    disableChat: true,
    informationId: 'TD0001_2'
  },
  TEST: {
    hash: 'test',
    name: 'Test Agency',
    shouldBeNotified: true,
    agency: true,
    informationId: 'TEST',
    disableChat: true
  },
  TEST1: {
    hash: 'test1',
    name: 'Test Agency 1',
    shouldBeNotified: true,
    agency: true,
    informationId: 'TEST1',
    disableChat: true
  },
  TEST2: {
    hash: 'test2',
    name: 'Test Agency 2',
    shouldBeNotified: true,
    agency: true,
    informationId: 'TEST2',
    disableChat: true
  },
  TEST3: {
    hash: 'test3',
    name: 'Test Agency 3',
    shouldBeNotified: true,
    agency: true,
    informationId: 'TEST3',
    disableChat: true
  },
  TEST4: {
    hash: 'test4',
    name: 'Test Agency 4',
    shouldBeNotified: true,
    agency: true,
    informationId: 'TEST4',
    disableChat: true
  },
  TEST5: {
    hash: 'test5',
    name: 'Test Agency 5',
    shouldBeNotified: true,
    agency: true,
    informationId: 'TEST5',
    disableChat: true
  },
  TC0001P: {
    hash: 'TC0001Phash',
    name: 'TCDS Insurance Agency',
    agency: true,
    informationId: 'TC0001P',
    disableChat: true
  },
  TCE: {
    hash: 'RiumkE',
    name: 'Total Choice',
    mortgageLender: true,
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  TGS1: {
    hash: 'tgs1hash',
    name: 'TGS Insurance Agency',
    agency: true,
    disableChat: true
  },
  TH0001: {
    hash: 'th00011hash',
    name: 'Thomas J Robinson Insurance',
    agency: true,
    informationId: 'TH0001',
    disableChat: true
  },
  TH0005: {
    hash: 'th0005hash',
    name: 'The Insurance Market Place US, LLC',
    agency: true,
    informationId: 'TH0005',
    disableChat: true
  },
  TH0007: {
    hash: 'th0007hash',
    name: 'The Walke Agency',
    agency: true,
    informationId: 'TH0007',
    disableChat: true
  },
  TH0009_1: {
    hash: 'TH0009_1hash',
    name: 'The Major Agency, LLC',
    agency: true,
    informationId: 'TH0009_1',
    disableChat: true
  },
  TH0010_1P: {
    hash: 'TH0010_1Phash',
    name: 'The Shell Agency, LLC',
    agency: true,
    informationId: 'TH0010_1P',
    disableChat: true
  },
  TH0011_1P: {
    hash: 'TH0011_1Phash',
    name: 'The Leavitt Agency Inc',
    agency: true,
    informationId: 'TH0011_1P',
    disableChat: true
  },
  TH0012_1P: {
    hash: 'TH0012_1Phash',
    name: 'The Loeber Agency',
    agency: true,
    informationId: 'TH0012_1P',
    disableChat: true
  },
  TH0015_1P: {
    hash: 'TH0015_1Phash',
    name: 'The Peak Agency',
    agency: true,
    informationId: 'TH0015_1P',
    disableChat: true
  },
  THRV1: {
    hash: 'thrv1hash',
    name: 'Thrive Insurance',
    agency: true,
    disableChat: true
  },
  TI0001_1P: {
    hash: 'TI0001_1Phash',
    name: 'The Insurance Alliance',
    agency: true,
    informationId: 'TI0002',
    disableChat: true
  },
  TI0002: {
    hash: 'TI0002hash',
    name: 'Turbo Insurance Group LLC',
    agency: true,
    informationId: 'TI0002',
    disableChat: true
  },
  TI0002P: {
    hash: 'TI0002Phash',
    name: 'Turbo Insurance Group LLC',
    agency: true,
    informationId: 'TI0002P',
    disableChat: true
  },
  TK0001_1P: {
    hash: 'TTK0001_1Phash',
    name: '2K Financial Services LLC DBA The Kennedy Agency',
    agency: true,
    informationId: 'TK0001_1P',
    disableChat: true
  },
  TLY1: {
    hash: 'zAn63Z8emHffr6Wv',
    name: 'Transparent.ly'
  },
  TNUNN: {
    hash: 'TNUNN1hash',
    name: 'Tabatha Nunn Test Agency',
    agency: true,
    disableChat: true
  },
  TO0001: {
    hash: 'TO0001hash',
    name: 'Toro Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'TO0001'
  },
  TO0003P: {
    hash: 'TO0003Phash',
    name: 'Town and Country Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'TO0003P'
  },
  TP0001: {
    hash: 'TP0001hash',
    name: 'The Portner Agency',
    agency: true,
    disableChat: true,
    informationId: 'TP0001'
  },
  TRELC: {
    hash: 'trelchash',
    name: 'Trellis Connect',
    agency: true,
    disableChat: true,
    informationId: 'TRELC'
  },
  TRELCR: {
    hash: 'trelcrhash',
    name: 'Trellis Connect',
    agency: true,
    disableChat: true,
    informationId: 'TRELC'
  },
  TRELCRD: {
    hash: 'trelcrdhash',
    name: 'Trellis Connect',
    disableChat: true,
    informationId: 'TRELCRD'
  },
  TRELCD: {
    hash: 'TRELCDhash',
    name: 'Trellis Connect'
  },
  TRESL1: {
    name: 'Tresl',
    informationId: 'TRESL1',
    hash: 'treslhash',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    }
  },
  TR0001: {
    hash: 'TR0001hash',
    name: 'Trailstone Insurance Group',
    agency: true,
    informationId: 'TR0001',
    disableChat: true
  },
  TR0002: {
    hash: 'TR0002hash',
    name: 'Truly Insurance',
    agency: true,
    informationId: 'TR0002',
    disableChat: true
  },
  TR0003: {
    hash: 'TR0003hash',
    name: 'Trusted Choice Ins',
    agency: true,
    informationId: 'TR0003',
    disableChat: true
  },
  TR0003P: {
    hash: 'TR0003Phash',
    name: 'Trusted Choice Ins',
    agency: true,
    informationId: 'TR0003P',
    disableChat: true
  },
  TR0005_1P: {
    hash: 'TR0005_1Phash',
    name: 'Triangle Insurance Group',
    agency: true,
    informationId: 'TR0005_1P',
    disableChat: true
  },
  TT0001: {
    hash: 'tt00011hash',
    name: '235 Insurance',
    agency: true
  },
  TT0002_1P: {
    hash: 'TT0002_1Phash',
    name: '321Insurance, LLC',
    agency: true,
    informationId: 'TT0002_1P',
    disableChat: true
  },
  TW0001: {
    hash: 'tw00011hash',
    name: 'V&A Multiservices and Associates',
    agency: true,
    informationId: 'TW0001',
    disableChat: true
  },
  TWFG133P: {
    hash: 'TWFG133Phash',
    name: 'V&A Multiservices and Associates',
    agency: true,
    informationId: 'TWFG133',
    disableChat: true
  },
  TXCL1: {
    hash: 'txcl1hash',
    name: 'Texas Classic Insurance',
    agency: true,
    disableChat: true
  },
  TXCL1_1P: {
    hash: 'txcl1_1phash',
    name: 'Texas Classic Insurance',
    agency: true,
    informationId: 'TXCL1_1P',
    disableChat: true
  },
  TXCL1_2P: {
    hash: 'txcl1_2phash',
    name: 'Texas Classic Insurance',
    agency: true,
    informationId: 'TXCL1_2P',
    disableChat: true
  },
  UB1: {
    hash: 'dfdsk8e',
    name: 'Unbiased Auto',
    states: {
      TX: 2
    }
  },
  UF0001: {
    hash: 'UF0001hash',
    name: 'UFirst Insurance Agency',
    agency: true,
    informationId: 'UF0001',
    disableChat: true
  },
  UN0001: {
    hash: 'UN0001hash',
    name: 'United Insurance and Risk Management',
    agency: true,
    informationId: 'UN0001',
    disableChat: true
  },
  UN0002_1P: {
    hash: 'UN0002_1Phash',
    name: 'UNITED INSURANCE GROUP LLC',
    agency: true,
    informationId: 'UN0002_1P',
    disableChat: true
  },
  UNQ1: {
    hash: 'unq1hash',
    agency: true,
    name: 'Unique Insurance Agency LLC',
    disableChat: true
  },
  UP0001_1P: {
    hash: 'UP0001_1Phash',
    name: 'Uptown Insurance, LLC.',
    agency: true,
    disableChat: true,
    informationId: 'UP0001_1P'
  },
  UPF1: {
    hash: '3kjlsdklcth',
    name: 'Upfront',
    allowRestricted: {
      MD: true,
      MI: true,
      TX: true
    }
  },
  UR0001_1P: {
    hash: 'UR0001_1Phash',
    name: 'UR Choice Holdings, LLC',
    agency: true,
    informationId: 'UR0001_1P',
    disableChat: true
  },
  UR1: {
    hash: 'YQfPs',
    name: 'Upside Roofing',
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  UTYA1P: {
    hash: 'utya1phash',
    name: 'Unity AIA',
    agency: true,
    informationId: 'UTYA1P',
    disableChat: true
  },
  UTYA1: {
    hash: 'utya1hash',
    name: 'Unity AIA',
    agency: true,
    informationId: 'UTYA1',
    disableChat: true
  },
  URE: {
    hash: 'YQfPsE',
    name: 'Upside Roofing',
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  VA0001: {
    hash: 'va00011hash',
    name: 'V&A Multiservices and Associates LLC',
    agency: true,
    disableChat: true
  },
  VA0002: {
    hash: 'va0002hash',
    name: 'Vans Insurance Co, LLC',
    agency: true,
    informationId: 'VA0002',
    disableChat: true
  },
  VA0003_1: {
    hash: 'VA0003_1hash',
    name: 'Value Insurance',
    agency: true,
    informationId: 'VA0003_1',
    disableChat: true
  },
  VA0002P: {
    hash: 'va0002Phash',
    name: 'Vans Insurance Co, LLC',
    agency: true,
    informationId: 'VA0002P',
    disableChat: true
  },
  VE0001: {
    hash: 'VE0001hash',
    name: 'Vernon Hooks Insurance Agency LLC.',
    agency: true,
    disableChat: true,
    informationId: 'VE0001'
  },
  VENS1: {
    hash: 'VENS1hash',
    agency: true,
    name: 'Venus Insurance Group',
    disableChat: true
  },
  VI1: {
    hash: 'vi3dfklv8',
    name: 'Voldico Insurance',
    agency: true,
    disableChat: true
  },
  VILLAGE: {
    hash: 'vidfsiewf',
    name: 'Village Ford',
    informationId: 'VILLAGE'
  },
  VGHN1: {
    hash: 'vghn1hash',
    name: 'Vaughan Insurance Agency LLC',
    agency: true,
    disableChat: true
  },
  VM1: {
    hash: 'TSjFy',
    name: 'Victory Mortgage',
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  VME: {
    hash: 'TSjFyE',
    name: 'Victory Mortgage',
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  VNZLA: {
    hash: 'ciconzds92',
    name: 'Vonzella',
    agency: true,
    disableChat: true
  },
  VS1: {
    hash: 'dd8y4R',
    name: 'Vector Security',
    homeSecurity: true,
    states: {
      IN: 2,
      MD: 2,
      OH: 2,
      OK: 2
    }
  },
  VSE: {
    hash: 'dd8y4RE',
    name: 'Vector Security',
    homeSecurity: true,
    states: {
      IN: 2,
      MD: 2,
      OH: 2
    }
  },
  VIV1: {
    hash: 's2LPmaa',
    name: 'Vivint Smart Home Agency',
    agency: true,
    informationId: 'VIV1',
    skipScheduledCall: true,
    disableChat: true
  },
  VO0001_1: {
    hash: 'VO0001_1hash',
    name: 'VO Enterprises LLC',
    agency: true,
    informationId: 'VO0001_1',
    disableChat: true
  },
  VO0001_2: {
    hash: 'VO0001_2hash',
    name: 'VO Enterprises LLC',
    agency: true,
    informationId: 'VO0001_2',
    disableChat: true
  },
  VV1: {
    hash: 'FuNUN',
    name: 'Vivint Smart Home',
    homeSecurity: true,
    achWildcard: true,
    skipScheduledCall: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    },
    informationId: 'VV1'
  },
  VV2: {
    hash: 's2LPm',
    name: 'Vivint Smart Home',
    homeSecurity: true,
    achWildcard: true,
    skipScheduledCall: true,
    disableChat: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    },
    informationId: 'VV1'
  },
  VV3: {
    hash: 's2LPm4',
    name: 'Vivint Smart Home',
    homeSecurity: true,
    skipScheduledCall: true,
    disableChat: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    },
    informationId: 'VV1'
  },
  VV4: {
    hash: 's2LPm444',
    name: 'Vivint Smart Home',
    homeSecurity: true,
    skipScheduledCall: true,
    disableChat: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    },
    informationId: 'VV1'
  },
  VVE: {
    hash: 's2LPmE',
    name: 'Vivint Smart Home',
    homeSecurity: true,
    skipScheduledCall: true,
    states: {
      AR: 2,
      AL: 2,
      AZ: 2,
      CO: 2,
      GA: 2,
      IA: 2,
      ID: 2,
      IL: 2,
      IN: 2,
      KS: 2,
      KY: 2,
      MD: 2,
      ME: 2,
      MI: 2,
      MO: 2,
      MT: 2,
      ND: 2,
      NE: 2,
      NH: 2,
      NJ: 2,
      NM: 2,
      NV: 2,
      NY: 2,
      OH: 2,
      OR: 2,
      OK: 2,
      PA: 2,
      RI: 2,
      SC: 2,
      SD: 2,
      TX: 2,
      UT: 2,
      VA: 2,
      VT: 2,
      WI: 2,
      WV: 2,
      WY: 2
    },
    informationId: 'VV1'
  },
  VW0001: {
    hash: 'vw0001hash',
    name: 'Vowels Insurance Agency',
    agency: true,
    informationId: 'VW0001',
    disableChat: true
  },
  VW0001P: {
    hash: 'vw0001Phash',
    name: 'Vowels Insurance Agency',
    agency: true,
    informationId: 'VW0001P',
    disableChat: true
  },
  WAFFLE1: {
    hash: 'WAFFLE1hash',
    name: 'Waffle Labs, Inc.'
  },
  WAY119: {
    hash: 'WAY119hash',
    name: 'Way.com Insurance Solutions',
    informationId: 'WAY119',
    disableChat: true
  },
  WA0001: {
    hash: 'wa0001hash',
    name: 'Waterton Insurance Group LLC',
    agency: true,
    informationId: 'WA0001',
    disableChat: true
  },
  WA0002: {
    hash: 'wa0002hash',
    name: 'Wasatch Insurance Advisors',
    agency: true,
    informationId: 'WA0002',
    disableChat: true
  },
  WAY0001_99P: {
    hash: 'WAY0001_99hash',
    name: 'Way.com',
    agency: true,
    informationId: 'WAY0001_99',
    disableChat: true
  },
  WA0004_1P: {
    hash: 'WA0004_1Phash',
    name: 'Walker Insurance Associates',
    agency: true,
    informationId: 'WA0004_1P',
    disableChat: true
  },
  WA0006_1P: {
    hash: 'WA0006_1Phash',
    name: 'Warrior Insurance and Services Group LLC',
    agency: true,
    informationId: 'WA0006_1P',
    disableChat: true
  },
  WC0001: {
    hash: 'wC0001hash',
    name: 'Windy City Insurance Group',
    agency: true,
    disableChat: true
  },
  WCI1: {
    hash: 'wci1hash',
    name: 'White Clay Insurance',
    agency: true,
    disableChat: true
  },
  WE0001_1: {
    hash: 'WE0001_1hash',
    name: 'Wenhold Insurance Agency, LLC.',
    agency: true,
    informationId: 'WE0001_1',
    disableChat: true
  },
  WE0002_1P: {
    hash: 'WE0002_1Phash',
    name: 'Weaver Insurance Group, LLC.',
    agency: true,
    informationId: 'WE0002_1P',
    disableChat: true
  },
  WE0003: {
    hash: 'WE0003hash',
    name: 'Westco Insurance',
    agency: true,
    informationId: 'WE0003',
    disableChat: true
  },
  WE0004: {
    hash: 'WE0004hash',
    name: 'We Insure Things',
    agency: true,
    informationId: 'WE0004',
    disableChat: true
  },
  WE0006_1P: {
    hash: 'WE0006_1Phash',
    name: 'Weatherford Insurance',
    agency: true,
    informationId: 'WE0006_1P',
    disableChat: true
  },
  WEA: {
    hash: '918ikjlkj12',
    name: 'Westerville Education Association',
    states: {
      OH: 2
    }
  },
  WEC1: {
    hash: 'dcwec1fisg',
    name: 'WEC Energy Group',
    states: {
      IL: 2,
      IN: 2,
      WI: 2
    }
  },
  WH0002_1P: {
    hash: 'WH0002_1Phash',
    name: 'Whittaker Chillton Agency',
    agency: true,
    disableChat: true,
    informationId: 'WH0002_1P'
  },
  WIF1: {
    hash: 'dafrwfg56',
    name: 'What If Media'
  },
  WI0002: {
    hash: 'WI0002hash',
    name: 'Wiley Insurance Agency Inc.',
    agency: true,
    disableChat: true,
    informationId: 'WI0002'
  },
  WI0003: {
    hash: 'WI0003hash',
    name: 'William Bodell Insurance',
    agency: true,
    disableChat: true,
    informationId: 'WI0003'
  },
  WI0004: {
    hash: 'WI0004hash',
    name: 'Wichita Insurance, LLC. ',
    agency: true,
    disableChat: true,
    informationId: 'WI0004'
  },
  WI0005_1: {
    hash: 'WI0005_1hash',
    name: 'Witt & Associates',
    agency: true,
    disableChat: true,
    informationId: 'WI0005_1'
  },
  WI0005_1P: {
    hash: 'WI0005_1Phash',
    name: 'Witt & Associates',
    agency: true,
    disableChat: true,
    informationId: 'WI0005_1P'
  },
  WI0006_1P: {
    hash: 'WI0006_1Phash',
    name: 'Willoughby Insurance Services',
    agency: true,
    disableChat: true,
    informationId: 'WI0006_1P'
  },
  WIT1: {
    hash: 'wit1hash',
    name: 'We Insure Things',
    agency: true,
    informationId: 'WIT1',
    disableChat: true
  },
  WIT1P: {
    hash: 'wit1Phash',
    name: 'We Insure Things',
    agency: true,
    informationId: 'WIT1P',
    disableChat: true
  },
  WIT2: {
    hash: 'wit2hash',
    name: 'Mirage Insurance',
    agency: true,
    disableChat: true
  },
  WLGS1: {
    hash: 'wlgs1hash',
    name: 'Wilgus Insurance Agency Inc.',
    agency: true,
    disableChat: true
  },
  WN0001: {
    hash: 'wn00011hash',
    name: 'Wynn Insurance Network',
    agency: true,
    disableChat: true
  },
  WH0001: {
    hash: 'WH0001hash',
    name: 'Whiteside Insurance',
    agency: true,
    informationId: 'WH0001',
    disableChat: true
  },
  WO0001_1P: {
    hash: 'WO0001_1Phash',
    name: 'Woop Insurance Agency',
    agency: true,
    disableChat: true,
    informationId: 'WI0001_1P'
  },
  WO0002_1P: {
    hash: 'WO0002_1Phash',
    name: 'Woodstock Car Insurance, LLC',
    agency: true,
    disableChat: true,
    informationId: 'WI0002_1P'
  },
  WP1: {
    hash: 'dafrwp16',
    name: 'Waypoint Benefits'
  },
  WSTX1: {
    hash: 'wstx1hash',
    name: 'West Texas Insurance Agency',
    agency: true,
    disableChat: true
  },
  WXFRD1: {
    hash: 'wxfrd1hash',
    agency: true,
    name: 'Wexford Insurance, LLC',
    disableChat: true
  },
  WYL1: {
    hash: 'dfkjvfusad87',
    name: 'Wyler'
  },
  YA: {
    hash: 'younaldef',
    name: 'Young Alfred',
    agency: true,
    informationId: 'YA1',
    disableChat: true
  },
  YA1: {
    hash: 'younaldef2',
    name: 'Young Alfred',
    agency: true,
    informationId: 'YA1',
    disableChat: true
  },
  EXEC1: {
    hash: '98vlkjwdv',
    agency: true,
    disableChat: true
  },
  YE0001_1: {
    hash: 'YE0001_1hash',
    name: 'Yeti Insurance LLC',
    agency: true,
    informationId: 'YE0001_1',
    disableChat: true
  },
  YE0001_1P: {
    hash: 'YE0001_1Phash',
    name: 'Yeti Insurance LLC',
    agency: true,
    informationId: 'YE0001_1P',
    disableChat: true
  },
  YL0001: {
    hash: 'yl0001hash',
    name: 'Your Life Protect LLC',
    agency: true,
    informationId: 'YL0001',
    disableChat: true
  },
  YL0001P: {
    hash: 'YL0001Phash',
    name: 'Your Life Protect LLC',
    agency: true,
    informationId: 'YL0001P',
    disableChat: true
  },
  YO0001_2P: {
    hash: 'YO0001_2Phash',
    name: 'PCF of the West dba Young Insurance & Financial',
    agency: true,
    informationId: 'YO0001_2P',
    disableChat: true
  },
  YP1: {
    hash: 'ypdldfe',
    name: 'Your Policy',
    agency: true,
    informationId: 'YP1',
    disableChat: true
  },
  ZB1: {
    hash: 'thewnvsapfew',
    name: 'The Zebra',
    agency: true,
    disableChat: true
  },
  /** For testing purposes */
  SEAMLESS1: {
    hash: 'SEAMLESS1',
    name: 'SEAMLESS1'
  },
  ...largeAgencyCodes
};

export default affinityGroups;
