import React from 'react';
import { Form } from 'formik';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { FormField } from 'core/components/form';

import { Label } from 'core';
import done from 'core/assets/svg/done.svg';
import {
  getValues,
  checkboxes,
  options,
  Sports,
  categories,
  nesting,
  getLabel
} from 'core/helpers/scheduled-pp-helper';
import { icons } from '../icons';

function PropertyForm({ classes, values, disabled, id, name }) {
  const vals = getValues(values);
  const { subCategory } = vals;
  const checkbox = checkboxes(classes, id, name)[subCategory || vals.category];
  const label = getLabel(vals.category);

  return (
    <div className={classes.container}>
      <Form>
        <Grid container justify="space-between" alignItems="flex-start" spacing={2}>
          <FormField
            id={`${name}.category`}
            name={`${name}.category`}
            label="Property Category"
            type="select"
            xs={5}
            icons={icons}
            options={options}
            mode="light"
          />
          {/* To include sub nesting --  only guns for now
           Remove slashes to get only the category name
           */}
          {(values.category === 'Sports' || subCategory) && (
            <FormField
              id={`${name}.subCategory`}
              name={`${name}.subCategory`}
              label="Sub Category"
              type="select"
              options={categories
                .concat(nesting)
                .filter((item) => Sports.options.includes(item.id))
                .map((option) => ({ ...option, value: option.value.split('/').pop() }))}
              mode="light"
              xs={5}
            />
          )}
          <FormField
            id={`${name}.value`}
            name={`${name}.value`}
            type="numeric"
            label="Amount"
            mode="light"
            xs={2}
            format={{ thousandSeparator: true, prefix: '$' }}
          />

          {subCategory === 'Sports_Guns' && (
            <Grid container item justify="space-between" alignItems="center">
              <Label type="infoLabel">Note: Rare or antique firearms should be insured as “Fine Arts” per rules.</Label>
            </Grid>
          )}

          <FormField
            id={`${name}.description`}
            name={`${name}.description`}
            type="string"
            label="Description"
            mode="light"
            props={{
              multiline: true,
              rows: 4,
              classes: { 'light-textFieldInput': classes.textarea }
            }}
            xs={12}
          />
          {checkbox && (
            <Grid container item justify="space-between" alignItems="center">
              <FormField
                type="checkbox"
                {...checkbox}
                mode="light"
                classnames={{ container: classes.checkbox, 'light-checkboxColorPrimary': classes.checkboxIcon }}
                xs={3}
              />
            </Grid>
          )}

          {label && (
            <Label type="darkSmall" className={classes.checkbox}>
              <img key="tickIcon" alt="tick" className={classes.tickIcon} src={done} />
              {label}
            </Label>
          )}
        </Grid>
      </Form>
    </div>
  );
}

PropertyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
};
PropertyForm.defaultProps = {
  disabled: false
};

export default PropertyForm;
