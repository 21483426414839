import React, { useCallback, useContext } from 'react';
import { observer } from 'mobx-react';
import { Formik, yupToFormErrors } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { policyTypes } from '@ourbranch/policy-types';

import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { AuthContext } from 'core/components/auth';
import { types } from 'core/helpers/sanitize';
import { UWPropertyValidationSchema } from './components/common/pre-bind-UW.validation';
import VerifyPropertyContainer from './components/common/verify-property-container';
import { getUWPreBindHomeVerificationInitialValues } from './components/home/get-UW-pre-bind-home-verification-initial-values';

const PreBindUW = observer(() => {
  const history = useHistory();
  const { offer: offerStore } = useStore();
  const { offerId } = useParams();
  const { offer, getOffer } = offerStore;
  const session = useContext(AuthContext);
  const {
    user: { username }
  } = session;

  const UWPreBindHomeVerificationInitialValues = getUWPreBindHomeVerificationInitialValues(
    offerStore?.offer?.quote?.home
  );

  const validate = useCallback(
    async (values) => {
      // don't validate if ineligible
      if (!values.ineligibleForHomeDueToUWSelfReport) {
        try {
          await UWPropertyValidationSchema().validate(values, {
            abortEarly: false,
            context: { ...values, UWConditions: UWPreBindHomeVerificationInitialValues.conditions }
          });
        } catch (error) {
          const formErrors = yupToFormErrors(error);

          // disabling eslint for the console log so we can monitor validation errors in prod
          // eslint-disable-next-line
          console.log({ formErrors });
          return formErrors;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const submitRecalculate = async (details) => {
    // bring in the offer values from the store and add the preBindUWCheck values
    let formattedDetails = {
      ...offerStore.offer.quote,
      preBindUWCheck: {
        homeVerification: {
          ...details
        },
        attestationAgent: username
      }
    };
    if (details.ineligibleForHomeDueToUWSelfReport) {
      formattedDetails.selectedOption = policyTypes.Auto;
    }
    // clean the values before submitting
    formattedDetails = types.QuoteDetailsInput(formattedDetails);

    // if still eligible, push to checkout after recalc
    const pushToCheckout = !details.ineligibleForHomeDueToUWSelfReport;
    await offerStore.recalculateQuoteToCluster(offerStore.offer.id, formattedDetails, history, pushToCheckout);
  };

  if (!offer) {
    getOffer(offerId);
    return <Loading noBackground />;
  }

  if (!UWPreBindHomeVerificationInitialValues?.initialValues || offerStore.loading) {
    return <Loading noBackground />;
  }

  return (
    <Formik
      validateOnChange={false}
      initialValues={UWPreBindHomeVerificationInitialValues.initialValues}
      validate={validate}
      onSubmit={submitRecalculate}
      validateOnBlur={false}
    >
      <VerifyPropertyContainer conditions={UWPreBindHomeVerificationInitialValues.conditions} />
    </Formik>
  );
});

export default PreBindUW;
