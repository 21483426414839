import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  buttons: {
    padding: '0px 30px',
    marginRight: theme.spacing(5),
    marginBottom: theme.spacing(5)
  },
  notificationContent: {
    marginTop: theme.spacing(5),
    color: 'black'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: 30
  },
  mainLabel: {
    marginTop: 10,
    fontSize: 38
  },
  helpDesk: {
    marginLeft: 3,
    color: 'black'
  }
});

export default makeStyles(styles);
