const styles = (theme) => ({
  container: {
    borderRadius: theme.sizes.roundCorners,
    backgroundColor: theme.colors.card_background_secondary,
    overflow: 'hidden',
    padding: theme.spacing(6, 8)
  },
  containerDark: {
    backgroundColor: theme.colors.card_background_secondary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 32
  },
  icon: {
    marginRight: 16
  },
  containerInner: {
    backgroundColor: theme.colors.button__background_primary,
    paddingRight: 32,
    paddingLeft: 32,
    paddingTop: 24,
    paddingBottom: 24,
    color: theme.colors.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:first-child': {
      marginBottom: 16,
      justifyContent: 'flex-start'
    }
  },
  addressFormfield: {
    paddingBottom: 8
  },
  flex: {
    display: 'flex',
    alignItems: 'center'
  },
  disclosure: {
    display: 'flex',
    alignItems: 'center',
    marginTop: -110
  },
  header: { display: 'flex', alignItems: 'center', fontSize: '40px', fontWeight: 300 },
  dollarIcon: { fontSize: '16', marginRight: 5 },
  thinText: { fontWeight: 'lighter', fontSize: 12, marginLeft: 10 },
  smallText: { fontSize: 12, marginLeft: 10 },
  smallIcon: {
    width: 16,
    height: 16
  }
});

export default styles;
