/* eslint-disable func-names */
import * as Yup from 'yup';
import { isAfter, isBefore, isToday, differenceInYears } from 'date-fns';
import {
  paymentMethod,
  noUDRsAllowedStates,
  combinedUMUIM,
  driverCarAssignmentStates,
  preBindMVRStates,
  windHailDeductibleIsAlwaysPercent,
  aopDeductibleIsAlwaysPercent,
  UMPDOnlyWithUMBIStates,
  paymentType,
  policyType,
  noWindHailDeductibleStates,
  windHailDeductibleCanVaryByCounty,
  doNotShowCarLevelUMPDStates,
  policyLevelUMPDStates,
  allow50100UMBIon2550BIStates,
  standaloneRentersStates
} from '@ourbranch/lookups';

import { canAddUmbrella } from 'core/helpers/quoter.service';
import { FormAction } from 'core/store/offer-store';
import { HEALTH_INSURANCE_TYPE } from 'core/helpers/constants';
import { isLimitACPEValid, getUMPDValue, getPLimitValue } from 'core/helpers/car-validations';
import { connectedHomeSchema } from 'common/components/discounts/connected-home-form/connected-home.validation-schema';
import { validUSState, requiredString, validAffinityCode } from 'common/helpers/yup-helpers';
import { haveAnyOfThisCarsSymbolsChanged, hasMakeModelStyleSymbols } from 'common/helpers/car-symbols-helpers';
import { driversLicenseNumberSchema } from 'core/helpers/drivers-license-number-validations';

// Effective date for SA Renters policies must be after October 1st, 2022
const standaloneRentersLimitDate = new Date(2022, 9, 1);

const maxDate = new Date(new Date().setDate(new Date().getDate() + 59));

Yup.addMethod(Yup.string, 'requiredString', requiredString);
Yup.addMethod(Yup.mixed, 'validUSState', validUSState);
Yup.addMethod(Yup.mixed, 'validAffinityCode', validAffinityCode);

const offerValidationSchema = (session, state, affinityLookups) =>
  Yup.object().shape({
    isBix: Yup.boolean().nullable(),
    auto: Yup.object().shape({
      pipAllResidents: Yup.number()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value >= 0;
          }
          return true;
        })
        .nullable(),
      pipResidentsWithQHC: Yup.number()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value >= 0;
          }
          return true;
        })
        .test(
          'compareToAllPipEligibleResidents',
          'The amount of residents with QHC cannot exceed the amount of eligible pip residents',
          function (value) {
            if (this.options.context?.correctedAddress?.state === 'MI') {
              const pipEligibleResidentsCount =
                this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
              if (value > pipEligibleResidentsCount) {
                // using this.createError to have a dynamic error message
                return this.createError({
                  message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
                });
              }
            }
            return true;
          }
        )
        .test('compareToHealthInsurance', 'Must be at least 1 because Medicare is selected', function (value) {
          if (
            this.options.context?.correctedAddress?.state === 'MI' &&
            this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
            !this.options.context.auto?.pipEveryoneOnSamePlan
          ) {
            return value >= 1;
          }
          return true;
        })
        .test(
          'compareToHealthInsurance2',
          'Must be the same as the number of residents in household because Medicare is selected and everyone is on the same plan',
          function (value) {
            if (
              this.options.context?.correctedAddress?.state === 'MI' &&
              this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
              this.options.context.auto?.pipEveryoneOnSamePlan
            ) {
              return value === this.options.context.auto?.pipAllResidents;
            }
            return true;
          }
        )
        .nullable(),
      pipExcludedResidents: Yup.number()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value >= 0;
          }
          return true;
        })
        .test(
          'checkIfMedicare',
          'The number of excluded residents must be 0 or the same as the number of residents in the household',
          function (value) {
            if (
              this.options.context?.correctedAddress?.state === 'MI' &&
              this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE
            ) {
              return value === 0 || value === this.options.context.auto?.pipAllResidents;
            }
            return true;
          }
        )
        .test(
          'checkIfMedicare2',
          'The number of excluded residents must be 0 because residents with QHC is less than residents in household',
          function (value) {
            if (
              this.options.context?.correctedAddress?.state === 'MI' &&
              this.options.context.auto?.pipHealthInsuranceType === HEALTH_INSURANCE_TYPE.MEDICARE &&
              this.options.context.auto?.pipAllResidents > this.options.context.auto?.pipResidentsWithQHC
            ) {
              return value === 0;
            }
            return true;
          }
        )
        .test(
          'compareToAllPipEligibleResidents',
          'The amount of excluded residents cannot exceed the amount of eligible pip residents',
          function (value) {
            if (this.options.context?.correctedAddress?.state === 'MI') {
              const pipEligibleResidentsCount =
                this.options.context.auto?.pipAllResidents - this.options.context.auto?.pipAdditionalResidents;
              if (value > pipEligibleResidentsCount) {
                // using this.createError to have a dynamic error message
                return this.createError({
                  message: `This cannot exceed ${pipEligibleResidentsCount} - the amount of pip eligible residents`
                });
              }
            }
            return true;
          }
        )
        .nullable(),
      pipHealthInsuranceType: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      pipAdditionalResidents: Yup.number()
        .test(
          'compareToAllResidents',
          'The amount of excluded residents cannot exceed the amount of residents living in the household',
          function (value) {
            if (this.options.context?.correctedAddress?.state === 'MI') {
              return value <= this.options.context.auto?.pipAllResidents;
            }
            return true;
          }
        )
        .nullable()
    }),
    autoCoverage: Yup.object().shape({
      policyLimitUMBI: Yup.string()
        .test('umbi', 'UMBI cannot be higher than BIPD', function (ob) {
          const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          if (
            !hasAutoPolicy ||
            !policyLimitUMBI ||
            !policyLimitBIPD ||
            (allow50100UMBIon2550BIStates[this.options.context?.correctedAddress?.state] &&
              policyLimitUMBI === '50/100')
          ) {
            return true;
          }

          const valueUMBI = policyLimitUMBI.includes('/')
            ? Number(policyLimitUMBI.split('/')[0])
            : Number(policyLimitUMBI.split(' ')[0]);
          const valueBIPD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[0])
            : Number(policyLimitBIPD.split(' ')[0]);

          return valueBIPD >= valueUMBI;
        })
        .test('umbiCSL', 'BI cannot be CSL unless policy UM/UIM BI is also CSL.', function () {
          const { policyLimitUMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          const combinedUMUIMState = combinedUMUIM[this.options.context?.correctedAddress?.state];
          if (!combinedUMUIMState || !hasAutoPolicy) {
            return true;
          }
          const umbiCSL = policyLimitUMBI.includes('CSL');
          const bipdCSL = policyLimitBIPD.includes('CSL');

          return policyLimitUMBI === '0/0' || umbiCSL === bipdCSL;
        }),
      policyLimitUIMBI: Yup.string()
        .test('uimbi', 'UIMBI cannot be higher than BIPD', function (obj) {
          const { policyLimitUIMBI, policyLimitBIPD } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');

          if (
            combinedUMUIM[this.options.context?.correctedAddress?.state] ||
            !hasAutoPolicy ||
            !policyLimitUIMBI ||
            !policyLimitBIPD
          ) {
            return true;
          }

          const valueUIMBI = policyLimitUIMBI.includes('/')
            ? Number(policyLimitUIMBI.split('/')[0])
            : Number(policyLimitUIMBI.split(' ')[0]);
          const valueBIPD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[0])
            : Number(policyLimitBIPD.split(' ')[0]);
          return valueBIPD >= valueUIMBI;
        })
        .test('uimbiCSL', 'UIM BI cannot be CSL unless policy UM BI and BI/PD are also CSL.', function () {
          const { policyLimitBIPD, policyLimitUIMBI } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          const combinedUMUIMState = combinedUMUIM[this.options.context?.correctedAddress?.state];
          if (combinedUMUIMState || !hasAutoPolicy) {
            return true;
          }
          const bipdCSL = policyLimitBIPD.includes('CSL');
          const uimbiCSL = policyLimitUIMBI.includes('CSL');
          return policyLimitUIMBI === '0/0' || bipdCSL === uimbiCSL;
        }),
      policyLimitUMPD: Yup.string()
        .test('UMPDMustBeLowerThanPD', 'UMPD cannot be higher than PD', function (ob) {
          const { policyLimitBIPD, policyLimitUMPD } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          const state = this.options.context.correctedAddress?.state;

          if (!hasAutoPolicy || !policyLimitBIPD || !policyLevelUMPDStates[state]) {
            return true;
          }

          if (this.options.context?.correctedAddress?.state === 'NM') {
            // in NM, we just have a UMPD = PD limit option, dont run validation
            return true;
          }

          const valuePD = policyLimitBIPD.includes('/')
            ? Number(policyLimitBIPD.split('/')[2])
            : Number(policyLimitBIPD.split(' ')[0]);

          const valueUMPD = parseFloat(policyLimitUMPD === 'NONE' ? '0' : policyLimitUMPD.split('/')[0]) / 1000;

          return valuePD >= valueUMPD;
        })
        .test('UMPDMustHaveUMBIInThiState', 'UMPD cannot be chosen in this state without UMBI', function (value) {
          const { policyLimitUMBI, policyLimitUMPD } = this.options.context?.autoCoverage || {};
          const hasAutoPolicy = this.options.context.selectedOption.includes('A');
          if (!hasAutoPolicy || !UMPDOnlyWithUMBIStates[this.options.context?.correctedAddress?.state]) {
            return true;
          }

          const valueUMBI = policyLimitUMBI.includes('/')
            ? Number(policyLimitUMBI.split('/')[0])
            : Number(policyLimitUMBI.split(' ')[0]);

          const valueUMPD = Number(policyLimitUMPD === 'NONE' ? '0' : policyLimitUMPD) / 1000;

          return !(valueUMBI === 0 && valueUMPD > 0);
        }),

      policyLimitPIPME: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      policyLimitPIPACR: Yup.string()
        .test('requiredForMI', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'MI') {
            return value?.length;
          }
          return true;
        })
        .nullable(),
      policyLimitMedicalPayments: Yup.string()
        .test('medicalPaymentsOrComboFBP', 'Required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'PA') {
            const { policyLimitComboFBP } = this.parent;
            if (policyLimitComboFBP && policyLimitComboFBP !== 'NONE' && value !== 'NONE') {
              return this.createError({
                message: 'Can not choose both this and combined first party benefits'
              });
            }
            if (policyLimitComboFBP && policyLimitComboFBP === 'NONE' && value === 'NONE') {
              return this.createError({
                message: 'Choose medical payments or combined first party benefits'
              });
            }
          }
          return true;
        })
        .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and PIP in TX', function (value) {
          if (this.options.context?.correctedAddress?.state === 'TX') {
            const { policyLimitPIP } = this.parent;
            if (value !== 'NONE' && policyLimitPIP && policyLimitPIP !== 'NONE') {
              return false;
            }
            return true;
          }
          return true;
        })
        .nullable(),
      policyLimitComboFBP: Yup.string()
        .test('medicalPaymentsOrComboFBP', 'required', function (value) {
          if (this.options.context?.correctedAddress?.state === 'PA') {
            const { policyLimitMedicalPayments } = this.parent;
            if (policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE' && value !== 'NONE') {
              return this.createError({
                message: 'Can not choose both this and medical payments'
              });
            }
            if (policyLimitMedicalPayments && policyLimitMedicalPayments === 'NONE' && value === 'NONE') {
              return this.createError({
                message: 'Choose medical payments or combined first party benefits'
              });
            }
          }
          return true;
        })
        .nullable(),
      policyLimitPIP: Yup.string()
        .test('medicalPaymentsOrPIPNotBothTX', 'Cannnot chose this and MedPay in TX', function (value) {
          if (this.options.context?.correctedAddress?.state === 'TX') {
            const { policyLimitMedicalPayments } = this.parent;
            if (value !== 'NONE' && policyLimitMedicalPayments && policyLimitMedicalPayments !== 'NONE') {
              return false;
            }
            return true;
          }
          return true;
        })
        .nullable()
    }),
    homeCoverage: Yup.object().shape({
      deductibleWindHail: Yup.number()
        .nullable()
        .test('WindHailPercentageMin', 'Wind & Hail Coverage must be a percentage for this state', function (value) {
          const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
          if (
            hasHomeSelected &&
            windHailDeductibleIsAlwaysPercent[this.options.context?.correctedAddress.state] &&
            value > 1
          ) {
            return false;
          }
          return true;
        })
        .test(
          'CompareToAllOtherPerils',
          'Wind & Hail Deductible must be greater than or equal to all other perils deductible',
          function (value) {
            const windHailExclusion = this.options.context?.homeCoverage?.windHailExclusion;
            if (windHailExclusion) {
              // force pass since no need to validiate this if excluded
              return true;
            }
            const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
            const state = this.options.context.correctedAddress.state;
            const county = this.options.context.home.county.toUpperCase();
            const windHailInState =
              (!windHailDeductibleCanVaryByCounty[state] ||
                (windHailDeductibleCanVaryByCounty[state] && windHailDeductibleCanVaryByCounty[state][county])) &&
              !noWindHailDeductibleStates[state];

            if (hasHomeSelected && windHailInState) {
              const coverageA = this.options.context.homeCoverage?.coverageA;
              const windHailIsPercent = value < 1;
              const allOtherPerilsIsPercent = this.options.context.homeCoverage?.deductibleAllOther < 1;

              // since value is a %, we need to multiply it by coverage A to see the deductible amount
              const allOtherPerilsAmount = allOtherPerilsIsPercent
                ? this.options.context.homeCoverage?.deductibleAllOther * coverageA
                : this.options.context.homeCoverage?.deductibleAllOther;
              const windHailAmount = windHailIsPercent ? value * coverageA : value;

              return windHailAmount >= allOtherPerilsAmount;
            }
            return true;
          }
        ),
      deductibleAllOther: Yup.number()
        .nullable()
        .test(
          'WindHailPercentageMin',
          'All Other Perils deductible must be a percentage for this state',
          function (value) {
            const hasHomeSelected = this.options.context?.selectedOption?.includes('H');
            if (
              hasHomeSelected &&
              aopDeductibleIsAlwaysPercent[this.options.context?.correctedAddress?.state] &&
              value > 1
            ) {
              return false;
            }
            return true;
          }
        )
    }),
    drivers: Yup.array().of(
      Yup.object().shape({
        driversLicenseNumber: Yup.string()
          .test(
            'driversLicenseRequired',
            'Drivers license is required for this state',
            function (val, { options: { context, parent } }) {
              const hasAutoSelected = context?.selectedOption?.includes('A');
              if (hasAutoSelected && preBindMVRStates.includes(context.correctedAddress.state) && parent.postBindMVR) {
                return !!val;
              }
              return true;
            }
          )
          .concat(driversLicenseNumberSchema)
          .nullable(),
        driversLicenseState: Yup.string().validUSState('offer.drivers.driverLicenseState'),
        schoolName: Yup.string()
          .when('fullTimeStudent', {
            is: (fullTimeStudent) => fullTimeStudent === true,
            then: Yup.string().requiredString('School Name is required')
          })
          .nullable(),

        schoolLocation: Yup.object()
          .when('fullTimeStudent', {
            is: (fullTimeStudent) => fullTimeStudent === true,
            then: Yup.object().shape({
              address: Yup.string().requiredString('School Address is required'),
              city: Yup.string().requiredString('School City is required'),
              state: Yup.string().requiredString('School State is required'),
              zip: Yup.string().requiredString('School Zip code is required')
            })
          })
          .nullable(),

        autoViolations: Yup.object().test(
          'hasUDR',
          "There are drivers on this offer that have an unverified driving record (UDR), and we do not allow that in this state. Please drop the drivers and re-add with Driver's License information to pull driving records.",
          function (obj) {
            if (noUDRsAllowedStates.includes(this.options.context?.correctedAddress.state)) {
              return obj.UDR === 0;
            }
            return true;
          }
        ),
        assignedCar: Yup.mixed().test('assignedCar', 'Each driver must be assigned to one car', function (value) {
          const hasAutoSelected = this.options.context?.selectedOption?.includes('A');
          if (hasAutoSelected && driverCarAssignmentStates[this.options.context?.correctedAddress?.state]) {
            if (!value) {
              return this.createError({
                message: 'All drivers must be assigned a primary vehicle'
              });
            }
            const cars = this.options.context.cars;
            if (value && !cars.some((car) => car.VIN === value)) {
              return this.createError({
                message: 'Driver assigned VIN that does not exist '
              });
            }
            const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
            const assignedDrivers = cars.map((car) => car.assignedDriver);

            if (drivers.length === cars.length) {
              const driversSet = new Set(assignedDrivers);
              // assert that each driver assigned exactly once
              return driversSet.size === assignedDrivers.length && drivers.length === driversSet.size
                ? true
                : this.createError({
                    message: 'Each driver must be assigned to one car'
                  });
            }

            return true;
          }
          return true;
        })
      })
    ),
    cars: Yup.array()
      .of(
        Yup.object().shape({
          garageLocation: Yup.object().shape({
            state: Yup.string().validUSState('offer.cars.garageLocation.state')
          }),
          limitUMPD: Yup.mixed()
            .nullable()
            .test(
              'limitUMPDMustBeLowerThanPLimitValue',
              "Error, this can't be higher than property damage limit",
              function (value) {
                const hasAutoPolicy = this.options.context.selectedOption.includes('A');
                const state = this.options.context?.correctedAddress?.state;

                if (!hasAutoPolicy || state === 'NM' || doNotShowCarLevelUMPDStates[state]) {
                  // in NM, we just have a UMPD = PD limit option and shouldn't run this check.
                  // or we don't have UMPD in this state or car level UMPD in this state
                  // or not selecting auto
                  // so no need to validate
                  return true;
                }
                const { autoCoverage } = this.options.context;
                return getUMPDValue(value) <= getPLimitValue(autoCoverage.policyLimitBIPD);
              }
            )
            .test(
              'noLimitUMPDWithoutUMBI',
              'Error, UMPD cannot be chosen in this state without UMBI',
              function (value) {
                const hasAutoPolicy = this.options.context.selectedOption.includes('A');
                const state = this.options.context?.correctedAddress.state;
                if (!hasAutoPolicy || doNotShowCarLevelUMPDStates[state]) {
                  // we don't have UMPD in this state, or car level UMPD in this state, or not selecting Auto so don't run this check
                  return true;
                }
                const { autoCoverage } = this.options.context;
                return !(
                  value !== 'NONE' &&
                  UMPDOnlyWithUMBIStates[state] &&
                  (autoCoverage.policyLimitUMBI?.startsWith('0/0') || autoCoverage.policyLimitUMBI === 'NONE')
                );
              }
            ),
          assignedDriver: Yup.mixed().test(
            'assignedDriver',
            'A driver may only be assigned to one car',
            function (value) {
              if (driverCarAssignmentStates[this.options.context?.correctedAddress?.state]) {
                if (!value) {
                  return this.createError({
                    message: 'All cars must be assigned a driver'
                  });
                }
                const cars = this.options.context.cars;
                const drivers = this.options.context.drivers.filter((driver) => !driver.excludeDriver);
                const assignedDrivers = cars.map((car) => car.assignedDriver);
                const driversSet = new Set(assignedDrivers);

                if (drivers.length <= cars.length) {
                  return drivers.length === driversSet.size
                    ? true
                    : this.createError({
                        message: 'Every driver must be assigned to a car'
                      });
                }
                if (drivers.length > cars.length) {
                  return cars.length <= driversSet.size
                    ? true
                    : this.createError({
                        message: 'A driver may only be assigned to one car'
                      });
                }

                return true;
              }
              return true;
            }
          ),
          symbolMake: Yup.string()
            .test(
              'symbolMake-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolAux: Yup.string()
            .test(
              'symbolAux-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolModel: Yup.string()
            .test(
              'symbolModel-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          symbolStyle: Yup.string()
            .test(
              'symbolStyle-length-is-2',
              'Must be exactly 2 characters',
              (value, { options: { context, parent } }) => {
                if (!context.canAddCarsManually) return true;
                if (parent.symbolAux && !hasMakeModelStyleSymbols(parent)) return !value || value?.length === 2;
                return haveAnyOfThisCarsSymbolsChanged(parent, context.initialValues.cars) ? value?.length === 2 : true;
              }
            )
            .nullable(),
          limitACPE: Yup.string().test(
            'Invalid',
            'To add Additional Custom Parts Limit, Collision and Comprehensive Deductibles are required',
            function (value) {
              return isLimitACPEValid(value, this.parent);
            }
          )
        })
      )
      .test(
        'garage-state-test',
        'Garage address must be in the same state as the home address for at least one vehicle',
        function (cars) {
          const homeState = this.options.context?.correctedAddress.state;
          if (this.options.context?.selectedOption.includes('A') && cars.length > 0) {
            return cars.some((car) => car.garageLocation.state === homeState);
          }
          return true;
        }
      ),
    global: Yup.object()
      .shape({
        affinity: Yup.string()
          .validAffinityCode('global.affinity', { affinityLookups, isAgency: session.isAgency })
          .nullable(),
        autoBillingDayOfMonth: Yup.number().nullable(),
        autoPaymentMethod: Yup.string().requiredString(),
        autoPaymentType: Yup.string()
          .requiredString()
          .test(
            'payInFullRequired',
            "Offer requires One Time Payment Frequency due to customer's prior canceled policy for non-payment",
            function (value) {
              if (session.isTeamLeader || !this.options.parent.priorCancelNonPay) {
                return true;
              }
              // if paying in full, or if policy type is home, pass validation
              if (value === paymentType.OneTime || this.options.context?.selectedOption === policyType.Home) {
                return true;
              }
              return false;
            }
          ),
        billingDayOfMonth: Yup.number().nullable(),
        currentAutoCarrier: Yup.string().nullable(),
        currentHomeownersCarrier: Yup.string().nullable(),
        currentlyAutoInsured: Yup.boolean(),
        discountInventoryScore: Yup.boolean(),
        discountPaperless: Yup.boolean(),
        homeBillingDayOfMonth: Yup.number().nullable(),
        homeownersPaymentMethod: Yup.string().requiredString(),
        homeownersPaymentType: Yup.string()
          .requiredString()
          .test(
            'payInFullRequired',
            "Offer requires One Time Payment Frequency due to customer's prior canceled policy for non-payment",
            function (value) {
              if (
                session.isTeamLeader ||
                !this.options.parent.priorCancelNonPay ||
                this.options.parent?.homeownersPaymentMethod === paymentMethod.Escrow
              ) {
                return true;
              }
              // if paying in full, or if policy type is not a home policy
              if (value === paymentType.OneTime || !this.options.context?.selectedOption.includes('H')) {
                return true;
              }
              return false;
            }
          ),
        personalPropertyProtection: Yup.boolean()
      })
      .nullable(),
    includeUmbrella: Yup.boolean()
      .test(
        'canAddUmbrella',
        "Policy does not meet requirements to add Umbrella: Auto BI and UM/UIM BI need to be at least 250/500 and Homeowner's Liability needs to be at least $300K",
        function (val) {
          // if val is true return canaddumbrella
          // in every other case return true
          if (val) {
            return canAddUmbrella({
              policyLimitBIPD: this.options?.context?.autoCoverage?.policyLimitBIPD,
              policyLimitUMBI: this.options?.context?.autoCoverage?.policyLimitUMBI,
              coverageX: this.options?.context?.homeCoverage?.coverageX
            });
          }

          return true;
        }
      )
      .nullable(),
    umbrellaCoverage: Yup.object()
      .shape({
        liabilityCoverageLimit: Yup.number().test(
          'umbrella-limit-caped-at-2mil',
          'You cannot increase coverage over $2m',
          function (value) {
            // currently not allowing umbrella higher than 2mil
            if (value > 2000000) {
              return false;
            }
            return true;
          }
        ),
        watercraftHullLengths: Yup.array().of(
          Yup.number().max(30, 'We do not allow boats with hull lengths over 30 feet.')
        ),
        numVehiclesTotal: Yup.number()
          .test(
            'minCount',
            'The total number of Umbrella vehicles cannot be less than the number of vehicles on the policy',
            function (val) {
              const numCars = this.options.context?.cars?.length;
              return this.options.context?.includeUmbrella ? val >= numCars : true;
            }
          )
          .required('Number Of Vehicles is required'),
        numLicensedDriversUnder25: Yup.number().test(
          'minCountDrivers',
          'The total number of Umbrella drivers under age 25 cannot be less than the number of drivers under age 25 on the auto policy',
          function (val) {
            const driversUnder25 = this.options.context?.drivers.filter((d) => {
              // not relying on d.age here because that node is only accurate after save
              // and we want to catch drivers under 25 in case they were added in the same update as adding umbrella
              const age = differenceInYears(new Date(), new Date(d.dateOfBirth));
              return age < 25;
            }).length;
            return this.options.context?.includeUmbrella ? val >= driversUnder25 : true;
          }
        )
      })

      .nullable()
      .transform((_, val) => (val === Number(val) ? val : null)),
    scheduledPersonalProperty: Yup.object()
      .shape({
        deductible: Yup.string()
          .test('sppDeductible', 'SPP deductible is required.', function (obj) {
            if (this.options.context?.scheduledPersonalProperty?.items?.length) {
              return !!this.options.context?.scheduledPersonalProperty?.deductible;
            }
            return true;
          })
          .nullable(),
        items: Yup.array().nullable()
      })
      .nullable(),
    trailers: Yup.array(
      Yup.object().shape({
        VIN: Yup.string().requiredString('VIN is required'),
        type: Yup.string().requiredString('Trailer type is required'),
        year: Yup.number().typeError('Trailer year should be a number').required('Trailer year is required'),
        value: Yup.number()
          .typeError('Trailer value should be a number between $0 - $50000')
          .positive('Trailer value should be between $0 - $50000')
          .integer('Trailer value should be between $0 - $50000')
          .min(1, 'Trailer value should be greater than $0')
          .max(50000, 'Trailer value should be less than or equal to $50000')
          .when(
            ['deductibleCollision', 'deductibleComprehensive'],
            (deductibleCollision, deductibleComprehensive, schema) => {
              const validValues = [deductibleCollision.split('/')[0], deductibleComprehensive.split('/')[0]].filter(
                (value) => value !== 'NONE' && value
              );

              if (!validValues.length) return schema;
              const minValue = Math.min(...validValues.map((value) => value.split('/')[0]));

              return schema.min(500 + minValue, `Trailer value should be greater than ${500 + minValue}`);
            }
          )
          .required('Trailer value is required'),
        contents: Yup.number()
          .typeError('Content value should be a number between $0 - $5000')
          .positive('Content value should be between $0 - $5000')
          .integer('Content value should be between $0 - $5000')
          .min(0, 'Content value should be greater than $0')
          .max(5000, 'Content value should be less than or equal to $5000')
          .required('Trailer content value is required'),

        deductibleCollision: Yup.string()
          .requiredString('Collision deductible is required')
          .test(
            'minimumCollDeductibleMet',
            'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
            function (currentTrailerDeductibleCollision, { options }) {
              const { context, parent } = options;
              if (currentTrailerDeductibleCollision === 'NONE' && parent.deductibleComprehensive === 'NONE')
                return true;
              const { cars } = context;
              for (const car of cars) {
                if (
                  car.deductibleCollision === currentTrailerDeductibleCollision &&
                  car.deductibleComprehensive.split('/')[0] === parent.deductibleComprehensive.split('/')[0]
                ) {
                  return true;
                }
              }
              return false;
            }
          ),

        deductibleComprehensive: Yup.string()
          .requiredString('Comprehensive deductible is required')
          .test(
            'minimumCompDeductibleMet',
            'Trailer deductibles must match deductibles of at least one insured vehicle, or be NO COVERAGE for liability ONLY',
            function (currentTrailerDeductibleComprehensive, { options }) {
              const { context, parent } = options;
              if (currentTrailerDeductibleComprehensive === 'NONE' && parent.deductibleCollision === 'NONE')
                return true;
              const { cars } = context;
              for (const car of cars) {
                if (
                  car.deductibleComprehensive.split('/')[0] === currentTrailerDeductibleComprehensive.split('/')[0] &&
                  car.deductibleCollision === parent.deductibleCollision
                ) {
                  return true;
                }
              }
              return false;
            }
          ),
        garageLocation: Yup.object().shape({
          address: Yup.string()
            .requiredString('Address is required')
            .test('is-po-box', 'PO Box Addresses are invalid', (value) => {
              const pattern = new RegExp('\\b[p]*(ost)*\\.*\\s*[o|0]*(ffice)*\\.*\\s*b[o|0]x\\b', 'i');
              return !(pattern.test(value) || pattern.test(value));
            }),
          city: Yup.string().requiredString('City is required'),
          state: Yup.string().requiredString('State is required').validUSState('offer.trailers.garageLocation'),
          zip: Yup.string().requiredString('Zip code is required')
        })
      })
    ).nullable()
  });

// @TODO there is a way to programmatically update schemas, and this would having the more or less the same schema, but you need a workaround to support updating nested fields see  https://github.com/jquense/yup/issues/283#issuecomment-420279808
const checkoutValidationSchema = (session, affinityLookups) =>
  Yup.object().shape({
    isBix: Yup.boolean().nullable(),
    global: Yup.object().shape({
      affinity: Yup.string()
        .validAffinityCode('global.affinity', { affinityLookups, isAgency: session.isAgency })
        .nullable(),
      autoBillingDayOfMonth: Yup.number().nullable(),
      autoEffectiveDate: Yup.date().nullable(),
      autoPaymentMethod: Yup.string().requiredString(),
      autoPaymentType: Yup.string().requiredString(),
      billingDayOfMonth: Yup.number().nullable(),
      currentAutoCarrier: Yup.string().nullable(),
      currentHomeownersCarrier: Yup.string().nullable(),
      currentlyAutoInsured: Yup.boolean(),
      discountInventoryScore: Yup.boolean(),
      discountPaperless: Yup.boolean(),
      homeBillingDayOfMonth: Yup.number().nullable(),
      homeEffectiveDate: Yup.date()
        .test('min', 'Policy start date cannot be in the past or before the home purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('H')) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateHome =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateHome, date);
          }
          return true;
        })

        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('H')) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      homeownersPaymentMethod: Yup.string().requiredString(),
      homeownersPaymentType: Yup.string().requiredString(),
      personalPropertyProtection: Yup.boolean()
    }),
    primaryMortgageDetail: Yup.object()
      .shape({
        mortgageHolderName: Yup.string()
          .nullable()

          .test('required', 'Mortgage lender name is required to checkout', function (obj) {
            // the test passes if the payment method is not mortgage or if there's a value filled for mortgage holder name but we only need to check this in cases where the selected policy has a Home
            if (this.options.context?.selectedOption?.includes('H')) {
              return (
                this.options.context?.global?.homeownersPaymentMethod !== paymentMethod.Escrow ||
                this.options.context?.primaryMortgageDetail?.mortgageHolderName?.trim().length
              );
            }
            return true;
          }),
        loanNumber: Yup.string().nullable(),
        mortgageHolderAddress: Yup.object()
          .shape({
            address: Yup.string().nullable(),
            address2: Yup.string().nullable(),
            city: Yup.string().nullable(),
            state: Yup.string().nullable().validUSState('offer.mortgageHolderAddress.state'),
            zip: Yup.string().nullable()
          })
          .nullable()
      })
      .nullable()
  });

const effectiveDateSchema = Yup.object().shape({
  global: Yup.object()
    .shape({
      homeEffectiveDate: Yup.date()
        .test('min', 'Policy start date cannot be in the past or before the home purchase date', function (obj) {
          if (this.options.context?.selectedOption?.includes('H') && !this.options.context?.noBindHome) {
            const purchaseDate = this.options.context?.home?.purchaseDate;
            const purchaseDateToCompare = new Date(`${purchaseDate}T00:00`);
            const now = new Date();
            const minDateHome =
              purchaseDateToCompare && isAfter(purchaseDateToCompare, now)
                ? purchaseDateToCompare.setDate(purchaseDateToCompare.getDate() - 1)
                : now.setDate(now.getDate() - 1);
            const date = new Date(obj);
            return isBefore(minDateHome, date);
          }
          return true;
        })
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('H') && !this.options.context?.noBindHome) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        }),
      autoEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (this.options.context?.selectedOption?.includes('A') && !this.options.context?.noBindAuto) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('min', 'Policy start date cannot be in the past', function (obj) {
          if (this.options.context?.selectedOption?.includes('A') && !this.options.context?.noBindAuto) {
            const now = new Date();
            const date = new Date(obj);
            return isToday(date) || isBefore(now, date);
          }
          return true;
        }),
      rentersEffectiveDate: Yup.date()
        .test('max', 'Policy start date must be within the next 60 days', function (obj) {
          if (
            this.options.context?.selectedOption?.includes('R') &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date < maxDate;
          }
          return true;
        })
        .test('standalone-renters-min', 'Policy start date must be after 10/1/2022', function (obj) {
          if (
            this.options.context?.selectedOption?.includes('R') &&
            standaloneRentersStates[this.options.context.state]
          ) {
            const date = new Date(obj);
            return date >= standaloneRentersLimitDate;
          }
          return true;
        })
        .test('min', 'Policy start date cannot be in the past', function (obj) {
          if (this.options.context?.selectedOption?.includes('A') && !this.options.context?.noBindAuto) {
            const now = new Date();
            const date = new Date(obj);
            return isToday(date) || isBefore(now, date);
          }
          return true;
        })
    })
    .nullable()
});

export const buildSchema = ({
  formAction,
  includeConnectedHome,
  isAdvancedConnectedHome,
  needMVRs,
  session,
  affinityLookups
}) => {
  let schema =
    formAction === FormAction.Checkout && !needMVRs
      ? checkoutValidationSchema(session, affinityLookups)
      : offerValidationSchema(session, affinityLookups);

  if (includeConnectedHome) {
    schema = schema.concat(connectedHomeSchema(isAdvancedConnectedHome));
  }
  // If the offer is out of date (meaning the effective dates are in the past)
  // we don't want the validation to enforce the effective date rules, since the form's submit function
  // will automatically update the effective dates to today
  // This is because the validation will stop the form from being submitted if there are validation errors
  if (formAction !== FormAction.UpdateEffectiveDates) {
    schema = schema.concat(effectiveDateSchema);
  }
  return schema;
};
