import { useEffect, useMemo } from 'react';
import { lookupsJson, newCustomersDefaultDevices, monitoredStatusThatNeedProof } from '@ourbranch/lookups';
import { isEqual, find } from 'lodash-es';
import { useFormikContext } from 'formik';

import { emptyConnectedHome } from 'common/helpers/connected-home';
import { NEW_CUSTOMER_HOME_SECURITY, CONNECTED_HOME_MONITORED_TYPES } from 'core/helpers/constants';

const securityProviders = lookupsJson.securityProviders;

// security providers allowing new monitoring sign up
const providersAllowingSignUp = securityProviders.filter((provider) => provider.signUpThroughBranch);

// all of our security provider options but with a key of id and value that is needed for an autocomplete field type
const advancedConnectedHomeOptions = securityProviders.map((option) => ({ id: option.id, text: option.value }));

export const getMonitoredStatus = ({ isAdvancedConnectedHome, isMonitoredByPartner }) => {
  if (isMonitoredByPartner) {
    return isAdvancedConnectedHome
      ? CONNECTED_HOME_MONITORED_TYPES.ADVANCED.MONITORED_BY_PARTNER
      : CONNECTED_HOME_MONITORED_TYPES.REGULAR.CENTRAL;
  }

  return CONNECTED_HOME_MONITORED_TYPES.ADVANCED.MONITORED_BY_NON_PARTNER;
};

export const useHomeSecurityPartnerEffects = ({ isAdvancedConnectedHome }, affinityLookupsStore) => {
  const { setFieldValue, values, initialValues } = useFormikContext();
  const { isMonitoredByPartner, isHomeSecurityAffinity } = useConnectedHomeSecurityProviderHelpers(
    {
      isAdvancedConnectedHome
    },
    affinityLookupsStore
  );

  useEffect(() => {
    const monitored = getMonitoredStatus({ isAdvancedConnectedHome, isMonitoredByPartner });

    /**
     * Evaluate what the monitoring status/provider name should be ONLY IF provider name changes, or connected home gets toggled off then on.
     * This is because we don't want to evaluate/fix monitoring status automatically anytime anything on the policy changes.
     */
    const connectedHomeWasOff =
      initialValues.connectedHome?.monitored === 'N' ||
      !initialValues.connectedHome ||
      !initialValues.connectedHome.monitored;

    const connectedHomeNowOn = monitoredStatusThatNeedProof.includes(monitored);
    const modifiedProviderName = initialValues.connectedHome?.providerName !== values.connectedHome.providerName;

    if (modifiedProviderName || (connectedHomeWasOff && connectedHomeNowOn)) {
      setFieldValue('connectedHome.monitored', monitored);

      if (isHomeSecurityAffinity) {
        const affinityName = affinityLookupsStore.data.find(
          (partner) => partner.affinity === values?.global?.affinity
        )?.name;
        if (values?.connectedHome?.providerName !== affinityName) {
          setFieldValue('connectedHome.providerName', affinityName);
        }
      }
    }
  }, [
    isMonitoredByPartner,
    isAdvancedConnectedHome,
    values,
    setFieldValue,
    isHomeSecurityAffinity,
    affinityLookupsStore,
    initialValues
  ]);
};

export const useNewMonitoringServiceEffects = (affinityLookups) => {
  const { setFieldValue, values } = useFormikContext();
  const { canAffinityAllowNewCustomerSignUp, canProviderAllowNewCustomerSignup, isSigningUpForHomeSecurity } =
    useNewMonitoringServiceHelpers(affinityLookups);

  useEffect(() => {
    if (canProviderAllowNewCustomerSignup && isSigningUpForHomeSecurity) {
      const updatedConnectedHome = {
        ...values.connectedHome,
        ...newCustomersDefaultDevices[values.connectedHome.providerName]
      };
      if (!isEqual(updatedConnectedHome, values.connectedHome)) {
        setFieldValue('connectedHome', updatedConnectedHome);
      }
    }

    if (!canAffinityAllowNewCustomerSignUp && isSigningUpForHomeSecurity) {
      setFieldValue('global.homeSecurityPartnerCustomerType', NEW_CUSTOMER_HOME_SECURITY.NO_SIGN_UP_TYPE);
      setFieldValue('connectedHome.homeSecurityPartnerCustomerTypeOption', 'new');
    }
  }, [
    canProviderAllowNewCustomerSignup,
    canAffinityAllowNewCustomerSignUp,
    isSigningUpForHomeSecurity,
    values,
    setFieldValue
  ]);
};

export const useConnectedHomeSecurityProviderHelpers = ({ isAdvancedConnectedHome }, affinityLookupsStore) => {
  const { values } = useFormikContext();
  const affinity = useMemo(() => values?.global?.affinity, [values.global.affinity]);
  const isHomeSecurityAffinity = affinityLookupsStore.data.find((aff) => aff.affinity === affinity)?.data?.homeSecurity;

  const isHomeSecurityProvider = useMemo(
    () => find(securityProviders, (provider) => provider.value === values?.connectedHome?.providerName)?.isPartner,
    [values.connectedHome]
  );

  const isMonitoredByPartner = useMemo(
    () =>
      !emptyConnectedHome(values?.connectedHome) &&
      (isHomeSecurityAffinity ||
        isHomeSecurityProvider ||
        (!isAdvancedConnectedHome && values.connectedHome.providerName)),
    [values.connectedHome, isHomeSecurityAffinity, isHomeSecurityProvider, isAdvancedConnectedHome]
  );

  const disableSecurityProvider = useMemo(
    () => isHomeSecurityAffinity && !emptyConnectedHome(values?.connectedHome),
    [isHomeSecurityAffinity, values.connectedHome]
  );

  return { isHomeSecurityAffinity, isHomeSecurityProvider, isMonitoredByPartner, disableSecurityProvider };
};

export const useNewMonitoringServiceHelpers = (affinityLookupsStore) => {
  const { values } = useFormikContext();
  const affinity = useMemo(() => values?.global?.affinity, [values.global.affinity]);

  const canAffinityAllowNewCustomerSignUp = useMemo(() => {
    const affinityData = affinityLookupsStore.data.find((aff) => aff.affinity === affinity);
    return (
      !affinity || affinity === 'None' || affinityData?.data?.signUpThroughBranch || !affinityData?.data?.homeSecurity
    );
  }, [affinity, affinityLookupsStore]);

  const canProviderAllowNewCustomerSignup = useMemo(
    () =>
      !values?.connectedHome?.providerName ||
      find(securityProviders, (provider) => provider.value === values?.connectedHome?.providerName)
        ?.signUpThroughBranch,
    [values.connectedHome]
  );

  const homeSecurityCustomerType = useMemo(
    () =>
      values?.global?.homeSecurityPartnerCustomerType === NEW_CUSTOMER_HOME_SECURITY.SIGN_UP_TYPE ? 'new' : 'existing',
    [values.global]
  );

  return {
    canAffinityAllowNewCustomerSignUp,
    canProviderAllowNewCustomerSignup,
    isSigningUpForHomeSecurity: homeSecurityCustomerType === 'new',
    homeSecurityCustomerType
  };
};

export const useConnectedHomeSecurityProviders = ({ isAdvancedConnectedHome }, affinityLookupsStore) => {
  const { isSigningUpForHomeSecurity } = useNewMonitoringServiceHelpers(affinityLookupsStore);

  const securityProviderOptions = useMemo(() => {
    if (isSigningUpForHomeSecurity) {
      return providersAllowingSignUp;
    }

    if (isAdvancedConnectedHome) {
      return advancedConnectedHomeOptions;
    }

    return affinityLookupsStore.securityProviders;
  }, [isSigningUpForHomeSecurity, isAdvancedConnectedHome, affinityLookupsStore]);
  return securityProviderOptions;
};
