import React, { useCallback } from 'react';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import ScreenHeader from 'core/components/screen-header';

const CustomerHeader = ({ history }) => {
  const store = useStore();
  const { account: accountStore } = store;

  const titleText = () => {
    return accountStore.accountLoading ? 'Loading...' : `${accountStore.fname} ${accountStore.lname}`;
  };

  const title = titleText();

  const back = useCallback(() => {
    history.push(`/search/customers`);
  }, [history]);

  return <ScreenHeader title={title} onBack={back} />;
};

export default observer(CustomerHeader);
