import React from 'react';
import PropTypes from 'prop-types';
import { TableHeaderCell } from 'core';

const headerTypes = {
  select: (
    <TableHeaderCell key="select" columnId="select">
      To Select
    </TableHeaderCell>
  ),
  id: (
    <TableHeaderCell key="id" columnId="id">
      ID
    </TableHeaderCell>
  ),
  fname: (
    <TableHeaderCell key="fname" columnId="fname">
      First Name
    </TableHeaderCell>
  ),
  lname: (
    <TableHeaderCell key="lname" columnId="lname">
      Last Name
    </TableHeaderCell>
  ),
  address: (
    <TableHeaderCell key="address" columnId="address">
      Address
    </TableHeaderCell>
  ),
  city: (
    <TableHeaderCell key="city" columnId="city">
      City
    </TableHeaderCell>
  ),
  state: (
    <TableHeaderCell key="state" columnId="state">
      State
    </TableHeaderCell>
  ),
  zip: (
    <TableHeaderCell key="zip" columnId="zip">
      Zip
    </TableHeaderCell>
  ),
  phone: (
    <TableHeaderCell key="phone" columnId="phone">
      Phone
    </TableHeaderCell>
  ),
  created: (
    <TableHeaderCell key="created" columnId="created">
      Created
    </TableHeaderCell>
  )
};

const Header = ({ columnsToUse }) => {
  return columnsToUse.map((name) => headerTypes[name]);
};

Header.propTypes = {
  columnsToUse: PropTypes.arrayOf(
    PropTypes.oneOf(['select', 'id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'phone', 'created'])
  )
};

Header.defaultProps = {
  columnsToUse: ['id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'created']
};

export default Header;
