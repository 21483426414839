import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Button, Divider } from '@material-ui/core';
import Section from 'core/components/section';
import TermLifeModal from 'common/components/term-life-modal';
import useStyles from './additional-offers.styles';
import CheckIcon from 'core/assets/svg/check.svg';

const AdditionalOffers = () => {
  const {
    account: { policies },
    additionalOffers
  } = useStore();
  const policy = policies.getRecentPolicy();
  const webUserId = policy?.webUserId;
  useEffect(() => {
    if (webUserId && webUserId !== additionalOffers?.webUserId) {
      additionalOffers.getAdditionalOfferData(webUserId);
    }
  }, [webUserId]);
  const [showLifeModal, setShowLifeModal] = useState(false);
  const classes = useStyles();
  return (
    <>
      <Section title="Additional Offers" rightLabel="Total" rightValue="1 offer">
        <Card className={classes.card}>
          <div className={classes.cardTop}>
            <Label className={classes.label}>Term Life Insurance</Label>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => setShowLifeModal(true)}
            >
              View details
            </Button>
          </div>
          {additionalOffers.termLifeLastSubmitted && (
            <>
              <Divider className={classes.divider} />
              <div className={classes.bottomMessage}>
                <img src={CheckIcon} alt="check icon" />
                <Label className={classes.lowerLabel}>{additionalOffers.termLifeLastSubmitted}</Label>
              </div>
            </>
          )}
        </Card>
      </Section>
      {showLifeModal && <TermLifeModal open={showLifeModal} onClose={() => setShowLifeModal(false)} fromAccount />}
    </>
  );
};

export default observer(AdditionalOffers);
