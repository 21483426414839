import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { useStore } from 'core/store';
import SystemStatus from 'core/components/system-status';
import { LottieLoader } from '../loading';
import { AuthContext } from '../auth';
import Header from '../header/header';
import styles from './layout.styles';

const Layout = observer(({ children, classes }) => {
  const session = useContext(AuthContext);
  const { affinityLookups: affinityLookupsStore } = useStore();
  const isLoading = session.isLoggedIn && (affinityLookupsStore.loading || !affinityLookupsStore.data.length);
  // @note needs to be updated with a smarter handle of this list as the affinity lookups list size grows.
  // see my notes on affinityLookupsStore
  useEffect(() => {
    if (session.isLoggedIn && !affinityLookupsStore.data.length && !affinityLookupsStore.loading) {
      affinityLookupsStore.getAll();
    }
  }, [affinityLookupsStore, session]);

  return (
    <>
      <Header />
      {!isLoading && <SystemStatus />}
      <div className={classNames(classes.layout, { [classes.loading]: isLoading })}>
        {isLoading ? <LottieLoader /> : <div className={classes.container}>{children}</div>}
      </div>
    </>
  );
});

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Layout);
