import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

type IsFuturePaymentArgs = {
  effectiveDate: string;
  paymentMethod: string;
};

export function isFuturePayment({ effectiveDate, paymentMethod }: IsFuturePaymentArgs) {
  // Any Policy sold after midnight east coast will bill immediately
  const parsedEffectiveDate = dayjs.tz(effectiveDate, 'America/New_York').toDate();
  return parsedEffectiveDate > new Date() && ['C', 'E'].includes(paymentMethod);
}
