import uniqBy from 'lodash-es/uniqBy';

export const contactType = [
  { id: 'personal', value: 'Personal' },
  { id: 'work', value: 'Work' },
  { id: 'contact', value: 'Contact' }
];

export const policyTypes = [
  { id: 'A', value: 'Auto' },
  { id: 'H', value: 'Home' },
  { id: 'R', value: 'Renters' }
];

export const homeMultiPolicyDiscountOptions = [
  {
    id: 'N',
    value: 'None'
  },
  {
    id: 'S',
    value: 'Second Home'
  },
  {
    id: 'A',
    value: 'Auto'
  },
  {
    id: 'AR',
    value: 'Auto + Renters'
  },
  {
    id: 'ARS',
    value: 'Auto + Renters + Second Home'
  },
  {
    id: 'ARU',
    value: 'Auto + Renters + Umbrella'
  },
  {
    id: 'ARUS',
    value: 'Auto + Renters + Umbrella + Second Home'
  },
  {
    id: 'AS',
    value: 'Auto + Second Home'
  },
  {
    id: 'AU',
    value: 'Auto + Umbrella'
  },
  {
    id: 'AUS',
    value: 'Auto + Umbrella + Second Home'
  }
];

export const autoMultiPolicyDiscountOptions = [
  {
    id: 'N',
    value: 'None'
  },
  {
    id: 'R',
    value: 'Renters'
  },
  {
    id: 'HRU',
    value: 'Home + Renters + Umbrella'
  },
  {
    id: 'HR',
    value: 'Home + Renters'
  },
  {
    id: 'HU',
    value: 'Home + Umbrella'
  },
  {
    id: 'H',
    value: 'Home'
  }
];

/**
 *
 * @param {array} type one of the above list of types
 *
 * @returns {function} receives an Id and returns the value
 */
export const get = (type) => (id) => (type.find((item) => item.id === id) || {}).value;

export const formattedAffinityOptions = (affinityLookups, noneValue = 'None') => {
  const affinityOptions = affinityLookups.data
    .filter((partner) => !partner.data.hideFromOffers)
    .map((partner) => {
      return { id: partner.affinity, text: `${partner.name} - ${partner.affinity}`, affinityCode: partner.affinity };
    });
  affinityOptions.push({ id: 'None', value: noneValue });

  return uniqBy(affinityOptions, (affinity) => affinity.id);
};
