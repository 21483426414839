function roundTwoDecimals(num: number) {
  return Math.round(num * 1e2) / 1e2;
}

function getPB(premiumBreakdown: Record<string, any>, premium: number) {
  console.log(`setting PB w/ premium ${premium}`);
  premiumBreakdown.umbrella = roundTwoDecimals(
    Number(premiumBreakdown.umbrella || premiumBreakdown.personal_umbrella_liability_total || 0)
  );
  premiumBreakdown.spp = roundTwoDecimals(Number(premiumBreakdown.spp || premiumBreakdown.spp_total || 0));
  premiumBreakdown.home = roundTwoDecimals(Number(premium) - (premiumBreakdown.umbrella + premiumBreakdown.spp));
  console.log(`returning with home of ${premiumBreakdown.home}`);
  return premiumBreakdown;
}

export function fixHomePremiums(policy: Record<string, any>) {
  // adjust main:
  policy.premiumBreakdown = getPB(policy.premiumBreakdown, policy.premium);
  const policyDays = policy.segments.reduce((acc: number, c: Record<string, any>) => {
    return acc + (c.segmentDays || 0);
  }, 0);

  const effdateyyyy = Number(String(policy.effectiveDate).replace(/-/g, ''));
  const renewalChangeStartDate = effdateyyyy + 10000;

  for (const segment of policy.segments) {
    const sdateyyyy = Number(String(segment.startDate).replace(/-/g, ''));
    if (sdateyyyy !== renewalChangeStartDate) {
      if (!segment.premium && segment.dailyRate) {
        segment.premium = roundTwoDecimals(segment.dailyRate * policyDays);
        console.log(
          `setting segment.premium to ${segment.premium} using ${segment.dailyRate} daily rate and ${policyDays} policy days`
        );
      }
      if (!segment.segmentPremium && segment.dailyRate && segment.segmentDays) {
        segment.segmentPremium = segment.dailyRate * segment.segmentDays;
        console.log(`setting segment.segmentPremium from dailyRate and days`);
      }
      segment.premiumBreakdown = getPB(segment.premiumBreakdown, segment.premium);
      segment.segmentPremiumBreakdown = getPB(segment.segmentPremiumBreakdown, segment.segmentPremium);
    }
  }
  return policy;
} // fixHomePremiums
