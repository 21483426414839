import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  cardContainer: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(8)
  },
  title: {
    fontSize: 14,
    color: theme.colorPalette.green_5,
    fontWeight: 400
  },
  separator: {
    width: '100%',
    margin: theme.spacing(4, 0),
    borderBottom: `1px solid ${theme.colorPalette.beige_10_op_60}`
  },
  titleErrored: {
    color: theme.colors.red
  }
});

export default makeStyles(styles);
