import gql from 'graphql-tag';

export const OFFER_FRAGMENT = gql`
  fragment OfferFragment on Offer {
    id
    clusterId
    code
    webUserId
    logrocketSessionURL
    quote {
      fname
      lname
      email
      phone
      additionalPhoneNumbers {
        phoneNumber
        note
        canText
      }
      inviter {
        id
        firstName
        lastName
      }
      selectedOption
      fromStaff
      rep
      isAgentSold
      leadSource
      noBindHome
      noBindAuto
      isBix
      includeUmbrella
      includeRenters
      includeEarthquake
      includeFlood
      existingCustomer {
        id
      }
      offerings {
        autoRejectCode
        monolineAutoRejectCode
        homeownersRejectCode
        rentersRejectCode
        offerAuto
        offerUmbrella
        offerRenters
        offerHomeowners
        offerWaterBackup
      }
      correctedAddress {
        address
        address2
        city
        state
        zip
      }
      priorAddress {
        address
        address2
        unit
        city
        state
        zip
      }
      connectedHome {
        monitored
        moistureDevices
        theftPreventionDevices
        motionDetectingDevices
        smokeDetectingDevices
        autoWaterShutoffDevices
        providerName
        agreeToTerms
      }
      auto {
        hasSetPip
        householdMembers
        pipResidentsWithQHC
        pipExcludedResidents
        pipAllResidents
        pipEveryoneOnSamePlan
        pipHealthInsuranceType
        pipAdditionalResidents
      }
      home {
        basementType
        basementSqFt
        constructionType
        county
        exteriorWallType
        garageCarCapacity
        garageType
        homeQuality
        numFamiliesInDwelling
        numFireplaces
        numFullBathrooms
        numHalfBathrooms
        numOccupants
        numStories
        numWoodBurningStoves
        ownershipStatus
        purchaseDate
        roofShape
        roofType
        roofYear
        roofDeck
        roofCover
        roofDeckAttachment
        roofToWallConnection
        openingProtection
        roofSWR
        sqFt
        typeOfHome
        yearBuilt
        latitude
        longitude
        fireHydrantWithin1000ft
        fpc
        fpcFromLocation
        wildfireHazardScore
        dtoName
        dtbowName
        dtbowLabel
        windstormMitigationLevel
        stormShutters
        homeLocation {
          address
          address2
          city
          state
          zip
        }
        replacementCostEstimate {
          total
          itemized {
            calculatedValue
            type
            desc
          }
        }
        mortgageDetails {
          loanNumber
          mortgageHolderName
          mortgageHolderAddress {
            address
            address2
            country
            city
            state
            zip
          }
          primary
        }
      }
      cars {
        addDate
        VIN
        year
        make
        model
        bodyType
        assignedDriver
        primaryUse
        purchaseDate
        deductibleCollision
        deductibleComprehensive
        coverageLoan
        limitUMPD
        limitRental
        limitACPE
        limitIncomeLoss
        roadsideAssistance
        rideSharing
        aebStatus
        aebConfirmed
        antiTheftStatus
        antiTheftConfirmed
        blindSpotStatus
        blindSpotConfirmed
        singleAirbagStatus
        singleAirbagConfirmed
        doubleAirbagStatus
        doubleAirbagConfirmed
        luxury
        lastSevereIncidentDate
        annualMilesDriven
        garageLocation {
          address
          country
          city
          state
          zip
        }
        symbolMake
        symbolModel
        symbolStyle
        symbolAux
      }
      trailers {
        VIN
        type
        year
        value
        contents
        purchaseDate
        deductibleCollision
        deductibleComprehensive
        garageLocation {
          address
          country
          city
          state
          zip
        }
        confirmed
        coverageLoan
        addDate
      }
      autoCoverage {
        policyLimitBIPD
        policyLimitUMBI
        policyLimitUIMBI
        policyLimitMedicalPayments
        policyLimitUMPD
        policyLimitPIP
        policyLimitLPD
        petInjury
        policyLimitPIPME
        policyLimitPIPWL
        policyLimitPIPACR
        policyLimitIncomeLoss
        policyLimitAccidentalDeath
        policyLimitFuneralBenefits
        policyLimitExtraMedBenefits
        policyLimitTortOption
        policyLimitComboFBP
        policyLimitGuestPIP
        policyLimitNoFaultPIP
        uimAddedOn
      }
      homeCoverage {
        coverageA
        coverageIBSC
        coverageBPctOfA
        coverageBCPctOfA
        coverageCPctOfA
        coverageDPctOfA
        coverageX
        coverageY
        coverageMS
        coverageMSALE
        coverageWaterBackup
        coverageBuildingMaterialsTheft
        coverageCourseOfConstruction
        coverageExtendedPremises
        coverageFairRental
        coveragePetBite
        coverageRSExtended
        coverageYardAndGarden
        coverageSinkhole
        coverageOilStorageTank
        coverageBP
        coverageCA
        coverageDC
        coverageDR
        coverageF
        coverageG
        coverageJ
        coverageMI
        coverageSP
        coverageST
        coverageEBSL
        homeownerProtection
        deductibleAllOther
        deductibleHurricane
        deductibleWindHail
        deductibleWaterBackup
        windHailExclusion
        minimumDeductibleValue
        minimumWindHailDeductibleValue
        interestedInSeparateWindHail
        externalPolicyId
      }
      homeViolations {
        AAF
        CMP
        D
        E
        F
        G
        MAJ
        MIN
        NAF
      }
      homeClaimsForUW {
        amount
        lossTypeCode
        lossDate
      }
      homeClaimDates
      homeViolationPeople
      homeViolationDates
      homeSingleViolationDates
      drivers {
        id
        pipEligible
        waivedPIPWL
        pipOptOut
        pipHasQHC
        postBindMVR
        addDate
        assignedCar
        autoViolationDates
        communityDriveParticipation
        isPrimary
        isCoApplicant
        firstName
        middleName
        lastName
        gender
        maritalStatus
        highestEducation
        age
        dateOfBirth
        driversLicenseState
        driversLicenseNumber
        ageFirstLicensed
        fullTimeStudent
        goodStudent
        takenCourse
        courseDiscountConditionCheck
        excludeDriver
        schoolName
        schoolZip
        needSR22
        schoolLocation {
          address
          address2
          country
          city
          state
          zip
        }
        insuranceScore {
          homeTotal
          autoTotal
          total
        }
        autoViolationsForUW {
          AAF
          AFM
          ANC
          ANO
          ASW
          BOT
          CCW
          CML
          CMP
          CMU
          CRD
          DEQ
          DEV
          DR
          DWI
          FAR
          FDL
          FEL
          FFR
          FLE
          FRA
          FTC
          FTY
          HOM
          IBK
          IP
          IT
          LDL
          LIC
          LTS
          MAJ
          MMV
          NAF
          NFX
          PUA
          PUN
          REF
          RKD
          SAF
          SCH
          SLV
          SPD
          SUS
          TMP
          WSR
        }
        autoViolations {
          AAF
          AFM
          ANC
          ANO
          ASW
          BOT
          CCW
          CML
          CMP
          CMPTV
          CMU
          CRD
          DEQ
          DEV
          DR
          DWI
          FAR
          FDL
          FEL
          FFR
          FLE
          FRA
          FTC
          FTY
          HOM
          IBK
          IP
          IT
          LDL
          LIC
          LTS
          MAJ
          MMV
          NAF
          NFX
          PUA
          PUN
          REF
          RKD
          SAF
          SCH
          SLV
          SPD
          SUS
          TMP
          UDR
          WSR
        }
      }
      buyUp {
        homeCoverage {
          coverageX {
            buyUps {
              value
              buyUp
            }
          }
        }
        rentersCoverage {
          coverageX {
            buyUps {
              value
              buyUp
            }
          }
        }
      }
      global {
        affinity
        rateControlDate
        employeeDiscount
        priorCancelNonPay
        ## home
        homeEffectiveDate
        homeownersPaymentType
        homeownersPaymentMethod
        personalPropertyProtection
        currentHomeownersCarrier
        currentHomeownersCarrierPolicyNumber
        homeBillingDayOfMonth
        ## auto
        autoEffectiveDate
        discountInventoryScore
        discountPaperless
        autoPaymentType
        autoPaymentMethod
        currentlyAutoInsured
        currentAutoCarrier
        currentAutoCarrierStartDate
        currentAutoCarrierEndDate
        currentAutoCarrierPolicyNumber
        continuousAutoCoverageStartDate
        mostRecentAutoInsuranceCoverageEndDate
        billingDayOfMonth
        autoBillingDayOfMonth
        homeSecurityPartnerCustomerType
        ## renters
        rentersEffectiveDate
        rentersPaymentType
        rentersPaymentMethod
        rentersBillingDayOfMonth
        currentRentersCarrier
        currentRentersCarrierPolicyNumber
        breakupWithRenters

        ## cv data
        receivedCVData
        hadInforceCVPolicy
        priorIndividualBILimit
        priorOccurrenceBILimit
        priorUmIndividualBodilyInjury
        priorUmOccurrenceBodilyInjury
        priorDrivers {
          firstName
          lastName
        }
        priorCars {
          VIN
          year
          make
          model
          limitRental
          deductibleCollision
          deductibleComprehensive
          roadsideAssistance
        }
      }
      rentersCoverage {
        rentersLocation {
          address
          address2
          country
          city
          state
          zip
        }
        coverageCD
        coverageX
        coverageY
        deductible
      }
      umbrellaCoverage {
        numVehiclesTotal
        numLicensedDriversUnder25
        numMotorcyclesScooters
        numATVs
        numWatercraft
        numPersonalWatercraft
        watercraftHullLengths
        numRVs
        numRentalProperties
        numOtherProperties
        liabilityCoverageLimit
        limitBIOccurrence
        uninsuredMotoristLimit
        rentalPropertyAddresses {
          address
          address2
          country
          city
          state
          zip
        }
        otherPropertyAddresses {
          address
          address2
          country
          city
          state
          zip
        }
      }
      scheduledPersonalProperty {
        deductible
        items {
          category
          description
          value
          breakageOption
          exTheftOption
        }
      }
      earthquakeCoverage {
        policyId
      }
      floodCoverage {
        policyId
      }
      preBindUWCheck {
        homeVerification {
          signedPropertyUWAttestation
          ineligibleForHomeDueToUWSelfReport
          noAsbestosCheck
          homeWiringCheck
          homePlumbingCheck
          noOpenFoundationCheck
        }
        attestationAgent
      }
    }
    options {
      id
      name
      annual
      monthly
      type
      autoBill
      autoPremium
      autoTotal
      homeBill
      homeTotal
      homePremium
      rentersBill
      rentersTotal
      rentersPremium
      homeFees {
        type
        description
        amount
      }
      autoFees {
        type
        description
        amount
      }
      rentersFees {
        type
        description
        amount
      }
      autoCoverages {
        type
        amount
        iteration
      }
      homeCoverages {
        type
        amount
      }
      rentersCoverages {
        type
        amount
      }
      alternateAutoOptionsByBIPD {
        bi {
          id
          value
        }
      }
      floodRates {
        options {
          deductible
          premium
          totalAmount
        }
        fees {
          amount
          type
        }
        caseLink
      }
      eqRates {
        options {
          deductible
          premium
          totalAmount
        }
        fees {
          amount
          type
        }
        caseLink
      }
    }
  }
`;
