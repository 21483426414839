type Coverages = {
  coverages: string[];
  includeOnlyIfPresent: string[];
  from: Record<string, any>[];
};

export function addCoveragesToPremiumBreakdown(
  premiumBreakdown: Record<string, any>,
  { coverages: coveragesToAdd, includeOnlyIfPresent: coveragesToOmitWhenEmpty, from: sourceCoverages }: Coverages
) {
  // save all coverages in list, summed globally
  for (const coverage of coveragesToAdd) {
    const foundMatchingCoverages = sourceCoverages.filter((c) => c.type.toLowerCase() === coverage.toLowerCase());
    const ctowe = !Array.isArray(coveragesToOmitWhenEmpty) ? [] : coveragesToOmitWhenEmpty;
    if (!ctowe.includes(coverage) || foundMatchingCoverages.length > 0) {
      premiumBreakdown[coverage.toLowerCase()] = foundMatchingCoverages.reduce((acc, curr) => acc + curr.amount, 0);
    }
  }

  // save iteration-based coverages
  for (const coverageObj of sourceCoverages) {
    if (coverageObj.iteration && coveragesToAdd.includes(coverageObj.type.toUpperCase())) {
      premiumBreakdown[`it${coverageObj.iteration}-${coverageObj.type.toLowerCase()}`] = coverageObj.amount;
    }
  }
} // addCoveragesToPremiumBreakdown
