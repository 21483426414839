import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import uuidv4 from 'uuid/v4';
import { differenceInYears } from 'date-fns';
import { Formik, Form as FormikForm, validateYupSchema, yupToFormErrors } from 'formik';
import * as Yup from 'yup';
import { Button } from '@material-ui/core';

import Form from 'core/components/form';
import useStyles from './add-form.styles';

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required!'),
  lastName: Yup.string().required('Last Name is required'),
  dateOfBirth: Yup.string().required('Date of birth is required!')
});

function AddForm({ onAdd, label, disabled, addContent, currentPeopleOrDrivers }) {
  const classes = useStyles();

  const validate = useCallback(
    (values) => {
      try {
        const errors = {};
        if (values.firstName && values.lastName && values.dateOfBirth) {
          const today = new Date();
          const birthDate = new Date(values.dateOfBirth);
          const age = differenceInYears(today, birthDate);
          const existingPerson = currentPeopleOrDrivers?.find(
            (d) =>
              values.firstName.toUpperCase() === d.firstName.toUpperCase() &&
              values.lastName.toUpperCase() === d.lastName.toUpperCase() &&
              age === d.age
          );

          if (existingPerson) {
            errors.existingDriver = existingPerson.excludeDriver
              ? "We're sorry, this driver is not eligible to be covered by Branch."
              : "Oops! It looks like you've already added this person.";
            errors.firstName = true;
            errors.lastName = true;
            errors.dateOfBirth = true;
            return errors;
          }
        }
        validateYupSchema(values, validationSchema, true);
        return errors;
      } catch (err) {
        return yupToFormErrors(err);
      }
    },
    [currentPeopleOrDrivers]
  );

  return (
    <Formik
      initialValues={{
        id: uuidv4(),
        firstName: '',
        lastName: '',
        dateOfBirth: new Date(),
        isCoApplicant: false,
        isPrimary: false
      }}
      validate={validate}
      onSubmit={onAdd}
    >
      {({ errors }) => (
        <>
          <FormikForm className={classes.container}>
            {addContent}
            <Form
              disabled={disabled}
              data={[
                {
                  component: Grid,
                  props: {
                    key: 'container',
                    container: true,
                    justify: 'space-around',
                    alignItems: 'center',
                    spacing: 2
                  },
                  children: [
                    {
                      id: 'firstName',
                      type: 'string',
                      label: 'First Name',
                      mode: 'light',
                      width: 3
                    },
                    {
                      id: 'lastName',
                      type: 'string',
                      label: 'Last Name',
                      mode: 'light',
                      width: 3
                    },
                    {
                      id: 'dateOfBirth',
                      name: 'dateOfBirth',
                      type: 'date',
                      label: 'Date of Birth',
                      mode: 'light',
                      width: 3
                    },
                    {
                      component: Grid,
                      props: {
                        item: true,
                        xs: 3,
                        key: 'btn'
                      },
                      children: [
                        {
                          type: 'submit',
                          component: Button,
                          props: {
                            key: 'addButton',
                            mode: 'big',
                            children: label || 'Add People',
                            className: classes.button,
                            type: 'submit',
                            variant: 'contained',
                            color: 'secondary'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]}
            />
            {errors.existingDriver && <span className={classes.error}>{errors.existingDriver}</span>}
          </FormikForm>
        </>
      )}
    </Formik>
  );
}

AddForm.propTypes = {
  onAdd: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  addContent: PropTypes.node
};

AddForm.defaultProps = {
  disabled: false,
  addContent: null
};

export default AddForm;
