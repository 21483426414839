import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { lookupsJson, standaloneRentersStates } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import { LabelTooltip } from 'core/components/label-tooltip';
import { tooltipHoverTexts } from 'core/helpers/constants';

import useStyles from './renters-coverage.styles';

const id = 'rentersCoverage';

const RentersCoverage = ({ state }) => {
  const liabilityOptions = standaloneRentersStates[state]
    ? lookupsJson.rentersLiability.filter((x) => +x.id <= 300000)
    : lookupsJson.rentersLiability;

  const classes = useStyles();
  return (
    <Grid container justify="space-around" alignItems="flex-start" spacing={4} className={classes.containerDark}>
      <Grid item xs={6}>
        <LabelTooltip
          label="Personal Liability Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.personalLiabilityCoverage }}
        >
          <FormField
            mode="dark"
            name={`${id}.coverageX`}
            id={`${id}.coverageX`}
            options={liabilityOptions}
            type="select"
          />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="PP/AL Expense Coverage"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.PPALExpenseCoverage }}
        >
          <FormField
            mode="dark"
            name={`${id}.coverageCD`}
            id={`${id}.coverageCD`}
            options={lookupsJson.coverageCD}
            type="select"
          />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="Deductible"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.deductibleAllOther }}
        >
          <FormField
            mode="dark"
            name={`${id}.deductible`}
            id={`${id}.deductible`}
            options={[
              { id: '500', value: '500' },
              { id: '1000', value: '1000' }
            ]}
            type="select"
          />
        </LabelTooltip>
      </Grid>

      <Grid item xs={6}>
        <LabelTooltip
          label="Guest Medical Limit"
          tooltip={{ label: 'More Info', onHoverText: tooltipHoverTexts.guestMedicalLimit }}
        >
          <FormField
            mode="dark"
            name={`${id}.coverageY`}
            id={`${id}.coverageY`}
            options={[
              { id: '1000', value: '1000' },
              { id: '2500', value: '2500' },
              { id: '5000', value: '5000' }
            ]}
            type="select"
          />
        </LabelTooltip>
      </Grid>
    </Grid>
  );
};

RentersCoverage.propTypes = {
  state: PropTypes.string.isRequired
};

export default RentersCoverage;
