import React, { useContext, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { policyTypes } from '@ourbranch/policy-types';
import { AuthContext } from 'core/components/auth';
import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';

import useStyles from './reset-UW-form-modal.styles';

const ResetUWFormModal = observer(({ setShowUWResetModal }) => {
  const {
    offer: { offer, removeFromPreBindUWRejections, setSelectedOption }
  } = useStore();
  const {
    user: { username }
  } = useContext(AuthContext);
  const { handleSubmit, setFieldValue, setFieldTouched } = useFormikContext();

  const { isAgency } = useContext(AuthContext);
  const classes = useStyles(isAgency);

  const hasAuto = offer?.quote?.offerings?.offerAuto;

  const onClick = useCallback(async () => {
    await removeFromPreBindUWRejections(true, false, username);
    await setFieldValue('preBindUWCheck.homeVerification', null);
    await setFieldValue('selectedOption', hasAuto ? policyTypes.HABundle : policyTypes.Home);
    await setFieldTouched('preBindUWCheck.homeVerification', true);
    await setFieldTouched('selectedOption', true);
    setSelectedOption(hasAuto ? policyTypes.HABundle : policyTypes.Home);

    handleSubmit();
    setShowUWResetModal(false);
  }, [removeFromPreBindUWRejections, setSelectedOption, hasAuto, setFieldValue, setFieldTouched]);

  return (
    <BaseDialog size="lg" onClose={() => setShowUWResetModal(false)} open>
      <Label className={classes.mainLabel} type="greenBig">
        Reset the underwriting check?
      </Label>
      <Label className={classes.notificationContent}>
        This will reset the policy to what it was before the underwriting check. Any data entered during the
        underwriting check will be lost.
      </Label>
      <Grid className={classes.buttonContainer}>
        <Button className={classes.buttons} variant="contained" color="primary" onClick={onClick}>
          Yes, reset underwriting check
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowUWResetModal(false)}
          className={classes.buttons}
        >
          No, keep
        </Button>
      </Grid>
    </BaseDialog>
  );
});

export default ResetUWFormModal;
