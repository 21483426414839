import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import useStyles from './option-not-available.styles';

const OptionNotAvailable = observer(({ optionNotAvailable }) => {
  const classes = useStyles();
  const history = useHistory();
  const { offer: offerStore } = useStore();

  const onClick = () => {
    history.push(`/offer/${offerStore.offer.id}`);
  };

  return (
    <BaseDialog open={optionNotAvailable} size="lg">
      <Label type="greenBig" className={classes.modalHeader}>
        This option is not available for checkout
      </Label>
      <Label type="infoSubtitle" className={classes.modalContent}>
        Please return to the offer and select an available policy type.
      </Label>
      <Grid className={classes.modalBtnContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classNames(classes.modalCloseButton, classes.btn)}
          onClick={onClick}
        >
          Go back to offer
        </Button>
      </Grid>
    </BaseDialog>
  );
});

OptionNotAvailable.propTypes = {
  optionNotAvailable: PropTypes.bool.isRequired
};

OptionNotAvailable.defaultProps = {
  optionNotAvailable: false
};

export default OptionNotAvailable;
