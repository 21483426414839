import React, { useContext } from 'react';
import { observer } from 'mobx-react';
import { CircularProgress, Card, Grid } from '@material-ui/core';
import { connect } from 'formik';
import { getSnapshot } from 'mobx-state-tree';

import { useStore } from 'core/store';
import { useErrors } from 'quote/helpers/error-hooks';
import { AuthContext } from 'core/components/auth';
import PolicyErrors from './components/error/policy-errors';
import { Additionals } from './components/additionals';
import { DiscountsCard } from './components/discounts-card';
import { AdditionalOffersCard } from './components/additional-offers';
import { PriceDetailsCard } from './components/price-details-card';
import { PolicyPickerCard } from './components/policy-picker-card';
import { Downloads } from './components/downloads';

import useStyles from './sidebar.styles';

const Sidebar = observer(() => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const { isAgency, isExternalSales } = useContext(AuthContext);
  const getErrorsAndRejections = (code) => code >= 4003;
  const errorsAndRejections = useErrors(getSnapshot(store.errors), getErrorsAndRejections);

  const isLoading = store.loading || !store.offer;

  if (isLoading) {
    return (
      <>
        <div className={classes.columnHeader} />
        <Card className={classes.container}>
          <Grid container className={classes.loaderContainer} alignItems="center" justify="center">
            <CircularProgress className={classes.loader} />
          </Grid>
        </Card>
      </>
    );
  }

  return (
    <div className={classes.sidebar}>
      <div className={classes.columnHeader} />
      <PolicyPickerCard />
      <PriceDetailsCard />
      <DiscountsCard />
      {store.shouldShowAdditionalPaymentsSection && <Additionals />}
      {errorsAndRejections.length > 0 && <PolicyErrors />}
      {!store?.offer?.isAgentSold && !isAgency && !isExternalSales && <AdditionalOffersCard />}
      <Downloads />
    </div>
  );
});

export default connect(Sidebar);
