import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  header: {
    marginBottom: 40,
    flexWrap: 'nowrap'
  },
  container: {
    position: 'relative'
  },
  iconButtonRoot: {
    position: 'absolute',
    color: theme.colors.button__background_secondary,
    left: -48,
    padding: 0
  },
  iconButtonLabel: {
    height: 48,
    width: 48
  },
  icon: {
    height: 48,
    width: 48,
    transform: 'rotate(180deg)'
  },
  rightLabel: {
    fontSize: '14px',
    display: 'inline',
    marginRight: 8
  },
  madeByStaff: {
    textTransform: 'uppercase',
    display: 'inline',
    marginLeft: 16
  },
  rightValue: {
    fontSize: '14px',
    display: 'inline'
  },
  rightContainer: {
    marginTop: 10
  },
  rightContainerNoLabel: {
    marginTop: 15
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  subtitle: {
    paddingRight: 10
  },
  additionalLink: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
});

export default makeStyles(styles);
