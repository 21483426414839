import React, { useContext, useState, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { AuthContext } from 'core/components/auth';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { getYear } from 'date-fns';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { Paper } from 'core/components/paper';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { useToast } from 'core/components/toast';
import { dateFormatter } from 'core/helpers/formatters';
import useStyles from './property-inspection.styles';

const INSPECTION_STATUS = {
  COMPLETED: 'Complete',
  IN_PROGRESS: 'Ordered',
  NOT_ORDERED: 'Not ordered',
  FAILED: 'Failed'
};

const Icon = ({ status, classes }) => {
  if (status === INSPECTION_STATUS.IN_PROGRESS) {
    return <AccessTimeIcon className={classes.inProgressIcon} />;
  }
  if (status === INSPECTION_STATUS.FAILED) {
    return <HighlightOffIcon className={classes.failedIcon} />;
  }
  if (status === INSPECTION_STATUS.COMPLETED) {
    return <CheckCircleOutlineIcon className={classes.completedIcon} />;
  }
  return <></>;
};

Icon.propTypes = {
  status: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired
};

const PropertyInspection = () => {
  const classes = useStyles();
  const { isService, isAgency } = useContext(AuthContext);
  const [ordering, setOrdering] = useState(false);
  const toast = useToast();

  const {
    account: {
      fname,
      lname,
      phoneNumber,
      mailingAddress,
      policies: {
        policy: { inspection, orderInspection, policy, loading, policyDetails }
      }
    }
  } = useStore();

  const order = useCallback(async () => {
    setOrdering(true);
    const { address, address2, city, state, zip } = policy.offer.quote.correctedAddress;
    const { __typename, ...correctedMailingAddress } = mailingAddress;
    const currentHomeDetails = policyDetails;
    const homeAge = policyDetails.home.yearBuilt ? getYear(new Date()) - currentHomeDetails?.yearBuilt : undefined;

    const requestParams = {
      policyId: policy.id,
      address: { address, address2, city, state, zip },
      mailingAddress: correctedMailingAddress,
      fname,
      lname,
      phone: phoneNumber,
      coverageA: policy?.offer?.quote?.homeCoverage?.coverageA,
      effectiveDate: policy?.offer?.quote?.global?.homeEffectiveDate,
      wildfireHazardScore: currentHomeDetails?.wildfireHazardScore,
      homeAge
    };

    const res = await orderInspection(requestParams);
    if (res?.inspection?.error) {
      toast.notify({ type: 'error', message: res.inspection.error });
    } else {
      toast.notify({ type: 'success', message: `Inspection successfully ordered.` });
    }

    setOrdering(false);
  }, [policy, orderInspection, fname, lname, phoneNumber, mailingAddress, toast]);

  const getClassName = (status) => {
    return status === INSPECTION_STATUS.COMPLETED
      ? classes.completed
      : status === INSPECTION_STATUS.FAILED
      ? classes.failed
      : status === INSPECTION_STATUS.IN_PROGRESS
      ? classes.inProgress
      : classes.notOrdered;
  };

  return (
    <Paper className={classes.cardContainer}>
      {loading ? (
        <Grid container alignItems="center">
          <Loading noBackground />
        </Grid>
      ) : (
        <>
          <Grid container spacing={4} className={classes.inspectionInfoContainer}>
            {(inspection.status === INSPECTION_STATUS.COMPLETED ||
              inspection.status === INSPECTION_STATUS.IN_PROGRESS) && (
              <Grid container>
                <Grid item xs={4}>
                  <Label type="infoLabel">Order #</Label>
                  <Label type="infoValue">{inspection.caseNumber}</Label>
                </Grid>
                {(inspection.status === INSPECTION_STATUS.IN_PROGRESS ||
                  inspection.status === INSPECTION_STATUS.FAILED) && (
                  <Grid item xs={4}>
                    <Label type="infoLabel">Ordered Date</Label>
                    <Label type="infoValue">{dateFormatter(inspection.dateOrdered)}</Label>
                  </Grid>
                )}
                {inspection.status === INSPECTION_STATUS.COMPLETED && (
                  <Grid item xs={4}>
                    <Label type="infoLabel">Completed Date</Label>
                    <Label type="infoValue">{dateFormatter(inspection.dateCompleted)}</Label>
                  </Grid>
                )}
              </Grid>
            )}

            <Grid>
              <Label type="infoLabel">Status</Label>
              <Grid
                container
                className={classnames(classes.status, getClassName(inspection.status))}
                alignItems="center"
              >
                <Icon status={inspection.status} classes={classes} />
                <Label type="infoValue">{inspection.status}</Label>
              </Grid>
            </Grid>
          </Grid>
          {isService && !isAgency && inspection.status === INSPECTION_STATUS.NOT_ORDERED && (
            <Button variant="contained" color="secondary" className={classes.btn} onClick={order} disabled={ordering}>
              <Label>{ordering ? 'Ordering...' : 'Order Inspection'}</Label>
            </Button>
          )}
        </>
      )}
    </Paper>
  );
};

export default PropertyInspection;
