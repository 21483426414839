import { useFormikContext, getIn } from 'formik';
import { Grid, Paper } from '@material-ui/core';
import { useLocalStore, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext, useRef } from 'react';
import Autosuggest from 'react-autosuggest';
import throttle from 'lodash-es/throttle';

import { AuthContext } from 'core/components/auth';
import { TextField } from 'core/components/text-field';
import { useDisabledState } from 'common/disabled-context/context';
import SmartyStreetsAPI from 'core/helpers/smartyStreetsAPI';
import useStyles from './address-autocomplete.styles';
import Suggestion from './suggestion';

function AddressAutocomplete({ id, name, mode, xs, label }) {
  const session = useContext(AuthContext);
  const classes = useStyles();
  const { disabled } = useDisabledState();
  const {
    setTouched,
    setValues,
    setFieldTouched,
    setFieldValue,
    values: formValues,
    touched,
    errors
  } = useFormikContext();
  // change potential initial value based on id sent in

  let initialValue = '';

  if (id === 'priorAddress.address') {
    initialValue = formValues?.priorAddress?.address || '';
  } else if (id === 'rentersCoverage.rentersLocation.address') {
    // accounting for addresses saved as weird object-strings - eventually we could get rid of this when no more of these exist
    // ex. "{city=Columbus, state=OH, zip=43202, address=123 Sesame St.}"
    const storedAddress = formValues?.rentersCoverage?.rentersLocation?.address;
    if (storedAddress?.includes('{')) {
      const parsedAddress = storedAddress.slice(storedAddress.indexOf('address')).split('=')[1];
      initialValue = parsedAddress.slice(0, parsedAddress.length - 1);
    } else {
      initialValue = storedAddress;
    }
  } else {
    initialValue = formValues?.address || '';
  }

  const store = useLocalStore(() => ({
    suggestions: [],

    value: initialValue,

    clearSuggestions() {
      store.suggestions = [];
    },

    setValue(newValue) {
      store.value = newValue;
    },

    async search({ value }) {
      const newSuggestions = await SmartyStreetsAPI.search(value, session.allowedStates);
      store.suggestions = newSuggestions;
    }
  }));

  const onChange = (_, { newValue }) => {
    setFieldTouched(id);
    setFieldValue(id, newValue);
    store.setValue(newValue);
  };

  const onSuggestionSelected = (_, { suggestion }) => {
    if (id === 'priorAddress.address') {
      const priorAddress = {
        address: suggestion.streetLine,
        unit: suggestion.secondary ? suggestion.secondary : undefined,
        city: suggestion.city,
        state: suggestion.state,
        zip: suggestion.zipcode
      };
      setValues({
        ...formValues,
        priorAddress
      });
      setTouched({ ...touched, priorAddress: { address: true, unit: true, city: true, state: true, zip: true } });
    } else if (id.includes('rentersLocation')) {
      const { streetLine: address, secondary: address2, city, state, zipcode: zip } = suggestion;
      setValues({
        ...formValues,
        rentersCoverage: {
          rentersLocation: { address, address2, city, state, zip }
        }
      });
      setTouched({ ...touched, address: true, unit: true, city: true, state: true, zip: true });
    } else {
      const { streetLine: address, secondary: unit, city, state, zipcode: zip } = suggestion;
      setValues({
        ...formValues,
        address,
        unit,
        city,
        state,
        zip
      });
      setTouched({ ...touched, address: true, unit: true, city: true, state: true, zip: true });
    }
  };

  const renderInputComponent = (inputProps) => {
    const { ref, ...rest } = inputProps;
    return (
      <TextField
        inputRef={ref}
        {...rest}
        error={getIn(touched, name || id) && !!getIn(errors, name || id)}
        helperText={getIn(touched, name || id) && getIn(errors, name || id)}
      />
    );
  };

  const renderSuggestionsContainer = (options) => {
    const { containerProps, children } = options;
    return (
      <Paper {...containerProps} square>
        {children}
      </Paper>
    );
  };

  const throttledSearch = useRef(throttle(store.search, 1000));
  const inputProps = {
    className: classes.noMarginBottom,
    fullWidth: true,
    type: 'string',
    value: store.value,
    name: id || name,
    id,
    label,
    mode,
    disabled,
    onChange
  };

  return (
    <Grid item xs={xs}>
      <Autosuggest
        getSuggestionValue={(suggestion) => suggestion.streetLine}
        inputProps={inputProps}
        onSuggestionSelected={onSuggestionSelected}
        onSuggestionsClearRequested={store.clearSuggestions}
        onSuggestionsFetchRequested={throttledSearch.current}
        renderInputComponent={renderInputComponent}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderSuggestion={Suggestion}
        suggestions={store.suggestions}
        theme={{ suggestionsContainer: classes.suggestionsContainer, suggestionsList: classes.suggestionsList }}
      />
    </Grid>
  );
}

AddressAutocomplete.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  mode: PropTypes.oneOf(['light', 'dark']).isRequired
};

AddressAutocomplete.defaultProps = {
  name: undefined,
  label: 'Address'
};

export default observer(AddressAutocomplete);
