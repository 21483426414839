import { useMemo } from 'react';
import addDays from 'date-fns/addDays';

import { paymentType, policyType as policyTypeLookup } from '@ourbranch/lookups';
import { awsDateToDateObject } from 'core/helpers/formatters';

const getFirstPolicyDayOfMonth = (offer, policyType) => {
  const { global } = offer.quote;
  const { homeEffectiveDate, autoEffectiveDate, homeownersPaymentType, autoPaymentType } = global;

  let effectiveDate = null;
  const PolicyType = policyTypeLookup;
  const PaymentType = paymentType;

  if (policyType === PolicyType.HABundle) {
    if (homeEffectiveDate !== autoEffectiveDate) {
      if (homeownersPaymentType === PaymentType.Monthly && autoPaymentType === PaymentType.OneTime) {
        effectiveDate = awsDateToDateObject(homeEffectiveDate);
      } else if (homeownersPaymentType === PaymentType.OneTime && autoPaymentType === PaymentType.Monthly) {
        effectiveDate = awsDateToDateObject(autoEffectiveDate);
      } else {
        const hDate = awsDateToDateObject(homeEffectiveDate);
        const aDate = awsDateToDateObject(autoEffectiveDate);

        effectiveDate = hDate < aDate ? hDate : aDate;
      }
    } else {
      effectiveDate = awsDateToDateObject(homeEffectiveDate);
    }
  } else if (policyType === PolicyType.Auto || policyType === PolicyType.ARBundle) {
    effectiveDate = awsDateToDateObject(autoEffectiveDate);
  } else if (policyType === PolicyType.Home) {
    effectiveDate = awsDateToDateObject(homeEffectiveDate);
  }

  return addDays(effectiveDate, 1).getDate();
};

const useBillingDayOfMonthOptions = (offer, policyType) => {
  return useMemo(() => {
    if (!offer || !offer.options.find((o) => o.type === policyType)) {
      return null;
    }

    let firstDay = getFirstPolicyDayOfMonth(offer, policyType);
    if (firstDay > 28) {
      firstDay = 1;
    }
    let defaultDay = firstDay + 15;
    if (defaultDay > 28) {
      defaultDay -= 28;
    }

    const options = [];
    for (let i = 1; i <= 28; i += 1) {
      options.push({ id: i, value: i.toString(), selected: defaultDay === i });
    }

    return options;
  }, [offer, policyType]);
};

export default useBillingDayOfMonthOptions;
