import { Formik } from 'formik';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import * as Yup from 'yup';
import { add, subDays } from 'date-fns';

import config from 'aws-exports';
import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import PriceDetails from './price-details';
import PaymentDetails from './payment-details';
import Footer from '../footer';
import { localToUtcTime } from '../dates';
import { validationSchema } from './payment-tab.validation';

const stripePromise = loadStripe(config.stripeKey);

const PaymentTab = observer(({ loadingPreview, handleChangePolicy }) => {
  const session = useContext(AuthContext);
  const { bt } = useBasisTheory(config.basisTheoryApiKey, { elements: true });

  const { isTeamLeader, canEdit, viewOnly } = session;
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();

  const onSubmit = (values) => {
    handleChangePolicy({
      ...policyStore?.policy,
      fees: values.fees,
      billingAddInstallments: values.billingAddInstallments,
      billingHoldUntil: values.billingHoldUntil,
      skipImmediateBillOrRefund: values.skipImmediateBillOrRefund
    });
    policyStore.setChanged(false);
  };

  const showFooter = canEdit && !viewOnly && policyStore.changed;

  if (policyStore.loading) {
    return <Loading type="secondary" />;
  }
  const sevenDaysBeforeEndDate = subDays(new Date(policyStore.policy.endDate), 7);

  const determineMaxBillingHoldDate = () => {
    // billing holds should only last up to 7 days after billingDayOfMonth
    const billingDayOfMonth = policyStore?.policy?.billingDayOfMonth;

    // start with the billingDayOfMonth without adding anything yet
    const startBillingHoldDate = localToUtcTime(
      new Date().setDate(billingDayOfMonth),
      policyStore?.geographicState
    ).setHours(0, 0, 0, 0);
    let maxBillingHoldDate;

    // need to add a month and a week if today is after billing date
    if (new Date().getDate() > billingDayOfMonth) {
      maxBillingHoldDate = add(startBillingHoldDate, { weeks: 1, months: 1 });
    } else {
      // otherwise, just add a week
      maxBillingHoldDate = add(startBillingHoldDate, { weeks: 1 });
    }

    return maxBillingHoldDate < sevenDaysBeforeEndDate ? maxBillingHoldDate : sevenDaysBeforeEndDate;
  };

  return (
    <BasisTheoryProvider bt={bt}>
      <Elements stripe={stripePromise}>
        <Formik
          initialValues={{
            billingAddInstallments: 0,
            billingHoldUntil: policyStore.policy ? policyStore.policy.billingHoldUntil : undefined,
            fees: policyStore.policy.fees,
            maxBillingHoldDate: isTeamLeader ? sevenDaysBeforeEndDate : determineMaxBillingHoldDate()
          }}
          validationSchema={() => {
            return Yup.lazy((values) =>
              validationSchema({
                values,
                isTeamLeader,
                geographicState: policyStore?.geographicState
              })
            );
          }}
          onSubmit={onSubmit}
        >
          <>
            <PriceDetails />
            <PaymentDetails />
            {showFooter && <Footer title="Save changes" loadingPreview={loadingPreview} />}
          </>
        </Formik>
      </Elements>
    </BasisTheoryProvider>
  );
});

PaymentTab.propTypes = {
  loadingPreview: PropTypes.bool.isRequired,
  handleChangePolicy: PropTypes.func.isRequired
};

export default PaymentTab;
