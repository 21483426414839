import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { paymentType } from '@ourbranch/lookups';

import { useStore } from 'core/store/store.mobx';
import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { StripeCustomerIdField } from '../stripe-customer-id-field';
import useStyles from '../billing-details.styles';

const Mortgage = () => {
  const classes = useStyles();
  const { setFieldValue } = useFormikContext();
  const {
    account: {
      policies: { policy }
    }
  } = useStore();

  const primaryMortgage = policy.segment?.home?.mortgageDetails?.find((mortgage) => mortgage.primary) || {};

  useEffect(() => {
    setFieldValue('paymentType', paymentType.Escrow);
  }, [setFieldValue]);

  return (
    <>
      <Grid container justify="flex-start" className={classes.billingDetailsContainer} spacing={4}>
        <Label style={{ width: '100%', paddingLeft: '6px' }} type="smallWarning">
          Navigate to Details to edit mortgage holder information.
        </Label>
        <FormField
          name="primaryMortgage.mortgageHolderName"
          value={primaryMortgage.mortgageHolderName}
          type="string"
          mode="dark"
          label="Lender Name"
          xs={4}
          disabled
        />
        <FormField
          name="primaryMortgage.loanNumber"
          value={primaryMortgage.loanNumber}
          type="string"
          mode="dark"
          label="Loan Number (optional)"
          disabled
          xs={4}
        />
        <StripeCustomerIdField />
        <FormField
          name="primaryMortgage.mortgageHolderAddress"
          value={primaryMortgage.mortgageHolderAddress}
          type="address"
          mode="dark"
          label="Lender Address"
          disabled
          xs={12}
        />
      </Grid>
    </>
  );
};

export default observer(Mortgage);
