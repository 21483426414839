const styles = (theme) => ({
  'dark-tableSortLabelRoot': {
    color: theme.colors.light_text,
    opacity: 0.6,
    '&:hover': {
      color: theme.colors.light_text,
      opacity: 1
    },
    '&:focus': {
      color: theme.colors.light_text
    }
  },
  'dark-tableSortLabelActive': {
    color: theme.colors.light_text,
    opacity: 1
  },
  'dark-tableCellRoot': {
    borderBottom: 'none',
    '.MuiTableCell-root.MuiTableCell-head': {
      color: theme.colorPalette.beige_10
    }
  }
});

export default styles;
