import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import _pickBy from 'lodash-es/pickBy';
import _uniq from 'lodash-es/uniq';
import flat from 'flat';
import { format, parseISO } from 'date-fns';

import { SelectableRow } from 'core/components/table/selectable-row';
import { SelectableCell } from 'core/components/table/selectable-cell';

import useStyles from './policies-results.styles';

const policyLink = (hit) => `/customer/${hit.accountId}/policy/${hit.id}`;

const ATTRIBUTE_FRIENDLY_NAME = {
  firstName: 'first name',
  lastName: 'last name',
  cars: 'Car VIN',
  dateOfBirth: 'DOB',
  accountId: 'Account ID',
  id: 'Policy ID',
  state: 'State',
  mortgageHolderName: 'Mortgage Lender'
};

const Row = ({ columnsToUse, hit, onClick, ...props }) => {
  const classes = useStyles();

  const getPrimaryApp = useCallback((people) => {
    return people.find((p) => p.isPrimary);
  }, []);

  const getMatchedAttributes = useCallback((hit) => {
    /* Flatten the highlight result object from algolia so this:
          { policyDetails: { cars: [ { VIN: { value: 1234567, matchLevel: "full" }} ] } }
        turns into this:
          { 'policyDetails.cars.0.VIN.matchLevel': "full", 'policyDetails.cars.0.VIN.value': 1234567 }
    */
    const matchedAttributes = [];
    // eslint-disable-next-line no-underscore-dangle
    const flattenedFullResults = flat(hit._highlightResult);
    const flattenedOnlyMatches = _pickBy(flattenedFullResults, (match) => match === 'full' || match === 'partial');

    Object.keys(flattenedOnlyMatches).forEach((fullAttributePath) => {
      Object.keys(ATTRIBUTE_FRIENDLY_NAME).forEach((attribute) => {
        if (fullAttributePath.includes(attribute)) {
          let friendlyAttributeName = ATTRIBUTE_FRIENDLY_NAME[attribute];
          if (attribute === 'firstName' || attribute === 'lastName') {
            friendlyAttributeName = fullAttributePath.includes('drivers')
              ? `Driver ${friendlyAttributeName}`
              : `Person ${friendlyAttributeName}`;
          }
          matchedAttributes.push(friendlyAttributeName);
        }
      });
    });

    return _uniq(matchedAttributes).join(', ');
  }, []);

  const columnTypes = {
    id: (hit) => (
      <SelectableCell to={policyLink(hit)} key="id">
        {hit.id}
      </SelectableCell>
    ),
    policyType: (hit) => (
      <SelectableCell to={policyLink(hit)} key="policyType">
        {hit.policyType === 'A' ? 'Auto' : 'Home'}
      </SelectableCell>
    ),
    firstName: (hit) => (
      <SelectableCell to={policyLink(hit)} key="firstName">
        {getPrimaryApp(hit.policyDetails.drivers || hit.policyDetails.people)?.firstName}
      </SelectableCell>
    ),
    lastName: (hit) => (
      <SelectableCell to={policyLink(hit)} key="lastName">
        {getPrimaryApp(hit.policyDetails.drivers || hit.policyDetails.people)?.lastName}
      </SelectableCell>
    ),
    matchedOn: (hit) => (
      <SelectableCell to={policyLink(hit)} key="matchedOn">
        {getMatchedAttributes(hit)}
      </SelectableCell>
    ),
    effectiveDate: (hit) => (
      <SelectableCell to={policyLink(hit)} key="effectiveDate">
        {format(parseISO(hit.effectiveDate), 'MM/dd/yyyy')}
      </SelectableCell>
    ),
    endDate: (hit) => (
      <SelectableCell to={policyLink(hit)} key="endDate">
        {format(parseISO(hit.endDate), 'MM/dd/yyyy')}
      </SelectableCell>
    ),
    state: (hit) => (
      <SelectableCell to={policyLink(hit)} key="state">
        {hit.state}
      </SelectableCell>
    )
  };

  return (
    <SelectableRow id={hit.id} className={classes.tableRow} onClick={onClick} {...props}>
      {columnsToUse.map((name) => columnTypes[name](hit))}
    </SelectableRow>
  );
};

Row.propTypes = {
  columnsToUse: PropTypes.array.isRequired,
  hit: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

Row.defaultProps = {
  onClick: () => {}
};

export default Row;
