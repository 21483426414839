import * as Yup from 'yup';
import { isWithinInterval, parseISO, isSameDay, isAfter, format } from 'date-fns';
import { getValue, paymentMethod, paymentType, mustRenewAutoLimit } from '@ourbranch/lookups';
import { PolicyStatus } from 'core/helpers/policy-status';

import { requiredString } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.string, 'requiredString', requiredString);

/* FYI
 * address node is already validated as required in the add-interested-party-form.js
 * we don't want to add required again here, because we can load up a policy with an address as null,
 * and we should allow the policy modification to go through without this validation blocking it
 */
const lienHolderValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().required('Name or Company are required!'),
    address: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable()
      })
      .nullable(),
    loanNumber: Yup.string().nullable(),
    VIN: Yup.string().nullable()
  })
).nullable();

const partiesValidation = Yup.array(
  Yup.object().shape({
    name: Yup.string().requiredString('Name or Company are required!'),
    relationship: Yup.string().requiredString('Relationship is required'),
    address: Yup.object()
      .shape({
        address: Yup.string().nullable(),
        address2: Yup.string().nullable(),
        city: Yup.string().nullable(),
        state: Yup.string().nullable(),
        zip: Yup.string().nullable()
      })
      .nullable()
  })
).nullable();

const validations = {
  A: lienHolderValidation,
  H: partiesValidation
};

export const validationSchema = ({
  policyType,
  minDate,
  maxDate,
  values,
  currentEffectiveDate,
  wasEffectiveDateUpdated,
  policyStatus,
  policyDetails,
  billingDetails
}) => {
  const effectiveDateSchema = Yup.object().shape({
    effectiveDate: Yup.string().test('invalidEffectiveDate', `Invalid Effective Date`, function test(value) {
      const valueParsed = parseISO(value);

      // reference getStartMinMaxDate function for min/max date logic
      const effectiveDateWithinValidRange =
        isWithinInterval(valueParsed, { start: minDate, end: maxDate }) ||
        isSameDay(valueParsed, minDate) ||
        isSameDay(valueParsed, maxDate);

      if (!effectiveDateWithinValidRange) {
        return this.createError({
          message: `Effective date must be between ${format(minDate, 'MM/dd/yyyy')} and ${format(
            maxDate,
            'MM/dd/yyyy'
          )}`
        });
      }

      const policyIsActive = PolicyStatus.Active === policyStatus;
      const movingEffectiveDateBackwards = isAfter(parseISO(currentEffectiveDate), parseISO(values.effectiveDate));

      if (wasEffectiveDateUpdated && policyIsActive && movingEffectiveDateBackwards) {
        return this.createError({
          message: 'Cannot move effective date backwards once policy is already active.'
        });
      }

      return true;
    })
  });

  // only validate end date
  if (values.cancel) {
    return Yup.object().shape({
      endDate: Yup.string().test('invalid', 'Cannot set END DATE prior to policy START DATE', function test(value) {
        return new Date(this.parent.effectiveDate) <= new Date(value);
      })
    });
  }

  let baseSchema = effectiveDateSchema.concat(
    Yup.object().shape({
      additionalParties: validations[policyType],
      paymentType: Yup.string().test('valid', 'Invalid payment type for that payment method', function test(value) {
        if (value === null) {
          return true;
        }

        if (
          (value === paymentType.Escrow && this.parent.paymentMethod !== paymentMethod.Escrow) ||
          (this.parent.paymentMethod === paymentMethod.Escrow && value !== paymentType.Escrow)
        ) {
          const paymentMethod = getValue('homeownersPaymentMethod', this.parent.paymentMethod);
          const paymentType = getValue('paymentType', value);
          return this.createError({
            path: this.path,
            message: `${paymentType} payment type is not valid for ${paymentMethod} payment method`
          });
        }

        return true;
      }),
      renewalPaymentMethod: Yup.string()
        .test('hasPaymentMethodReadyToCharge', 'There is no payment for this payment type', function test(value) {
          if (!value) return true;
          const primaryMortgage = policyDetails?.home?.mortgageDetails.find((md) => md.primary);
          if (value === paymentMethod.Escrow && !primaryMortgage) {
            return this.createError({
              path: this.path,
              message: `Need primary mortgage on policy to be able to have a renewal payment method of Mortgage.`
            });
          }
          if (value === paymentMethod.CreditCard) {
            const hasCreditCard = billingDetails.allPaymentMethods.find(({ id }) => id?.startsWith('card'));
            const hasStripeCustomerId = values.stripeCustomerId?.startsWith('cus');
            if (!hasStripeCustomerId || !hasCreditCard) {
              return this.createError({
                path: this.path,
                message: `Need credit card on policy to have renewal payment method of Credit Card`
              });
            }
          }
          if (value === paymentMethod.ACH) {
            const hasStripeCustomerId = values.stripeCustomerId?.startsWith('cus');
            const hasBankAccount = billingDetails.allPaymentMethods.find(({ id }) => id?.startsWith('ba'));
            if (!hasStripeCustomerId || !hasBankAccount) {
              return this.createError({
                path: this.path,
                message: `Need bank account on policy to have renewal payment method of ACH`
              });
            }
          }
          return true;
        })
        .nullable(),
      renewalPaymentType: Yup.string().test(
        'valid',
        'Invalid payment type for the renewal payment method',
        function test(value) {
          if (value == null) return true;

          if (
            (value === paymentType.Escrow && values.renewalPaymentMethod !== paymentMethod.Escrow) ||
            (values.renewalPaymentMethod === paymentMethod.Escrow && value !== paymentType.Escrow)
          ) {
            return false;
          }

          return true;
        }
      ),
      defaultEscrowAccount: Yup.object().when('paymentMethod', {
        is: paymentMethod.Escrow,
        then: Yup.object().shape({
          mortgageHolderName: Yup.string().test('requireIfNotCancelling', 'Required', function test(value) {
            return value || values.cancel;
          })
        }),
        otherwise: Yup.object().nullable()
      }),
      defaultBankAccount: Yup.object().when('paymentMethod', {
        is: paymentMethod.ACH,
        then: Yup.object()
          .shape({
            id: Yup.string().required().default('')
          })
          .default({ id: undefined }),
        otherwise: Yup.object().nullable()
      }),
      nonRenewReason: Yup.mixed().test('non renew reason', 'Required', function test(value) {
        const isNonRenew = this.parent.isNonRenew;
        if (isNonRenew && !value) {
          return this.createError({
            message: 'Please choose a Non-renewal reason'
          });
        }
        return true;
      })
    })
  );

  const limitForCancellingAutoPolicyRenewal = mustRenewAutoLimit[values.state]?.[policyType];
  if (limitForCancellingAutoPolicyRenewal) {
    baseSchema = baseSchema.concat(
      Yup.object().shape({
        renew: Yup.boolean().test(
          'valid',
          `Policies more than ${limitForCancellingAutoPolicyRenewal} terms must be renewed.`,
          function test(value) {
            if (this.parent.term <= limitForCancellingAutoPolicyRenewal) {
              return true;
            }
            return this.parent.term > limitForCancellingAutoPolicyRenewal && value;
          }
        )
      })
    );
  }

  return baseSchema;
};
