const { policyTypes: PolicyType } = require('@ourbranch/policy-types');
const {
  alabamaLogicInstallmentFeeStates,
  localPremiumTaxStates,
  statePremiumTaxStates,
  shortRateFeeStates,
  getFinancialResponsibilityTier,
  directAffinityCodes,
  altCreditAlgorithmStates
} = require('@ourbranch/lookups');
const _ = require('lodash');
const { getTodayStr } = require('@ourbranch/date-helpers');

const installmentCountByTypeAndState = {
  A: {
    NH: 5
  },
  H: {
    NH: 11
  }
};

const nonBixNonThreeDollarInstallmentFeeStates = {
  IL: true,
  MO: true,
  TX: true
};

const getInventoryDiscount = ({ policyType, state, inBundle = false, isBix }) => {
  if (policyType === PolicyType.Auto || policyType === PolicyType.ARBundle) {
    if (state === 'TX') {
      // auto and auto/renters bundle in TX
      return 0;
    }
    if (state === 'IL' || (state === 'MO' && !isBix)) {
      // auto and auto/renters bundle in IL & MO GSNIC
      return inBundle ? 15 : 20;
    }
  }
  if (state === 'IL') {
    // home, IL
    return 50;
  }
  // home and home/auto bundle, elsewhere
  return inBundle ? 55 : 65;
};

const FeeType = {
  Policy: 'policy',
  Imagery: 'imagery',
  Paper: 'paper',
  Installment: 'installment',
  Cancellation: 'cancellation',
  Reinstatement: 'reinstatement',
  AutoTheft: 'auto_theft',
  PoliceTraining: 'police_training',
  RuralFireDept: 'rural_fire_dept',
  FairPlan: 'fair_plan',
  AutoTheftPrevention: 'auto_theft_prevention',
  AutoCrimePrevention: 'auto_crime_prevention',
  MCCA: 'mcca'
};

/**
 *
 * @param {string} feeType, cant be A for autoPolicy fees or H for HomePolicyFees
 * @param {object} feeOptions: { policyType, inBundle, state }
 */
function generateFee(feeType, feeOptions) {
  let feeDescription;
  let feeAmount;
  let deviceCost;

  const { policyType, state, inBundle, isBix, policyLimitPIPME, premium, effectiveDate } = feeOptions;
  const effectiveDateInt = Number(String(effectiveDate).replace(/-/g, ''));
  const paymentCount =
    policyType === PolicyType.Auto
      ? installmentCountByTypeAndState[policyType]?.[state] || 6
      : installmentCountByTypeAndState[policyType]?.[state] || 12;

  switch (feeType.toLowerCase()) {
    case 'policy':
      feeDescription = 'Policy';
      if ((state === 'MO' && !isBix) || (state === 'TX' && !isBix) || (state === 'AL' && policyType === 'H')) {
        feeAmount = 0;
      } else {
        feeAmount = 5;
      }
      break;
    case 'paper':
      feeDescription = 'Paper Documentation';
      feeAmount = state === 'TX' && !isBix ? 25 : 0;
      break;
    case 'imagery': {
      feeDescription = 'Imagery';
      feeAmount =
        policyType === PolicyType.HABundle
          ? getInventoryDiscount({ policyType: 'H', state, inBundle: true, isBix }) +
            getInventoryDiscount({ policyType: 'A', state, inBundle: true, isBix })
          : getInventoryDiscount({ policyType, state, inBundle, isBix });
      break;
    }
    case 'installment':
      feeDescription = 'Installment';
      if (!isBix && nonBixNonThreeDollarInstallmentFeeStates[state]) {
        feeAmount = feeOptions.paymentMethod === 'E' ? 1 * paymentCount : 4 * paymentCount;
      } else {
        feeAmount = 3 * paymentCount; // flat $3/installment for BIX and new GSNIC
      }
      break;
    case 'cancellation':
      feeDescription = 'Cancellation';
      if (state === 'SC') {
        feeAmount = 10;
      } else if (state === 'ND') {
        feeAmount = 0;
      } else {
        feeAmount = 35;
      }
      break;
    case 'short_rate':
      feeDescription = 'Short Rate VA Cancellation Amount';
      feeAmount = premium || 1; // need to know premium here to figure out unearned premium
      break;
    case 'reinstatement':
      feeDescription = 'Reinstatement';
      if ((state === 'MI' && policyType === 'A') || (state === 'SD' && policyType === 'H') || state === 'ND') {
        feeAmount = 0;
      } else {
        feeAmount = 5;
      }

      break;
    case 'police_training':
      feeDescription = 'State Police Training and Academy Fund Assessment';
      feeAmount =
        2 *
        (Array.isArray(feeOptions.cars)
          ? feeOptions.cars.filter((c) => c.deductibleComprehensive !== 'NONE').length
          : 1);
      break;
    case 'auto_theft':
      feeDescription = 'Auto Theft Authority';
      feeAmount = 0.5 * (Array.isArray(feeOptions.cars) ? feeOptions.cars.length : 1);
      break;
    case 'mcca':
      feeDescription = 'MCCA Full Recoupment Fee';
      feeAmount =
        policyLimitPIPME && policyLimitPIPME === 'UNLIMITED'
          ? 43 * (Array.isArray(feeOptions.cars) ? feeOptions.cars.length : 1)
          : 0;
      break;
    case 'rural_fire_dept':
      feeDescription = 'Rural Volunteer Fire Department Assistance Program Recoupment Fee';
      feeAmount = 0;
      break;
    case 'fair_plan':
      feeDescription = 'FAIR Plan Surcharge';
      feeAmount = 0;
      break;
    case 'home_hazard':
      feeDescription = 'Home Hazard Mitigation Fee';
      feeAmount = effectiveDateInt >= 20220301 ? 2 : 0;
      break;
    case 'auto_theft_prevention':
      feeDescription = 'Auto Theft Prevention';
      deviceCost = state === 'TX' && policyType !== 'H' ? 2 : 0.5;
      feeAmount =
        deviceCost *
        (Array.isArray(feeOptions.cars)
          ? feeOptions.cars.filter((c) => c.deductibleComprehensive !== 'NONE').length
          : 1);
      break;
    case 'auto_crime_prevention':
      feeDescription = 'Motor Vehicle Crime Prevention';
      feeAmount = 2 * (Array.isArray(feeOptions.cars) ? feeOptions.cars.length : 1);
      break;

    default:
      break;
  }

  return { description: feeDescription, amount: feeAmount };
} // generateFee

function roundTwoDecimals(num) {
  return Math.round(num * 1e2) / 1e2;
}

function getPremiumBreakdownForFees({ coverages, premium, policyType, premiumBreakdown }) {
  let homePremium = 0;
  let sppPremium = 0;
  let umbrellaPremium = 0;
  let autoPremium = 0;
  let rentersPremium = 0;
  if (coverages) {
    if (policyType === 'A') {
      rentersPremium = coverages
        .filter((c) => c.type === 'renters_total')
        .reduce((acc, c) => {
          return acc + c.amount;
        }, 0);
      autoPremium = premium - rentersPremium;
    }
    if (policyType === 'H') {
      sppPremium = (coverages.find((x) => x.type.toLowerCase() === 'spp_total') || {}).amount || 0;
      umbrellaPremium =
        (coverages.find((x) => x.type.toLowerCase() === 'personal_umbrella_liability_total') || {}).amount || 0;
      homePremium = premium - (sppPremium + umbrellaPremium);
    }
  } else if (premiumBreakdown) {
    homePremium = premiumBreakdown.home || 0;
    sppPremium = premiumBreakdown.spp || 0;
    umbrellaPremium = premiumBreakdown.umbrella || 0;
    autoPremium = premiumBreakdown.car || 0;
    rentersPremium = premiumBreakdown.renters || 0;
  } else {
    throw new Error(`Did not receive coverages or premium breakdown as part of local premium tax calculations.`);
  }
  return {
    homePremium,
    sppPremium,
    umbrellaPremium,
    autoPremium,
    rentersPremium
  };
}

function getLocalTaxRates({ localPremiumTax }) {
  const retObj = {
    minimum: 0
  };
  const lookupCodes = [
    { name: 'home', code: 'KY01' },
    { name: 'spp', code: 'KY04' },
    { name: 'umbrella', code: 'KY02' },
    { name: 'auto', code: 'KY03' },
    { name: 'renters', code: 'KY01' }
  ];

  const { minimumTax } = localPremiumTax;

  if (minimumTax && minimumTax !== 'N/A') {
    const minimumCandidate = Number(minimumTax.replace('$', ''));
    if (!Number.isNaN(minimumCandidate)) {
      retObj.minimum = minimumCandidate;
    }
  }

  for (const lookup of lookupCodes) {
    const { name, code } = lookup;
    const record = localPremiumTax?.lineOfBusinessDetails?.find((lob) => lob?.code === code);
    if (!record) {
      console.log(
        `could not find local premium tax (assuming not a taxable area) for ${name} with code ${code} in ${JSON.stringify(
          localPremiumTax,
          null,
          2
        )}`
      );
      retObj[`${name}Percentage`] = 0;
    } else {
      const premiumTaxPercentageCandidate = parseInt(record?.taxValue, 10);
      if (Number.isNaN(premiumTaxPercentageCandidate)) {
        console.log(`premium tax candidate is NaN: ${record?.taxValue} / ${parseInt(record?.taxValue, 10)}`);
        throw new Error('Premium Tax is not a number.');
      } else {
        retObj[`${name}Percentage`] = premiumTaxPercentageCandidate / 100;
      }
    }
  } // for each thing we need a lookup
  return retObj;
} // getLocalTaxRates

function calculatePremiumTax({ premium, premiumPart, nonPremiumExtraTotal, percentage, minimum }) {
  // if premiumPart is 0, then no tax owed:
  if (premiumPart === 0) {
    return 0;
  }

  // spread nonPremiumExtraTotal pro-rata based upon the premium part of the total premium
  // and respect the minimum
  const calcValue = (premiumPart + (nonPremiumExtraTotal * premiumPart) / premium) * percentage;
  console.log(
    `calculate premium tax: ${minimum}, ${calcValue}; yielding: ${minimum > calcValue ? minimum : calcValue}`
  );
  return minimum > calcValue ? minimum : calcValue;
} // calculatePremiumTax

/**
 *
 * @param {string} state, state in uppercase
 * @param {string} policyType, H or A
 * @param {object} fees: { amount, appliedDate, description, type }
 * @param {float} premium
 * @param {float} downPayment
 */
function adjustFees({
  fees,
  state,
  policyType,
  premium,
  downPayment,
  surplusContribution,
  localPremiumTax,
  coverages,
  premiumBreakdown
}) {
  console.log(`adjustFees called with state ${state}`);
  // At this point, this function only implements the Alabama fee logic,
  // premium taxes, and short rating in VA cancels
  if (shortRateFeeStates[state]) {
    const shortRateFee = fees.find((f) => f.type === 'short_rate');
    if (shortRateFee) {
      // we have a short rate fee stubbed out with the premium on the full policy
      // now premium is the earned amount, so subtracting the two will give us the unearned amount
      // and the short rate fee is 10% of that
      shortRateFee.amount = roundTwoDecimals((shortRateFee.amount - (premium || 0)) * 0.1);
    }
  } // short rating

  if (localPremiumTaxStates[state]) {
    // first, set up the local premium tax fee:
    if (!fees.find((f) => f.type === 'local_premium_tax')) {
      fees.push({
        type: 'local_premium_tax',
        description: `${state} Municipal Tax`,
        appliedDate: getTodayStr(state),
        amount: 0
      });
    }
    const taxFee = fees.find((f) => f.type === 'local_premium_tax');
    // second, update it based upon the various variables:

    // non-tax fee total
    const feeTotal = fees
      .filter((fee) => !['local_premium_tax', 'state_premium_tax'].includes(fee?.type))
      .reduce((acc, curr) => acc + curr.amount, 0);
    const nonPremiumExtraTotal = (surplusContribution || 0) + (feeTotal || 0);

    // now we build up how much of each type of premium we have here:
    const premiumBreakdownResult = getPremiumBreakdownForFees({
      coverages,
      premium,
      policyType,
      premiumBreakdown
    });
    const { homePremium, sppPremium, umbrellaPremium, autoPremium, rentersPremium } = premiumBreakdownResult;
    console.log(`premium breakdown for fees: ${JSON.stringify(premiumBreakdown, null, 2)}`);

    // local premium tax:
    if (!localPremiumTax) {
      throw new Error('Could not find localPremiumTax node.');
    }

    const localTax = getLocalTaxRates({ localPremiumTax });
    console.log(`localTax: ${JSON.stringify(localTax, null, 2)}`);
    const { homePercentage, sppPercentage, umbrellaPercentage, autoPercentage, rentersPercentage, minimum } = localTax;

    const homeTax = calculatePremiumTax({
      premium,
      premiumPart: homePremium,
      nonPremiumExtraTotal,
      percentage: homePercentage,
      minimum
    });

    const sppTax = calculatePremiumTax({
      premium,
      premiumPart: sppPremium,
      nonPremiumExtraTotal,
      percentage: sppPercentage,
      minimum
    });

    const umbrellaTax = calculatePremiumTax({
      premium,
      premiumPart: umbrellaPremium,
      nonPremiumExtraTotal,
      percentage: umbrellaPercentage,
      minimum
    });

    const autoTax = calculatePremiumTax({
      premium,
      premiumPart: autoPremium,
      nonPremiumExtraTotal,
      percentage: autoPercentage,
      minimum
    });

    const rentersTax = calculatePremiumTax({
      premium,
      premiumPart: rentersPremium,
      nonPremiumExtraTotal,
      percentage: rentersPercentage,
      minimum
    });

    const sumOfTaxes = homeTax + sppTax + umbrellaTax + autoTax + rentersTax;
    console.log(`sum Of Taxes: ${sumOfTaxes}`);

    if (policyType === 'H') {
      taxFee.homeTax = homeTax;
      taxFee.sppTax = sppTax;
      taxFee.umbrellaTax = umbrellaTax;
    }
    if (policyType === 'A') {
      taxFee.autoTax = autoTax;
      taxFee.rentersTax = rentersTax;
    }
    if (policyType === 'R') {
      taxFee.sppTax = sppTax;
      taxFee.rentersTax = rentersTax;
    }

    taxFee.amount = roundTwoDecimals(sumOfTaxes);
    taxFee.description = `${state} Municipal Tax${
      localPremiumTax?.territoryName ? ` - ${localPremiumTax.territoryName}` : ''
    }`;
  } // local premium tax

  if (statePremiumTaxStates[state]) {
    // first, set up the local premium tax fee:
    if (!fees.find((f) => f.type === 'state_premium_tax')) {
      fees.push({
        type: 'state_premium_tax',
        description: `${state} Insurance Premium Surcharge`,
        appliedDate: getTodayStr(state),
        amount: 0
      });
    }
    const taxFee = fees.find((f) => f.type === 'state_premium_tax');
    // second, update it based upon the various variables:

    // non-tax fee total
    const feeTotal = fees
      .filter((fee) => !['local_premium_tax', 'state_premium_tax'].includes(fee?.type))
      .reduce((acc, curr) => acc + curr.amount, 0);
    const underlyingTotal = premium + (surplusContribution || 0) + (feeTotal || 0);

    // state premium tax:
    const premiumTaxPercentage = 0.018;
    taxFee.amount = roundTwoDecimals(underlyingTotal * premiumTaxPercentage);
  } // state premium tax

  // if at some future point this function needs to do more, please refactor!
  if (!alabamaLogicInstallmentFeeStates[state] || !Array.isArray(fees)) {
    return;
  }

  const installmentFee = fees.find((f) => f.type === 'installment');

  if (!installmentFee) {
    return;
  }

  const rate = policyType === 'H' || policyType === 'R' ? 0.09 : 0.045;
  const paymentCount = policyType === 'H' || policyType === 'R' ? 11 : 5; // don't count the downpayment
  const feeMin = 3;
  const altFeeCalc = roundTwoDecimals(Math.floor((((premium || 0) - (downPayment || 0)) * rate + 15) / paymentCount));

  console.log(
    `fee adjustment from ${installmentFee.amount} to greater of ${feeMin} or ${altFeeCalc} (${premium}, ${downPayment}, ${rate}, ${paymentCount})`
  );

  installmentFee.amount = (altFeeCalc < feeMin ? altFeeCalc : feeMin) * (paymentCount + 1);
} // adjustFees

/**
 *
 * @param {float} premium
 * @param {string} state, state in uppercase
 * @param {string} policyType, H or A
 *
 */
function getMinimumDownPayment({ premium, policyType, state, quote }) {
  const isAgency = quote.isAgentSold;
  const isPartner =
    !!quote.global.affinity && !quote.isAgentSold && !directAffinityCodes.includes(quote.global.affinity);
  const biFirstNum = Number(quote.global.priorIndividualBILimit);
  const drivers = quote?.drivers?.length ? quote.drivers : quote.people;
  const primary = drivers.find((driver) => driver.isPrimary);
  const coApplicant = drivers.find((driver) => driver.isCoApplicant);
  const primaryInsuranceScore = _.get(primary, 'insuranceScore.autoTotal') || _.get(primary, 'insuranceScore.total');
  const coAppInsuranceScore =
    _.get(coApplicant, 'insuranceScore.autoTotal') || _.get(coApplicant, 'insuranceScore.total');
  const frTier = getFinancialResponsibilityTier(
    primaryInsuranceScore,
    coAppInsuranceScore,
    altCreditAlgorithmStates[state],
    state
  );
  const clean = !drivers.some((d) => d.nonUDRIncidentCount > 0 || d.hasUDR);

  const isDirect = !isAgency && !isPartner;
  const policyMonths = policyType === PolicyType.Auto ? 6 : 12;
  let minimum = 0;

  if (policyType === PolicyType.Auto) {
    // State regulations in MO require auto insurers to take a down pay of at least 1/6 of the premium
    if (state === 'MO') {
      minimum = premium / 6;
    }

    // rules for everyone:
    if (['AK', 'CA', 'MD', 'NM'].includes(state)) {
      if (!clean || Number(biFirstNum) < 50000) {
        minimum = (3 * premium) / policyMonths;
      } else if (!isAgency) {
        minimum = (2 * premium) / policyMonths;
      }
    } else if (['AZ'].includes(state)) {
      if (['A-1', 'B-1', 'C-1', 'D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1', 'J-1', 'K-1', 'L-1'].includes(frTier)) {
        minimum = (2 * premium) / policyMonths;
      } else if (
        ['D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1', 'J-1', 'K-1', 'L-1', 'M-1', 'N-1'].includes(frTier) &&
        clean &&
        Number(biFirstNum) >= 50000
      ) {
        minimum = (2 * premium) / policyMonths;
      } else {
        minimum = (3 * premium) / policyMonths;
      }
    } else if (['SC'].includes(state)) {
      if (['A-1', 'B-1', 'C-1'].includes(frTier)) {
        minimum = (2 * premium) / policyMonths;
      } else if (
        ['D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1', 'J-1', 'K-1', 'L-1', 'M-1', 'N-1'].includes(frTier) &&
        clean &&
        Number(biFirstNum) >= 50000
      ) {
        minimum = (2 * premium) / policyMonths;
      } else {
        minimum = (3 * premium) / policyMonths;
      }
    } else if (['KS'].includes(state)) {
      if (['A-1', 'B-1', 'C-1'].includes(frTier)) {
        if (isAgency) {
          minimum = 0; // use regular rules
        } else {
          minimum = (2 * premium) / policyMonths;
        }
      } else if (
        ['D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1', 'J-1', 'K-1', 'L-1', 'M-1', 'N-1'].includes(frTier) &&
        clean &&
        Number(biFirstNum) >= 50000
      ) {
        minimum = (2 * premium) / policyMonths;
      } else {
        minimum = (3 * premium) / policyMonths;
      }
    } else if (!isDirect) {
      // Countrywide Agency/Partner Rules
      if (['A-1', 'B-1', 'C-1', 'D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1', 'J-1', 'K-1', 'L-1'].includes(frTier)) {
        minimum = 0; // regular rules
      } else if (clean && Number(biFirstNum) >= 50000) {
        minimum = (2 * premium) / policyMonths;
      } else {
        minimum = (3 * premium) / policyMonths;
      }
    } else {
      // Countrywide direct rules
      // eslint-disable-next-line no-lonely-if
      if (
        ['A-1', 'B-1', 'C-1', 'D-1', 'E-1', 'F-1', 'G-1', 'H-1', 'I-1', 'J-1', 'K-1', 'L-1', 'M-1'].includes(frTier)
      ) {
        minimum = (2 * premium) / policyMonths;
      } else if (clean && Number(biFirstNum) >= 50000) {
        minimum = (2 * premium) / policyMonths;
      } else {
        minimum = (3 * premium) / policyMonths;
      }
    }
  } // special downpay rules for auto

  return roundTwoDecimals(minimum);
} // getMinimumDownPayment

module.exports = {
  getMinimumDownPayment,
  generateFee,
  adjustFees,
  FeeType
};
