import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import classNames from 'classnames';

import styles from './new-construction-flag.styles';
import { Label } from 'core/components/label';

const MoreInfoNeededFlagItem = ({ classes }) => {
  return (
    <div className={classes.flagContainer}>
      <div className={classes.triangle} />
      <div className={classes.flagContent}>
        <Label type="infoSubtitle" className={classes.flagMessage}>
          Add this info to get a price
        </Label>
      </div>
    </div>
  );
};

MoreInfoNeededFlagItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(MoreInfoNeededFlagItem);
