import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import { Grid, Button } from '@material-ui/core';
import { paymentType } from '@ourbranch/lookups';

import { useBasisTheoryToken } from 'common/hooks/use-basis-theory-token';
import { FormField } from 'core/components/form';
import { useToast } from 'core/components/toast';
import { useStore } from 'core/store';
import { ACHFields } from 'common/components/payment/ach-fields';
import { useDisabledState } from 'common/disabled-context/context';
import { CreditCardForm } from 'customer/components/policy/payment-tab/credit-card-form';
import { StripeCustomerIdField } from '../stripe-customer-id-field';
import useStyles from '../billing-details.styles';

const BankAccounts = ({ isCancelled, goToPaymentTab }) => {
  const classes = useStyles();
  const toast = useToast();
  const { disabled } = useDisabledState();
  const {
    account: {
      id: accountId,
      policies: {
        policy: {
          policy: { id },
          addCreditCard,
          billingDetails,
          addBankAccount,
          stripeCustomerId
        }
      }
    }
  } = useStore();
  const { setFieldValue, values, setFieldTouched } = useFormikContext();
  const { getBasisTheoryCCToken, getBasisTheoryACHToken } = useBasisTheoryToken();

  const allPaymentMethods = billingDetails.allPaymentMethods || [];
  const bankAccounts = allPaymentMethods
    .filter((method) => method.id?.startsWith('ba') || method.basisTheoryBankAccountToken)
    .map((account) => ({
      id: account.id,
      value: account.last4.slice(-4).padStart(account.last4.length * 1.5, '*')
    }));

  const [showAccountFields, setShowAccountFields] = useState(!bankAccounts.length);
  const [showCreditCardFields, setShowCreditCardFields] = useState(false);

  useEffect(() => {
    if (values.paymentType === paymentType.Escrow) {
      setFieldValue('paymentType', paymentType.OneTime);
    }
  });

  const toggleAccountFields = useCallback(() => {
    setShowAccountFields(!showAccountFields);
  }, [setShowAccountFields, showAccountFields]);

  const toggleAddCreditCardForm = useCallback(() => {
    setShowCreditCardFields(!showCreditCardFields);
    setShowAccountFields(false);
  }, [setShowCreditCardFields, showCreditCardFields, setShowAccountFields]);

  const addAccount = async () => {
    try {
      const basisTheoryBankAccountTokenInfo = await getBasisTheoryACHToken({
        routingNumberTextElementId: 'routingNumber',
        accountNumberTextElementId: 'accountNumber',
        accountHolderNameTextElementId: 'accountHolderName'
      });

      const last4 = basisTheoryBankAccountTokenInfo.data.account_number.slice(-4); // "XXXXX2023" -> "2023"
      const basisTheoryBankAccountToken = basisTheoryBankAccountTokenInfo.id;

      // check if existing bank account
      const bankAccountExists = billingDetails?.allPaymentMethods?.find(
        (existingPayment) => last4.endsWith(existingPayment.last4) && existingPayment.id.startsWith('ba')
      );
      if (bankAccountExists) {
        throw new Error(`Bank account ending in ${last4} already exists`);
      }

      const accountData = {
        id: basisTheoryBankAccountTokenInfo.id,
        basisTheoryBankAccountToken,
        routingNumber: basisTheoryBankAccountTokenInfo.data.routing_number,
        last4
      };
      setFieldValue('defaultBankAccount', accountData);
      addBankAccount(accountData);
      setFieldTouched('stripeCustomerId', values.stripeCustomerId !== stripeCustomerId);
      setFieldValue('stripeCustomerId', values.stripeCustomerId);
      setShowAccountFields(false);
    } catch (error) {
      await toast.notify({
        type: 'error',
        message: error.message
      });
    }
  };

  const handleAddCard = async () => {
    const { token, card } = await getBasisTheoryCCToken();
    try {
      if (token) {
        await addCreditCard({
          accountId,
          policyId: id,
          basisTheoryCardToken: token
        });
        setShowCreditCardFields(false);
        toast.notify({
          type: 'success',
          message: `Card ending in ${card.last4} has been added.`,
          label: 'Go to Payment and Price tab',
          action: goToPaymentTab
        });
      }
    } catch (error) {
      toast.notify({
        type: 'error',
        message: `An error occurred, ${error.message}`
      });
    }
  };

  return (
    <>
      <Grid container key="account-selected" justify="space-between" className={classes.bankAccounts} spacing={4}>
        <Grid item xs={4}>
          <FormField
            name="defaultBankAccount.id"
            type="select"
            mode="dark"
            options={bankAccounts}
            label="Account Number"
            permissions={{ isLicensedAction: false }}
            fast={false}
          />
        </Grid>
        <Grid container>
          <Button
            xs={4}
            style={{ paddingLeft: 0 }}
            disabled={disabled}
            onClick={toggleAccountFields}
            variant="text"
            color="secondary"
          >
            Add new account
          </Button>
          {isCancelled && (
            <>
              <div className={classes.separator} />
              <Button xs={4} onClick={toggleAddCreditCardForm} variant="text" color="secondary">
                Add new card
              </Button>
            </>
          )}
        </Grid>
        <StripeCustomerIdField />
      </Grid>
      {showCreditCardFields && (
        <CreditCardForm onSubmit={handleAddCard} close={toggleAddCreditCardForm} buttonText="Add Card" />
      )}
      {showAccountFields && (
        <>
          <Grid container item key="account-info" justify="flex-start" alignItems="center">
            <ACHFields mode="dark" />
          </Grid>
          <Grid container item xs={12}>
            <Button style={{ paddingLeft: 0 }} variant="text" color="secondary" type="submit" onClick={addAccount}>
              Add account
            </Button>
            <Button onClick={() => toggleAccountFields(false)} variant="text" color="secondary">
              Close
            </Button>
          </Grid>
        </>
      )}
      {!showAccountFields && values.defaultBankAccount?.accountHolder && (
        <Grid container className={classes.bankAccounts} spacing={4}>
          <FormField
            name="defaultBankAccount.accountHolder"
            type="value"
            label="Account Holder"
            mode="dark"
            xs={4}
            value={values.defaultBankAccount.accountHolder}
          />
          <FormField
            name="defaultBankAccount.routingNumber"
            type="value"
            label="Routing Number"
            xs={4}
            mode="dark"
            value={values.defaultBankAccount.routingNumber}
          />
        </Grid>
      )}
    </>
  );
};

BankAccounts.propTypes = {
  isCancelled: PropTypes.bool,
  frequencyOptions: PropTypes.array.isRequired,
  goToPaymentTab: PropTypes.func.isRequired
};

BankAccounts.defaultProps = {
  isCancelled: false
};

export default observer(BankAccounts);
