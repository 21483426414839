/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { useDisabledState } from 'common/disabled-context';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { observer } from 'mobx-react';
import { HomeCoverage, Umbrella, Additionals } from './coverages';
import Detail from './detail';
import { SchedulePP } from '../schedule-pp';

function HomeOffer() {
  const { disabled } = useDisabledState();
  return (
    <>
      <Detail disabled={disabled} />
      <SchedulePP disabled={disabled} />
      <HomeCoverage disabled={disabled} />
      <Umbrella disabled={disabled} />
      <Additionals disabled={disabled} />
    </>
  );
}

export default observer(HomeOffer);
