/* eslint-disable func-names */
import * as Yup from 'yup';
import { validUSState } from 'common/helpers/yup-helpers';

Yup.addMethod(Yup.mixed, 'validUSState', validUSState);

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name or Company is required!'),
  address: Yup.string().ensure().required('Address is required!'),
  address2: Yup.string().nullable(),
  city: Yup.string().nullable(),
  state: Yup.string().nullable().validUSState('lienholder.state'),
  zip: Yup.string().nullable(),
  loanNumber: Yup.string().nullable(),
  VIN: Yup.string()
    .nullable()
    // eslint-disable-next-line func-names
    .test('Select Associated Entity', 'Invalid Relationship Selection', function (value, context) {
      if (context?.parent?.relationship === 'renters' && value === 'renters') {
        return true;
      }
      if (context?.parent?.relationship === 'lienholder' && value !== 'renters') {
        return true;
      }
      return false;
    })
});
