import _assignWith from 'lodash.assignwith';
import _uniqBy from 'lodash.uniqby';

const customizer = (newVal: any, srcVal: any, key: string) => {
  if (srcVal === undefined || srcVal === null) {
    return newVal;
  }
  if (newVal && (key === 'home' || key === 'auto' || key === 'global')) {
    return Object.assign(newVal, srcVal);
  }
};

export function mergeAndRemoveDuplicates(policyDetailsIn: Record<string, any>, segment: Record<string, any>) {
  const policyDetails = policyDetailsIn;
  _assignWith(policyDetails, segment, customizer);

  // now remove duplicates from the arrays where things could have been removed:
  if (policyDetails.cars) {
    policyDetails.cars = _uniqBy(policyDetails.cars, 'VIN');
  }
  if (policyDetails.drivers) {
    policyDetails.drivers = _uniqBy(policyDetails.drivers, 'id');
  }
  if (policyDetails.people) {
    policyDetails.people = _uniqBy(policyDetails.people, 'id');
  }
  if (policyDetails.trailers) {
    policyDetails.trailers = _uniqBy(policyDetails.trailers, 'VIN');
  }
  if (
    segment.scheduledPersonalProperty &&
    segment.scheduledPersonalProperty.items &&
    !segment.scheduledPersonalProperty.items.length
  ) {
    policyDetails.scheduledPersonalProperty.items = [];
  }
  if (policyDetails.fees) {
    policyDetails.fees = _uniqBy(policyDetails.fees, 'type');
  }

  return policyDetails;
}
