import React, { useState, useEffect, useContext } from 'react';
import { observer } from 'mobx-react';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button } from '@material-ui/core';

import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import { CardLine } from '../card-line';
import TermLifeModal from 'common/components/term-life-modal';
import useStyles from './additional-offers.styles';
import CheckIcon from 'core/assets/svg/check-white.svg';

const AdditionalOffers = () => {
  const {
    offer: { offer },
    additionalOffers
  } = useStore();
  const webUserId = offer?.webUserId;
  useEffect(() => {
    if (webUserId && webUserId !== additionalOffers?.webUserId) {
      additionalOffers.getAdditionalOfferData(webUserId);
    }
  }, [webUserId]);
  const [showLifeModal, setShowLifeModal] = useState();
  const classes = useStyles();
  const { viewOnly } = useContext(AuthContext);
  return (
    <>
      <Card className={classes.card}>
        <div className={classes.header}>
          <Label type="infoSubtitleWhite">Addtional Offers</Label>
        </div>
        <CardLine>
          <div className={classes.section}>
            <Label type="discount">Term Life Insurance</Label>
            <Button
              disabled={viewOnly}
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => setShowLifeModal(true)}
            >
              View details
            </Button>
          </div>
        </CardLine>
        {additionalOffers.termLifeLastSubmitted && (
          <div className={classes.bottomMessage}>
            <img className={classes.icon} src={CheckIcon} alt="check icon" />
            <Label className={classes.lowerLabel}>{additionalOffers.termLifeLastSubmitted}</Label>
          </div>
        )}
      </Card>
      {showLifeModal && <TermLifeModal open={showLifeModal} onClose={() => setShowLifeModal(false)} />}
    </>
  );
};

export default observer(AdditionalOffers);
