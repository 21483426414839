import React from 'react';
import { Label } from 'core/components/label';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { useStore } from 'core/store';
import useStyles from './suspicious-account-notification.styles';

const SuspiciousAccountNotification = () => {
  const { account } = useStore();
  const classes = useStyles();
  const flaggedDate = new Date(account.customerMetadata.suspiciousActivity.timestampISO8601);
  return (
    <Grid>
      <Label className={classes.notificationText}>
        <strong>This member has been flagged for suspicious activity.</strong>
      </Label>
      <Label className={classes.notificationSubtitleText}>
        By {account.customerMetadata.suspiciousActivity.rep} on {flaggedDate.toLocaleDateString()} at{' '}
        {flaggedDate.toLocaleTimeString()}
      </Label>
    </Grid>
  );
};

export default observer(SuspiciousAccountNotification);
