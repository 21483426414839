import React from 'react';
import PropTypes from 'prop-types';

import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';
import { dateTimeFormatter } from 'core/helpers/formatters';

const TicketDetail = ({ ticketDetail, setTicketDetailModalOpen, ticketDetailModalOpen }) => {
  return (
    <BaseDialog open={ticketDetailModalOpen} onClose={() => setTicketDetailModalOpen(false)} size="lg">
      <Label type="subtitle">{ticketDetail.subject}</Label>
      <Label type="infoValue">{dateTimeFormatter(ticketDetail.date)}</Label>
      <Label type="action" onClick={() => window.open(ticketDetail.url, '_blank')}>
        Open this ticket in Front
      </Label>
      {/* eslint-disable react/no-danger */}
      {ticketDetail?.description && (
        <div dangerouslySetInnerHTML={{ __html: ticketDetail.description.replace('\n', '') }} />
      )}
    </BaseDialog>
  );
};

TicketDetail.propTypes = {
  setTicketDetailModalOpen: PropTypes.func.isRequired,
  ticketDetailModalOpen: PropTypes.bool.isRequired
};

export default TicketDetail;
