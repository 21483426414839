import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: '100%',
    marginTop: theme.spacing(4),
    '&> div': {
      borderTop: `1px solid ${theme.colorPalette.white_30_op_30} `
    },
    '&> div:first-child': {
      borderTop: 'none'
    },
    marginBottom: theme.spacing(4),
    minHeight: 60,
    composes: '$root',
    backgroundColor: theme.colorPalette.beige_10,
    padding: '10px 10px',
    boxShadow: 'none !important'
  },
  columnContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0px 0px 0px 0px',
    margin: '-8px 0px 0px 6px'
  },
  quinary: {},
  iconSmall: {
    width: 24,
    height: 24,
    marginRight: 8
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    margin: '0px 0px 4px 0px'
  },
  boldText: {
    fontWeight: 'bold'
  }
}));

export default useStyles;
