import React from 'react';
import { TextElement } from '@basis-theory/basis-theory-react';
import { useTheme } from '@material-ui/core/styles';

import { styles } from './text.styles';

function BasisTheoryText(props) {
  const { mode } = props;
  const theme = useTheme();
  const inlineStyles = styles(theme);

  return (
    <TextElement
      {...props}
      style={mode === 'dark' ? inlineStyles.textElementStyleDark : inlineStyles.textElementStyleLight}
    />
  );
}

export default BasisTheoryText;
