import React from 'react';
import {
  paymentType as PaymentTypes,
  paymentMethod as PaymentMethods,
  lookupsJson,
  policyType as PolicyTypes
} from '@ourbranch/lookups';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';

import { useStore } from 'core/store';
import { FormField } from 'core/components/form';

const RenewalPaymentOptions = () => {
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { policy } = policyStore;

  const { values, setFieldValue } = useFormikContext();
  const isAuto = policy.policyType === PolicyTypes.Auto;

  const frequencyOptions =
    values.renewalPaymentMethod === PaymentMethods.Escrow
      ? lookupsJson.paymentType.filter(({ id }) => id === PaymentTypes.Escrow)
      : isAuto
      ? lookupsJson.autoPaymentType
      : lookupsJson.paymentType.filter(({ id }) => id !== PaymentTypes.Escrow);

  return (
    <>
      <FormField
        name="renewalPaymentMethod"
        type="select"
        label="Renewal Payment Method"
        mode="dark"
        xs={6}
        options={isAuto ? lookupsJson.autoPaymentMethod : lookupsJson.homeownersPaymentMethod}
        permissions={{ isLicensedAction: false }}
        fast={false}
        onChange={(val) => {
          if (val === PaymentMethods.Escrow) {
            setFieldValue('renewalPaymentType', PaymentTypes.Escrow);
          } else {
            // ach or credit card
            setFieldValue(
              'renewalPaymentType',
              values.renewalPaymentType === PaymentTypes.Escrow ? PaymentTypes.OneTime : values.renewalPaymentType
            );
          }
        }}
      />
      <FormField
        name="renewalPaymentType"
        type="select"
        label="Renewal Payment Frequency"
        mode="dark"
        xs={6}
        options={frequencyOptions}
        permissions={{ isLicensedAction: false }}
        fast={false}
      />
    </>
  );
};

export default observer(RenewalPaymentOptions);
