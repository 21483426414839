import { lookupsJson } from '@ourbranch/lookups';

const states = lookupsJson.usStates;

export function validUSState(name) {
  return this.test({
    name: `${name}.validUSState`,
    message: 'Please enter a valid US state',
    test: (val) => !val || states.some((state) => state.id === val),
    exclusive: false
  });
}

export function requiredString(label) {
  return this.ensure().required(label || 'Required');
}

// @precondition, needs to have affinityLookups store object in context
export function validAffinityCode(name, ctx) {
  const { affinityLookups, isAgency } = ctx;
  return this.test({
    name: `${name}.validAffinityCode`,
    message: 'Please enter a valid affinity partner name or code',
    test: function test(val) {
      if (val) {
        const matchedToAffinityInLookups = affinityLookups.data.find(
          (affinityPartner) => affinityPartner.affinity === val
        );

        if (!matchedToAffinityInLookups) {
          return this.createError({
            message: `Affinity value ${val} not found in our database. ${
              isAgency ? 'Please contact your designated agency success manager to fix the error to continue' : ''
            }`,
            path: name
          });
        }
      }
      return true;
    }
  });
}
