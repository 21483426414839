import React from 'react';
import { Grid } from '@material-ui/core';
import { paymentType, lookupsJson, standaloneRentersStates } from '@ourbranch/lookups';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import { getPolicyType } from 'core/helpers/policy-type';
import getDetailedPrice from 'offer/helpers/detailed-price';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { numberFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Card } from 'core/components/card';
import { Loading } from 'core/components/loading';
import { Button } from 'core/components/button';
import { PriceDetail } from './components/price-details-card/price-detail';
import { Additionals } from './components/additionals';
import { Downloads } from './components/downloads';
import useStyles from './sidebar.styles';

const CheckoutSidebar = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const { offer: offerStore } = useStore();

  const { offer } = offerStore;
  const { option: selectedOptionPolicyType } = useParams();

  if (!offerStore?.offer) {
    return <Loading />;
  }

  const selectedOption = getQuoteFriendlySelectedOption(selectedOptionPolicyType);
  const { hasHome, hasAuto, isRenters } = getPolicyType(
    selectedOption,
    offer?.quote?.noBindHome,
    offer?.quote?.noBindAuto
  );

  const isStandaloneRenters = isRenters && standaloneRentersStates[offerStore.state];

  const isHomeMonthly = offer?.quote?.global?.homeownersPaymentType === paymentType.Monthly;
  const isAutoMonthly = offer?.quote?.global?.autoPaymentType === paymentType.Monthly;
  const isRentersMonthly = offer?.quote?.global?.rentersPaymentType === paymentType.Monthly;

  const prices = getDetailedPrice(offer, selectedOption, { isHomeMonthly, isAutoMonthly, isRentersMonthly });

  let option;

  if (offer?.options) {
    option = offer?.options?.find((opt) => opt.type === selectedOption);
  }

  return (
    <Grid>
      <Card className={classes.checkoutContainer}>
        {hasHome && hasAuto && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Home and Auto
          </Label>
        )}
        {hasAuto && isRenters && (
          <Label type="policyLabel" className={classes.checkoutHeader}>
            Auto and Renters
          </Label>
        )}
        {hasHome && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Home
            </Label>
            <PriceDetail
              name="Home"
              type="Home"
              isMonthly={isHomeMonthly}
              downPaymentDate={prices?.homeDownPaymentDate}
              remainingPayments={prices?.homeRemainingPayments}
              remainingPaymentsAmount={prices?.homeRemainingPaymentsAmount}
              payment={prices?.homePrice}
              paymentMethod={
                lookupsJson.homeownersPaymentMethod.find((t) => t.id === offer?.quote?.global?.homeownersPaymentMethod)
                  .value
              } //
              paymentType={
                lookupsJson.paymentType.find((t) => t.id === offer?.quote?.global?.homeownersPaymentType).value
              }
              termTotal={option?.homeTotal}
              policyType={option?.type}
              nextPayments={prices?.homeNextPayments}
            />
          </div>
        )}
        {hasAuto && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Auto
            </Label>
            <PriceDetail
              name="Auto"
              type="Auto"
              isMonthly={isAutoMonthly}
              downPaymentDate={prices?.autoDownPaymentDate}
              remainingPayments={prices?.autoRemainingPayments}
              remainingPaymentsAmount={prices?.autoRemainingPaymentsAmount}
              payment={prices?.autoPrice}
              paymentMethod={
                lookupsJson.autoPaymentMethod.find((t) => t.id === offer?.quote?.global?.autoPaymentMethod).value
              }
              paymentType={lookupsJson.paymentType.find((t) => t.id === offer?.quote?.global?.autoPaymentType).value}
              termTotal={option?.autoTotal}
              policyType={option?.type}
              autoTotalAR={isRenters ? numberFormatter(prices.autoTotalAR, 2) : null}
              rentersTotalAR={isRenters ? numberFormatter(prices.rentersTotalAR, 2) : null}
              nextPayments={prices?.autoNextPayments}
              standaloneRenters={isStandaloneRenters}
            />
          </div>
        )}
        {isStandaloneRenters && (
          <div className={classes.checkoutPolicySection}>
            <Label type="policyLabel" className={classes.policyHeader}>
              Renters
            </Label>
            <PriceDetail
              name="Renters"
              type="Renters"
              isMonthly={isRentersMonthly}
              downPaymentDate={prices?.rentersDownPaymentDate}
              remainingPayments={prices?.rentersRemainingPayments}
              remainingPaymentsAmount={prices?.rentersRemainingPaymentsAmount}
              payment={prices?.rentersPrice}
              paymentMethod={
                lookupsJson.rentersPaymentMethod.find((t) => t.id === offer?.quote?.global?.rentersPaymentMethod).value
              }
              paymentType={
                lookupsJson.rentersPaymentType.find((t) => t.id === offer?.quote?.global?.rentersPaymentType).value
              }
              termTotal={option?.rentersTotal}
              policyType={option?.type}
              nextPayments={prices?.rentersNextPayments}
              standaloneRenters
            />
          </div>
        )}
        <Button
          className={classes.backLink}
          onClick={() => history.push(`/offer/${offer.id}?selectedOption=${selectedOption}`)} // fixme have to set in store
        >
          Back to modify payment options
        </Button>
      </Card>
      {offerStore.shouldShowAdditionalPaymentsSection && <Additionals />}
      <Downloads />
    </Grid>
  );
});

export default CheckoutSidebar;
