import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { lazy, memo, Suspense } from 'react';
import { useDisabledState } from '../../../common/disabled-context/context';
import { fieldTypes } from './field-types';
import styles from './form.styles';
import withSpacing from './with-spacing';

const Submit = lazy(fieldTypes.submit);

const FormSubmit = memo(({ className, ...props }) => {
  const { disabled: disabledContext } = useDisabledState();
  return (
    <Suspense fallback={null}>
      <Submit disabled={!!disabledContext} className={classNames(className, 'no-margin-bottom')} {...props} />
    </Suspense>
    // TODO: Remove "no-margin-bottom" class once we migrate to use new forms in the whole application
  );
});

FormSubmit.propTypes = {
  props: PropTypes.object,
  className: PropTypes.string
};

FormSubmit.defaultProps = {
  props: {},
  className: ''
};

export default flowRight(withStyles(styles), withSpacing)(FormSubmit);
