import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Grid } from '@material-ui/core';

import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import useStyles from './incomplete-tasks-notification.styles';

const IncompleteTasksNotification = ({ fromPolicy }) => {
  const classes = useStyles();
  const {
    account: { id, incompleteTasks, fname, lname }
  } = useStore();
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.push(`/customer/${id}`);
  }, [id, history]);

  return (
    <Grid container alignItems="center" justify="space-between">
      <Label className={classes.warning}>
        {incompleteTasks.length} incomplete onboarding tasks remain for {fname} {lname}
      </Label>
      {fromPolicy && (
        <Button color="secondary" variant="contained" className={classes.button} onClick={handleClick}>
          Go to customer
        </Button>
      )}
    </Grid>
  );
};

IncompleteTasksNotification.propTypes = {
  fromPolicy: PropTypes.bool
};

IncompleteTasksNotification.defaultProps = {
  fromPolicy: false
};

export default observer(IncompleteTasksNotification);
