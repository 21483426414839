import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';

import { AuthContext } from 'core/components/auth';
import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import editIcon from './edit-icon.svg';
import useStyles from './edit-referral.styles';

const EditReferral = ({ inviter }) => {
  const { viewOnly, isAgency } = useContext(AuthContext);
  const classes = useStyles();
  const {
    referralSearch: { setSearchString, setShowReferralMatches, searchCustomersAction }
  } = useStore();

  const hasInviter = inviter && inviter.firstName && inviter.lastName;

  const canAddReferral = !viewOnly && !isAgency;

  const onEditClick = () => {
    if (!canAddReferral) return;
    if (hasInviter) {
      setSearchString(`${inviter.firstName} ${inviter.lastName}`);
      searchCustomersAction();
    }
    setShowReferralMatches(true);
  };

  if (!hasInviter && !canAddReferral) {
    return null;
  }

  return (
    <Grid container alignItems="center" className={classes.container}>
      <Label type="noteSmall" className={classes.label}>
        REFERRED BY{' '}
      </Label>
      <Label
        className={!canAddReferral ? classes.inviter : classes.link}
        type="infoSubtitleOrange"
        onClick={onEditClick}
      >
        {hasInviter && (
          <>
            {inviter.firstName} {inviter.lastName}
            {canAddReferral && <img src={editIcon} alt="square-and-pencil" />}
          </>
        )}
        {!hasInviter && canAddReferral && 'Add a referral'}
      </Label>
    </Grid>
  );
};

EditReferral.propTypes = {
  inviter: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  })
};

EditReferral.defaultProps = {
  inviter: null
};

export default EditReferral;
