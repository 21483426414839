import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  listBullets: {
    color: theme.colorPalette.beige_10,
    paddingBottom: theme.spacing(2),
    '&:last-child': {
      paddingBottom: 0
    }
  },
  privacyLink: {
    color: theme.colorPalette.orange_10
  },
  agreementCheckbox: {
    height: 'auto',
    margin: '15px 0 !important'
  },
  spacing: {
    paddingTop: theme.spacing(6)
  },
  autoWaterShutoff: {
    marginTop: theme.spacing(4)
  },
  disclaimer: {
    margin: theme.spacing(7, 0, 8)
  }
}));

export default useStyles;
