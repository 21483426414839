/* eslint-disable func-names */
import * as Yup from 'yup';

export const UWPropertyValidationSchema = () =>
  Yup.object().shape({
    signedPropertyUWAttestation: Yup.boolean().oneOf([true], `Agent must check attestation checkbox`).required(),
    noAsbestosCheck: Yup.string().test('noAsbestosCheck', 'noAsbestosCheck required', function (value) {
      const needsValidation = !!this.options.context?.UWConditions.noAsbestosCheck;
      if (needsValidation) {
        return value !== 'Yes';
      }
      return true; // node is not part of initial values and not being rendered, force pass here
    }),
    homeWiringCheck: Yup.string().test('homeWiringCheck', 'homeWiringCheck required', function (value) {
      const needsValidation = !!this.options.context?.homeWiringCheck;
      if (needsValidation) {
        return value !== 'No';
      }
      return true; // node is not part of initial values and not being rendered, force pass here
    }),
    homePlumbingCheck: Yup.string().test('homePlumbingCheck', 'homePlumbingCheck required', function (value) {
      const needsValidation = !!this.options.context?.homePlumbingCheck;
      if (needsValidation) {
        return value !== 'No';
      }
      return true; // node is not part of initial values and not being rendered, force pass here
    }),
    noOpenFoundationCheck: Yup.string().test(
      'noOpenFoundationCheck',
      'noOpenFoundationCheck required',
      function (value) {
        const needsValidation = !!this.options.context?.noOpenFoundationCheck;
        if (needsValidation) {
          return value !== 'No';
        }
        return true; // node is not part of initial values and not being rendered, force pass here
      }
    )
  });
