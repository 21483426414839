import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    height: 34,
    alignItems: 'flex-end',
    fontWeight: 300,
    paddingLeft: 8
  },
  footerContainer: {
    height: 98,
    display: 'flex',
    background: theme.colorPalette.beige_10,
    boxShadow: '0px -1px 3px 0px rgba(0,0,0,0.2)'
  },
  footerAlert: {
    background: theme.colorPalette.orange_10
  },
  floatingContainer: {
    position: 'fixed',
    left: 0,
    width: '100%',
    bottom: 0,
    zIndex: 1000
  },
  footerContent: {
    width: '100%',
    maxWidth: 1280,
    height: 98,
    margin: '0 auto'
  },
  subtitle: {
    marginLeft: '3px'
  },
  subtitleLink: {
    color: 'white',
    borderBottom: '1px solid white',
    marginLeft: '3px'
  }
}));

export default useStyles;
