import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { withStyles } from '@material-ui/core/styles';

import styles from './header-cell.styles';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';

const wrapWithTooltip = (tooltipText, content) => {
  if (tooltipText) {
    return (
      <Tooltip title={tooltipText} placement="bottom-end" enterDelay={300}>
        {content}
      </Tooltip>
    );
  }
  return content;
};

const HeaderCell = memo(({ classes, columnId, direction, active, children, tooltipText, onSort, mode }) => (
  <TableCell classes={mode && MapClassesToElem(`${mode}-${ElemType.TableCell}`, classes)}>
    {children &&
      wrapWithTooltip(
        tooltipText,
        onSort ? (
          <TableSortLabel
            classes={mode && MapClassesToElem(`${mode}-${ElemType.TableSortLabel}`, classes)}
            active={active}
            direction={direction}
            onClick={() => onSort(columnId)}
            IconComponent={KeyboardArrowDownIcon}
          >
            {children}
          </TableSortLabel>
        ) : (
          children
        )
      )}
  </TableCell>
));

HeaderCell.propTypes = {
  columnId: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  onSort: PropTypes.func,
  children: PropTypes.node,
  direction: PropTypes.oneOf(['asc', 'desc']),
  active: PropTypes.bool,
  mode: PropTypes.oneOf(['dark']),
  classes: PropTypes.object.isRequired
};

HeaderCell.defaultProps = {
  tooltipText: null,
  mode: null,
  onSort: undefined,
  children: undefined,
  direction: undefined,
  active: false
};
export default withStyles(styles)(HeaderCell);
