import React, { useRef } from 'react';
import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog/base-dialog';
import { Label } from 'core/components/label';
import { FormField } from 'core/components/form';

import useStyles from './edit-home-location-details-modal.styles';

const EditHomeLocationDetailsModal = ({ open, onClose }) => {
  const classes = useStyles();
  const { values, setFieldValue } = useFormikContext();

  const {
    home: { homeLocation }
  } = values;
  const startingAddress = useRef(homeLocation);

  const onSubmit = () => {
    setFieldValue('home.homeLocation.address', homeLocation.address.toUpperCase());
    setFieldValue('home.homeLocation.city', homeLocation.city.toUpperCase());
    setFieldValue('correctedAddress.address', homeLocation.address.toUpperCase());
    setFieldValue('correctedAddress.city', homeLocation.city.toUpperCase());
    onClose(false);
  };

  const onExit = () => {
    setFieldValue('home.homeLocation.address', startingAddress.current.address);
    setFieldValue('home.homeLocation.city', startingAddress.current.city);
    onClose(false);
  };

  return (
    <BaseDialog open={open} onClose={onExit} size="md">
      <Label type="greenBig" className={classes.modalHeader}>
        Modify Home Address
      </Label>
      <Label type="greenSmall" className={classes.titleSecondary}>
        Change the address of the insured property as it will appear on documents
      </Label>
      <Grid container direction="column" className={classes.addressContainer}>
        <FormField
          name="home.homeLocation.address"
          type="string"
          label="street address"
          mode="light"
          xs={6}
          className={classes.addressField}
        />
        <FormField
          name="home.homeLocation.city"
          type="string"
          label="city"
          mode="light"
          xs={6}
          className={classes.addressField}
        />
        <FormField
          name="stateAndZip"
          type="string"
          label="state/zip"
          mode="light"
          xs={6}
          value={`${homeLocation.state}/${homeLocation.zip}`}
          disabled
          className={classes.addressField}
        />
      </Grid>
      <Button className={classes.saveBtn} variant="contained" color="secondary" onClick={onSubmit}>
        Save Changes
      </Button>
    </BaseDialog>
  );
};

export default EditHomeLocationDetailsModal;
