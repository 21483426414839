const styles = (theme) => ({
  modalPaper: {
    maxWidth: 800,
    width: 720
  },
  centered: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  inline: {
    display: 'inline-block'
  },
  mContent: {
    width: 720,
    minHeight: 290,
    position: 'relative',
    flexDirection: 'column',
    display: 'flex',
    backgroundColor: theme.colors.form__background_primary,
    padding: 50
  },
  closeButton: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  loadingContainer: {
    textAlign: 'center'
  },
  errors: {
    color: theme.colors.error
  },
  title: {
    paddingBottom: 32,
    paddingTop: 24
  },
  arrow: {
    display: 'block'
  },
  priceInfo: {
    color: theme.colorPalette.green_20,
    marginBottom: 20
  },
  previewInfo: {
    marginBottom: 16
  },
  boldPrice: {
    fontWeight: 'bold',
    paddingLeft: 4
  },
  fields: {
    paddingBottom: 8
  },
  submit: {
    marginTop: 20
  },
  submitWarning: {
    marginTop: 20
  },
  orangeLink: {
    color: 'orange'
  },
  priceDiff: {
    marginBottom: 40
  }
});

export default styles;
