import React from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';

import { Button } from 'core/components/button';
import BaseDialog from 'core/components/base-dialog';
import { Label } from 'core/components/label';

import useStyles from './no-home-modal.styles';

const NoHomeModal = observer(({ setShowNoHomeModal }) => {
  const { handleSubmit, setFieldValue, setFieldTouched } = useFormikContext();

  const classes = useStyles();

  const onClick = async () => {
    await setFieldValue('ineligibleForHomeDueToUWSelfReport', true);
    await setFieldTouched('ineligibleForHomeDueToUWSelfReport');

    handleSubmit();
  };

  return (
    <BaseDialog onClose={() => setShowNoHomeModal(false)} open>
      <Label className={classes.mainLabel} type="greenBig">
        Based on the provided answers, we are unable to offer homeowner's insurance on this property.
      </Label>
      <Label className={classes.notificationContent}>
        This property does not meet Branch underwriting requirements. If you have questions, please contact your manager
        or Agency Success Manager.
      </Label>
      <Grid className={classes.buttonContainer}>
        <Button className={classes.buttons} variant="contained" color="primary" onClick={onClick}>
          Return to modify offer without home
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setShowNoHomeModal(false)}
          className={classes.buttons}
        >
          Go back
        </Button>
      </Grid>
    </BaseDialog>
  );
});

export default NoHomeModal;
