import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  label: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    padding: theme.spacing(0, 7)
  },
  row: {
    marginBottom: theme.spacing(3),
    padding: theme.spacing(0, 8)
  },
  billingDetailsContainer: {
    margin: 'unset',
    marginTop: theme.spacing(3)
  },
  bankAccounts: {
    width: '100%',
    margin: theme.spacing(2, 0, 2, 0.5)
  },
  separator: {
    width: 1,
    height: 28,
    margin: theme.spacing('auto', 2),
    backgroundColor: theme.colorPalette.white_10_op_30
  },
  checkImage: {
    width: 72,
    height: 36
  }
});

export default makeStyles(styles);
