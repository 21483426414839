import gql from 'graphql-tag';

export const PolicyPreviewResponse = gql`
  fragment PolicyPreviewResponse on PolicyPreview {
    id
    coverageChange
    itemsChanged
    billingDescription
    newPremium
    newTotalPrice
    clarionDoorIds {
      requestIdBeforePreview
      requestIdAfterPreview
    }
    feesChanged
    nextPayments {
      date
      remainingMonthly
    }
    diff
    skipImmediateBillOrRefund
  }
`;

export const Address = gql`
  fragment Address on AddressDetails {
    address
    address2
    unit
    country
    city
    state
    zip
  }
`;

export const People = gql`
  fragment People on PersonDetails {
    id
    age
    isPrimary
    isCoApplicant
    firstName
    middleName
    lastName
    suffix
    gender
    excludeDriver
    maritalStatus
    takenCourse
    courseDiscountConditionCheck
    highestEducation
    dateOfBirth
    driversLicenseNumber
    driversLicenseState
    ageFirstLicensed
    fullTimeStudent
    goodStudent
    schoolName
    addDate
    communityDriveParticipation
    needSR22
    insuranceScore {
      homeTotal
      autoTotal
      total
    }
    schoolLocation {
      ...Address
    }
    autoViolationDates
    waivedPIPWL
    pipEligible
    pipOptOut
    pipHasQHC
    assignedCar
    autoViolations {
      AAF
      AFM
      ANC
      ANO
      ASW
      BOT
      CCW
      CML
      CMP
      CMPTV
      CMU
      CRD
      DEQ
      DEV
      DR
      DWI
      FAR
      FDL
      FEL
      FFR
      FLE
      FRA
      FTC
      FTY
      HOM
      IBK
      IP
      IT
      LDL
      LIC
      LTS
      MAJ
      MMV
      NAF
      NFX
      PUA
      PUN
      REF
      RKD
      SAF
      SCH
      SLV
      SPD
      SUS
      TMP
      UDR
      WSR
    }
  }
`;
