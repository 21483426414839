import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  applyDiscountBtn: {
    width: '200px',
    margin: 'auto'
  }
}));

export default useStyles;
