import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';

import { Table } from 'core';
import { Label } from 'core/components/label';
import NoResults from '../no-results';
import Row from './row';
import Header from './header';
import useStyles from './customer-results.styles';

const CustomersResults = ({
  loading,
  hits,
  totalHits,
  page,
  onChangePage,
  rowClickHandler,
  columnsToUse,
  showTitle,
  useLink,
  selectFieldName,
  mode,
  noDataMessage
}) => {
  const classes = useStyles({ mode });
  const handleChangePage = useCallback((_, newPage) => onChangePage(newPage), [onChangePage]);

  return (
    <>
      {showTitle && (
        <Label type="subtitle" style={{ marginBottom: 16 }}>
          Customers
        </Label>
      )}
      <Paper
        square={mode === 'dropdown'}
        elevation={mode === 'dropdown' ? 0 : null}
        className={classes.resultsContainer}
      >
        {Boolean(loading || hits.length) && (
          <Table
            selfContained={false}
            loading={loading}
            paginationEnabled={mode !== 'dropdown'}
            count={totalHits}
            rowsPerPage={hits.length}
            currentPage={page}
            onChangePage={handleChangePage}
            header={mode !== 'dropdown' ? <Header columnsToUse={columnsToUse} /> : null}
            body={
              Boolean(hits && hits.length) &&
              hits.map((hit, ind) => (
                <Row
                  key={`hit-${ind}`}
                  hit={hit}
                  onClick={rowClickHandler}
                  useLink={useLink}
                  columnsToUse={columnsToUse}
                  selectFieldName={selectFieldName}
                />
              ))
            }
          />
        )}
        {Boolean(!loading && hits.length === 0 && noDataMessage) && (
          <NoResults className={classes.resultsContainer} message={noDataMessage} />
        )}
      </Paper>
    </>
  );
};

CustomersResults.propTypes = {
  loading: PropTypes.bool.isRequired,
  hits: PropTypes.array,
  totalHits: PropTypes.number,
  page: PropTypes.number,
  onChangePage: PropTypes.func,
  rowClickHandler: PropTypes.func,
  columnsToUse: PropTypes.arrayOf(
    PropTypes.oneOf(['select', 'id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'phone', 'created'])
  ),
  showTitle: PropTypes.bool,
  useLink: PropTypes.bool,
  mode: PropTypes.string,
  selectFieldName: PropTypes.string,
  noDataMessage: PropTypes.string
};

CustomersResults.defaultProps = {
  hits: [],
  page: 1,
  onChangePage: null,
  totalHits: 0,
  rowClickHandler: null,
  columnsToUse: ['id', 'fname', 'lname', 'address', 'city', 'state', 'zip', 'created'],
  showTitle: true,
  useLink: false,
  mode: '',
  selectFieldName: null,
  noDataMessage: null
};

export default CustomersResults;
