import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  accountCard: {
    padding: 32,
    position: 'relative',
    overflowWrap: 'anywhere'
  },
  buttonContainer: {
    marginLeft: 4,
    marginRight: 4,
    width: '100%'
  },
  inviterLink: {
    color: theme.colorPalette.orange_10
  },
  secondaryPhoneContainer: {
    border: '3px solid white',
    borderRadius: 3,
    padding: '10px 20px 10px 20px',
    margin: '15px 0px'
  }
});

export default makeStyles(styles);
