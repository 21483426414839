import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FastField, Field } from 'formik';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import React, { lazy, Suspense, memo, useMemo } from 'react';

import { CognitoPermissionGroups, PermissionRequireTypes } from 'core/helpers/cognito-permission-groups';
import { useFormFieldPermissionHelpers, defaultPermissions } from 'core/hooks/use-form-field-permission-helper';
import { useDisabledState } from 'common/disabled-context/context';
import { fieldTypes } from './field-types';
import styles from './form.styles';
import withSpacing from './with-spacing';

const ValidPermissionProp = (propValue, key) => {
  const permission = propValue[key];
  if (!CognitoPermissionGroups[permission]) {
    return new Error(`Invalid 'permissions' group prop with value ${permission} supplied to 'FormField'`);
  }
};

const FormField = memo(
  ({
    fieldProps,
    type,
    props: elementProps,
    className,
    fast,
    permissions,
    disabled: manuallyDisabled,
    ignoreGlobalDisabledState,
    ...props
  }) => {
    const { disabled: disabledContext } = useDisabledState();
    const { id, name, ...rest } = props;
    const FieldComponent = useMemo(() => lazy(fieldTypes[type]), [type]);
    const { allowedToModify } = useFormFieldPermissionHelpers({ permissions });
    const disableField = (!ignoreGlobalDisabledState && !!disabledContext) || !allowedToModify || manuallyDisabled;
    const shouldUseFastField = !disableField && fast;
    const FieldProvider = useMemo(() => (shouldUseFastField ? FastField : Field), [shouldUseFastField]);

    return (
      <Suspense fallback={null}>
        <FieldProvider id={id || name} name={name}>
          {({ form, field: { value } }) => (
            <FieldComponent
              field={{
                id: id || name,
                name,
                value,
                disabled: disableField,
                // TODO: Remove "no-margin-bottom" class once we migrate to use new forms in the whole application
                className: classNames(className, 'no-margin-bottom'),
                ...elementProps,
                ...rest
              }}
              ctx={{
                classes: {},
                ...form
              }}
              fieldProps={fieldProps}
            />
          )}
        </FieldProvider>
      </Suspense>
    );
  }
);

FormField.propTypes = {
  fieldProps: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  mode: PropTypes.string,
  props: PropTypes.object,
  className: PropTypes.string,
  fast: PropTypes.bool,
  ignoreGlobalDisabledState: PropTypes.bool,
  permissions: PropTypes.shape({
    isLicensedAction: PropTypes.bool,
    edit: PropTypes.shape({
      groups: PropTypes.arrayOf(ValidPermissionProp),
      type: PropTypes.oneOf([PermissionRequireTypes.All, PermissionRequireTypes.AtLeastOne])
    })
  })
};

FormField.defaultProps = {
  fieldProps: {},
  label: undefined,
  mode: undefined,
  props: {},
  className: '',
  id: '',
  fast: true,
  ignoreGlobalDisabledState: false,
  permissions: defaultPermissions
};

export default flowRight(withStyles(styles), withSpacing)(FormField);
