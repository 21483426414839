import React, { useCallback, useState } from 'react';
import { Button, LinearProgress } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { Label, Select } from 'core';
import styles from './regenerate-document.styles';

const RegenerateDocument = observer(function RegenerateDocument({
  classes,
  onRegenerate,
  regenerating,
  documentPolicyId
}) {
  const [policyId, setPolicyId] = useState(documentPolicyId);

  const { account } = useStore();
  const policies = account.policies.list;
  const regenerateCallback = useCallback(() => {
    const currentPolicy = policies.find((p) => p.id === policyId);
    if (currentPolicy) {
      const policyTypeNames = {
        H: 'Home',
        A: 'Auto',
        R: 'Renters'
      };
      onRegenerate(policyId, policyTypeNames[currentPolicy.policyType]);
    }
  }, [onRegenerate, policies, policyId]);
  return (
    <>
      <div className={classNames(classes.regenerateDocContainer, { [classes.withDocumentPolicy]: documentPolicyId })}>
        <Label type="greenSmall">Need to Recreate policy’s documents?</Label>
        <div className={classNames(classes.actions, documentPolicyId && classes.withDocumentAction)}>
          {!documentPolicyId && (
            <Select
              className={classes.selectPolicy}
              name="policies"
              onChange={(name, value) => {
                setPolicyId(value);
              }}
              value={policyId || ''}
              options={policies.map(({ id }) => ({
                id,
                value: id.slice(10)
              }))}
              label="Select a Policy"
              mode="light"
              inputProps={{ 'aria-label': 'Select a Policy' }}
            />
          )}

          <div
            className={classNames(classes.buttonContainer, {
              [classes.regeneratingDocs]: regenerating
            })}
          >
            <Button
              disabled={!policyId || regenerating}
              key="addButton"
              onClick={regenerateCallback}
              mode="big"
              type="submit"
              variant="contained"
              color="secondary"
            >
              Recreate Policy Documents
            </Button>
          </div>
        </div>
      </div>
      {regenerating && <LinearProgress color="secondary" />}
    </>
  );
});

RegenerateDocument.propTypes = {
  onRegenerate: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  policies: PropTypes.array.isRequired,
  regenerating: PropTypes.bool,
  documentPolicyId: PropTypes.string
};

RegenerateDocument.defaultProps = {
  regenerating: false,
  documentPolicyId: null
};

export default withStyles(styles)(RegenerateDocument);
