import React from 'react';
import { ConnectedHomeForm } from '../connected-home-form';
import { ConnectedHomeFormFields } from '../connected-home-form-fields';

/*
Read about the business logic behind Connected Home here:
https://www.notion.so/branch/All-About-Affinity-and-Connected-Home-Discounts-c4f5abad218049e0a37991cd60157f41
*/

const ConnectedHomeSettings = ({ comingFromModal = false }) => {
  const mode = comingFromModal ? 'light' : 'dark';

  return comingFromModal ? <ConnectedHomeForm mode={mode} /> : <ConnectedHomeFormFields mode={mode} />;
};

export default ConnectedHomeSettings;
