import React, { Suspense, useCallback, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Grid, Dialog, DialogContent } from '@material-ui/core';

import x from 'core/assets/svg/x.svg';
import { ActionButton } from 'core/components/action-button';
import { Button } from 'core/components/button';
import { Label } from 'core/components/label';
import { NotificationCard } from 'core/components/notification-card';
import { SearchBar } from 'core/components/search-bar';
import CustomersResults from 'core/components/search-results/customers-results';
import { useStore } from 'core/store';
import useStyles from './search-dialog.styles';
import { FormField } from 'core/components/form';

const schema = (referralsEmail) =>
  Yup.object().shape({
    email: Yup.string()
      .trim()
      .email()
      .nullable()
      .required(
        'Email to receive gift card is required. If the referred is unwilling to provide an email address, please follow the appropriate process to complete the request.'
      )
      .test(
        'is-valid-email',
        'The email provided is the email of the referrer. Please provide the email of the referred to complete this request.',
        (email) => email && referralsEmail && email.toLowerCase().trim() !== referralsEmail.toLowerCase().trim()
      )
  });

const SearchReferralDialog = observer(() => {
  const classes = useStyles();
  const history = useHistory();
  const {
    referralSearch: {
      customersResults,
      showReferralMatchesModal,
      clearInput,
      resetCustomerResults,
      searchCustomersAction,
      setShowReferralMatches,
      currentResultsPage,
      setCurrentResultsPage,
      loading,
      searchString,
      setSearchString
    },
    offer: { addReferral, addingReferral, offer }
  } = useStore();
  const { inviter } = offer.quote;
  const [referral, setReferral] = useState(inviter);

  const isSameReferral = referral && inviter && inviter.id === referral.customerId;

  const isButtonDisabled = !searchString || !referral || addingReferral || isSameReferral;

  const handleRowClick = useCallback(
    (id, value) => {
      setReferral({
        customerId: id,
        firstName: value.attributes.name,
        lastName: value.attributes.family_name,
        email: value.attributes.email
      });
    },
    [setReferral]
  );

  const onClose = useCallback(() => {
    resetCustomerResults();
    clearInput();
    setShowReferralMatches(false);
  }, [resetCustomerResults, clearInput, setShowReferralMatches]);

  const onSubmit = useCallback(
    ({ email }) => {
      addReferral({ ...referral, quoterEmail: email });
    },
    [referral, addReferral]
  );

  const onSearchStringChange = useCallback(
    (event) => {
      setSearchString(event.target.value);
      if (event.target.value) {
        searchCustomersAction();
      } else {
        resetCustomerResults();
      }
    },
    [setSearchString, searchCustomersAction, resetCustomerResults]
  );

  const onChangePage = useCallback(
    (page) => {
      setCurrentResultsPage(page);
      searchCustomersAction();
    },
    [setCurrentResultsPage, searchCustomersAction]
  );

  return (
    <Dialog maxWidth="md" fullWidth open={showReferralMatchesModal} onClose={onClose}>
      <DialogContent className={classes.searchDialog}>
        <Suspense fallback={null}>
          <Formik
            enableReinitialize
            initialValues={{
              id: inviter ? inviter.id : '',
              email: offer ? offer.quote.email : ''
            }}
            validationSchema={schema(referral?.email)}
            onSubmit={onSubmit}
          >
            {({ setFieldValue }) => (
              <Form>
                <Grid container>
                  <Grid item xs={12} className={classes.searchHeader}>
                    <Label type="greenBig" className={classes.modalHeader}>
                      Add a referrer
                    </Label>
                  </Grid>
                  <Grid item xs={7} className={classes.searchBar}>
                    <SearchBar
                      onCloseIconClick={clearInput}
                      onInputChange={onSearchStringChange}
                      value={searchString}
                      label="Search"
                    />
                  </Grid>
                </Grid>
                <CustomersResults
                  loading={loading}
                  hits={customersResults.hits}
                  totalHits={customersResults.nbHits}
                  page={currentResultsPage}
                  onChangePage={onChangePage}
                  rowClickHandler={(id, value) => {
                    setFieldValue('id', id);
                    handleRowClick(id, value);
                  }}
                  history={history}
                  mode="modal"
                  showTitle={false}
                  columnsToUse={['select', 'fname', 'lname', 'address', 'city', 'state', 'phone']}
                  selectFieldName="id"
                  noDataMessage={
                    !searchString ? 'Begin by searching for a referrer' : `There are no results for “${searchString}”.`
                  }
                />
                <Grid container className={classes.footer}>
                  <NotificationCard className={classes.notificationCard} type="light">
                    <Label className={classes.notification}>
                      Adding a referral to this offer will trigger a gift card for the referring member. The referred
                      quoter will also receive a gift card if you <strong>enter their email below</strong>. Please note
                      that this action does not add “My Community Discount” to the offer.
                    </Label>
                  </NotificationCard>
                  <FormField
                    className={classes.email}
                    type="string"
                    name="email"
                    label="Email to receive gift card"
                    placeholder="Email"
                    mode="light"
                  />
                  <Button
                    type="submit"
                    loading={loading || addingReferral}
                    variant="contained"
                    color="secondary"
                    disabled={isButtonDisabled}
                  >
                    Add as referrer
                  </Button>
                </Grid>
              </Form>
            )}
          </Formik>
        </Suspense>
        <ActionButton size="big" type="edit" icon={x} className={classes.closeButton} onClick={onClose} />
      </DialogContent>
    </Dialog>
  );
});

export default SearchReferralDialog;
