import { policyTypes } from '@ourbranch/policy-types';
import { fixableRejectionCodes } from '@ourbranch/lookups';

export default {
  Offer: {
    AbodeProPlan: 'AbodeProPlan',
    ConnectedHome: 'ConnectedHome',
    NewConnectedHomeSignUpCustomer: 'NewConnectedHomeSignUpCustomer',
    PriorQuoteWithSelfReportedHomeUWFlags: 'PriorQuoteWithSelfReportedUWFlags',
    OfferNeedsUWReviewForHome: 'OfferNeedsUWReviewForHome',
    SuspiciousAccount: 'SuspiciousAccount'
  },
  Policy: {
    CrossSellEligibility: 'CrossSellEligibility',
    SignedConversionDocuments: 'SignedConversionDocuments',
    PendingThirdPartyPurchases: 'PendingThirdPartyPurchases',
    SuspiciousAccount: 'SuspiciousAccount',
    IncompleteTasks: 'IncompleteTasks'
  },
  Customer: {
    CrossSellEligibility: 'CrossSellEligibility',
    SuspiciousAccount: 'SuspiciousAccount',
    IncompleteTasks: 'IncompleteTasks'
  }
};

export function isPolicyCrossSellEligible({ offer, policyType, policyDetails }) {
  const { offerAuto, autoRejectCode, homeownersRejectCode, offerHomeowners } = offer?.quote?.offerings;

  const canCrossSellHome =
    !policyDetails?.includeRenters &&
    offerHomeowners &&
    (!homeownersRejectCode || fixableRejectionCodes.includes(homeownersRejectCode));

  const canCrossSellAuto = offerAuto && (!autoRejectCode || fixableRejectionCodes.includes(autoRejectCode));

  const isCrossSellEligible = policyType === policyTypes.Home ? canCrossSellAuto : canCrossSellHome;
  return isCrossSellEligible;
}
