import React, { useState } from 'react';
import { observer } from 'mobx-react';
import Button from '@material-ui/core/Button';
import classNames from 'classnames';

import { useStore } from 'core/store';
import { useToast } from 'core/components/toast';
import flagIcon from 'core/assets/svg/ic-flag.svg';
import unflagIcon from 'core/assets/svg/ic-unflag.svg';
import SuspiciousAccountModal from './suspicious-modal';
import useStyles from '../account-details-display/account-details-display.styles';

const Suspicious = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { account } = useStore();
  const classes = useStyles();
  const toast = useToast();

  const suspicious = account?.customerMetadata?.suspiciousActivity?.flagged;

  const toggleSuspiciousFlag = async () => {
    setLoading(true);
    const res = await account.toggleSupiciousFlag();
    if (res.error) {
      toast.notify({
        type: 'error',
        message: `There was an error flagging the customer - ${res.error}`
      });
    } else {
      toast.notify({
        type: 'success',
        message: 'Success'
      });
    }
    setLoading(false);
    setOpen(false);
  };

  return (
    <>
      <Button
        disabled={loading}
        disableFocusRipple
        className={classes.actionContainer}
        onClick={() => setOpen(true)}
        variant="text"
        color="secondary"
      >
        <img
          alt="flag icon"
          className={classNames(classes.icon, { [classes.disabledIcon]: loading })}
          src={suspicious ? unflagIcon : flagIcon}
        />
        {suspicious ? 'Un-flag as suspicious' : 'Flag as suspicious'}
      </Button>
      <SuspiciousAccountModal
        open={open}
        onClose={() => setOpen(false)}
        toggleSuspiciousFlag={toggleSuspiciousFlag}
        loading={loading}
        suspicious={suspicious}
      />
    </>
  );
};

export default observer(Suspicious);
