import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { useDisabledState } from 'common/disabled-context';
import Section from 'core/components/section';
import { Card } from 'core/components/card';
import People from 'common/components/people';
import AddApplicant from 'common/components/people/add-form';
import Applicant from 'common/components/people/applicant';
import Discounts from 'common/components/discounts';
import { SchedulePP } from 'common/components/schedule-pp';
import { RentersAddressForm } from './renters-address-form';
import useStyles from './renters.styles';
import { RentersCoverage } from './renters-coverage';

function RentersPolicy() {
  const classes = useStyles();
  const session = useContext(AuthContext);
  const {
    account: {
      policies: { policy: policyStore }
    }
  } = useStore();
  const { disabled } = useDisabledState();

  const allowLicensedActions = policyStore.getAllowLicensedActions(session);

  return (
    <>
      <People
        disabled={disabled || !allowLicensedActions}
        id="people"
        title="People"
        person={Applicant}
        addForm={AddApplicant}
        addLabel="Add People"
        fromPolicy
      />
      <Section title="Renters Property Details" type="SubSection">
        <Card className={classes.container}>
          <RentersAddressForm />
        </Card>
      </Section>
      <Section title="Renters Property Coverage" type="SubSection">
        <Card>
          <RentersCoverage state={policyStore.geographicState} />
        </Card>
      </Section>

      <Discounts disabled={disabled} rentersDiscounts />
      <SchedulePP disabled={disabled} />
    </>
  );
}

RentersPolicy.propTypes = {
  policy: PropTypes.object.isRequired
};

export default observer(RentersPolicy);
