import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import reverse from 'lodash-es/reverse';
import sortBy from 'lodash-es/sortBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ElemType, MapClassesToElem } from '../../helpers/styles-helpers';
import { Table } from '../table';
import TableHeaderCell from './header-cell';
import styles from './sortable.styles';

const renderRow = ({ row, onClick, dataCy, headers, mode, classes, formatters }) => {
  // allows for a row to be a different color mode than the rest of the table
  // example: mode="red" for cancelled policy row, but rest of table is mode="dark"
  const { rowMode } = row;
  const tableRowMode = rowMode || mode;
  return (
    <TableRow
      key={row.id}
      onClick={onClick ? () => onClick(row) : undefined}
      data-cy={`${dataCy}-${row.id}`}
      classes={tableRowMode && MapClassesToElem(`${tableRowMode}-${ElemType.TableRow}`, classes)}
    >
      {headers.map(([key]) => (
        <TableCell
          key={key}
          data-cy={`${dataCy}-${key}-${row.id}`}
          classes={mode && MapClassesToElem(`${mode}-${ElemType.TableCell}`, classes)}
        >
          {formatters[key] ? formatters[key](row[key], row) : row[key]}
        </TableCell>
      ))}
    </TableRow>
  );
};

renderRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rowMode: PropTypes.PropTypes.oneOf([null, 'dark', 'red']).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  dataCy: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
  mode: PropTypes.oneOf([null, 'dark']),
  classes: PropTypes.object.isRequired,
  formatters: PropTypes.object.isRequired
};

renderRow.defaultProps = {
  mode: null
};

class SortableTable extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    headers: PropTypes.arrayOf(PropTypes.array).isRequired,
    rowCreator: PropTypes.func,
    mode: PropTypes.oneOf(['dark']),
    formatters: PropTypes.object,
    onClick: PropTypes.func,
    dataCy: PropTypes.string
  };

  static defaultProps = {
    rowCreator: renderRow,
    mode: null,
    onClick: null,
    formatters: {},
    dataCy: 'Sortable'
  };

  constructor(props) {
    super(props);
    const { onClick, dataCy, headers, classes, mode, formatters } = props;
    this.state = {
      data: props.data.map((row) => ({
        row,
        onClick,
        dataCy,
        headers,
        mode,
        classes,
        formatters
      })),
      orderBy: '',
      direction: 'asc'
    };
  }

  sort = (column) => {
    this.setState(({ data, orderBy, direction }) => ({
      orderBy: column,
      direction: orderBy === column && direction === 'asc' ? 'desc' : 'asc',
      data: orderBy !== column ? sortBy(data, (element) => element.row[column]) : reverse(data)
    }));
  };

  render() {
    const { state, sort } = this;
    const { headers, rowCreator, mode, classes, ...rest } = this.props;
    const { orderBy, direction, data } = state;
    return (
      <Table
        {...rest}
        mode={mode}
        header={headers.map(([key, value]) => (
          <TableHeaderCell
            key={key}
            columnId={key}
            mode={mode}
            onSort={sort}
            classes={classes}
            active={orderBy === key}
            direction={direction}
          >
            {value}
          </TableHeaderCell>
        ))}
        body={data.map(rowCreator)}
      />
    );
  }
}

export default withStyles(styles)(SortableTable);
