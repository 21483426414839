import React, { useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useObserver } from 'mobx-react';

import { AuthContext } from 'core/components/auth';
import ValueField from 'core/components/value-field';
import { currencyFormatter } from 'core/helpers/formatters';
import { useStore } from 'core/store';

import useStyles from './styles';
import useFeeUpdater from './use-fee-updater';

function FeeRow({ fee }) {
  const session = useContext(AuthContext);
  const { updateFees } = useFeeUpdater();
  const classes = useStyles();
  const {
    account: {
      policies: {
        policy: { policy: store }
      }
    }
  } = useStore();

  const toggleFeeForRemoval = () => {
    fee.toggleMarkedForRemoval();
    updateFees();
  };

  function variableFeePlaceholder(fee) {
    if (store.state === 'AL' && fee.type === 'installment' && fee.status !== 'pre-existing') {
      return '?';
    }
    return fee.amount;
  }

  return useObserver(() => (
    <TableRow>
      <TableCell align="center">
        <Checkbox
          className={classes.checkbox}
          onChange={toggleFeeForRemoval}
          checked={fee.status === 'removed'}
          disabled={!session.isService || !session.isInternalAgent}
        />
      </TableCell>
      <TableCell className={classes.feeDescription}>{fee.description}</TableCell>
      <TableCell>
        <ValueField
          className={classes.valueField}
          formatter={currencyFormatter}
          value={variableFeePlaceholder(fee)}
          mode="dark"
        />
      </TableCell>
    </TableRow>
  ));
}

export default FeeRow;
