import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import Grid from '@material-ui/core/Grid';
import { policyType as policyTypes, lookupsJson, paymentType, paymentMethod } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { CognitoPermissionGroups } from 'core/helpers/cognito-permission-groups';
import { BankAccounts } from './bank-accounts';
import { CreditCards } from './credit-cards';
import { Mortgage } from './mortgage';
import { daysOfMonth } from './helpers';
import { RenewalPaymentOptions } from './renewal-payment-options';
import useStyles from './billing-details.styles';

const paymentMethodComponentMap = {
  [paymentMethod.ACH]: BankAccounts,
  [paymentMethod.Escrow]: Mortgage,
  [paymentMethod.CreditCard]: CreditCards
};

const BillingDetails = ({ policyType, isCancelled, goToPaymentTab }) => {
  const { values } = useFormikContext();
  const classes = useStyles();
  const isMortgage = values.paymentMethod === paymentMethod.Escrow;
  const paymentTypeOptions = policyType === policyTypes.Auto ? lookupsJson.autoPaymentType : lookupsJson.paymentType;
  const frequencyOptions = isMortgage
    ? paymentTypeOptions
    : paymentTypeOptions.filter((opt) => opt.id !== paymentType.Escrow);

  const defaultPaymentMethod = policyType === policyType.Auto ? paymentType.CreditCard : paymentType.Escrow;
  const PaymentMethod = paymentMethodComponentMap[values.paymentMethod || defaultPaymentMethod];

  return (
    <>
      <Grid container item xs={12} spacing={2}>
        <Label type="formSubTitle" className={classes.label}>
          Billing Details
        </Label>
      </Grid>
      <Grid container justify="space-between" className={classes.row} spacing={4}>
        <FormField
          name="paymentMethod"
          type="select"
          label="Payment Method"
          options={
            policyType === policyTypes.Auto ? lookupsJson.autoPaymentMethod : lookupsJson.homeownersPaymentMethod
          }
          mode="dark"
          xs={4}
          permissions={{ isLicensedAction: false }}
        />

        <FormField
          name="paymentType"
          type="select"
          label="Payment Frequency"
          mode="dark"
          xs={4}
          disabled={isMortgage}
          options={frequencyOptions}
          permissions={{ isLicensedAction: false }}
        />
        <FormField
          name="billingDayOfMonth"
          type="select"
          label="Billing Day"
          mode="dark"
          icon="calendar"
          xs={4}
          options={daysOfMonth}
          permissions={{
            edit: {
              groups: [CognitoPermissionGroups.isService]
            }
          }}
        />
        <PaymentMethod frequencyOptions={frequencyOptions} isCancelled={isCancelled} goToPaymentTab={goToPaymentTab} />
        <RenewalPaymentOptions />
      </Grid>
    </>
  );
};

BillingDetails.propTypes = {
  policyType: PropTypes.string.isRequired,
  isCancelled: PropTypes.bool.isRequired,
  goToPaymentTab: PropTypes.func.isRequired
};

export default BillingDetails;
