import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: 0
  },
  link: {
    display: 'inline-flex',
    padding: '4px 18px 4px 18px',
    width: '100%',
    justifyContent: 'flex-start',
    color: theme.colors.black
  }
});

export default makeStyles(styles);
