import React, { PureComponent } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import * as Sentry from '@sentry/browser';
import classNames from 'classnames';
import flowRight from 'lodash-es/flowRight';
import PropTypes from 'prop-types';
import { cancelCodeInformationalArray } from '@ourbranch/lookups';

import { withStore } from 'core/store';
import { Loading } from 'core';
import { AuthContext } from 'core/components/auth';
import ErrorDialog from 'core/components/error-dialog/error-dialog';
import LeavePageDialog from 'core/components/leave-page-dialog';
import Section from 'core/components/section';
import { withToast } from 'core/components/toast/context';
import { track } from 'core/helpers/analytics';
import { getPolicyStatus, PolicyStatus } from 'core/helpers/policy-status';
import { haveAnyOfThisCarsSymbolsChanged } from 'common/helpers/car-symbols-helpers';
import { DisableProvider } from 'common/disabled-context';
import PolicyDetails from './details';
import Incidents from './incidents';
import { PaymentTab } from './payment-tab';
import PolicySettings from './policy-settings';
import Preview from './preview';
import Documents from '../documents/documents';
import { PolicyNotifications } from './policy-notifications';
import UnsignedBixConversionWarning from './unsigned-bix-conversion-warning';
import RenewalNotice from './renewal-notice';
import CancelledPolicyFooter from './footer/cancelled-policy-footer';

import styles from './policy.styles';

const cleanState = {
  modal: 'preview',
  loadingPreview: true,
  error: {},
  preview: undefined,
  total: 0
};

class Policy extends PureComponent {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    store: PropTypes.object.isRequired,
    toast: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      preview: null,
      modal: '',
      changed: false,
      settingsChanged: false,
      error: {},
      loadingPreview: false,
      startDate: new Date(),
      changeType: 'new_change',
      tab: 0,
      total: 0,
      promptTab: -1,
      renewalPolicyId: null,
      cancelReason: null,
      nonRenewReason: null,
      warnings: {},
      automatedFixesDescription: null,
      mismatchedDataDescription: null
    };
  }

  handleSegmentChange = (segment, changeType, initialValues, automatedFixesDescription, mismatchedDataDescription) => {
    this.setState({ action: undefined, ...cleanState });
    this.warnIfUnsignedConversion();
    const policyStore = this.props.store.account.policies.policy;
    const func = changeType === 'edit_change' ? policyStore.changeSegmentMutation : policyStore.addSegment;
    const policyId = policyStore.policy.id;
    const hasSymbols = (car) => haveAnyOfThisCarsSymbolsChanged(car, initialValues.cars);
    if (segment.cars?.some((car) => hasSymbols(car))) {
      segment.cars.forEach((car) => {
        if (hasSymbols(car)) {
          const aux = car.symbolAux?.toUpperCase() || '  ';
          car.manuallyAddedSymbols = true;
          car.symbolMake = car.symbolMake?.toUpperCase();
          car.symbolModel = car.symbolModel?.toUpperCase();
          car.symbolStyle = car.symbolStyle?.toUpperCase();
          car.symbolAux = String(aux).length !== 2 ? aux : '  ';
        }
      });
    }
    func(policyId, segment)
      .then((res) => {
        track('Staff Segment Change', { segment, changeType, res });
        this.calcTotal();
        this.setState({
          preview:
            res.data[changeType === 'edit_change' ? 'previewPolicyChangeToSegment' : 'previewPolicyChangeAddSegment'],
          automatedFixesDescription,
          mismatchedDataDescription,
          loadingPreview: false
        });
      })
      .catch((res) => {
        Sentry.captureException(
          JSON.stringify({
            res,
            segment
          })
        );
        if (
          res.networkError ||
          (res.graphQLErrors && res.graphQLErrors.find((err) => err.errorType === 'ExecutionTimeout'))
        ) {
          this.networkError();
        } else {
          const messages = [res.message];
          this.setState({
            error: {
              messages,
              title: 'Something went wrong.',
              label: 'Try Again',
              tryAgain: this.handleSegmentChange.bind(this, segment, changeType, initialValues)
            },
            loadingPreview: false,
            preview: undefined,
            modal: ''
          });
        }
      });
  };

  backToCustomer = () => {
    const { history } = this.props;
    history.push(`/customer/${this.props.store.account.id}`);
  };

  policyChangeAction(current, newPolicy) {
    if (current === 'Cancelled' && (newPolicy === 'Active' || newPolicy === 'Future')) {
      return 'reinstate';
    }
    if (current === 'In Cancellation' && (newPolicy === 'Active' || newPolicy === 'Future')) {
      return 'rescind';
    }
    if (newPolicy === 'Cancelled' || newPolicy === 'In Cancellation') {
      return 'cancel';
    }
  }
  formatAdditionalParties({ additionalParties }) {
    if (!Array.isArray(additionalParties)) {
      return additionalParties;
    }
    return additionalParties.map((party) => {
      if (party.address && !party.address.address) {
        return {
          ...party,
          address: {
            address: '',
            address2: party.address?.address2 || '',
            city: party.address?.city || '',
            state: party.address?.state || '',
            zip: party.address?.zip || ''
          }
        };
      }
      return party;
    });
  }

  warnIfUnsignedConversion = () => {
    const {
      account: {
        policies: {
          getUnsignedBixConversion,
          policy: { policy: currentPolicy }
        }
      }
    } = this.props.store;
    if (!getUnsignedBixConversion(currentPolicy?.id).signedDocUploadedInS3) {
      this.setState({
        warnings: {
          unsignedBixConversion: true
        }
      });
    }
  };

  handleCloseWarning = () => {
    this.setState({
      warnings: {}
    });
  };

  handleChangePolicy = (values) => {
    const {
      account: {
        policies: { policy: policyStore }
      }
    } = this.props.store;
    const currentPolicy = getPolicyStatus(policyStore.policy);
    const newPolicy = getPolicyStatus(values);
    const action = this.policyChangeAction(currentPolicy, newPolicy);
    // @TODO move this logic to store ?
    this.setState({ action, ...cleanState });
    this.warnIfUnsignedConversion();
    const formattedValues = {
      ...values,
      additionalParties: this.formatAdditionalParties(values)
    };
    policyStore
      .changePolicy(formattedValues)
      .then((res) => {
        track('Staff Preview Policy Change', { res });
        this.calcTotal();
        this.setState({
          preview: {
            ...res.data.previewPolicyChangePolicy,
            endDate: values.cancellationDate || policyStore.policy.endDate
          },
          loadingPreview: false,
          action,
          cancelReason: values.cancelReason,
          nonRenewReason: values.nonRenewReason
        });
      })

      .catch((res) => {
        Sentry.captureException(
          JSON.stringify({
            res,
            values
          })
        );
        if (res.networkError) {
          this.networkError();
        } else {
          this.setState({
            error: {
              messages: [
                'Sorry about that. It looks like our systems are not able process a preview for the changes.',
                'Please try again'
              ],
              title: 'Settings preview error',
              label: 'Try Again',
              tryAgain: this.handleChangePolicy.bind(this, values)
            },
            loadingPreview: false,
            modal: '',
            action: undefined
          });
        }
      });
  };

  previewPolicyChangeScrubIncidents = async (policyValue, incidents) => {
    try {
      const {
        account: {
          policies: {
            policy: { policy, previewPolicyChangeScrubIncidents }
          }
        }
      } = this.props.store;
      this.setState(cleanState);
      this.warnIfUnsignedConversion();

      const res = await previewPolicyChangeScrubIncidents(policyValue, incidents);

      track('Staff Preview Policy Change', { res });

      this.setState({
        preview: {
          ...res.previewPolicyChangeScrubIncidents,
          endDate: policy.endDate
        },
        loadingPreview: false
      });
    } catch (res) {
      Sentry.captureException(
        JSON.stringify({
          res,
          policyValue
        })
      );
      if (res.networkError) {
        this.networkError();
      } else {
        this.previewError({
          tryAgain: this.previewPolicyChangeScrubIncidents.bind(this, policyValue, incidents)
        });
      }
    }
  };

  handleConfirm = (id, notes) =>
    this.setState(
      {
        loadingPreview: true,
        modal: 'preview',
        preview: null,
        error: {}
      },
      () =>
        this.props.store.account.policies.policy
          .confirm(this.props.store.account.policies.policy.policy.id, id, notes)
          .then((res) => {
            if (res.data.confirmPolicyChange.success) {
              track('Staff Policy Change', { notes, res });
              const {
                toast,
                store: { account }
              } = this.props;
              const {
                policies: {
                  policy: { policy, getRenewalPolicyId },
                  getUnsignedBixConversion
                }
              } = account;
              getRenewalPolicyId(policy.id).then((renewalPolicyId) => {
                this.setState({
                  modal: renewalPolicyId ? 'renewal' : '',
                  loadingPreview: false,
                  changed: false,
                  settingsChanged: false,
                  renewalPolicyId
                });
                if (!getUnsignedBixConversion(policy.id).signedDocUploadedInS3) {
                  toast.notify({
                    type: 'success',
                    message: 'Renewal policy document is successfully endorsed'
                  });
                } else {
                  toast.notify({
                    type: 'success',
                    message: 'The policy was updated successfully',
                    label: "Go to Customer's Details",
                    action: this.backToCustomer
                  });
                }

                account.fetchFullAccountAndPolicy(policy.id, account.id);
              });
            } else {
              this.failedConfirmation(id, notes);
            }
          })
          .catch((res) => {
            Sentry.captureException(
              JSON.stringify({
                res,
                body: {
                  id,
                  notes
                }
              })
            );
            if (res.networkError) {
              this.networkError();
            } else {
              this.failedConfirmation(res?.message);
            }
          })
    );
  networkError = () => {
    this.setState({
      error: {
        messages: [
          'Our servers are not responding.',
          'You might have lost your internet connection, please check it.',
          'If not, just refresh this page.'
        ],
        label: 'Refresh',
        title: 'Whoops!',
        tryAgain: window.location.reload.bind(window.location)
      },
      loadingPreview: false,
      modal: '',
      action: undefined
    });
  };
  failedConfirmation = (additionalErrorMessage) => {
    const messages = [
      'Sorry about that. It looks like our systems are not able process the policy update.',
      additionalErrorMessage
    ];
    this.setState({
      error: {
        messages,
        title: 'Confirmation error',
        label: 'Refresh page',
        tryAgain: window.location.reload.bind(window.location)
      },
      loadingPreview: false,
      modal: '',
      action: undefined
    });
  };

  previewError = (options = {}) => {
    this.setState({
      error: {
        messages: [
          'Sorry about that. It looks like our systems are not able process a preview for the changes.',
          'Please try again'
        ],
        title: 'Settings preview error',
        label: 'Try Again',
        ...options
      },
      loadingPreview: false,
      modal: '',
      action: undefined
    });
  };

  onDialogClose = () => {
    this.setState({
      error: {}
    });
  };

  calcTotal = () => {
    const {
      account: {
        policies: {
          policy: { policy }
        }
      }
    } = this.props.store;
    const feesTotal = policy.fees.reduce((val, fee) => fee.amount + val, 0);
    this.setState({
      total: feesTotal + policy.premium + (policy.surplusContribution || 0)
    });
  };

  closeModal = () => this.setState({ modal: '', action: undefined });

  closePromptChanges = () => this.setState({ promptTab: -1 });

  setTab = (tab) => {
    this.setState({ tab });
  };

  promptCallback = (setChanged) => {
    this.setTab(this.state.promptTab);
    this.closePromptChanges();
    setChanged(false);
  };

  changeTab = (e, tab) => {
    const { changed } = this.props.store.account.policies.policy;
    if (changed) {
      e.preventDefault();
      this.setState({
        promptTab: tab
      });
    } else {
      this.setTab(tab);
    }
  };

  determineboxTwoText = (cancelReason) => {
    return cancelCodeInformationalArray.find((cancelCodeObject) => cancelCodeObject.cancelCode === cancelReason)
      .boxTwoText;
  };

  openDetailsTab = () => {
    this.setTab(2);
  };

  openPaymentPriceTab = () => {
    this.setTab(1);
  };

  render() {
    const {
      handleSegmentChange,
      handleChangePolicy,
      closeModal,
      handleConfirm,
      onDialogClose,
      changeTab,
      closePromptChanges,
      promptCallback,
      previewPolicyChangeScrubIncidents,
      determineboxTwoText,
      handleCloseWarning
    } = this;

    const {
      classes,
      store: {
        account: { policies, id: accountId }
      }
    } = this.props;
    const { policy: policyStore } = policies;
    const { policy, loading, setChanged } = policyStore;

    const {
      modal,
      preview,
      loadingPreview,
      error,
      changed,
      tab,
      total,
      action,
      promptTab,
      renewalPolicyId,
      cancelReason,
      nonRenewReason,
      warnings,
      automatedFixesDescription,
      mismatchedDataDescription
    } = this.state;
    const isLoading = loading || !policy;

    const policyStatus = isLoading ? null : getPolicyStatus(policy);
    const cancelled = policyStatus === PolicyStatus.InCancellation || policyStatus === PolicyStatus.Cancelled;

    return (
      <AuthContext.Consumer>
        {(session) => (
          <DisableProvider>
            {!isLoading && policies?.notifications && <PolicyNotifications onClick={this.openDetailsTab} />}
            {(session.canEdit || session.viewOnly) && (
              <Tabs value={tab} variant={'fullWidth'} onChange={changeTab}>
                <Tab label="Settings" className={classes.tab} />
                <Tab label="Payment & Price" className={classes.tab} />
                <Tab label="Details" className={classes.tab} />
                <Tab label="Documents" className={classes.tab} />
                <Tab label="Incidents" className={classes.tab} />
              </Tabs>
            )}
            {tab === 0 && (session.canEdit || session.viewOnly) && (
              <Section>
                {isLoading ? (
                  <Loading type="secondary" />
                ) : (
                  <PolicySettings
                    key={`version-${policy.version}`}
                    policy={policy}
                    accountId={accountId}
                    loadingPreview={loadingPreview}
                    onPreviewPolicy={handleChangePolicy}
                    goToPaymentTab={this.openPaymentPriceTab}
                  />
                )}
              </Section>
            )}
            {tab === 1 && (
              <Section className={classes.details}>
                {isLoading ? (
                  <Loading type="secondary" />
                ) : (
                  <PaymentTab loadingPreview={loadingPreview} handleChangePolicy={handleChangePolicy} />
                )}
              </Section>
            )}
            {tab === 2 && (
              <Section className={classNames([classes.details, { [classes.withFooter]: changed }])}>
                {isLoading ? (
                  <Loading type="secondary" />
                ) : (
                  <PolicyDetails
                    key={`version-${policy.version}`}
                    loadingPreview={loadingPreview}
                    onSegmentPreview={handleSegmentChange}
                    repEmail={session.user.email}
                  />
                )}
              </Section>
            )}
            {(tab === 3 || (!session.canEdit && !session.viewOnly)) && (
              <Section className={classNames([classes.details, { [classes.withFooter]: changed }])}>
                {isLoading ? <Loading type="secondary" /> : <Documents showRecreateApplication={session.canEdit} />}
              </Section>
            )}
            {tab === 4 && (
              <Section className={classNames([classes.details, { [classes.withFooter]: changed }])}>
                {isLoading ? (
                  <Loading type="secondary" />
                ) : (
                  <Incidents loadingPreview={loadingPreview} handleChangePolicy={previewPolicyChangeScrubIncidents} />
                )}
              </Section>
            )}
            {warnings?.unsignedBixConversion && (
              <UnsignedBixConversionWarning open={warnings.unsignedBixConversion} onClose={handleCloseWarning} />
            )}
            {modal === 'preview' && (
              <Preview
                open={modal === 'preview'}
                total={total}
                preview={preview}
                loading={loadingPreview}
                onClose={closeModal}
                onConfirm={handleConfirm}
                action={action}
                policyType={policy.policyType}
                cancelReason={cancelReason}
                nonRenewReason={nonRenewReason}
                determineboxTwoText={determineboxTwoText}
                automatedFixesDescription={automatedFixesDescription}
                mismatchedDataDescription={mismatchedDataDescription}
              />
            )}
            {modal === 'renewal' && (
              <RenewalNotice open={modal === 'renewal'} onClose={closeModal} renewalPolicyId={renewalPolicyId} />
            )}
            <LeavePageDialog onClose={closePromptChanges} open={promptTab > -1} cb={() => promptCallback(setChanged)} />
            <ErrorDialog
              open={!!error.messages}
              errors={error.messages}
              onClose={onDialogClose}
              label={error.label}
              onClick={error.tryAgain}
              title={error.title}
            />
            {tab !== 0 && cancelled && (
              <CancelledPolicyFooter
                title={
                  policyStatus === PolicyStatus.InCancellation
                    ? 'This policy is pending cancellation.'
                    : 'This policy is cancelled.'
                }
                isAgency={session.isAgency}
              />
            )}
          </DisableProvider>
        )}
      </AuthContext.Consumer>
    );
  }
}

export default flowRight(withToast, withStyles(styles), withStore)(Policy);
