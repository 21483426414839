import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import flowRight from 'lodash-es/flowRight';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { getOptions } from '@ourbranch/lookups';

import { FormField } from 'core/components/form';
import withDatePicker from 'core/components/with-date-picker';
import { awsDateToJs } from 'core/helpers/formatters';
import styles from './cancellation-form.styles';

const FlatCancelReasons = { DuplicatePolicy: 'CNDP' };

const CancellationForm = ({ classes }) => {
  const { values, setFieldValue } = useFormikContext();
  const { endDate, effectiveDate } = values;

  const original = awsDateToJs(values.fullTermPolicyEndDate);
  const maxDate = new Date(new Date(original).setDate(original.getUTCDate() - 1));
  const minDate = new Date(effectiveDate);
  minDate.setDate(new Date(effectiveDate).getDate() + 1);

  if (values.cancelReason === FlatCancelReasons.DuplicatePolicy && endDate !== effectiveDate) {
    setFieldValue('endDate', effectiveDate);
  }

  useEffect(() => {
    if (endDate !== effectiveDate && values.term === 1) {
      setFieldValue('applyFee', true);
    } else if (endDate === effectiveDate) {
      setFieldValue('applyFee', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate, effectiveDate, values.term]);

  return (
    <Grid container item xs={8}>
      <Grid container key="details" justify="space-between" className={classes.form}>
        <FormField
          name="cancelReason"
          type="select"
          label="Cancel Reason"
          mode="light"
          options={getOptions('cancelReason')}
          xs={4}
          ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        />
        <FormField
          name="endDate"
          type="date"
          label="End Date"
          mode="light"
          disableFuture={false}
          maxDate={maxDate}
          minDate={minDate}
          initialFocusedDate={maxDate}
          className={classes.endDate}
          xs={4}
          ignoreGlobalDisabledState // cancel policy sets all form fields to disabled, so need to manually exclude this field
        />
        <FormField
          type="checkbox"
          id="applyFee"
          name="applyFee"
          mode="light"
          label="Apply Fee"
          xs={3}
          className={classes.checkbox}
          ignoreGlobalDisabledState // Add fee
        />
      </Grid>
    </Grid>
  );
};

CancellationForm.propTypes = {
  classes: PropTypes.object.isRequired
};

export default flowRight(withStyles(styles), withDatePicker)(CancellationForm);
