export default {
  graphqlEndpoint: process.env.REACT_APP_STAFF_APPSYNC_BE_GRAPHQL_ENDPOINT,
  region: process.env.REACT_APP_REGION,
  authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  userPoolId: process.env.REACT_APP_STAFF_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_STAFF_USER_POOL_WEB_CLIENT_ID,
  identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID,
  stripeKey: process.env.REACT_APP_STRIPE_KEY,
  basisTheoryApiKey: process.env.REACT_APP_BASIS_THEORY_FRONTEND_API_KEY,
  Storage: {
    bucket: process.env.REACT_APP_POLICY_DOCS_S3_BUCKET,
    region: process.env.REACT_APP_REGION,
    identityPoolId: process.env.REACT_APP_STAFF_IDENTITY_POOL_ID
  },
  Analytics: {
    disabled: true
  },
  stackeryEnvironmentName: process.env.REACT_APP_STACKERY_ENVIRONMENT,
  logRocketApiKey: process.env.REACT_APP_LOGROCKET_API_KEY,
  segmentKey: process.env.REACT_APP_SEGMENT_KEY,
  friendBuyMerchantId: process.env.REACT_APP_FRIENDBUY_MERCHANT_ID,
  trackingEnabled: process.env.REACT_APP_TRACKING_ENABLED === 'true',
  smartyStreetsKey: process.env.REACT_APP_SMARTY_STREETS_KEY,
  googleGeocoderKey: process.env.REACT_APP_GOOGLE_GEOCODER_KEY,
  coreResourcesApiKey: process.env.REACT_APP_CORE_RESOURCES_API_KEY,
  coreResourcesEndpoint: process.env.REACT_APP_CORE_RESOURCES_ENDPOINT
};
