import React from 'react';
import { observer } from 'mobx-react';

import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { withToast } from 'core/components/toast';
import { useStore } from 'core/store/store.mobx';

import useStyles from '../footer.styles';

const OfferOutOfDate = observer(({ onUpdate }) => {
  const { offer: store } = useStore();
  const classes = useStyles();

  return (
    <>
      <Label type="whiteMedium">Offer Out of Date!</Label>
      <Button
        onClick={onUpdate}
        disabled={store.loading}
        loading={store.loading}
        variant="contained"
        color="primary"
        className={classes.btn}
      >
        <Label className={classes.btnLabel}>Update To Purchase</Label>
      </Button>
    </>
  );
});
export default withToast(OfferOutOfDate);
