export function samePolicyRoot(policyId1: string, policyId2: string) {
  const splitPolicyId1 = policyId1.split('-');
  const splitPolicyId2 = policyId2.split('-');
  return splitPolicyId1[0] === splitPolicyId2[0] && splitPolicyId1[1] === splitPolicyId2[1];
}

export function isPrecedingTerm(policyId1: string, policyId2: string) {
  const policy1Term = policyId1.split('-')[2];
  const policy2Term = policyId2.split('-')[2];
  return samePolicyRoot(policyId1, policyId2) && +policy2Term - +policy1Term === 1;
}
