import React from 'react';
import PropTypes from 'prop-types';
import { BasisTheoryProvider, useBasisTheory } from '@basis-theory/basis-theory-react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';

import config from 'aws-exports';
import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { OfferNotifications } from 'offer/components/notifications';
import getQuoteFriendlySelectedOption from 'offer/helpers/quote-friendly-selected-option';
import { CheckoutForm } from './components/checkout-form';
import OptionNotAvailable from './components/option-not-available-modal';

const stripePromise = loadStripe(config.stripeKey);

const Checkout = observer(({ option }) => {
  const { offer: offerStore } = useStore();
  const { offer, getOffer } = offerStore;
  const { offerId } = useParams();
  const { bt } = useBasisTheory(config.basisTheoryApiKey, { elements: true });
  let optionNotAvailable = false;

  if (!offer) {
    getOffer(offerId);
    return <Loading noBackground />;
  }

  offerStore.setSelectedOption(option);

  if (offer?.options) {
    const quoteFriendlyOption = getQuoteFriendlySelectedOption(option);
    const offeringOption = offer?.options?.find((opt) => opt.type === quoteFriendlyOption);
    if (!offeringOption) {
      optionNotAvailable = true;
    }
  }

  return (
    <BasisTheoryProvider bt={bt}>
      <Elements stripe={stripePromise}>
        {optionNotAvailable && <OptionNotAvailable optionNotAvailable={optionNotAvailable} />}
        {offerStore.notifications?.length && <OfferNotifications />}
        <CheckoutForm offer={offer} optionFromRoute={option} disabled={optionNotAvailable} />
      </Elements>
    </BasisTheoryProvider>
  );
});

Checkout.propTypes = {
  offerStore: PropTypes.object
};

export default Checkout;
