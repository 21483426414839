import React from 'react';
import PropTypes from 'prop-types';

import { TableHeaderCell } from 'core';

const headerTypes = {
  id: (
    <TableHeaderCell key="id" columnId="id">
      ID
    </TableHeaderCell>
  ),
  policyType: (
    <TableHeaderCell key="policyType" columnId="policyType">
      Policy Type
    </TableHeaderCell>
  ),
  firstName: (
    <TableHeaderCell key="firstName" columnId="firstName">
      Primary First Name
    </TableHeaderCell>
  ),
  lastName: (
    <TableHeaderCell key="lastName" columnId="lastName">
      Primary Last Name
    </TableHeaderCell>
  ),
  matchedOn: (
    <TableHeaderCell key="matchedOn" columnId="matchedOn">
      Matched On
    </TableHeaderCell>
  ),
  effectiveDate: (
    <TableHeaderCell key="effectiveDate" columnId="effectiveDate">
      Effective Date
    </TableHeaderCell>
  ),
  endDate: (
    <TableHeaderCell key="endDate" columnId="endDate">
      End Date
    </TableHeaderCell>
  ),
  state: (
    <TableHeaderCell key="state" columnId="state">
      State
    </TableHeaderCell>
  )
};

const Header = ({ columnsToUse }) =>
  columnsToUse.map((name) => {
    return headerTypes[name];
  });

Header.propTypes = {
  columnsToUse: PropTypes.array.isRequired
};

export default Header;
