import React, { useContext, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Tabs, Tab } from '@material-ui/core';

import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import useStyles from './tabs.styles';

// only show all tabs if all policies are in user's licensed states, otherwise show no tabs
const getShowAllTabs = ({ session, policies }) => {
  if (!policies.length) {
    return false;
  }
  const policyStates = policies.map((policy) => policy.state);
  return policyStates.every((policyState) => policyState && session.allowedStates.includes(policyState));
};

const CustomerTabs = observer(({ activeTab, handleTabChange, setActiveTab }) => {
  const session = useContext(AuthContext);
  const classes = useStyles();
  const store = useStore();
  const showAllTabs = getShowAllTabs({ session, policies: store?.account?.policies?.list?.toJSON() });

  useEffect(() => {
    // redirect to first tab, Details, if user isn't allowed to see Tickets or Claims
    if (!showAllTabs && activeTab !== 0) {
      setActiveTab(0);
    }
  }, [showAllTabs, activeTab, setActiveTab]);

  if ((!session.canEdit && !session.viewOnly) || !showAllTabs) {
    return null;
  }

  return (
    <Tabs value={activeTab} variant="fullWidth" onChange={handleTabChange} className={classes.tabs}>
      <Tab label="Details" className={classes.tab} />
      <Tab label="Interactions" className={classes.tab} />
      <Tab label="Claims" className={classes.tab} />
    </Tabs>
  );
});

export default CustomerTabs;
