import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    width: 720,
    margin: '0 auto',
    paddingTop: 30,
    overflow: 'hidden'
  },
  overflowContainer: {
    composes: '$container',
    overflow: 'visible'
  },
  content: {
    padding: '0px 30px 32px 30px'
  },
  subtitle: {
    marginBottom: 8,
    padding: '0 32px'
  },
  subtitleWithoutPadding: {
    marginBottom: 8
  },
  datesContainer: {
    marginBottom: 32,
    padding: '0 32px'
  },
  withBottomLine: {
    borderBottom: `1px solid ${theme.colorPalette.white_30_op_30}`,
    paddingBottom: 20,
    marginBottom: 20
  },
  checkbox: {
    paddingTop: 15
  },
  toggle: {
    minHeight: 10,
    padding: 0,
    margin: 0
  },
  loadingBar: {
    height: 2
  },
  checkboxTooltip: {
    paddingLeft: 25
  }
}));

export default useStyles;
