import React, { useCallback } from 'react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import { useToast } from 'core/components/toast';
import { Button } from 'core/components/button';

import useStyles from './verify-property-container.styles.js';

const VerifyPropertyFooter = observer(({ setShowNoHomeModal }) => {
  const classes = useStyles();

  const { handleSubmit, setErrors, setTouched, setFieldValue, validateForm, setFieldTouched } = useFormikContext();
  const toast = useToast();

  const onContinueToCheckout = useCallback(async () => {
    // run validation before submit so that we have the updated errors
    const updateErrors = await validateForm();
    if (Object.keys(updateErrors).length > 0) {
      setErrors(updateErrors);
      setTouched(updateErrors);
      if (updateErrors?.signedPropertyUWAttestation) {
        toast.notify({
          type: 'error',
          message: 'You must sign off you verified the property before continuing'
        });
      } else {
        setShowNoHomeModal(true);
      }
    } else {
      await handleSubmit();
    }
  }, [validateForm, setErrors, setTouched, handleSubmit, setShowNoHomeModal]);

  const onClick = async () => {
    await setFieldValue('ineligibleForHomeDueToUWSelfReport', false);
    await setFieldTouched('ineligibleForHomeDueToUWSelfReport');

    await onContinueToCheckout();
  };

  return (
    <Grid className={classes.floatingContainer}>
      <Grid className={classes.footerContainer}>
        <Button variant="contained" color="primary" className={classes.footerBtn} onClick={onClick}>
          Continue to Checkout
        </Button>
      </Grid>
    </Grid>
  );
});

export default VerifyPropertyFooter;
