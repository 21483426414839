import React from 'react';
import { Grid } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { CardElement } from '@basis-theory/basis-theory-react';
import { useFormikContext } from 'formik';
import { observer } from 'mobx-react';

import { FormField } from 'core/components/form';
import { Label } from 'core/components/label';
import { useStore } from 'core/store';
import { useBasisTheoryToken } from 'common/hooks/use-basis-theory-token';
import useStyles, { styles } from './credit-card-field.styles';

const CreditCardField = () => {
  const { setFieldValue, errors: formikErrors, values, touched } = useFormikContext();
  const classes = useStyles();
  const theme = useTheme();
  const { cardElementId } = useBasisTheoryToken();

  const {
    offer: { saveCheckoutFormData, checkoutFormData }
  } = useStore();
  const inlineStyles = styles(theme);

  const onChange = async (res) => {
    try {
      if (res.cardLast4) {
        setFieldValue('cardLast4', res.cardLast4);
      }
      // if all cc fields are filled in, placeholder field will be true and create token on submit
      setFieldValue('completeCardData', res.complete);
      if (res.errors.length) {
        const errorMessages = res.errors.map((err) => `${err.type} ${err.targetId}`).join('. ');
        // using global state to hold basis theory api errors because formik sucks with setFieldError in an onChange function
        // see https://github.com/jaredpalmer/formik/issues/1278
        saveCheckoutFormData({ id: values.address, basisTheoryError: errorMessages });
      }
    } catch (error) {
      saveCheckoutFormData({ id: values.address, basisTheoryError: error.message });
      setFieldValue('basisTheoryCardToken', '');
    }
  };

  return (
    <div className={classes.root}>
      {values?.recoveredPaymentData?.creditCard ? (
        <Grid container>
          <FormField
            type="value"
            name="recoveredPaymentData.creditCard.card.last4"
            label="Saved Credit Card Last 4"
            xs={4}
          />
          <FormField
            type="value"
            name="recoveredPaymentData.creditCard.card.expirationMonth"
            label="Saved Credit Card Expiration Month"
            xs={4}
          />
          <FormField
            type="value"
            name="recoveredPaymentData.creditCard.card.expirationYear"
            label="Saved Credit Card Expiration Year"
            xs={4}
          />
        </Grid>
      ) : (
        <>
          <Label
            type="infoLabel"
            className={{ [classes.error]: touched.basisTheoryCardToken && !!checkoutFormData?.basisTheoryError }}
          >
            Credit Card Number
          </Label>
          <CardElement id={cardElementId} onChange={onChange} style={inlineStyles.cardElementStyle} />
        </>
      )}
      {touched.basisTheoryCardToken && (checkoutFormData?.basisTheoryError || formikErrors?.completeCardData) && (
        <span className={classes.errorMessage}>
          {checkoutFormData?.basisTheoryError || formikErrors?.completeCardData}
        </span>
      )}
    </div>
  );
};

export default observer(CreditCardField);
