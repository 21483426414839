import React, { useState } from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';

import { useStore } from 'core/store';
import { Loading } from 'core/components/loading';
import { Label } from 'core/components/label';

const getInitialAccumulatorValue = (removedChanges, addedChanges) => {
  const obj = {};
  Object.keys(removedChanges).forEach((key) => {
    obj[key] = null;
  });
  Object.keys(addedChanges).forEach((key) => {
    obj[key] = null;
  });
  return obj;
};

const sortedObj = (obj, defaults) =>
  Object.keys(obj)
    .sort()
    .reduce((accumulator, key) => {
      accumulator[key] = obj[key];
      return accumulator;
    }, defaults);

const PreviewClarionDoorRequestDifference = ({ policyPreviewId, policyId }) => {
  const {
    account: {
      policies: {
        policy: { getClarionDoorRequestDiff }
      }
    }
  } = useStore();

  const [data, setData] = useState();
  const [gqlError, setGqlError] = useState();
  const [loading, setLoading] = useState(false);

  if (!loading && !data) {
    setLoading(true);
    getClarionDoorRequestDiff({ policyId, policyPreviewId }).then(({ requests, error }) => {
      setLoading(false);
      if (requests) {
        setData({ ...requests, diff: JSON.parse(requests?.diff ?? {}) });
      }
      if (error) {
        setGqlError(error);
      }
    });
  }

  const sortedRemovedChanges =
    data?.diff &&
    sortedObj(
      data?.diff.full.removedChanges,
      getInitialAccumulatorValue(data?.diff.full.removedChanges, data?.diff.full.addedChanges)
    );
  const sortedAddedChanges =
    data?.diff &&
    sortedObj(
      data?.diff.full.addedChanges,
      getInitialAccumulatorValue(data?.diff.full.removedChanges, data?.diff.full.addedChanges)
    );

  if (loading) {
    return <Loading />;
  }

  if (!data?.xmlBeforePreview?.success || !data?.xmlAfterPreview?.success || gqlError) {
    return (
      <>
        <ReactDiffViewer
          oldValue={JSON.stringify(sortedRemovedChanges, null, 4)}
          newValue={JSON.stringify(sortedAddedChanges, null, 4)}
          splitView
          compareMethod={DiffMethod.WORDS} // full set of possible diff compare methods: https://github.com/praneshr/react-diff-viewer/tree/v3.0.0#text-block-diff-comparison
          leftTitle={`Branch Data Before Preview`}
          rightTitle={`Branch Data After Preview`}
        />
        <br />
        <Label>Error getting ClarionDoor data.</Label>
        <Label>{data?.xmlBeforePreview?.message || data?.xmlAfterPreview?.message || gqlError}</Label>
      </>
    );
  }

  return (
    <>
      <ReactDiffViewer
        oldValue={JSON.stringify(sortedRemovedChanges, null, 4)}
        newValue={JSON.stringify(sortedAddedChanges, null, 4)}
        splitView
        compareMethod={DiffMethod.WORDS} // full set of possible diff compare methods: https://github.com/praneshr/react-diff-viewer/tree/v3.0.0#text-block-diff-comparison
        leftTitle={`Branch Data Before Preview`}
        rightTitle={`Branch Data After Preview`}
      />
      <br />
      <ReactDiffViewer
        oldValue={data.xmlBeforePreview.data}
        newValue={data.xmlAfterPreview.data}
        splitView
        compareMethod={DiffMethod.WORDS} // full set of possible diff compare methods: https://github.com/praneshr/react-diff-viewer/tree/v3.0.0#text-block-diff-comparison
        leftTitle={`ClarionDoor Data Before Preview - ID: ${data?.requestIdBeforePreview}`}
        rightTitle={`ClarionDoor Data After Preview - ID: ${data?.requestIdAfterPreview}`}
      />
    </>
  );
};

export default PreviewClarionDoorRequestDifference;
