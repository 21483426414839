import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { observer } from 'mobx-react';

import { useStore } from 'core/store';
import Documents from './components/documents';
import Policies from './components/policies';
import Tickets from './components/tickets';
import Claims from './components/claims';
import { CustomerNotifications } from './components/customer-notifications';
import CustomerTabs from './components/tabs';
import { OnboardingTaskCenter } from './components/onboarding-task-center';

const Customer = observer(function Customer({ match, history }) {
  const [activeTab, setActiveTab] = useState(0);

  const { account } = useStore();
  const { notifications } = account;

  const handleTabChange = useCallback(
    (_, tab) => {
      setActiveTab(tab);
    },
    [setActiveTab]
  );

  return (
    <>
      {notifications?.length && <CustomerNotifications />}
      <CustomerTabs activeTab={activeTab} handleTabChange={handleTabChange} setActiveTab={setActiveTab} />
      {activeTab === 0 && (
        // DETAILS
        <>
          <Policies
            id={match.params.id}
            onClick={(policy) => history.push(`/customer/${match.params.id}/policy/${policy.id}`)}
          />
          <OnboardingTaskCenter />
          <Documents showPolicyIdColumn />
        </>
      )}
      {activeTab === 1 && <Tickets accountId={match.params.id} />}
      {activeTab === 2 && <Claims />}
    </>
  );
});

Customer.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default withRouter(Customer);
