import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from 'core';
import { numberFormatter } from 'core/helpers/formatters';
import useStyles from './price-breakdown.styles';

const PriceBreakdown = ({ price, frequency, isDisabled }) => {
  const classes = useStyles();
  return (
    <div className={classes.price}>
      <Label type="toast" className={classNames({ [classes.disabled]: isDisabled })}>
        {' '}
        {`$${numberFormatter(price, 2)}`}{' '}
      </Label>
      <Label className={classNames({ [classes.disabled]: isDisabled }, classes.priceNote)} type="noteSmall">
        {frequency}
      </Label>
    </div>
  );
};

PriceBreakdown.propTypes = {
  price: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
};

PriceBreakdown.defaultProps = {
  isDisabled: false
};

export default PriceBreakdown;
