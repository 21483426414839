import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  inviterLink: {
    color: theme.colorPalette.orange_10
  },
  icon: {
    width: 24,
    height: 24,
    marginRight: 6
  },
  actionContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 16,
    padding: 0,
    height: 'auto',
    '&:hover': {
      background: 'transparent'
    }
  },
  disabledIcon: {
    opacity: 0.5
  }
});

export default makeStyles(styles);
