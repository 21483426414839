import React, { useState, useCallback } from 'react';
import { observer } from 'mobx-react';
import { useFormikContext } from 'formik';
import { Button } from '@material-ui/core';
import { getValue, paymentType, standaloneRentersStates } from '@ourbranch/lookups';

import { useStore } from 'core/store';
import { Card } from 'core/components/card';
import { Label } from 'core/components/label';
import Switch from 'core/components/switch';
import { numberFormatter } from 'core/helpers/formatters';
import getDetailedPrice from 'offer/helpers/detailed-price';
import { PriceDetail } from './price-detail';
import { CardLine } from '../card-line';
import { getFactorsImpactingPrice } from '../factors-impacting-price/helpers';
import FactorsImpactingPrice from '../factors-impacting-price';
import useStyles from './price-details-card.styles';

const PriceDetailsCard = observer(() => {
  const classes = useStyles();
  const { offer: store } = useStore();
  const { values } = useFormikContext();
  const [showFactorsImpactingPriceModal, setOpenFactorsImpactingPriceModal] = useState(false);
  const [showExtendedPriceInformation, setShowExtendedPriceInformation] = useState(false);

  const toggleModal = useCallback(
    () => setOpenFactorsImpactingPriceModal(!showFactorsImpactingPriceModal),
    [setOpenFactorsImpactingPriceModal, showFactorsImpactingPriceModal]
  );

  const toggleExtendedPriceInformation = useCallback(
    () => setShowExtendedPriceInformation(!showExtendedPriceInformation),
    [setShowExtendedPriceInformation, showExtendedPriceInformation]
  );

  const type = values.selectedOption || '';

  const isHomeMonthly = store.offer.quote.global.homeownersPaymentType === paymentType.Monthly;
  const isAutoMonthly = store.offer.quote.global.autoPaymentType === paymentType.Monthly;
  const isRentersMonthly = store.offer.quote.global.rentersPaymentType === paymentType.Monthly;

  const showAuto = type.includes('A');
  const showHome = type.includes('H');
  const showRenters = type.includes('R') && standaloneRentersStates[store.state];
  const includeHomeFactors = store.selectedOption?.includes('H');
  const includeAutoFactors = store.selectedOption?.includes('A');
  const factors = getFactorsImpactingPrice({ offer: store.offer, includeHomeFactors, includeAutoFactors });
  const showHomeFactors = includeHomeFactors && factors?.homeFactors;
  const showPeopleFactors = factors?.peopleFactors;
  const showCarsFactors = includeAutoFactors && factors?.carFactors;

  // for the "fake" types we need the actual type to get the payments status
  const priceType = type === 'HB' || type === 'AB' ? 'HA' : type;
  const option = store.offer.options.find((opt) => opt.type === priceType);

  const prices = getDetailedPrice(store.offer, priceType, { isHomeMonthly, isAutoMonthly, isRentersMonthly });

  return option ? (
    <Card className={classes.card}>
      <div className={classes.header}>
        <Label type="infoSubtitleWhite">Price details</Label>
      </div>
      <div className={classes.content}>
        {showHome && (
          <PriceDetail
            name={option.name}
            type="Home"
            policyType={option.type}
            isMonthly={isHomeMonthly}
            downPaymentDate={prices.homeDownPaymentDate}
            remainingPayments={prices.homeRemainingPayments}
            remainingPaymentsAmount={prices.homeRemainingPaymentsAmount}
            payment={prices.homePrice}
            paymentMethod={getValue('homeownersPaymentMethod', store.offer.quote.global.homeownersPaymentMethod)}
            paymentType={getValue('paymentType', store.offer.quote.global.homeownersPaymentType)}
            termTotal={numberFormatter(option.homeTotal, 2)}
            stale={store.isStale}
            allowCollapsableContent
            nextPayments={prices.homeNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        {showAuto && (
          <PriceDetail
            name={option.name}
            type="Auto"
            policyType={option.type}
            isMonthly={isAutoMonthly}
            downPaymentDate={prices.autoDownPaymentDate}
            remainingPayments={prices.autoRemainingPayments}
            remainingPaymentsAmount={prices.autoRemainingPaymentsAmount}
            payment={prices.autoPrice}
            paymentMethod={getValue('autoPaymentMethod', store.offer.quote.global.autoPaymentMethod)}
            paymentType={getValue('paymentType', store.offer.quote.global.autoPaymentType)}
            termTotal={numberFormatter(option.autoTotal, 2)}
            autoTotalAR={numberFormatter(prices.autoTotalAR, 2)}
            rentersTotalAR={numberFormatter(prices.rentersTotalAR, 2)}
            stale={store.isStale}
            allowCollapsableContent
            nextPayments={prices.autoNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        {showRenters && (
          <PriceDetail
            name={option.name}
            type="Renters"
            policyType={option.type}
            isMonthly={isRentersMonthly}
            downPaymentDate={prices.rentersDownPaymentDate}
            remainingPayments={prices.rentersRemainingPayments}
            remainingPaymentsAmount={prices.rentersRemainingPaymentsAmount}
            payment={prices.rentersPrice}
            paymentMethod={getValue('rentersPaymentMethod', store.offer.quote.global.rentersPaymentMethod)}
            paymentType={getValue('rentersPaymentType', store.offer.quote.global.rentersPaymentType)}
            termTotal={numberFormatter(option.rentersTotal, 2)}
            stale={store.isStale}
            standaloneRenters={standaloneRentersStates[store.state]}
            allowCollapsableContent
            nextPayments={prices.rentersNextPayments}
            open={showExtendedPriceInformation}
          />
        )}
        <Button variant="contained" color="primary" disabled={!factors} onClick={toggleModal}>
          <Label className={classes.button}>
            {factors ? 'See factors impacting price' : 'There are no additional factors impacting price'}
          </Label>
        </Button>
      </div>
      {showFactorsImpactingPriceModal && (
        <FactorsImpactingPrice
          open={showFactorsImpactingPriceModal}
          onClose={toggleModal}
          factors={factors}
          showHomeFactors={showHomeFactors}
          showPeopleFactors={showPeopleFactors}
          showCarsFactors={showCarsFactors}
        />
      )}
      <CardLine className={classes.footer}>
        <Switch
          id="extendedPriceInfo"
          className={classes.switchContainer}
          switchClassName={classes.switch}
          mode="dark"
          label="Show extended price information"
          value={showExtendedPriceInformation}
          onChange={toggleExtendedPriceInformation}
        />
      </CardLine>
    </Card>
  ) : null;
});

export default PriceDetailsCard;
