const styles = (theme) => ({
  header: {
    height: theme.sizes.headerHeight,
    backgroundColor: theme.colors.header__background_primary,
    justifyContent: 'center',
    display: 'block'
  },
  logo: {
    height: 48,
    marginLeft: 15
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    height: theme.sizes.headerHeight
  },
  searchContainer: {
    width: '100%'
  }
});

export default styles;
