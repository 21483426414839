import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import flowRight from 'lodash-es/flowRight';
import { Button, FormControl, Grid, LinearProgress, withTheme } from '@material-ui/core';
import { CardElement } from '@basis-theory/basis-theory-react';

import { Label } from 'core';
import { useBasisTheoryToken } from 'common/hooks/use-basis-theory-token';
import { ActionButton } from 'core/components/action-button';
import useStyles, { styles } from './credit-card-form.styles';

const CreditCardForm = memo(({ onSubmit, theme, close, buttonText }) => {
  const [errors, setErrors] = useState(undefined);
  const [completed, setCompleted] = useState(undefined);
  const [cardData, setCardData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const inlineStyles = styles(theme);
  const { cardElementId } = useBasisTheoryToken();

  const handleChange = (changeEvent) => {
    const { complete, errors, cardBrand } = changeEvent;
    setCompleted(Boolean(complete));
    setCardData({ brand: cardBrand });
    if (errors.length) {
      const errorMessages = errors.map((err) => `${err.type} ${err.targetId}`).join('. ');
      setErrors(errorMessages);
    } else {
      setErrors(undefined);
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    await onSubmit(cardData);
    setLoading(false);
  };

  return (
    <Grid direction="column" container>
      <Grid container item alignItems="center" className={classes.container}>
        <FormControl className={classes.cardContainer}>
          <Label type="coverageLabel" className={classes.label}>
            Credit Card
          </Label>
          <CardElement id={cardElementId} onChange={handleChange} style={inlineStyles.cardElementStyle} />
        </FormControl>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="secondary"
          className={classes.submit}
          disabled={loading || errors || !completed}
        >
          {buttonText}
        </Button>
        <ActionButton type="close" alt="close form" onClick={close} disabled={loading} />
      </Grid>
      {loading && <LinearProgress color="secondary" className={classes.loadingBar} />}
    </Grid>
  );
});

CreditCardForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  buttonText: PropTypes.string
};

CreditCardForm.defaultProps = {
  buttonText: 'Confirm'
};

export default flowRight(withTheme)(CreditCardForm);
