export const isLimitACPEValid = (value, parent) => {
  if (value && value !== 'NONE') {
    const { deductibleCollision, deductibleComprehensive } = parent;
    return (
      !!deductibleCollision &&
      deductibleCollision !== 'NONE' &&
      !!deductibleComprehensive &&
      deductibleComprehensive !== 'NONE'
    );
  }
  return true;
};

export function getUMPDValue(limitUMPD = 0) {
  if (!limitUMPD || limitUMPD === 'NONE' || limitUMPD.includes('/')) {
    return 0;
  }
  if (limitUMPD.includes('CSL')) {
    return Number(limitUMPD.split(' ')[0]);
  }

  // in order to compare to policyLimitBIPD, we should divide by 1000
  // policyLimitBIPD is stored like 250/500/100, which represents 250000/500000/100000
  return Number(limitUMPD) / 1000;
}

export function getPLimitValue(policyLimitBIPD = 0) {
  if (!policyLimitBIPD) {
    return 0;
  }
  if (policyLimitBIPD.includes('CSL')) {
    return Number(policyLimitBIPD.split(' ')[0]);
  }
  const splitted = policyLimitBIPD.split('/');
  if (splitted && splitted.length === 3) {
    return Number(splitted[2]);
  }
  return Number(policyLimitBIPD.split(' ')[0]);
}
