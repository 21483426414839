import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Label } from '../label';
import useStyles from './tag.styles';

const Tag = ({ children, className }) => {
  const classes = useStyles();
  return (
    <Label variant="darkGreenSmall" className={classNames(classes.tag, className)}>
      {children}
    </Label>
  );
};

Tag.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

Tag.defaultProps = {
  className: ''
};

export default Tag;
