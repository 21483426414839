import { roofSurfacesExtendedCompositionMaxAge } from '@ourbranch/lookups';

type WillHaveCoverageRSExtended = {
  roofType: string;
  roofAge: number;
  state: keyof typeof roofSurfacesExtendedCompositionMaxAge;
};

export function willHaveCoverageRSExtended({ roofType, roofAge, state }: WillHaveCoverageRSExtended) {
  return (
    (['AF', 'AR', 'C'].includes(roofType) && roofAge <= (roofSurfacesExtendedCompositionMaxAge[state] || 15)) ||
    (['SL', 'ST', 'TN', 'CL', 'CO', 'MI', 'CP', 'CP', 'M', 'T', 'S'].includes(roofType) && roofAge <= 20) ||
    roofAge <= 10
  );
}
