import React, { useCallback, useContext, useMemo, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { observer } from 'mobx-react';
import { reaction } from 'mobx';

import { Loading } from 'core';
import { useStore } from 'core/store';
import { AuthContext } from 'core/components/auth';
import { Card } from 'core/components/card';
import EditableCard from 'core/components/editable-card';
import Section from 'core/components/section';
import { useToast } from 'core/components/toast';
import AccountDetailsForm from './components/account-details-form';
import AccountDetailsDisplay from './components/account-details-display';
import useStyles from './account-details.styles';

const AccountDetail = () => {
  const { account } = useStore();
  const { canEdit } = useContext(AuthContext);
  const [editing, setEditing] = useState(false);
  const toast = useToast();
  const classes = useStyles();

  const onUpdateSuccess = useCallback(() => {
    setEditing(false);
    toast.notify({
      type: 'success',
      message: 'Account updated successfully'
    });
  }, [toast, setEditing]);

  const onUpdateFailed = useCallback(
    (errorMessage) => {
      toast.notify({
        type: 'error',
        message: `There was an error updating the account - ${errorMessage}`
      });
    },
    [toast]
  );

  const CardComponent = useMemo(() => {
    if (canEdit) {
      return EditableCard;
    }
    return Card;
  }, [canEdit]);

  let noAccount = false;
  // reaction will trigger the second time tracked variable changes
  reaction(
    () => account.accountLoading,
    (loading) => {
      if (!loading && !account.id) {
        noAccount = true;
      }
    }
  );
  if (noAccount) {
    return <Redirect to="/search/customers" />;
  }

  return (
    <Section title="Account Details">
      {account.accountLoading || !account.id ? (
        <Loading />
      ) : (
        <CardComponent className={classes.accountCard} editing={editing} setEditing={setEditing}>
          {editing ? (
            <AccountDetailsForm onSuccess={onUpdateSuccess} onError={onUpdateFailed} />
          ) : (
            <AccountDetailsDisplay />
          )}
        </CardComponent>
      )}
    </Section>
  );
};

export default observer(AccountDetail);
