import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { policyType as PolicyType } from '@ourbranch/lookups';

import { Label } from 'core';
import { Card } from 'core/components/card';
import { FormField } from 'core/components/form';
import { getExtraQuestionsConditions } from '../../helper';
import useStyles from './extra-questions.styles';

const Options = {
  Sold: {
    value: 'Yes',
    label: 'Yes'
  },
  NotSold: {
    value: 'No',
    label: 'No'
  }
};

const ExtraQuestions = observer(({ offer, isAgency }) => {
  const classes = useStyles();
  const { option: selectedOptionPolicyType } = useParams();
  const { values, setFieldValue, setFieldTouched, errors, submitCount } = useFormikContext();
  const { quote } = offer;
  const { selectedOption, fname: firstName, lname: lastName } = quote;
  const policyType = selectedOption || selectedOptionPolicyType; // check the store, if not in store, grab from url
  const { opposingPolicySoldByAgency } = getExtraQuestionsConditions(quote, policyType, isAgency);
  const options = [{ ...Options.Sold }, { ...Options.NotSold }];
  const showErrors = errors.opposingPolicySoldByAgency && submitCount > 0;

  const onChange = useCallback(
    (e) => {
      setFieldValue('opposingPolicySoldByAgency', e.target.value === Options.Sold.value);
      setFieldTouched('opposingPolicySoldByAgency');
    },
    [setFieldValue, setFieldTouched]
  );

  return opposingPolicySoldByAgency ? (
    <Card type={showErrors ? 'error' : 'primary'} className={classes.cardContainer}>
      <Label type="darkSmall" className={classNames(classes.title, { [classes.titleErrored]: showErrors })}>
        {`Has the ${
          policyType === PolicyType.Home ? 'auto' : 'home'
        } policy for ${firstName} ${lastName} also been sold through your agency?`}
      </Label>
      <div className={classes.separator} />
      <FormField
        type="radio"
        name="opposingPolicySoldByAgency"
        mode="light"
        value={
          values.opposingPolicySoldByAgency === null
            ? null
            : values.opposingPolicySoldByAgency
            ? Options.Sold.value
            : Options.NotSold.value
        }
        onChange={onChange}
        options={options}
        optionsSideBySide={false}
      />
    </Card>
  ) : (
    <></>
  );
});

ExtraQuestions.propTypes = {
  offer: PropTypes.object.isRequired,
  isAgency: PropTypes.bool.isRequired
};

export default ExtraQuestions;
