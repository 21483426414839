import { useMemo } from 'react';
import { policyType as policyTypeLookup } from '@ourbranch/lookups';

import { awsDateToStringDate } from 'core/helpers/formatters';
import getDetailedPrice from 'offer/helpers/detailed-price';
import getPlanPrice from 'offer/helpers/plan-price';
import useBillingDayOfMonthOptions from 'core/hooks/use-billing-day-of-month-options';
import { getPrimaryOrBlankMortgageDetails } from 'offer/helpers/mortgage-helpers';

const useFormInitialValues = (offer, policyType) => {
  const { homePriceNumber, autoPriceNumber, rentersPriceNumber } = getPlanPrice(offer, policyType, getDetailedPrice);
  const billingDayOptions = useBillingDayOfMonthOptions(offer, policyType);
  const mortgageDetails = getPrimaryOrBlankMortgageDetails(offer.quote.home.mortgageDetails);
  return useMemo(() => {
    const isRenters = policyType === policyTypeLookup.ARBundle;
    const { quote } = offer;
    const { email, phone, additionalPhoneNumbers } = quote;
    const {
      autoEffectiveDate,
      autoPaymentMethod,
      autoPaymentType,
      homeEffectiveDate,
      homeownersPaymentType,
      homeownersPaymentMethod,
      billingDayOfMonth: globalBillingDayOfMonth,
      homeBillingDayOfMonth,
      autoBillingDayOfMonth,
      currentAutoCarrierPolicyNumber,
      breakupWithAuto,
      currentAutoCarrier,
      breakupWithHomeowners,
      currentHomeownersCarrier,
      rentersBillingDayOfMonth,
      rentersEffectiveDate,
      rentersPaymentType,
      rentersPaymentMethod,
      breakupWithRenters
    } = quote.global;

    const billingDayOfMonth = globalBillingDayOfMonth || billingDayOptions.pop()?.id; // default to the last option

    return {
      billingDayOfMonth,
      address: isRenters ? offer.quote.rentersCoverage.rentersLocation : offer.quote.correctedAddress,
      firstName: offer.quote.fname,
      lastName: offer.quote.lname,
      email,
      phone,
      additionalPhoneNumbers,
      cardBrand: '',
      cardLast4: '',
      completeCardData: false,
      completeACHData: false,
      breakupESignature: false,
      basisTheoryCardToken: '',
      basisTheoryBankAccountToken: '',

      // Auto
      autoEffectiveDate: awsDateToStringDate(autoEffectiveDate),
      autoBillingDayOfMonth: autoBillingDayOfMonth || billingDayOfMonth,
      autoPaymentType,
      autoPaymentMethod,
      autoDownPayment: autoPriceNumber,
      attestationsAutoAccepted: null,
      breakupWithAuto: breakupWithAuto || null,
      currentAutoCarrier: currentAutoCarrier || '',
      currentAutoCarrierPolicyNumber: currentAutoCarrierPolicyNumber || '',
      drivers: [],

      // Home
      homeBillingDayOfMonth: homeBillingDayOfMonth || billingDayOfMonth,
      homeEffectiveDate: awsDateToStringDate(homeEffectiveDate),
      homeownersPaymentType,
      homeownersPaymentMethod,
      homeDownPayment: homePriceNumber,
      attestationsHomeAccepted: null,
      mortgageDetails,
      loanOfficerEmail: '',
      breakupWithHomeowners: breakupWithHomeowners || null,
      currentHomeownersCarrier: currentHomeownersCarrier || '',
      currentHomeownersCarrierPolicyNumber: '',

      // Renters
      rentersBillingDayOfMonth: rentersBillingDayOfMonth || billingDayOfMonth,
      rentersEffectiveDate: awsDateToStringDate(rentersEffectiveDate),
      rentersPaymentType,
      rentersPaymentMethod,
      rentersDownPayment: rentersPriceNumber,
      attestationsRentersAccepted: null,

      breakupWithRenters: breakupWithRenters || null,
      currentRentersCarrier: currentHomeownersCarrier || '',
      currentRentersCarrierPolicyNumber: '',

      // Payment disclosures
      authorizePayments: false,

      // Pre-Sale Checklist
      buyingHomePolicyInformed: false,
      inColoradoInformed: false,
      branchCommunicationConsent: false,
      allApplicantsConfirmed: false,
      priorInsuranceEntered: false,
      homePaymentRemindersUpdated: false,
      autoPaymentRemindersUpdated: false,
      confirmDiscounts: false,
      confirmCoverages: false,
      microDepositsACH: false,
      noWindHailInformed: false,
      earthquakeAndFloodInformed: false,
      proofOfPriorCoverageInformed: false,
      excludedDriversInformed: false,

      // Extra questions
      opposingPolicySoldByAgency: null,
      /**
       * This node is populated with data when the staff user enters checkout payment info
       * but doesn't complete the purchase (usually because the customer asks for more offer modifications)
       * because they navigated back to the MQFS route
       */
      recoveredPaymentData: null
    };
  }, [offer, policyType, billingDayOptions, homePriceNumber, autoPriceNumber, mortgageDetails]);
};

export default useFormInitialValues;
