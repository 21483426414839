import React, { useContext } from 'react';
import { Button } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useFormikContext, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import * as Yup from 'yup';

import { AuthContext } from 'core/components/auth';
import { FormField } from 'core/components/form';
import { awsDateFormatter } from 'core/helpers/formatters';
import { isValidVin } from 'core/helpers/vin-validator';
import Suggestions from 'common/components/people/suggestions/suggestions';
import useStyles from 'common/components/people/add-form.styles';

const initialValues = {
  carVIN: '',
  initialOpen: true
};

const findGarageLocation = (cars) => {
  if (!Array.isArray(cars) || !cars.length) {
    return undefined;
  }
  const [car] = cars;
  if (car) {
    return car.garageLocation;
  }
};

const handleSubmit =
  (onAdd, cars) =>
  ({ carVIN, initialOpen }) => {
    onAdd({
      VIN: carVIN.toUpperCase(),
      initialOpen,
      purchaseDate: awsDateFormatter(new Date()),
      garageLocation: findGarageLocation(cars)
    });
  };

const validationSchema = (cars) =>
  Yup.object().shape({
    carVIN: Yup.string()
      .required('VIN is required')
      .ensure()
      .test('is-valid-vin', 'Please enter a valid car VIN', (val) => isValidVin(val))
      .test('is-new-vin', 'This VIN is already included in your quote', (val) => !cars.find((car) => car.VIN === val))
  });
function AddCar({ onAdd, id, disabled }) {
  const { viewOnly } = useContext(AuthContext);
  const { values } = useFormikContext();
  const { [id]: cars } = values || {};

  const classes = useStyles();

  return (
    <Formik
      initialValues={initialValues}
      validateOnChange={false}
      validateOnBlur={false}
      validationSchema={validationSchema(cars)}
      onSubmit={handleSubmit(onAdd, cars)}
    >
      <Form className={classes.container}>
        <Suggestions cars={cars} />
        <Grid key="container" container justify="space-between" alignItems="center" spacing={2}>
          <FormField id="carVIN" name="carVIN" type="string" label={`Car's VIN Number`} mode="light" xs={6} />
          <div>
            <Button
              type="submit"
              key="addButton"
              mode="big"
              variant="contained"
              color="secondary"
              className={classes.button}
              disabled={viewOnly}
            >
              Add Car
            </Button>
          </div>
        </Grid>
      </Form>
    </Formik>
  );
}

AddCar.propTypes = {
  onAdd: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired
};

AddCar.defaultProps = {
  disabled: false
};

export default AddCar;
