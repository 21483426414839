import _isEqual from 'lodash-es/isEqual';

// whether or not it's a bix or non-bix connected home, if the following values
// are present, then the connectedHome is empty
export const empty = {
  autoWaterShutoffDevices: false,
  theftPreventionDevices: null,
  smokeDetectingDevices: null,
  moistureDevices: null
};

export const emptyConnectedHome = (connectedHome) => {
  if (!connectedHome) {
    return true;
  }
  const { autoWaterShutoffDevices, theftPreventionDevices, smokeDetectingDevices, moistureDevices } = connectedHome;
  return _isEqual(empty, {
    autoWaterShutoffDevices,
    theftPreventionDevices,
    smokeDetectingDevices,
    moistureDevices
  });
};

export const processConnectedHomeValues = (values) => {
  // If Provider is SimpliSafe and smokeDetectingDevices or moistureDevices is marked as 0, send 1 to the backend
  if (values.providerName === ProvidersName.SimpliSafe) {
    if (values.smokeDetectingDevices === 0) {
      values.smokeDetectingDevices = 1;
    }
    if (values.moistureDevices === 0) {
      values.moistureDevices = 1;
    }
  }
  return values;
};

export const ProvidersName = {
  SimpliSafe: 'SimpliSafe'
};
