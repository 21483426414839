import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { AuthContext } from '../auth';

export const ProtectedRoute = (props) => {
  const session = useContext(AuthContext);
  const { permission } = props;

  if (session.isLoggedIn && permission) {
    return <Route {...props} />;
  }
  return <Redirect to="/" />;
};

ProtectedRoute.propTypes = {
  permission: PropTypes.bool
};

ProtectedRoute.defaultProps = {
  permission: false
};
