import { clean } from './lib/clean';
import { mergeAndRemoveDuplicates } from './lib/merge';
import { policyDetailsFromSegments } from './lib/policy-details-from-segments';
import { isFuturePayment } from './lib/payments';
import { willHaveCoverageRSExtended } from './lib/will-have-coverage-rs-extended';
import { createBixConversionApplicationLink } from './lib/create-bix-conversion-application-link';
import { addCoveragesToPremiumBreakdown } from './lib/add-coverages-to-premium-breakdown';
import { samePolicyRoot, isPrecedingTerm } from './lib/policy-id-matching-utils';
import { fixHomePremiums } from './lib/fix-home-premiums';

export {
  clean,
  mergeAndRemoveDuplicates,
  policyDetailsFromSegments,
  isFuturePayment,
  willHaveCoverageRSExtended,
  createBixConversionApplicationLink,
  addCoveragesToPremiumBreakdown,
  samePolicyRoot,
  isPrecedingTerm,
  fixHomePremiums
};
