import React, { useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import PropTypes from 'prop-types';

import Section from 'core/components/section';
import { Card } from 'core/components/card';
import { dateFormatter } from 'core/helpers/formatters';
import { Table, SelectableRow } from 'core';
import { Label } from 'core/components/label';
import { NoDataCard } from '../../no-data-card';
import Five9NotesModal from './five9-notes-modal';

const getStatusLabelType = (status) => {
  return status === 'open' ? 'statusOpen' : status === 'pending' ? 'statusWarning' : 'statusClosed';
};

const Five9TicketSection = ({ tickets }) => {
  const [ticketDetailModalOpen, setTicketDetailModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState({});

  const onSelectTicket = (ticket) => {
    setTicketDetailModalOpen(true);
    setSelectedTicket(ticket);
  };

  return (
    <>
      <Section
        title="Phone Calls (Branch Internal)"
        rightLabel="total"
        rightValue={`${tickets ? tickets.length : 0} Item(s)`}
      >
        {tickets && tickets.length > 0 ? (
          <Card type="scrollable">
            <Table
              selfContained={false}
              header={
                <>
                  <TableCell>Date</TableCell>
                  <TableCell>Subject</TableCell>
                  <TableCell>Status</TableCell>
                </>
              }
              body={
                <>
                  {tickets.map((ticket, index) => (
                    <SelectableRow
                      key={`five9-data-${index}`}
                      id={`five9-data-${index}`}
                      onClick={() => onSelectTicket(ticket)}
                    >
                      <TableCell>{dateFormatter(ticket.date)}</TableCell>
                      <TableCell>{ticket.subject || 'No Subject'}</TableCell>
                      <TableCell>
                        <Label type={getStatusLabelType(ticket.status)}>{ticket.status}</Label>
                      </TableCell>
                    </SelectableRow>
                  ))}
                </>
              }
            />
          </Card>
        ) : (
          <NoDataCard />
        )}
      </Section>
      {ticketDetailModalOpen && (
        <Five9NotesModal
          ticket={selectedTicket}
          isOpen={ticketDetailModalOpen}
          onClose={() => setTicketDetailModalOpen(false)}
        />
      )}
    </>
  );
};

Five9TicketSection.propTypes = {
  tickets: PropTypes.array
};

Five9TicketSection.defaultProps = {
  tickets: null
};

export default Five9TicketSection;
