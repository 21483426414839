import React from 'react';
import { observer } from 'mobx-react';
import Grid from '@material-ui/core/Grid';

import { useStore } from 'core/store';
import { Label } from 'core/components/label';
import { Button } from 'core/components/button';
import { Card } from 'core/components/card';
import { generateZillowLink } from 'core/helpers/property-view-links';

import useStyles from '../common/verify-property-container.styles.js';

const PropertyViews = observer(() => {
  const { offer: offerStore } = useStore();

  const homeAddress = offerStore?.offer?.quote?.home?.homeLocation;

  const classes = useStyles();
  return (
    <Card type="outline" className={classes.propertyViewsCard}>
      <div className={classes.propertyViewsContainer}>
        <Label type="formLabelLight">Property</Label>
        <Label type="infoValue">{`${homeAddress?.address}, ${homeAddress?.city}, ${homeAddress?.state} ${homeAddress?.zip}`}</Label>
        <Grid container>
          <Grid>
            <Button
              rel="noreferrer noopener"
              target="_blank"
              href={`https://www.google.com/maps/place/${homeAddress?.address} ${homeAddress?.city} ${homeAddress?.state} ${homeAddress?.zip}`}
              className={classes.propertyViewButton}
              variant="contained"
              color="secondary"
            >
              View property on Google Maps
            </Button>
          </Grid>
          <Grid>
            <Button
              rel="noreferrer noopener"
              target="_blank"
              href={generateZillowLink(homeAddress)}
              className={classes.propertyViewButton}
              variant="contained"
              color="secondary"
            >
              View property on Zillow
            </Button>
          </Grid>
          <Grid>
            <Button
              rel="noreferrer noopener"
              target="_blank"
              href={`https://earth.google.com/web/search/=${homeAddress.address} ${homeAddress.city} ${homeAddress.state} ${homeAddress.zip}`}
              className={classes.propertyViewButton}
              variant="contained"
              color="secondary"
            >
              View property on Google Earth
            </Button>
          </Grid>
        </Grid>
      </div>
    </Card>
  );
});

export default PropertyViews;
