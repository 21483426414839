import { useEffect } from 'react';
import { useStore } from 'core/store';
import { useHistory } from 'react-router-dom';

export const useCustomerProvider = ({ accountId, policyId }) => {
  const store = useStore();
  const { account } = store;
  const history = useHistory();

  /* @TODO I think there is a better way to do this with fragments that allow us to decouple the querying behavior with the component rendering.
  This could be especially useful and efficient for us because the queries could be constructed dynamically, based on which components are in the tree
  You could also add new data to queries simply by updating the fragment on an existing component.
  */

  // accountId changes, clear everything stored
  useEffect(() => {
    if (account.id && account.id !== accountId) {
      store.resetStore();
    }
  }, [store, account.id, accountId]);

  useEffect(() => {
    if (!account.id && policyId) {
      // no account, no policy, on policy page
      account.fetchFullAccountAndPolicy(policyId, accountId, history);
    } else if (!account.id) {
      // no account, on account summary page
      account.fetchFullAccount(accountId, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountId, policyId, account]);

  useEffect(() => {
    if (
      (account.id && policyId) ||
      (!account.accountLoading && policyId && policyId !== account.policies.policy.policy?.id)
    ) {
      // load full policy after being on the account summary page
      account.fetchFullAccountAndPolicy(policyId, accountId, history);
    } else if (account.id) {
      // load policies list after being on the policies page
      account.policies.getAccountPolicies(accountId, history);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId, accountId, account]);
};
