export const policyRebillSchedule = [
  {
    rebillAttemptDayCount: 1,
    rebillAttemptNumber: '1 of 1',
    todayIsARebillDay: true
  },
  {
    rebillAttemptDayCount: 2,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 3,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 4,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 5,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 6,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 7,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 8,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 9,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 10,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 11,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 12,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 13,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 14,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 15,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 16,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 17,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 18,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 19,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 20,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 21,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 22,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 23,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 24,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 25,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 26,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 27,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 28,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 29,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 30,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  },
  {
    rebillAttemptDayCount: 31,
    rebillAttemptNumber: null,
    todayIsARebillDay: false
  }
];
