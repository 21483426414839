import { makeStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  tag: {
    alignItems: 'center',
    backgroundColor: theme.colorPalette.beige_10,
    borderRadius: theme.spacing(8),
    fontSize: 12,
    fontWeight: 600,
    height: 24,
    padding: theme.spacing(1, 2),
    width: 'fit-content'
  }
});

export default makeStyles(styles);
